import { Colors, DAY_IN_MS, DeepWriteable } from "@kalyzee/kast-app-web-components";
import { OvenMediaEmitterWebRTCSessionOptions } from "@kalyzee/kast-webrtc-client-module";
import objectAssignDeep from "object-assign-deep";
import { MeetingSource, MeetingSourceDeviceType } from "../components/MeetingSource";
import { DeepRequired } from "../interfaces/utils";
import { appMessage } from "./message";
import { RTCBridgeTransmissionMode } from "./rtcBridgeMediaSession";
import { getSettings, setSettings } from "./storage";

export const DEFAULT_SETTINGS_SOURCES: MeetingSource[] = [
  {
    name: "Web",
    devices: [
      {
        mode: "audiovideo",
        type: MeetingSourceDeviceType.WEB,
        data: {},
      },
    ],
  },
];

export const DEFAULT_ASPECT_VIDEO_RATIO_TARGET = 16 / 9;

export const DEFAULT_SETTINGS_PLAYER = {
  volumeEffects: {
    enabled: true,
    color: Colors.getMountainMeadow(),
    size: 8,
    samplingInterval: 50,
    volumeThreshold: 80,
  },
};

export const DEFAULT_SETTINGS_MASTER = {
  bridgeMessages: {
    enableBroadcast: false,
  },
  pages: {
    meeting: {
      header: {
        hide: false,
        helpUri: undefined,
        clock: {
          enabled: true,
          seconds: true,
        },
      },
      debug: false,
      autoJoin: false,
      aspectVideoRatioTarget: DEFAULT_ASPECT_VIDEO_RATIO_TARGET,
      hideSourcesByDefault: true,
      disableAudioByDefault: false,
      disableVideoByDefault: false,
      displayMeOnly: false,
      disableTheExclusionOfUser: false,
      displayVideoOnlyOnSpectatorPage: false,
      alwaysDisplayButtonToDisplayOnWhiteboard: false,
      media: {
        internalTransmission: RTCBridgeTransmissionMode.PREFER_WEBCODECS,
      },
      stats: {
        publishOutputStreams: true,
        publishInputStreams: false,
      },
      sources: DEFAULT_SETTINGS_SOURCES,
      output: {
        pushOnRtmpServer: null,
        videoMediaContentHint: "",
        videoRtcEncoding: {},
        videoRtcParams: {
          degradationPreference: "balanced",
        },
        videoMediaConstrains: {
          echoCancellation: true,
        },
        audioMediaContentHint: "speech",
        audioRtcEncoding: {},
        audioRtcParams: {},
        audioMediaConstrains: {
          echoCancellation: true,
        },
        ovenMediaSessionOptions: undefined,
      },
      whiteboard: {
        enabled: true,
        id: undefined,
        name: undefined,
        isMaster: false,
        followMasters: false,
        hideNewIndicatorAfterDelay: 7 * DAY_IN_MS,
        showNewVersionsOnFirstUse: false,
        invisible: false,
        spectator: false,
      },
      notification: {
        newUser: false,
        newDevice: false,
        disconnect: false,
      },
      player: DEFAULT_SETTINGS_PLAYER,
      chat: {
        enabled: true,
        soundEffect: true,
      }
    },
    whiteboard: {
      debug: false,
      whiteboard: {
        autojoin: true,
        id: undefined,
        name: undefined,
        isMaster: false,
        followMasters: false,
        hideNewIndicatorAfterDelay: 7 * DAY_IN_MS,
        showNewVersionsOnFirstUse: false,
        invisible: false,
        spectator: false,
      },
    },
  },
} as const;

export const DEFAULT_SETTINGS_SLAVE = {
  bridgeMessages: {
    enableBroadcast: false,
    enableWebsocket: false,
    websocketEndpoint: "ws://localhost:8888",
  },
  pages: {
    spectator: {
      header: {
        hide: false,
        helpUri: undefined,
        clock: {
          enabled: true,
          seconds: false,
        },
      },
      debug: false,
      aspectVideoRatioTarget: DEFAULT_ASPECT_VIDEO_RATIO_TARGET,
      displayMe: false,
      loadMediaLocally: true,
      media: {
        internalTransmission: RTCBridgeTransmissionMode.PREFER_WEBCODECS,
      },
      whiteboard: {
        enabled: true,
        id: undefined,
        name: undefined,
        isMaster: false,
        followMasters: false,
        hideNewIndicatorAfterDelay: 7 * DAY_IN_MS,
        showNewVersionsOnFirstUse: false,
        invisible: false,
        spectator: false,
      },
      player: DEFAULT_SETTINGS_PLAYER,
    },
    whiteboard: {
      header: {
        hide: true,
        helpUri: undefined,
        clock: {
          enabled: true,
          seconds: false,
        },
      },
      debug: false,
      controls: true,
      hideControlsByDefault: true,
      displayPlayerAsBackground: {
        enabled: false,
        autoHideWhiteboardControlsAfterDelay: 5000,
        autoHideMeetingControlsAfterDelay: 5000,
      },
      whiteboard: {
        id: undefined,
        name: undefined,
        isMaster: false,
        followMasters: false,
        hideNewIndicatorAfterDelay: 7 * DAY_IN_MS,
        showNewVersionsOnFirstUse: false,
        invisible: false,
        spectator: false,
      },
    },
    controls: {
      header: {
        hide: false,
        helpUri: undefined,
        clock: {
          enabled: true,
          seconds: false,
        },
      },
      debug: false,
    },
  },
} as const;

export const DEFAULT_SETTINGS = {
  autoJoinMeeting: undefined,
  favorites: undefined,
  master: DEFAULT_SETTINGS_MASTER,
  slave: DEFAULT_SETTINGS_SLAVE,
  secret: undefined,
  extra: undefined,
} as const;

export interface SettingsWhiteboard {
  autojoin?: boolean;
  id?: string;
  name?: string;
  isMaster?: boolean;
  followMasters?: boolean;
  invisible?: boolean;
  spectator?: boolean;
  hideNewIndicatorAfterDelay?: number;
  showNewVersionsOnFirstUse?: boolean;
}

export interface SettingsChat {
  enabled?: boolean;
  soundEffect?: boolean;
}

export interface SettingsPlayer {
  volumeEffects?: {
    enabled?: boolean;
    color?: string;
    size?: number;
    samplingInterval?: string;
    volumeThreshold?: number;
  };
}

export interface SettingsSecret {
  localPassword?: string | null;
  remotePassword?: string | null;
}

export interface SeetingsMessagingBridgeMaster {
  enableBroadcast?: boolean;
}

export interface SeetingsMessagingBridgeSlave {
  enableBroadcast?: boolean;
  enableWebsocket?: boolean;
  websocketEndpoint?: string;
}

export interface SettingsMaster {
  bridgeMessages?: SeetingsMessagingBridgeMaster;
  pages?: {
    meeting?: SeetingsMeetingMasterPage;
    whiteboard?: SettingsWhiteboardMasterPage;
  };
}

export interface SettingClock {
  enabled?: boolean;
  seconds?: boolean;
}

export interface SettingsPage {
  debug?: boolean;
  header?: {
    hide?:boolean;
    helpUri?: string;
    clock?: SettingClock;
  }
}

export interface SettingsSlave {
  bridgeMessages?: SeetingsMessagingBridgeSlave;
  pages?: {
    spectator?: SettingsSpectatorSlavePage;
    controls?: SettingsControlsSlavePage;
    whiteboard?: SettingsWhiteboardSlavePage;
  };
}

export interface SettingsDebug {
  pushMedia?: {
    interceptor?: {
      enabled?: boolean;
      preview?: boolean;
      video?: {
        simulatePacketLoss?: number;
        logs?: boolean;
      };
      audio?: {
        simulatePacketLoss?: number;
        logs?: boolean;
      };
    };
  };
}

export interface Settings {
  autoJoinMeeting?: string; // meeting id
  favorites?: { id: string; name?: string }[];
  whiteboardFavorites?: { id: string; name?: string }[];
  adminPin?: string;
  master?: SettingsMaster;
  slave?: SettingsSlave;
  secret?: SettingsSecret;
  extra?: any;
  debug?: SettingsDebug;
}

export interface SeetingsMeetingMasterPage extends SettingsPage {
  autoJoin?: boolean;
  hideSourcesByDefault?: boolean;
  disableAudioByDefault?: boolean;
  disableVideoByDefault?: boolean;
  displayVideoOnlyOnSpectatorPage?: boolean;
  alwaysDisplayButtonToDisplayOnWhiteboard?: boolean;
  aspectVideoRatioTarget?: number;
  displayMeOnly?: boolean;
  disableTheExclusionOfUser?: boolean;
  media?: SettingsMeetingtMedia;
  stats?: SettingsMeetingStats;
  sources?: MeetingSource[];
  output?: SettingsMeetingtOutputMedia;
  whiteboard?: { enabled: boolean } & SettingsWhiteboard;
  notification?: SettingsMeetingNotification;
  player?: SettingsPlayer;
  chat?: SettingsChat;
}

export interface SettingsWhiteboardMasterPage extends SettingsPage {
  whiteboard: SettingsWhiteboard;
}

export interface SettingsMeetingStats {
  publishOutputStreams?: boolean;
  publishInputStreams?: boolean;
}

export interface SeetingsMeetingBridgeMessage {
  enableBroadcast?: boolean;
}

export interface SettingsMeetingtMedia {
  internalTransmission?: RTCBridgeTransmissionMode;
}

export interface SettingsMeetingtOutputMedia {
  pushOnRtmpServer?: string | { url: string; key: string } | (string | { url: string; key: string })[] | null;
  sdpOfferFormatterPipeline?: string;
  sdpAnswerFormatterPipeline?: string;
  videoMediaContentHint?: "" | "motion" | "detail" | "text";
  audioMediaContentHint?: "" | "speech" | "speech-recognition" | "music";
  videoMediaConstrains?: Partial<MediaTrackConstraints>;
  audioMediaConstrains?: Partial<MediaTrackConstraints>;
  videoRtcParams?: Partial<Omit<RTCRtpSendParameters, "encodings">>;
  audioRtcParams?: Partial<Omit<RTCRtpSendParameters, "encodings">>;
  videoRtcEncoding?: Partial<RTCRtpEncodingParameters>;
  audioRtcEncoding?: Partial<RTCRtpEncodingParameters>;
  ovenMediaSessionOptions?: OvenMediaEmitterWebRTCSessionOptions;
}

export interface SettingsMeetingNotification {
  newUser?: boolean;
  newDevice?: boolean;
  disconnect?: boolean;
}

export interface SettingsSpectatorSlavePage extends SettingsPage {
  aspectVideoRatioTarget?: number;
  displayMe?: boolean;
  loadMediaLocally?: boolean;
  media?: SettingsMeetingtMedia;
  whiteboard?: { enabled: boolean } & SettingsWhiteboard;
  player?: SettingsPlayer;
}

export interface SettingsWhiteboardSlavePage extends SettingsPage {
  controls?: boolean;
  hideControlsByDefault?: boolean;
  displayPlayerAsBackground?: {
    enabled?: boolean,
    autoHideWhiteboardControlsAfterDelay?: number,
    autoHideMeetingControlsAfterDelay?: number,
  },
  whiteboard: SettingsWhiteboard;
}

export interface SettingsControlsSlavePage extends SettingsPage {
}

let settings: Settings | undefined = undefined;
export const loadSettings = (): Settings => {
  if (settings) return settings;
  let data: object = {};
  try {
    const settingsStr: string | null =  getSettings();
    if (settingsStr) data = JSON.parse(settingsStr);
    else setSettings(JSON.stringify({}));
  } catch (err) {
    data = {};
  }
  const result: Settings = objectAssignDeep({}, data);
  settings = result;
  return result;
};

type RecKeys = string | { key: string; keys: RecKeys[] };
const findRecursiveValue = (obj: any, value: any): RecKeys[] => {
  if (!obj) return [];
  if (typeof obj !== "object") return [];
  const keys: RecKeys[] = [];
  Object.keys(obj).forEach((k) => {
    if (obj[k] === value) keys.push(k);
    else if (typeof obj === "object") {
      const childKeys = findRecursiveValue(obj[k], value);
      if (childKeys.length) keys.push({ key: k, keys: childKeys });
    }
  });
  return keys;
};

const setRecursiveValue = (obj: any, keys: RecKeys[], value: any) => {
  if (!obj) return [];
  if (typeof obj !== "object") return [];
  keys.forEach((k) => {
    if (typeof k === "string") {
      obj[k] = value;
    } else {
      setRecursiveValue(obj[k.key], k.keys, value);
    }
  });
  return obj;
};

export const mergeSettings = (settings: Partial<Settings>): Settings => {
  const currSettings = loadSettings();
  const result: Settings = objectAssignDeep(currSettings, settings);
  const recursiveKeys: RecKeys[] = findRecursiveValue(settings, "undefined");
  setRecursiveValue(result, recursiveKeys, undefined);
  return result;
};

// Set value only if not defined yet
export const setSettingsAsDefault = (settings: Partial<Settings>): Settings => {
  const currSettings = loadSettings();
  if (!settings) return currSettings;

  const assign = (target: any, source: any): any => {
    if (target === undefined) {
      // assign value as default
      return source;
    }
    if (source === undefined) return target;
    if (typeof source === "object") {
      Object.keys(source).forEach((k) => {
        target[k] = assign(target[k], source[k]);
      });
    }
    return target;
  };

  const result: Settings = assign(currSettings, settings);
  try {
    setSettings(JSON.stringify(result))
    appMessage.postSettings(result);
  } catch (err) {}
  return result;
};

export const saveSettings = (settings: Partial<Settings>): Settings => {
  const result: Settings = mergeSettings(settings);
  try {
    setSettings(JSON.stringify(result))
    appMessage.postSettings(result);
  } catch (err) {}
  return result;
};

import { CompatibilityInfo as CompatibilityInfoApiRest, UserOutputDTO } from '@kalyzee/kast-app-module';
import { CompatibilityInfo as CompatibilityInfoWebsocketServer } from '@kalyzee/kast-websocket-module'; 
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logger } from '../../helpers/logger';
import { CompatibilityInfos } from '../../helpers/request';
import { getToken as getLocalStorageToken, removeToken as removeLocalStorageToken, setToken as setLocalStorageToken, TokenType } from '../../helpers/storage';
import { AuthStatus } from '../../interfaces/session';


export interface Screen {
  width: number,
  height: number,
  x: number,
  y: number,
  label?: string,
  main: boolean,
}

export enum SessionMode {
  MASTER = 'master',
  SLAVE = 'slave'
}

export interface SessionState {
  user?: UserOutputDTO;
  token: string | null,
  refreshToken: string | null,
  isRefreshingToken: boolean,
  authStatus: AuthStatus,
  screen?: Screen,
  mode: SessionMode,
  compatibilityInfos: Partial<CompatibilityInfos>
}

const initialState: SessionState = {
  user: undefined,
  token: getLocalStorageToken(),
  refreshToken: getLocalStorageToken(TokenType.Refresh),
  isRefreshingToken: false,
  authStatus: getLocalStorageToken() ? AuthStatus.Loading : AuthStatus.Out,
  screen: undefined,
  mode: SessionMode.MASTER,
  compatibilityInfos: {},
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{token: string, refreshToken?: string}>) => {
      setLocalStorageToken(action.payload.token, TokenType.Normal);
      state.token = action.payload.token;
      if (action.payload.refreshToken) {
        setLocalStorageToken(action.payload.refreshToken, TokenType.Refresh);
        state.refreshToken = action.payload.refreshToken
      }
      state.authStatus = AuthStatus.In;
    },
    setUser: (state, action: PayloadAction<UserOutputDTO>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setIsRefreshingToken: (state, action: PayloadAction<boolean>) => {
      state.isRefreshingToken = action.payload;
    },
    setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.authStatus = action.payload;
    },
    setScreen: (state, action: PayloadAction<Screen>) => {
      state.screen = action.payload;
    },
    setMode: (state, action: PayloadAction<SessionState['mode']>) => {
      state.mode = action.payload;
    },
    reset: (state) => {
      state.token = getLocalStorageToken();
      state.refreshToken = getLocalStorageToken(TokenType.Refresh);
      state.isRefreshingToken = false;
      state.authStatus = getLocalStorageToken() ? AuthStatus.Loading : AuthStatus.Out;
    },
    logout: (state) => {
      logger.log("[LOGOUT] : on logout action");
      state.token = null;
      state.refreshToken = null;
      state.isRefreshingToken = false;
      state.authStatus = AuthStatus.Out;
      removeLocalStorageToken(TokenType.Normal);
      removeLocalStorageToken(TokenType.Refresh)
    },
    setApiRESTCompatibilityInfo: (state, action: PayloadAction<CompatibilityInfoApiRest>) => {
      state.compatibilityInfos.apiREST = action.payload;
    },
    setWebsocketServerCompatibilityInfo: (state, action: PayloadAction<CompatibilityInfoWebsocketServer>) => {
      state.compatibilityInfos.websocketServer = action.payload;
    },
  },
});

export const {
  login,
  setUser,
  setToken,
  setRefreshToken,
  setIsRefreshingToken,
  setAuthStatus,
  setScreen,
  setMode,
  reset,
  logout,
  setApiRESTCompatibilityInfo,
  setWebsocketServerCompatibilityInfo,
} = sessionSlice.actions;

export default sessionSlice.reducer;

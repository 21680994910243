import {
  Chat,
  ChatRef,
  Colors,
  delay,
  HoverMessage,
  Loading,
  OverlayHoverMessage,
  PopupButtonType,
  PopupIconType,
  showPopup,
  Touchable,
} from "@kalyzee/kast-app-web-components";
import {
  IAudioDecoderConfig,
  IEncodedAudioChunkInput,
  IEncodedVideoChunkInput,
  IMediaStreamTrackGenerator,
  IRTCRtpSenderEncodedStreams,
  IVideoDecoderConfig,
  MediaStreamAudioDecoder,
  MediaStreamVideoDecoder,
  OvenMediaEmitterWebRTCSession,
  OvenMediaEmitterWebRTCSessionOptions,
  SdpFormatter,
  sdpLineParser,
  WebRTCSessionState,
} from "@kalyzee/kast-webrtc-client-module";
import {
  ApiErrorCode,
  ChatroomStatus,
  Meeting,
  MeetingGetByIdRequest,
  MeetingGetByIdResult,
  MeetingJoinRequest,
  MeetingJoinResult,
  MeetingLeaveResult,
  MeetingOnExcludeEvent,
  MeetingOnJoinEvent,
  MeetingOnLeaveEvent,
  MeetingSession,
  MeetingSessionMedia,
  MeetingSessionMediaState,
  MeetingSessionMediaStats,
  MeetingSessionMediaUpdateRequest,
  MeetingSessionOnCustomEvent,
  MeetingSessionOnStatsEvent,
  MeetingSessionPushStatsResult,
  MeetingSessionRole,
  MeetingSessionStats,
  MeetingSessionUpdateMedia,
  MeetingSessionUpdateRequest,
} from "@kalyzee/kast-websocket-module";
import { debounce } from "lodash";
import objectAssignDeep from "object-assign-deep";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import ImgBackground from "../../assets/backgrounds/kast.png";
import { ReactComponent as IconClose } from "../../assets/icons/close.svg";
import { ReactComponent as IconViewer } from "../../assets/icons/icon-view.svg";
import { ReactComponent as IconMeetingPrivate } from "../../assets/icons/locked.svg";
import { ReactComponent as IconMeeting } from "../../assets/icons/meeting.svg";
import { ReactComponent as IconMeetingPublic } from "../../assets/icons/unlocked.svg";
import { ReactComponent as IconMeetingUsers } from "../../assets/icons/users.svg";
import { ReactComponent as IconWhiteboard } from "../../assets/icons/whiteboard.svg";
import ChatButton, { ChatButtonRef } from "../../components/ChatButton";
import MeetingHeader from "../../components/MeetingHeader";
import MeetingPlayer, { MeetingPlayerRef, MeetingPlayerRemoteButton } from "../../components/MeetingPlayer";
import MeetingPlayers, { MeetingPlayersDisplayMode } from "../../components/MeetingPlayers";
import MeetingSessionContainer from "../../components/MeetingSessionContainer";
import { MeetingSourceData, MeetingSourceDevice, MeetingSourceDeviceType } from "../../components/MeetingSource";
import MeetingSources, { MeetingSourcesRef } from "../../components/MeetingSources";
import { PageMasterMode, PageSlaveMode, usePageContextRef } from "../../components/navigation/PageContext";
import NetworkLevel, { NetworkLevelRef } from "../../components/NetworkLevel";
import VideoStats, { VideoStatsRef } from "../../components/VideoStats";
import Whiteboard, { WhiteboardConditionalAutojoin } from "../../components/Whiteboard";
import { useMessagingBridgeManagerMasterContext } from "../../contexts/messageBridgeManager";
import { Debug } from "../../helpers/debug";
import { logger } from "../../helpers/logger";
import { applyMediaConstraints, ApplyMediaConstraintsOptions } from "../../helpers/media";
import { canExcludeUsers, isSpectator, isSuperUser } from "../../helpers/meeting";
import {
  isMeetingControl,
  isMessagingBridgeClientData,
  MeetingControlAction,
  MeetingControlContent,
  MessagingBridgeManagerMasterClient,
} from "../../helpers/messagingBridge";
import {
  assignParamsOnRTCPeerConnection,
  AssignParamsOnRTCPeerConnectionParams,
  getRTCStreamStats,
  refreshMediaOnRTCPeerConnection,
  RefreshMediaOnRTCPeerConnectionOptions,
  RTCStreamStats,
  RTCStreamStatsResult,
} from "../../helpers/rtc";
import { DEFAULT_SETTINGS } from "../../helpers/settings";
import { BACKGROUND_COMPONENT_CLASSNAME } from "../../helpers/styles";
import { toastError, toastInfo, toastSuccess } from "../../helpers/toast";
import { useAppDispatch, useSocketAppDispatch } from "../../hooks/app";
import { useChat } from "../../hooks/chat";
import { useMeetingSessionMediaUpdaterRef, useMeetingSessionUpdaterRef, useMeetingSources } from "../../hooks/meeting";
import { useSettings } from "../../hooks/settings";
import { useSocket } from "../../hooks/socket";
import { useReduxAction } from "../../hooks/store";
import { MeetingSessionClients, MeetingSessionExtra, MeetingSessionMediaExtra } from "../../interfaces/meeting";
import { SocketStatus } from "../../interfaces/socket";
import { DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT } from "../../interfaces/whiteboard";
import socketMeetingActions from "../../store/meeting/actions";
import { useMeeting, useMeetingMediaDestinations, useMeetingSession } from "../../store/meeting/hooks";
import { cleanMeeting, updateSessionMediaState } from "../../store/meeting/slices";
import { SessionMode } from "../../store/session/slices";
import styles from "./meeting.module.css";

type OvenMediaEmitterWebRTCSessionData = { media: MediaStream | undefined; mediaId: MeetingMediaId };

const INTERVAL_GET_RTC_STATS = 5000;
declare const EncodedVideoChunk: any;
declare const MediaStreamTrackProcessor: any;
declare const MediaStreamTrackGenerator: any;
declare const VideoEncoder: any;
declare const RTCRtpScriptTransform: any;

export enum MeetingTab {
  MAIN = "main",
  WHITEBOARD = "whiteboard",
}

type MeetingMediaId = string;
type MeetingSessionId = string;

export interface MeetingExtraData {
  fullscreenForMediaId?: MeetingMediaId;
  canExcludeUsers: boolean;
  displayVideoOnlyOnSpectatorPage: boolean;
  clients: MeetingSessionClients;
  sources?: { id: number; name: string; hidden: boolean; using: boolean; audio?: MeetingSourceDevice; video?: MeetingSourceDevice }[];
  audio: {
    [key: MeetingMediaId]: {
      volume: number;
      muted: boolean;
    };
  };
  canBeDisplayedOnWhiteboard?: MeetingMediaId[];
}

export const defaultMeetingExtraData = {
  fullscreenForMediaId: undefined,
  canExcludeUsers: false,
  displayVideoOnlyOnSpectatorPage: false,
  clients: {},
  audio: {},
} as const;

const autoJoinInQueries = () => {
  const url = new URL(window.location.href);
  if (url.searchParams.get("autoJoin") === "true") return true;
  return false;
};

const MeetingPage = () => {
  const socketDispatch = useSocketAppDispatch();
  const dispatch = useAppDispatch();
  const { socketStatus } = useSocket();
  const pageContextRef = usePageContextRef();
  const meeting = useMeeting();
  const session = useMeetingSession();
  const meetingRef = useRef<Meeting | undefined>(undefined);
  const sessionRef = useRef<MeetingSession<true, MeetingSessionExtra> | undefined>(undefined);
  const mediaDestinations = useMeetingMediaDestinations();
  const meetingSourcesRef = useRef<MeetingSourcesRef>();
  const meetingSourcesIsOpenedOnMainTabRef = useRef<boolean>(true);
  const ovenMediaWebrtcSessionRef = useRef<OvenMediaEmitterWebRTCSession<OvenMediaEmitterWebRTCSessionData> | undefined>(undefined);
  const [mediasMeetingPlayersRefs] = useState<{ current: Map<MeetingMediaId, MeetingPlayerRef | undefined> }>({ current: new Map() });
  const [networkLevelRefs] = useState<{ current: Map<MeetingSessionId, NetworkLevelRef | undefined> }>({ current: new Map() });
  const mediaIsReadyRef = useRef(false);
  const videoStatsRef = useRef<VideoStatsRef>();
  const [settings] = useSettings();
  const pageSettings = settings.master?.pages?.meeting;
  const DEFAULT_PAGE_SETTINGS = DEFAULT_SETTINGS.master.pages.meeting;
  const [tab, setTab] = useState<MeetingTab>(MeetingTab.MAIN);
  const bridgeMessageManager = useMessagingBridgeManagerMasterContext();
  const bridgeMessageManagerRef = useRef(bridgeMessageManager);
  const [bridgeClients, setBridgeClients] = useState<MessagingBridgeManagerMasterClient<any, any>[]>([]);
  const [extraData, setExtraData] = useState<MeetingExtraData>(objectAssignDeep({}, defaultMeetingExtraData));
  const extraDataRef = useRef<MeetingExtraData>(extraData);
  const [localStatsResultRef] = useState<{ current: Map<MeetingMediaId, RTCStreamStatsResult> }>(() => ({ current: new Map() }));
  const [remoteStatsRef] = useState<{ current: Map<MeetingSessionId, MeetingSessionStats> }>(() => ({ current: new Map() }));
  const meetingSessionUpdater = useMeetingSessionUpdaterRef(meeting?.id);
  const meetingSessionMediaUpdater = useMeetingSessionMediaUpdaterRef(meeting?.id);
  const meetingSources = useMeetingSources();
  const [displayChat, setDisplayChat] = useState(false);
  const chatButtonRef = useRef<ChatButtonRef>();
  const chatRef = useRef<ChatRef>(null);
  const chatAdapter = useChat(meeting, chatRef, chatButtonRef, { soundEffect: pageSettings?.chat?.soundEffect ?? DEFAULT_PAGE_SETTINGS.chat.soundEffect });
  const pageIsDestroyedRef = useRef(false);
  const id = pageContextRef.current.params?.id ?? "";

  const getActiveWhiteboardShortdId = (): string | undefined => {
    if (!meeting) return undefined;
    const activeWhiteboardResult = meeting.whiteboards?.filter((w) => w.id === meeting.activeWhiteboardId);
    const activeWhiteboard = activeWhiteboardResult?.length ? activeWhiteboardResult[0] : undefined;
    return activeWhiteboard?.shortId;
  };
  const displayTab = session && (!isSpectator(session?.role) || isSuperUser(session?.role)) && (pageSettings?.whiteboard?.enabled ?? DEFAULT_PAGE_SETTINGS.whiteboard.enabled) && getActiveWhiteboardShortdId();

  const [ready, setReady] = useState((pageSettings?.autoJoin ?? DEFAULT_PAGE_SETTINGS.autoJoin) || autoJoinInQueries());
  const [meetingBeforeJoin, setMeetingBeforeJoin] = useState<Meeting | undefined>(undefined);

  const getDefaultMuteValue = (s: MeetingSession, m: MeetingSessionMedia<MeetingSessionMediaExtra>) => {
    if (!sessionRef.current) return true;
    const me = s.id === sessionRef.current.id;
    return me ? true : false;
  };

  const getDefaultVolumeValue = (s: MeetingSession, m: MeetingSessionMedia<MeetingSessionMediaExtra>) => {
    return 1;
  };

  const broadcastMeeting = useCallback(
    debounce(
      () => {
        if (!bridgeMessageManager) return;
        bridgeMessageManager.postMeeting(meetingRef.current, sessionRef.current);
      },
      100,
      { maxWait: 300 }
    ),
    [bridgeMessageManager]
  );

  const broadcastExtraData = useCallback(
    debounce(
      () => {
        if (!bridgeMessageManager) return;
        bridgeMessageManager.postMeetingExtraData(extraDataRef.current);
      },
      100,
      { maxWait: 300 }
    ),
    [bridgeMessageManager]
  );

  const updateExtraData = (data: Partial<MeetingExtraData>) => {
    const newExtraData = Object.assign({}, extraDataRef?.current ?? {}, data);
    setExtraData(newExtraData);
  };

  const getLocalMediaStats = useCallback(
    (session: MeetingSession, mode: "input" | "output" | "both" = "output"): (RTCStreamStats & { mediaId: string })[] => {
      const medias = session.medias;
      if (!medias) return [];
      const result: (RTCStreamStats & { mediaId: string })[] = [];
      medias.forEach((m) => {
        const mediaStatsResult = localStatsResultRef.current.get(m.id);
        const mediaStats = mediaStatsResult?.stats;
        if (mediaStats) {
          if (mode === "both" || mode === mediaStats.mode) result.push({ ...mediaStats, mediaId: m.id });
        }
      });
      return result;
    },
    [localStatsResultRef]
  );

  const getRemoteMediaStats = useCallback(
    (session: MeetingSession, mode: "input" | "output" | "both" = "output"): MeetingSessionMediaStats[] => {
      const medias = session.medias;
      if (!medias) return [];
      const sessionStats = remoteStatsRef.current.get(session.id);
      if (!sessionStats) return [];
      const result: MeetingSessionMediaStats[] = [];
      medias.forEach((m) => {
        const mediaStats = sessionStats.medias?.find((curr) => curr.mediaId === m.id);
        if (mediaStats) {
          if (mode === "both" || mode === mediaStats.mode) result.push(mediaStats);
        }
      });
      return result;
    },
    [remoteStatsRef]
  );

  const refreshNetworkLevels = useCallback(
    (sessionId?: string) => {
      const sessions = meeting?.sessions;
      if (!sessions || !session) return;
      sessions.forEach((s) => {
        if (sessionId && sessionId !== s.id) return;
        const me = session.id === s.id;
        const networkLevelRef = networkLevelRefs.current.get(s.id);
        if (!networkLevelRef) return;
        const stats = me ? getLocalMediaStats(s, "both") : [...getLocalMediaStats(s, "both"), ...getRemoteMediaStats(s, "both")];
        networkLevelRef.updateStats(stats);
      });
    },
    [meeting, session, networkLevelRefs, getLocalMediaStats, getRemoteMediaStats]
  );

  const manageMeetingControl = async <T extends MeetingControlAction>(content: MeetingControlContent<T>): Promise<boolean> => {
    if (isMeetingControl(content, MeetingControlAction.AUDIO)) {
      const { data } = content;
      const audio = extraDataRef.current.audio ?? {};
      audio[data.mediaId] = { volume: data.volume, muted: data.muted };
      updateExtraData({ audio });
      return true;
    }
    if (isMeetingControl(content, MeetingControlAction.MEDIA)) {
      const { data } = content;
      const playerRef = mediasMeetingPlayersRefs.current.get(data.mediaId);
      if (!playerRef) return false;
      const updateSessionMediaId: Omit<MeetingSessionUpdateMedia, "id"> = {};
      let updateAudio = false;
      let updateVideo = false;
      if (data.audio !== undefined) {
        playerRef.enableAudio(data.audio);
        updateSessionMediaId.audio = { enabled: data.audio };
        updateAudio = true;
      }
      if (data.video !== undefined) {
        playerRef.enableVideo(data.video);
        updateSessionMediaId.video = { enabled: data.video };
        updateVideo = true;
      }
      let success = false;
      try {
        if (updateAudio) playerRef?.audioButtonLoading(true);
        if (updateVideo) playerRef?.videoButtonLoading(true);
        await updateSessionMediaById(data.sessionId, data.mediaId, updateSessionMediaId);
        success = true;
      } catch (err) {
      } finally {
        if (updateAudio) playerRef?.audioButtonLoading(false);
        if (updateVideo) playerRef?.videoButtonLoading(false);
      }
      return success;
    }
    if (isMeetingControl(content, MeetingControlAction.REACTIONS)) {
      const { data } = content;
      const playerRef = mediasMeetingPlayersRefs.current.get(data.mediaId);
      if (!playerRef) return false;
      playerRef.setReactions(data.reactions);
      let success = false;
      try {
        playerRef?.reactionButtonLoading(true);
        await updateSession(data.sessionId, { reactions: data.reactions });
        success = true;
      } catch (err) {
      } finally {
        playerRef?.reactionButtonLoading(false);
      }
      return success;
    }
    if (isMeetingControl(content, MeetingControlAction.EXCLUDE)) {
      const { data } = content;
      const meetingId = meetingRef.current?.id;
      const session = meeting?.sessions?.find((s) => s.id === data.sessionId);
      if (!meetingId || !session) return false;
      const name = session.user?.username ?? "L'utilisateur";
      let success = false;
      try {
        await socketDispatch(
          socketMeetingActions.exclude({
            id: meetingId,
            sessionIds: [session.id],
          })
        );
        toastSuccess(`${name} a été exclu du meeting par une interface de contrôle.`);
        success = true;
      } catch (err) {}
      return success;
    }
    if (isMeetingControl(content, MeetingControlAction.FULLSCREEN)) {
      const { data } = content;
      updateExtraData({ fullscreenForMediaId: data.mediaId });
      return true;
    }
    if (isMeetingControl(content, MeetingControlAction.SET_SOURCE)) {
      const { data } = content;
      if (!meetingSourcesRef.current) return false;
      if (data.id === undefined) return false;
      const sources = meetingSourcesRef.current.getSources();
      const source = sources[data.id];
      if (!source) return false;
      return meetingSourcesRef.current.setSource(source.source);
    }
    if (isMeetingControl(content, MeetingControlAction.DISPLAY_WHITEBOARD)) {
      const { data } = content;
      const playerRef = mediasMeetingPlayersRefs.current.get(data.mediaId);
      if (!playerRef) return false;
      let success = false;
      playerRef.setDisplayWhiteboardOptions(data.options);
      try {
        playerRef?.displayWhiteboardButtonLoading(true);
        await updateSessionMediaById(data.sessionId, data.mediaId, {
          extra: { displayOnWhiteboard: { ...DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT, ...(data.options ?? {}) } },
        });
        success = true;
      } catch (err) {
      } finally {
        playerRef?.displayWhiteboardButtonLoading(false);
      }
      return success;
    }

    return false;
  };

  // ---------------------------------------------------------------- //
  // ---------------------------- EFFECT ----------------------------- //
  // ---------------------------------------------------------------- //

  useEffect(() => {
    bridgeMessageManagerRef.current = bridgeMessageManager;
  }, [bridgeMessageManager]);

  useEffect(() => {
    extraDataRef.current = extraData;
    broadcastExtraData();
  }, [extraData, broadcastExtraData]);

  useEffect(() => {
    if (!meeting || !session) return;
    const interval = setInterval(async () => {
      const promises: Promise<{ mediaId: string; stats: RTCStreamStats }>[] = [];
      // OUTPUT
      const rtc = ovenMediaWebrtcSessionRef.current?.rtcSession?.rtcPeerConnection;
      const media = ovenMediaWebrtcSessionRef.current?.data?.media;
      const currMediaId = ovenMediaWebrtcSessionRef.current?.data?.mediaId;
      if (rtc && media && currMediaId) {
        promises.push(
          new Promise(async (resolve) => {
            const result = await getRTCStreamStats(rtc, media, "output", localStatsResultRef.current.get(currMediaId));
            localStatsResultRef.current.set(currMediaId, result);
            resolve({ mediaId: currMediaId, stats: result.stats });
          })
        );
      }

      // INPUT
      const mediasMeetingPlayers = mediasMeetingPlayersRefs.current;
      mediasMeetingPlayers.forEach((currPlayer, currMediaId) => {
        if (!currPlayer) return;
        const session = currPlayer.getOvenmediaSessionInputStream();
        if (!session) return;
        const rtc = session.rtcSession?.rtcPeerConnection;
        const media = session.outputStream;
        if (rtc && media) {
          promises.push(
            new Promise(async (resolve) => {
              const result = await getRTCStreamStats(rtc, media, "input", localStatsResultRef.current.get(currMediaId));
              localStatsResultRef.current.set(currMediaId, result);
              resolve({ mediaId: currMediaId, stats: result.stats });
            })
          );
        }
      });
      await Promise.allSettled(promises);
      const stats: { [mediaId: string]: RTCStreamStats } = {};
      localStatsResultRef.current.forEach((currResult, mediaId) => {
        const currStats = currResult?.stats;
        if (!currStats) return;
        if (
          (currStats.mode === "output" && (pageSettings?.stats?.publishOutputStreams ?? DEFAULT_PAGE_SETTINGS.stats.publishOutputStreams)) ||
          (currStats.mode === "input" && (pageSettings?.stats?.publishInputStreams ?? DEFAULT_PAGE_SETTINGS.stats.publishInputStreams))
        ) {
          stats[mediaId] = currStats;
        }
      });
      {
        const meetingStatsEntries = Array.from(localStatsResultRef.current.entries());
        const meetingStats = meetingStatsEntries.reduce((prev: any, value) => {
          prev[value[0]] = value[1].stats;
          return prev;
        }, {});
        bridgeMessageManagerRef.current?.postMeetingStats(meetingStats);
      }
      await socketDispatch<MeetingSessionPushStatsResult>(socketMeetingActions.sessionStats({ meetingId: meeting.id, sessionId: session.id, stats }));
      refreshNetworkLevels(session.id);
    }, INTERVAL_GET_RTC_STATS);
    return () => clearInterval(interval);
  }, [socketDispatch, meeting, session, localStatsResultRef, settings, mediasMeetingPlayersRefs, refreshNetworkLevels]);

  useEffect(() => {
    if (!bridgeMessageManager) return;
    bridgeMessageManager.postJoinMeeting(id);
    const requestMeetingIdListener = bridgeMessageManager.addEventListener("requestMeetingId", (_, respond) => {
      respond(id);
    });
    const requestMeetingListener = bridgeMessageManager.addEventListener("requestMeeting", (_, respond) =>
      respond(meetingRef.current, sessionRef.current, extraDataRef.current)
    );
    const meetingControlListener = bridgeMessageManager.addEventListener("meetingControl", async (msg, respond) => {
      const result = await manageMeetingControl(msg.content);
      respond(result);
    });
    const bridgeClientJoinListener = bridgeMessageManager.addEventListener("clientJoin", () => setBridgeClients(bridgeMessageManager.getClients()));
    const bridgeClientLeaveListener = bridgeMessageManager.addEventListener("clientLeave", () => setBridgeClients(bridgeMessageManager.getClients()));
    const bridgeClientChangeListener = bridgeMessageManager.addEventListener("clientChange", () => setBridgeClients(bridgeMessageManager.getClients()));
    const bridgeClientDataListener = bridgeMessageManager.addEventListener("clientData", () => setBridgeClients(bridgeMessageManager.getClients()));

    setBridgeClients(bridgeMessageManager.getClients());
    broadcastMeeting();

    return () => {
      bridgeMessageManager.postLeaveMeeting();
      bridgeMessageManager.removeEventListener(...requestMeetingIdListener);
      bridgeMessageManager.removeEventListener(...requestMeetingListener);
      bridgeMessageManager.removeEventListener(...meetingControlListener);
      bridgeMessageManager.removeEventListener(...bridgeClientJoinListener);
      bridgeMessageManager.removeEventListener(...bridgeClientLeaveListener);
      bridgeMessageManager.removeEventListener(...bridgeClientChangeListener);
      bridgeMessageManager.removeEventListener(...bridgeClientDataListener);
    };
  }, [id, broadcastMeeting, bridgeMessageManager]);

  useEffect(() => {
    meetingRef.current = meeting;
    broadcastMeeting();
    if (!meeting) return;
    const sessions = meeting.sessions;
    if (sessions) {
      sessions.forEach((s) => {
        const medias = s.medias;
        if (medias) {
          medias.forEach((m) => {
            const meetingPlayerRef = mediasMeetingPlayersRefs.current.get(m.id);
            if (meetingPlayerRef) {
              meetingPlayerRef.enableAudio(m.audio.enabled);
              meetingPlayerRef.enableVideo(m.video.enabled);
            }
          });
        }
      });
    }
  }, [meeting, broadcastMeeting, mediasMeetingPlayersRefs]);

  // Clear
  useEffect(() => {
    const sessions = meeting?.sessions ?? [];
    const sessionIds = sessions.map((s) => s.id);
    // Remote stats
    const remoteStatsSessionIds = Array.from(remoteStatsRef.current.keys());
    remoteStatsSessionIds.forEach((id) => {
      if (!sessionIds.includes(id)) {
        logger.log("[STATS] Remove remote session stats : ", id);
        remoteStatsRef.current.delete(id);
      }
    });

    const mediaIds: string[] = [];
    // Local stats
    sessions.forEach((s) => s.medias?.forEach((m) => mediaIds.push(m.id)));
    const localStatsMediaIds = Array.from(localStatsResultRef.current.keys());
    localStatsMediaIds.forEach((id) => {
      if (!mediaIds.includes(id)) {
        logger.log("[STATS Remove remote local media stats : ", id);
        localStatsResultRef.current.delete(id);
      }
    });

    let extraDataUpdated = false;
    // Extra data
    const extraAudioMediaId = Object.keys(extraDataRef.current.audio);
    extraAudioMediaId.forEach((id) => {
      if (!mediaIds.includes(id)) {
        delete extraDataRef.current.audio[id];
        extraDataUpdated = true;
      }
    });
    if (extraDataUpdated) updateExtraData(extraDataRef.current);
  }, [meeting, remoteStatsRef, localStatsResultRef, extraData]);

  useEffect(() => {
    sessionRef.current = session;
    let extraDataUpdated = false;
    const sessions = meetingRef.current?.sessions;
    sessions?.forEach((s) =>
      s.medias?.forEach((m) => {
        const id = m.id;
        if (!extraDataRef.current.audio[id]) {
          extraDataRef.current.audio[id] = {
            muted: getDefaultMuteValue(s, m),
            volume: getDefaultVolumeValue(s, m),
          };
          extraDataUpdated = true;
        }
      })
    );
    if (extraDataUpdated) updateExtraData(extraDataRef.current);
    broadcastMeeting();
  }, [session, broadcastMeeting]);

  useEffect(() => {
    let extraDataUpdated = false;
    const excludeUsers = session
      ? !(pageSettings?.disableTheExclusionOfUser ?? DEFAULT_PAGE_SETTINGS.disableTheExclusionOfUser) && canExcludeUsers(session)
      : false;
    if (extraDataRef.current.canExcludeUsers !== excludeUsers) {
      extraDataRef.current.canExcludeUsers = excludeUsers;
      extraDataUpdated = true;
    }
    if (extraDataUpdated) updateExtraData(extraDataRef.current);
  }, [session, settings]);

  useEffect(() => {
    let extraDataUpdated = true;
    const data: MeetingSessionClients = {};
    bridgeClients.forEach((c) => {
      if (c.active) {
        data[c.id] = c.data;
      }
    });
    extraDataRef.current.clients = data;
    if (extraDataUpdated) updateExtraData(extraDataRef.current);
    updateMySession({
      extra: {
        clients: data,
      },
    });
  }, [bridgeClients]);

  useEffect(() => {
    let extraDataUpdated = false;
    const sessions = meeting?.sessions;
    sessions?.forEach((s) =>
      s.medias?.forEach((m) => {
        const id = m.id;
        if (!extraDataRef.current.audio[id]) {
          extraDataRef.current.audio[id] = {
            muted: getDefaultMuteValue(s, m),
            volume: getDefaultVolumeValue(s, m),
          };
          extraDataUpdated = true;
        }
      })
    );
    if (extraDataUpdated) updateExtraData(extraDataRef.current);
  }, [meeting, session]);

  useEffect(() => {
    let extraDataUpdated = false;
    const displayVideoOnlyOnSpectatorPage = pageSettings?.displayVideoOnlyOnSpectatorPage ?? DEFAULT_PAGE_SETTINGS.displayVideoOnlyOnSpectatorPage;
    if (extraDataRef.current.displayVideoOnlyOnSpectatorPage !== displayVideoOnlyOnSpectatorPage) {
      extraDataRef.current.displayVideoOnlyOnSpectatorPage = displayVideoOnlyOnSpectatorPage;
      extraDataUpdated = true;
    }
    if (extraDataUpdated) updateExtraData(extraDataRef.current);
    const windowWidth = window.innerWidth;
    meetingSourcesIsOpenedOnMainTabRef.current = !(pageSettings?.hideSourcesByDefault ?? DEFAULT_PAGE_SETTINGS.hideSourcesByDefault) && windowWidth > 800;
  }, [pageSettings]);

  useEffect(() => {
    // DisplayOnWhiteboard
    let extraDataUpdated = false;
    const sessions = meeting?.sessions;
    const mediaIdsCanBeDisplayedOnWhiteboard: string[] = [];
    sessions?.forEach((s) =>
      s.medias?.forEach((media) => {
        const me = s?.id === sessionRef.current?.id;
        const atLeastOneWhiteboardIsPresent = bridgeClients.find((c) => {
          if (!c.active) return false;
          const data = c.data;
          if (isMessagingBridgeClientData(data, SessionMode.SLAVE, PageSlaveMode.WHITEBOARD)) {
            return data.data?.enablePlayerAsBackground;
          }
          return false;
        });
        const mediaCanBeDisplayedOnWhiteboard = s?.medias?.find(
          (m: MeetingSessionMedia<MeetingSessionMediaExtra>) => m.extra?.displayOnWhiteboard?.enabled && m.id === media.id
        );
        const mediaIsDisplayingOnWhiteboard = s?.medias?.find(
          (m: MeetingSessionMedia<MeetingSessionMediaExtra>) => m.extra?.displayOnWhiteboard?.active && m.id === media.id
        );
        if (
          ((atLeastOneWhiteboardIsPresent || mediaIsDisplayingOnWhiteboard) &&
            (me || isSuperUser(sessionRef.current?.role)) &&
            mediaCanBeDisplayedOnWhiteboard) ||
          pageSettings?.alwaysDisplayButtonToDisplayOnWhiteboard
        ) {
          mediaIdsCanBeDisplayedOnWhiteboard.push(media.id);
        }
      })
    );

    let arrayAreDifferents = false;
    if (mediaIdsCanBeDisplayedOnWhiteboard.length !== extraDataRef.current.canBeDisplayedOnWhiteboard?.length) {
      arrayAreDifferents = true;
    } else {
      if (
        !mediaIdsCanBeDisplayedOnWhiteboard.find((m) => !extraDataRef.current.canBeDisplayedOnWhiteboard?.includes(m)) ||
        extraDataRef.current.canBeDisplayedOnWhiteboard?.find((m) => !mediaIdsCanBeDisplayedOnWhiteboard.includes(m))
      ) {
        arrayAreDifferents = true;
      }
    }
    if (arrayAreDifferents) {
      extraDataRef.current.canBeDisplayedOnWhiteboard = mediaIdsCanBeDisplayedOnWhiteboard;
      extraDataUpdated = true;
    }
    if (extraDataUpdated) updateExtraData(extraDataRef.current);
  }, [meeting, bridgeClients, pageSettings]);

  useEffect(() => {
    if (socketStatus === SocketStatus.Online) {
      if (ready) {
        joinMeeting();
      } else {
        getMeetingBeforeJoin();
      }
    }
  }, [socketStatus, ready]);

  useEffect(() => {
    pageIsDestroyedRef.current = false;
    return () => {
      pageIsDestroyedRef.current = true;
      if (meetingRef.current?.id) {
        socketDispatch<MeetingLeaveResult>(socketMeetingActions.leave({ id: meetingRef.current.id }));
      }
      bridgeMessageManagerRef.current?.postMeeting(undefined, undefined);
      bridgeMessageManagerRef.current?.postMeetingStats(undefined);
      ovenMediaWebrtcSessionRef.current?.destroy();
      ovenMediaWebrtcSessionRef.current = undefined;
      dispatch(cleanMeeting());
    };
  }, []);

  // ---------------------------------------------------------------- //
  // ---------------------------- REDUX ACTION ----------------------------- //
  // ---------------------------------------------------------------- //

  useReduxAction<MeetingOnJoinEvent>(
    (action) => {
      const payload = action.payload;
      if (payload.session?.user) {
        const name = payload.session?.user?.username;
        if (pageSettings?.notification?.newUser ?? DEFAULT_PAGE_SETTINGS.notification.newUser) {
          if (name) toastInfo(`${name} vient de se connecter.`);
          else toastInfo("Un nouvel utilisateur vient de se connecter.");
        }
      } else if (payload.session?.device) {
        if (pageSettings?.notification?.newDevice ?? DEFAULT_PAGE_SETTINGS.notification.newDevice) {
          toastInfo("Un nouvel appareil vient de se connecter.");
        }
      }
    },
    socketMeetingActions.onJoin,
    []
  );

  useReduxAction<MeetingOnLeaveEvent>(
    (action) => {
      const payload = action.payload;
      if (payload.session?.user) {
        if (pageSettings?.notification?.disconnect ?? DEFAULT_PAGE_SETTINGS.notification.disconnect) {
          const name = payload.session?.user?.username;
          if (name) toastInfo(`${name} vient de se déconnecter.`);
        }
      }
    },
    socketMeetingActions.onLeave,
    []
  );

  useReduxAction<MeetingSessionOnStatsEvent>(
    (action) => {
      const payload = action.payload;
      if (payload.meetingId !== meeting?.id) return;
      remoteStatsRef.current?.set(payload.sessionId, payload.stats);
      refreshNetworkLevels(payload.sessionId);
    },
    socketMeetingActions.onSessionStats,
    [meeting, refreshNetworkLevels]
  );

  useReduxAction<MeetingOnExcludeEvent>((action) => pageContextRef.current.goTo?.(PageMasterMode.EXCLUDED, { id }), socketMeetingActions.onExclude, [id]);

  useReduxAction<MeetingSessionOnCustomEvent>(
    (action) => {
      const payload = action.payload;
      // console.log('Custom event: ', payload);
    },
    socketMeetingActions.onSessionCustomEvent,
    []
  );
  // ---------------------------------------------------------------- //
  // ---------------------------- UTILS ----------------------------- //
  // ---------------------------------------------------------------- //

  const updateSession = async (sessionId: string, data: Omit<MeetingSessionUpdateRequest<MeetingSessionExtra>, "id" | "sessionId">) => {
    if (!meetingRef.current) return;
    const payload: MeetingSessionUpdateRequest<MeetingSessionExtra> = {
      id: meetingRef.current.id,
      sessionId,
      ...data,
    };
    return await meetingSessionUpdater.current(payload);
  };

  const updateMySession = async (data: Omit<MeetingSessionUpdateRequest<MeetingSessionExtra>, "id" | "sessionId">) => {
    if (!meetingRef.current || !sessionRef.current) return;
    return await updateSession(sessionRef.current.id, data);
  };

  const updateSessionMediaById = async (sessionId: string, mediaId: string, data: Omit<MeetingSessionUpdateMedia<MeetingSessionMediaExtra>, "id">) => {
    //console.trace();
    if (!data || !meetingRef.current || !sessionRef.current) return undefined;
    const payload: MeetingSessionMediaUpdateRequest = {
      id: meetingRef.current.id,
      sessionId,
      mediaId,
      ...data,
    };
    return await meetingSessionMediaUpdater.current(payload);
  };

  const updateMySessionMediaById = async (mediaId: string, data: Omit<MeetingSessionUpdateMedia<MeetingSessionMediaExtra>, "id">) => {
    if (!sessionRef.current) return undefined;
    return updateSessionMediaById(sessionRef.current.id, mediaId, data);
  };

  const updateMySessionMediaStateById = async (mediaId: string, state: MeetingSessionMediaState) => {
    dispatch(updateSessionMediaState({ id: mediaId, state }));
    await updateMySessionMediaById(mediaId, { state });
  };

  const refresMedia = async (mediaId: string, mediaStream: MediaStream | undefined) => {
    logger.log("[OVENMEDIA - REFRESH] : ", mediaStream, mediaStream?.getTracks());
    if (!ovenMediaWebrtcSessionRef.current) return;

    const peerConnection: RTCPeerConnection | undefined = ovenMediaWebrtcSessionRef.current.rtcSession.rtcPeerConnection;
    if (!peerConnection) return;
    ovenMediaWebrtcSessionRef.current.data = { media: mediaStream, mediaId };
    const options: RefreshMediaOnRTCPeerConnectionOptions = {
      params: {
        videoMediaContentHint: pageSettings?.output?.videoMediaContentHint ?? DEFAULT_PAGE_SETTINGS.output.videoMediaContentHint,
        videoMediaConstrains: pageSettings?.output?.videoMediaConstrains ?? DEFAULT_PAGE_SETTINGS.output.videoMediaConstrains,
        audioMediaContentHint: pageSettings?.output?.audioMediaContentHint ?? DEFAULT_PAGE_SETTINGS.output.audioMediaContentHint,
        audioMediaConstrains: pageSettings?.output?.audioMediaConstrains ?? DEFAULT_PAGE_SETTINGS.output.audioMediaConstrains,
        videoRtcEncoding: pageSettings?.output?.videoRtcEncoding ?? DEFAULT_PAGE_SETTINGS.output.videoRtcEncoding,
        videoRtcParams: pageSettings?.output?.videoRtcParams ?? DEFAULT_PAGE_SETTINGS.output.videoRtcParams,
        audioRtcEncoding: pageSettings?.output?.audioRtcEncoding ?? DEFAULT_PAGE_SETTINGS.output.audioRtcEncoding,
        audioRtcParams: pageSettings?.output?.audioRtcParams ?? DEFAULT_PAGE_SETTINGS.output.audioRtcParams,
      },
    };
    await refreshMediaOnRTCPeerConnection(peerConnection, mediaStream, options);
    if (mediaStream) {
      const videoTrackExists = mediaStream.getVideoTracks().length ? true : false;
      const audioTrackExists = mediaStream.getAudioTracks().length ? true : false;
      if (videoTrackExists && audioTrackExists) updateMySessionMediaStateById(mediaId, MeetingSessionMediaState.READY);
      else if (!audioTrackExists && !videoTrackExists) updateMySessionMediaStateById(mediaId, MeetingSessionMediaState.NO_TRACKS);
      else if (!audioTrackExists) updateMySessionMediaStateById(mediaId, MeetingSessionMediaState.NO_AUDIO_TRACK);
      else if (!videoTrackExists) updateMySessionMediaStateById(mediaId, MeetingSessionMediaState.NO_VIDEO_TRACK);
    }
    if (videoStatsRef.current) {
      // console.log("peerConnection : ", peerConnection);
      videoStatsRef.current.setMedia(mediaStream);
      videoStatsRef.current.setRTC(peerConnection);
    }
  };

  const start = async (mediaId: string, mediaStream: MediaStream) => {
    if (pageIsDestroyedRef.current) return;
    const mediaDestination = mediaDestinations[mediaId];
    if (!mediaDestination) return;
    if (ovenMediaWebrtcSessionRef.current) {
      ovenMediaWebrtcSessionRef.current.removeAllEventListeners();
      ovenMediaWebrtcSessionRef.current.destroy();
      ovenMediaWebrtcSessionRef.current = undefined;
    }

    const interceptEncodedChunk = Debug.getData()?.pushMedia?.interceptor?.enabled || settings.debug?.pushMedia?.interceptor?.enabled;
    const sessionOptions: OvenMediaEmitterWebRTCSessionOptions = {
      rtc: {
        conf: {
          encodedInsertableStreams: interceptEncodedChunk,
        },
      },
    };
    objectAssignDeep(sessionOptions, pageSettings?.output?.ovenMediaSessionOptions ?? DEFAULT_PAGE_SETTINGS?.output?.ovenMediaSessionOptions ?? {});
    logger.log("[OVENMEDIA - START] : ", mediaStream, mediaStream.getTracks(), " conf: ", sessionOptions);
    const session = new OvenMediaEmitterWebRTCSession<OvenMediaEmitterWebRTCSessionData>(sessionOptions);
    session.data = { media: mediaStream, mediaId };
    ovenMediaWebrtcSessionRef.current = session;

    let mediaAudioDecoder: MediaStreamAudioDecoder | undefined;
    let mediaVideoDecoder: MediaStreamVideoDecoder | undefined;
    if (interceptEncodedChunk) {
      mediaAudioDecoder = new MediaStreamAudioDecoder();
      mediaVideoDecoder = new MediaStreamVideoDecoder();
      const mediaAudioTrack = mediaAudioDecoder.getTrack();
      const mediaVideoTrack = mediaVideoDecoder.getTrack();
      const tracks: IMediaStreamTrackGenerator[] = [];
      if (mediaAudioTrack) tracks.push(mediaAudioTrack);
      if (mediaVideoTrack) tracks.push(mediaVideoTrack);
      const mediaStreamDecoded = new MediaStream(tracks);
      const videoTest = document.getElementById("video-test") as HTMLVideoElement;
      if (videoTest) {
        videoTest.srcObject = mediaStreamDecoded;
        videoTest.play();
      }

      const getVideoDecoderConfigFromRTPReceiverParams = (params: RTCRtpReceiveParameters): IVideoDecoderConfig | undefined => {
        const rtcCodecs = params?.codecs;
        logger.log("VIDEO params : ", params);
        if (!rtcCodecs?.length) return undefined;
        const rtcCodec = rtcCodecs[0];
        const mimeType = rtcCodec.mimeType;
        const sdpLineParsed = sdpLineParser(rtcCodec.sdpFmtpLine);
        let codec: string | undefined;
        if (mimeType === "video/VP8") {
          codec = "vp8";
        } else if (mimeType === "video/VP9") {
          codec = "vp09";
        } else if (mimeType === "video/H264") {
          const profileLevelId = sdpLineParsed["profile-level-id"]?.toLocaleLowerCase();
          if (profileLevelId) codec = `avc1.${profileLevelId}`;
        } else if (mimeType === "video/AV1") {
          // TODO
        }
        if (!codec) return undefined;
        const conf: IVideoDecoderConfig = {
          codec,
        };
        return conf;
      };

      const getAudioDecoderConfigFromRTPReceiverParams = (params: RTCRtpReceiveParameters): IAudioDecoderConfig | undefined => {
        const rtcCodecs = params?.codecs;
        logger.log("AUDIO params : ", params);
        if (!rtcCodecs?.length) return undefined;
        const rtcCodec = rtcCodecs[0];
        const mimeType = rtcCodec.mimeType;
        let conf: IAudioDecoderConfig | undefined;
        if (mimeType === "audio/OPUS") {
          if (rtcCodec.channels !== undefined) {
            conf = {
              codec: "opus",
              numberOfChannels: rtcCodec.channels,
              sampleRate: rtcCodec.clockRate,
            };
          }
        }
        return conf;
      };

      let startTime = -1;

      let videoPts = -1;
      let videoFirstTimestamp = -1;
      let videoDelay = -1;
      let videoFrame: any = null;
      let videoStartTime = -1;

      let audioPts = -1;
      let audioFirstTimestamp = -1;
      let audioDelay = -1;
      let audioFrame: any = null;
      let audioStartTime = -1;

      let syncPts = -1;
      let syncDelay = -1;

      const VIDEO_CLOCK_RATE = 90000;
      const AUDIO_CLOCK_RATE = 48000;
      const VIDEO_TIMEBASE = 1 / VIDEO_CLOCK_RATE;
      const AUDIO_TIMEBASE = 1 / AUDIO_CLOCK_RATE;

      session.rtcSession.addEventListener("senders", (senders) => {
        logger.log("ON SENDERS : ", senders);

        senders.forEach(async (s) => {
          if (mediaAudioDecoder?.state === "unconfigured" && s.track?.kind === "audio") {
            setTimeout(() => {
              const conf = getAudioDecoderConfigFromRTPReceiverParams(s.getParameters());
              logger.log("AUDIO DECODER - conf : ", conf);
              if (conf) mediaAudioDecoder?.configure(conf);
            }, 2000);
          }
          if (mediaVideoDecoder?.state === "unconfigured" && s.track?.kind === "video") {
            setTimeout(() => {
              const conf = getVideoDecoderConfigFromRTPReceiverParams(s.getParameters());
              logger.log("VIDEO DECODER - conf : ", conf);
              if (conf) mediaVideoDecoder?.configure(conf);
            }, 2000);
          }

          const getPts = (timestamp: number, timebase: number, firstimestamp: number): number => {
            return (timestamp - firstimestamp) * timebase * 1000;
          };

          const rtpReceiverStream: IRTCRtpSenderEncodedStreams | undefined = (s as any).createEncodedStreams();
          if (rtpReceiverStream) {
            const transformStream = new TransformStream({
              transform: (frame: IEncodedVideoChunkInput | IEncodedAudioChunkInput, controller: TransformStreamDefaultController) => {
                const PACKET_LOSS_SIMULATION_VIDEO =
                  Debug.getData()?.pushMedia?.interceptor?.video?.simulatePacketLoss ?? settings?.debug?.pushMedia?.interceptor?.video?.simulatePacketLoss ?? 0;
                const PACKET_LOSS_SIMULATION_AUDIO =
                  Debug.getData()?.pushMedia?.interceptor?.audio?.simulatePacketLoss ?? settings?.debug?.pushMedia?.interceptor?.audio?.simulatePacketLoss ?? 0;

                const random = Math.random();
                const now = new Date().getTime();
                const uptime = now - startTime;

                syncPts = Math.abs((videoPts - audioPts) / 1000);
                syncDelay = Math.abs((videoDelay - audioDelay) / 1000);

                // https://github.com/AirenSoft/OvenMediaEngine/blob/15e6b076355664dce33f03c29fb6c735357c8a42/src/projects/mediarouter/mediarouter_stream.cpp#L834
                if (s.track?.kind === "video") {
                  if (videoStartTime < 0) videoStartTime = now;
                  if (videoFirstTimestamp < 0 || audioFirstTimestamp < 0) videoFirstTimestamp = frame.timestamp;

                  if (videoFirstTimestamp > 0 && audioFirstTimestamp > 0) {
                    videoPts = getPts(frame.timestamp, VIDEO_TIMEBASE, videoFirstTimestamp);
                    videoDelay = uptime - videoPts;
                    videoFrame = {
                      type: frame.type,
                      timestamp: frame.timestamp,
                      duration: frame.duration,
                      dataLength: frame.data?.byteLength,
                      metadata: (frame as any).getMetadata(),
                    };

                    const displayLogsVideo = Debug.getData()?.pushMedia?.interceptor?.video?.logs || settings.debug?.pushMedia?.interceptor?.video?.logs;
                    const displayLogsAudio = Debug.getData()?.pushMedia?.interceptor?.audio?.logs || settings.debug?.pushMedia?.interceptor?.audio?.logs;

                    if (displayLogsVideo || displayLogsAudio) {
                      const logsVideo = displayLogsVideo
                        ? [
                            "\n[VIDEO] pts:",
                            (videoPts / 1000).toFixed(3),
                            " dly:",
                            (videoDelay / 1000).toFixed(3),
                            " ts:",
                            videoFrame?.timestamp.toFixed(0),
                            " fts:",
                            videoFirstTimestamp.toFixed(0),
                            ` tb: 1/${VIDEO_CLOCK_RATE}`,
                          ]
                        : [];
                      const logsAudio = displayLogsAudio
                        ? [
                            "\n[AUDIO] pts:",
                            (audioPts / 1000).toFixed(3),
                            " dly:",
                            (audioDelay / 1000).toFixed(3),
                            " ts:",
                            audioFrame?.timestamp.toFixed(0),
                            " fts:",
                            audioFirstTimestamp.toFixed(0),
                            ` tb: 1/${AUDIO_CLOCK_RATE}`,
                          ]
                        : [];

                      logger.log(
                        "DATA - start:",
                        startTime,
                        " - time:",
                        now,
                        " - uptime:",
                        (uptime / 1000).toFixed(3),
                        "\n[SYNC] pts:",
                        (syncPts * 1000).toFixed(0),
                        "ms dly:",
                        (syncDelay * 1000).toFixed(0),
                        "ms",
                        ...logsVideo,
                        ...logsAudio,
                        "\n",
                        {
                          decoder: {
                            video: mediaVideoDecoder,
                            audio: mediaAudioDecoder,
                          },
                          frame: {
                            video: videoFrame,
                            audio: audioFrame,
                          },
                        }
                      );
                    }
                  }

                  mediaVideoDecoder?.push(frame as IEncodedVideoChunkInput);
                  if (random <= 1 - PACKET_LOSS_SIMULATION_VIDEO) controller.enqueue(frame); // push frame for MediaStreamTrack (not cut pipeline)
                  else console.log("[PACKET LOSS] - video : ", frame.type);
                } else if (s.track?.kind === "audio") {
                  frame.type = "key";
                  if (audioStartTime < 0) audioStartTime = now;
                  if (videoFirstTimestamp < 0 || audioFirstTimestamp < 0) audioFirstTimestamp = frame.timestamp;
                  if (videoFirstTimestamp > 0 && audioFirstTimestamp > 0) {
                    audioPts = getPts(frame.timestamp, AUDIO_TIMEBASE, audioFirstTimestamp);
                    audioDelay = uptime - audioPts;
                    audioFrame = {
                      type: frame.type,
                      timestamp: frame.timestamp,
                      duration: frame.duration,
                      dataLength: frame.data?.byteLength,
                      metadata: (frame as any).getMetadata(),
                    };
                  }

                  mediaAudioDecoder?.push(frame as IEncodedAudioChunkInput);
                  if (random <= 1 - PACKET_LOSS_SIMULATION_AUDIO) controller.enqueue(frame); // push frame for MediaStreamTrack (not cut pipeline)
                  else logger.log("[PACKET LOSS] - audio");
                } else {
                  logger.log("no track");
                }

                if (videoFirstTimestamp > 0 && audioFirstTimestamp > 0 && startTime < 0) startTime = now;
              },
            });

            rtpReceiverStream.readable.pipeThrough(transformStream).pipeTo(rtpReceiverStream.writable);
          }
        });
      });
    }

    const updateSdp = (description: RTCSessionDescriptionInit | null): RTCSessionDescriptionInit | null => {
      if (!description?.sdp) return null;
      let result: RTCSessionDescriptionInit = description;
      const sdpFormatter = new SdpFormatter(description.sdp);
      if (description.type === "offer") {
        if (pageSettings?.output?.sdpOfferFormatterPipeline) {
          try {
            sdpFormatter.execPipeline(pageSettings.output.sdpOfferFormatterPipeline);
          } catch (err) {
            toastError("Invalid sdp offer pipeline from settings");
            throw err;
          }
        }
        result.sdp = sdpFormatter.sdp;
        result = Debug.getData()?.ovenMediaSessionEmitterMiddlewareSdpOffer?.(result, SdpFormatter) ?? result;
      } else if (description.type === "answer") {
        if (pageSettings?.output?.sdpAnswerFormatterPipeline) {
          try {
            sdpFormatter.execPipeline(pageSettings.output.sdpAnswerFormatterPipeline);
          } catch (err) {
            toastError("Invalid sdp answer pipeline from settings");
            throw err;
          }
        }
        result.sdp = sdpFormatter.sdp;
        result = Debug.getData()?.ovenMediaSessionEmitterMiddlewareSdpAnswer?.(result, SdpFormatter) ?? result;
      }
      return result;
    };

    // Offer
    session.rtcSession.middlewareRemoteSdp = (description: RTCSessionDescriptionInit | null) => {
      return updateSdp(description);
    };

    // Answer
    session.rtcSession.middlewareLocalSdp = (description: RTCSessionDescriptionInit | null) => {
      return updateSdp(description);
    };

    // DEBUG
    /*
    session.addEventListener("initialized", () => {
      [
        "connectionstatechange",
        "datachannel",
        "icecandidate",
        "icecandidateerror",
        "iceconnectionstatechange",
        "icegatheringstatechange",
        "negotiationneeded",
        "signalingstatechange",
        "track",
      ].forEach((event) => {
        const peerConnection = session.rtcSession.rtcPeerConnection;
        peerConnection?.addEventListener(event, (...args) => {
          console.log("PEERCONNECTION - event - " + event + ": ", ...args);
        });
      });
    });
    */

    const options: ApplyMediaConstraintsOptions = {
      videoMediaContentHint: pageSettings?.output?.videoMediaContentHint ?? DEFAULT_PAGE_SETTINGS.output.videoMediaContentHint,
      videoMediaConstrains: pageSettings?.output?.videoMediaConstrains ?? DEFAULT_PAGE_SETTINGS.output.videoMediaConstrains,
      audioMediaContentHint: pageSettings?.output?.audioMediaContentHint ?? DEFAULT_PAGE_SETTINGS.output.audioMediaContentHint,
      audioMediaConstrains: pageSettings?.output?.audioMediaConstrains ?? DEFAULT_PAGE_SETTINGS.output.audioMediaConstrains,
    };
    await applyMediaConstraints(mediaStream, options);
    session.addEventListener("state", (state) => {
      logger.log("[OVEMENDIA - PUSH - STATE] : ", WebRTCSessionState[state]);
      if (state === WebRTCSessionState.RUNNING) {
        mediaIsReadyRef.current = true;
        updateMySessionMediaStateById(mediaId, MeetingSessionMediaState.READY);
        const peerConnection = session.rtcSession.rtcPeerConnection;

        if (videoStatsRef.current) {
          // console.log("peerConnection : ", peerConnection);
          videoStatsRef.current.setMedia(session.data?.media);
          videoStatsRef.current.setRTC(peerConnection);
        }
        const params: AssignParamsOnRTCPeerConnectionParams = {
          videoMediaContentHint: pageSettings?.output?.videoMediaContentHint ?? DEFAULT_PAGE_SETTINGS.output.videoMediaContentHint,
          videoMediaConstrains: pageSettings?.output?.videoMediaConstrains ?? DEFAULT_PAGE_SETTINGS.output.videoMediaConstrains,
          audioMediaContentHint: pageSettings?.output?.audioMediaContentHint ?? DEFAULT_PAGE_SETTINGS.output.audioMediaContentHint,
          audioMediaConstrains: pageSettings?.output?.audioMediaConstrains ?? DEFAULT_PAGE_SETTINGS.output.audioMediaConstrains,
          videoRtcEncoding: pageSettings?.output?.videoRtcEncoding ?? DEFAULT_PAGE_SETTINGS.output.videoRtcEncoding,
          videoRtcParams: pageSettings?.output?.videoRtcParams ?? DEFAULT_PAGE_SETTINGS.output.videoRtcParams,
          audioRtcEncoding: pageSettings?.output?.audioRtcEncoding ?? DEFAULT_PAGE_SETTINGS.output.audioRtcEncoding,
          audioRtcParams: pageSettings?.output?.audioRtcParams ?? DEFAULT_PAGE_SETTINGS.output.audioRtcParams,
        };
        if (peerConnection) assignParamsOnRTCPeerConnection(peerConnection, params);
      }
    });
    let timeoutRetry: NodeJS.Timeout | undefined = undefined;
    const streamIsFinished = () => {
      mediaAudioDecoder?.destroy();
      mediaVideoDecoder?.destroy();
      if (pageIsDestroyedRef.current) return;
      if (mediaIsReadyRef.current) {
        updateMySessionMediaStateById(mediaId, MeetingSessionMediaState.ERROR);
      }
      mediaIsReadyRef.current = false;
      if (timeoutRetry) clearTimeout(timeoutRetry);
      timeoutRetry = setTimeout(() => {
        // Retry
        if (session.data?.media) start(mediaId, session.data.media);
      }, 500);
    };
    session.addEventListener("error", (error) => {
      if (!pageIsDestroyedRef.current) console.error("[OVEMENDIA - PUSH - ERROR] : ", error);
      streamIsFinished();
    });
    session.addEventListener("close", (reason) => {
      if (!pageIsDestroyedRef.current) console.error("[OVEMENDIA - PUSH - CLOSED] : ", reason);
      streamIsFinished();
    });
    updateMySessionMediaStateById(mediaId, MeetingSessionMediaState.PUBLISHING);
    const mediaDestinationUrl = new URL(mediaDestination);
    const pushOnRtmpServer = pageSettings?.output?.pushOnRtmpServer ?? DEFAULT_PAGE_SETTINGS.output.pushOnRtmpServer;
    if (pushOnRtmpServer) {
      const addRedirection = (url: string, key?: string) => {
        mediaDestinationUrl.searchParams.append("redirection", url);
        if (key) mediaDestinationUrl.searchParams.append("redirection_key", key);
      };
      if (Array.isArray(pushOnRtmpServer)) {
        pushOnRtmpServer.forEach((curr) => {
          if (typeof curr === "string") addRedirection(curr);
          else addRedirection(curr.url, curr.key);
        });
      } else if (typeof pushOnRtmpServer === "string") addRedirection(pushOnRtmpServer);
      else addRedirection(pushOnRtmpServer.url, pushOnRtmpServer.key);
    }
    await session.start(mediaDestinationUrl.href, mediaStream);
    if (Debug.isEnabled()) {
      const debugData = Debug.getData();
      if (debugData) debugData.ovenMediaSessionEmitterRef = session;
    }
  };

  const getMeetingBeforeJoin = async () => {
    const payload: MeetingGetByIdRequest = { id };
    const result = await socketDispatch<MeetingGetByIdResult>(socketMeetingActions.getById(payload));
    if (result.error?.code === -ApiErrorCode.MEETING_NOT_FOUND) {
      console.error("Meeting error : ", result.error);
      // Redirect to join page
      showPopup({
        title: "Oups",
        iconTitle: PopupIconType.ERROR,
        content: "Ce meeting ne semble pas exister.",
        buttons: [{ type: PopupButtonType.OK, element: "OK" /* TRANSLATION */ }],
        enableBackdropDismiss: false,
        enableCloseButton: false,
        onClose: () => pageContextRef.current.goTo?.(PageMasterMode.JOIN, undefined, { queries: { autojoin: "false" } }),
      });
    } else if (result.error || !result.response?.meeting) {
      console.error("Meeting error : ", result.error);
      // Redirect to join page
      showPopup({
        title: "Oups",
        iconTitle: PopupIconType.ERROR,
        content: "Une erreur est survenue. Vous allez être redirigé.",
        buttons: [{ type: PopupButtonType.OK, element: "OK" /* TRANSLATION */ }],
        enableBackdropDismiss: false,
        enableCloseButton: false,
        onClose: () => pageContextRef.current.goTo?.(PageMasterMode.JOIN, undefined, { queries: { autojoin: "false" } }),
      });
    } else {
      setMeetingBeforeJoin(result.response.meeting);
    }
  };

  const joinMeeting = async (password?: string) => {
    await delay(200);
    const payload: MeetingJoinRequest = {
      id,
      password,
      medias: [
        {
          video: {
            deviceType: MeetingSourceDeviceType.UNKNOWN,
            enabled: !(pageSettings?.disableVideoByDefault ?? DEFAULT_PAGE_SETTINGS.disableVideoByDefault),
          },
          audio: {
            deviceType: MeetingSourceDeviceType.UNKNOWN,
            enabled: !(pageSettings?.disableAudioByDefault ?? DEFAULT_PAGE_SETTINGS.disableAudioByDefault),
          },
          state: MeetingSessionMediaState.WAITING,
        },
      ],
    };
    const result = await socketDispatch<MeetingJoinResult>(socketMeetingActions.join(payload));
    if (result.error?.code === -ApiErrorCode.MEETING_PASSWORD_IS_NEEDED) {
      const inputRef = createRef<HTMLInputElement>();
      showPopup({
        title: "Mot de passe requis",
        iconTitle: PopupIconType.INFO,
        content: (
          <div className={styles.popupPasswordContainer}>
            <div className={styles.popupPasswordTitle}>Le meeting est protégé par un mot de passe.</div>
            <div className={styles.popupPasswordDescription}>Saisissez le mot de passe:</div>
            <input className={styles.popupPasswordInput} ref={inputRef} type="password" />
          </div>
        ),
        buttons: [{ type: PopupButtonType.VALIDATE, element: "Valider" /* TRANSLATION */ }],
        enableBackdropDismiss: false,
        enableCloseButton: false,
        onClose: () => {
          const password = inputRef.current?.value;
          joinMeeting(password);
        },
      });
    } else if (result.error?.code === -ApiErrorCode.MEETING_INVALID_PASSWORD) {
      const inputRef = createRef<HTMLInputElement>();
      showPopup({
        title: "Mot de passe incorrect",
        iconTitle: PopupIconType.ERROR,
        content: (
          <div className={styles.popupPasswordContainer}>
            <div className={styles.popupPasswordTitle}>Le mot de passe saisi est incorrect.</div>
            <div className={styles.popupPasswordDescription}>Veuillez réessayer:</div>
            <input className={styles.popupPasswordInput} ref={inputRef} type="password" />
          </div>
        ),
        buttons: [{ type: PopupButtonType.VALIDATE, element: "Valider" /* TRANSLATION */ }],
        enableBackdropDismiss: false,
        enableCloseButton: false,
        onClose: () => {
          const password = inputRef.current?.value;
          joinMeeting(password);
        },
      });
    } else if (result.error?.code === -ApiErrorCode.MEETING_NOT_FOUND) {
      console.error("Meeting error : ", result.error);
      // Redirect to join page
      showPopup({
        title: "Oups",
        iconTitle: PopupIconType.ERROR,
        content: "Ce meeting ne semble pas exister.",
        buttons: [{ type: PopupButtonType.OK, element: "OK" /* TRANSLATION */ }],
        enableBackdropDismiss: false,
        enableCloseButton: false,
        onClose: () => pageContextRef.current.goTo?.(PageMasterMode.JOIN, undefined, { queries: { autojoin: "false" } }),
      });
    } else if (result.error && !result.error?.timeout && result.error?.code !== -ApiErrorCode.MEETING_ALREADY_JOINED) {
      console.error("Meeting error : ", result.error);
      // Redirect to join page
      showPopup({
        title: "Oups",
        iconTitle: PopupIconType.ERROR,
        content: "Une erreur est survenue. Vous allez être redirigé.",
        buttons: [{ type: PopupButtonType.OK, element: "OK" /* TRANSLATION */ }],
        enableBackdropDismiss: false,
        enableCloseButton: false,
        onClose: () => pageContextRef.current.goTo?.(PageMasterMode.JOIN, undefined, { queries: { autojoin: "false" } }),
      });
    }
  };

  // ---------------------------------------------------------------- //
  // ---------------------------- RENDERING ------------------------- //
  // ---------------------------------------------------------------- //

  const renderSession = (s: MeetingSession) => {
    const me = s.id === session?.id;
    const user = s.user;
    const medias = s.medias;
    const media: MeetingSessionMedia<MeetingSessionMediaExtra, any, any> = medias?.[0];
    let currMeetingPlayerRef: MeetingPlayerRef | undefined;
    const mode =
      (pageSettings?.displayVideoOnlyOnSpectatorPage ?? DEFAULT_PAGE_SETTINGS.displayVideoOnlyOnSpectatorPage) && !me
        ? "no_stream_with_volum_control"
        : "distant";
    const renderHeader = () => {
      if (!meeting || !session) return null;
      const canExcludeUser = !me && !(pageSettings?.disableTheExclusionOfUser ?? DEFAULT_PAGE_SETTINGS.disableTheExclusionOfUser) && canExcludeUsers(session);
      const excludeButtonRef = createRef<HTMLDivElement>();
      return (
        <div className={styles.sessionHeader}>
          {mode === "distant" ? (
            <NetworkLevel
              mode={me ? "output" : "input"}
              ref={(r) => {
                if (r) networkLevelRefs.current.set(s.id, r);
                else networkLevelRefs.current.delete(s.id);
              }}
              width={20}
              stats={me ? getLocalMediaStats(session, "both") : [...getLocalMediaStats(session, "both"), ...getRemoteMediaStats(session, "both")]}
            />
          ) : null}
          {canExcludeUser ? (
            <div ref={excludeButtonRef}>
              <Touchable
                onPress={() => {
                  /* */
                  showPopup({
                    title: "Exclure du meeting",
                    iconTitle: PopupIconType.WARNING,
                    content: `Êtes-vous sûr de vouloir exclure ${user?.username} ?`,
                    buttons: [
                      { type: PopupButtonType.CANCEL, element: "Non" },
                      {
                        type: PopupButtonType.VALIDATE,
                        element: "Oui",
                        onClick: () => {
                          socketDispatch(
                            socketMeetingActions.exclude({
                              id: meeting.id,
                              sessionIds: [s.id],
                            })
                          )
                            .then(() => {
                              toastSuccess(`${user?.username} a été exclu du meeting.`);
                            })
                            .catch((err) => {
                              toastError("Une erreur est survenue.");
                            });
                          return true;
                        },
                      },
                    ],
                    enableBackdropDismiss: true,
                    enableCloseButton: true,
                  });
                }}
              >
                <IconClose width={20} />
              </Touchable>
              <OverlayHoverMessage targetRef={excludeButtonRef} message={"Exclure du meeting"} />
            </div>
          ) : null}
        </div>
      );
    };
    const enableAudioButton = me || isSuperUser(sessionRef.current?.role);
    const enableVideoButton = me || isSuperUser(sessionRef.current?.role);
    const mediaIsDisplayingByAnother = bridgeClients.find((c) => {
      if (!c.active) return false;
      const data = c.data;
      if (!data) return false;
      if (isMessagingBridgeClientData(data, SessionMode.SLAVE, PageSlaveMode.SPECTATOR)) {
        const displayingMedias = data.data?.displayingMedias ?? [];
        return displayingMedias.includes(media.id);
      }
      return false;
    })
      ? true
      : false;

    const sessionDisplayingInBackground = meeting?.sessions?.find((s) => {
      const me = s.id === session?.id;
      const media: MeetingSessionMedia<MeetingSessionMediaExtra> = s.medias?.[0];
      if (media?.extra?.displayOnWhiteboard?.enabled === false) return false;
      if (me && media?.extra?.displayOnWhiteboard?.localDisplay === false) return false;
      if (!me && media?.extra?.displayOnWhiteboard?.remoteDisplay === false) return false;
      return media?.extra?.displayOnWhiteboard?.active;
    });

    return (
      <MeetingSessionContainer me={me} session={s} playerSettings={pageSettings?.player}>
        {media ? (
          <>
            <MeetingPlayer
              ref={(r) => {
                if (r) {
                  mediasMeetingPlayersRefs.current.set(media.id, r);
                } else {
                  mediasMeetingPlayersRefs.current.delete(media.id);
                }
                if (Debug.isEnabled()) {
                  const debugData = Debug.getData();
                  if (debugData) debugData.mediasMeetingPlayersRefs = mediasMeetingPlayersRefs.current;
                }
                currMeetingPlayerRef = r ?? undefined;
              }}
              rightHeaderElement={renderHeader()}
              me={me}
              muted={extraData?.audio?.[media.id]?.muted ?? getDefaultMuteValue(s, media)}
              volume={extraData?.audio?.[media.id]?.volume ?? getDefaultVolumeValue(s, media)}
              mode={mode}
              internalTransmissionMode={pageSettings?.media?.internalTransmission ?? DEFAULT_PAGE_SETTINGS.media.internalTransmission}
              bridgeMessageManager={bridgeMessageManager}
              sourcesRef={meetingSourcesRef}
              reactions={s.reactions}
              reactionsEnabled={me ? meeting?.reactionsEnabled ?? [] : []}
              session={s}
              mediaSession={media}
              user={user}
              displayVideoStats={pageSettings?.debug ?? DEFAULT_PAGE_SETTINGS.debug}
              audioButtonEnabled={enableAudioButton}
              videoButtonEnabled={enableVideoButton}
              onAudioEnable={async (e) => {
                const ref = currMeetingPlayerRef;
                if (me) {
                  ref?.disableAudioButton(true);
                  await updateMySessionMediaById(media.id, { audio: { enabled: e } });
                  ref?.disableAudioButton(false);
                } else {
                  ref?.audioButtonLoading(true);
                  updateSessionMediaById(s.id, media.id, { audio: { enabled: e } });
                  ref?.audioButtonLoading(false);
                }
              }}
              onVideoEnable={async (e) => {
                const ref = currMeetingPlayerRef;
                if (me) {
                  ref?.disableVideoButton(true);
                  await updateMySessionMediaById(media.id, { video: { enabled: e } });
                  ref?.disableVideoButton(false);
                } else {
                  ref?.videoButtonLoading(true);
                  updateSessionMediaById(s.id, media.id, { video: { enabled: e } });
                  ref?.videoButtonLoading(false);
                }
              }}
              onReaction={async (e, r, reactions) => {
                const ref = currMeetingPlayerRef;
                if (me) {
                  ref?.disableReactionButtons(true);
                  await updateMySession({ reactions });
                  ref?.disableReactionButtons(false);
                }
              }}
              onMediaStream={(m) => {
                logger.log("ON MEDIA STREAM: ", m);
                if (me) {
                  // console.log("REFRESH OVENMEDIA");
                  const ovenmediaMedia = ovenMediaWebrtcSessionRef.current?.data?.media;
                  // console.trace();
                  if (ovenMediaWebrtcSessionRef.current) {
                    // replace tracks
                    refresMedia(media.id, m);
                  } else {
                    logger.log("START OVENMEDIA", m?.getTracks());
                    if (m) start(media.id, m);
                  }
                }
              }}
              onSource={(src) => {
                if (me) {
                  const video = {
                    deviceType: src?.video?.device?.type ?? (MeetingSourceDeviceType.UNKNOWN as any),
                    label: src?.video?.label,
                    extra: src?.video?.device?.extra,
                  };
                  const audio = {
                    deviceType: src?.audio?.device?.type ?? (MeetingSourceDeviceType.UNKNOWN as any),
                    label: src?.audio?.label,
                    extra: src?.audio?.device?.extra,
                  };
                  updateMySessionMediaById(media.id, {
                    video,
                    audio,
                    name: src?.source?.name,
                    extra: {
                      source: {
                        audio: {
                          error: src?.audio?.error ?? "",
                        },
                        video: {
                          error: src?.video?.error ?? "",
                        },
                      },
                    },
                  });
                }
              }}
              remoteControls={{
                fullscreen: {
                  enabled: mediaIsDisplayingByAnother,
                  value: extraData.fullscreenForMediaId === media.id,
                },
              }}
              onRemoteButton={(type, enabled) => {
                if (type === MeetingPlayerRemoteButton.FULLSCREEN) {
                  if (enabled || !extraData.fullscreenForMediaId || extraData.fullscreenForMediaId === media.id) {
                    updateExtraData({ fullscreenForMediaId: enabled ? media.id : undefined });
                  }
                }
              }}
              onVolume={(volume, muted) => {
                const audio = extraDataRef?.current?.audio ?? {};
                audio[media.id] = { volume, muted };
                updateExtraData({ audio });
              }}
              displayOnWhiteboardIsEnabled={
                extraData.canBeDisplayedOnWhiteboard?.includes(media.id) && (!sessionDisplayingInBackground || sessionDisplayingInBackground.id === s.id)
              }
              onDisplayOnWhiteboardOptions={(options) => {
                if (!me) return;
                updateSessionMediaById(s.id, media.id, { extra: { displayOnWhiteboard: { ...DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT, ...(options ?? {}) } } });
              }}
              onDisplayOnWhiteboardAction={(e) => {
                if (me) return;
                updateSessionMediaById(s.id, media.id, { extra: { displayOnWhiteboard: { ...(media.extra?.displayOnWhiteboard) , active: e } } });
              }}
            />
            {me && (pageSettings?.debug ?? DEFAULT_PAGE_SETTINGS.debug) ? (
              <VideoStats ref={videoStatsRef} mode={"output"} className={styles.videoStats} />
            ) : null}
          </>
        ) : null}
      </MeetingSessionContainer>
    );
  };

  const renderHeader = () => {
    if (!meeting) return null;
    if (pageSettings?.header?.hide ?? DEFAULT_PAGE_SETTINGS.header.hide) return null;
    return (
      <MeetingHeader
        meeting={meeting}
        helpUri={pageSettings?.header?.helpUri ?? DEFAULT_PAGE_SETTINGS.header.helpUri}
        hideClock={!(pageSettings?.header?.clock?.enabled ?? DEFAULT_PAGE_SETTINGS.header.clock.enabled)}
        displaySeconds={pageSettings?.header?.clock?.seconds ?? DEFAULT_PAGE_SETTINGS.header.clock.seconds}
      />
    );
  };


  const renderSources = () => {
    if (!ready) return null;
    let showSources = !isSpectator(session?.role);
    if (!showSources || !meetingSources?.length) return null;
    const isOpen = meetingSourcesIsOpenedOnMainTabRef.current && tab === MeetingTab.MAIN;
    return (
      <MeetingSources
        ref={meetingSourcesRef}
        defaultIsOpen={isOpen}
        className={styles.sources}
        displayVideoStats={pageSettings?.debug ?? DEFAULT_PAGE_SETTINGS.debug}
        sources={meetingSources}
        onSources={(sources: MeetingSourceData[]) => {
          extraDataRef.current.sources = sources.map((s, index) => ({
            id: meetingSources.findIndex((curr) => curr === s.source),
            name: s.source.name ?? `Device ${index + 1}`,
            using: s.using,
            hidden: s.hidden,
            audio: s.audio.device,
            video: s.video.device,
          }));
          updateExtraData(extraDataRef.current);
        }}
        onOpen={(value) => {
          if (tab === MeetingTab.MAIN) meetingSourcesIsOpenedOnMainTabRef.current = value;
        }}
      />
    );
  };

  const renderContent = () => {
    if (!ready && meetingBeforeJoin) {
      const iconUsersSize = 25;
      const iconPrivacySize = 15;
      const password = meetingBeforeJoin?.usePassword;
      const privacyRef = createRef<HTMLDivElement>();
      const privacyMessage = !password
        ? "N'importe quel utilisateur authentifié peut rejoindre ce meeting"
        : "Un mot de passe sera demandé aux utilisateurs non paramétrés pour rejoindre ce meeting";
      const users = meetingBeforeJoin.sessions?.filter((s) => s.role !== MeetingSessionRole.SUPERSPECTATOR).length ?? 0;
      const name = meetingBeforeJoin?.name?.trim()?.length ? meetingBeforeJoin.name.trim() : undefined;
      return (
        <div className={styles.joinMeetingContainer}>
          <div className={styles.joinMeetingContent}>
            <div className={styles.joinMeetingTitle}>
              Rejoindre le meeting
              <div className={styles.joinMeetingSubtitle}>{`${meetingBeforeJoin?.shortId}${name ? ` - ${name}` : ""}`}</div>
            </div>
            <div className={styles.joinMeetingDetails}>
              <IconMeetingUsers className={styles.joinMeetingDetailsIcon} width={iconUsersSize} height={iconUsersSize} />
              <div>
                Ce meeting comporte actuellement <b>{users}</b> participant(s)
              </div>
            </div>
            <div ref={privacyRef} className={styles.joinMeetingDetails}>
              {!password ? (
                <>
                  <IconMeetingPublic className={styles.joinMeetingDetailsIcon} width={iconPrivacySize} height={iconPrivacySize} />
                  Ce meeting est en accès libre.
                </>
              ) : (
                <>
                  <IconMeetingPrivate className={styles.joinMeetingDetailsIcon} width={iconPrivacySize} height={iconPrivacySize} />
                  Ce meeting est protégé par un mot de passe.
                </>
              )}
              <OverlayHoverMessage targetRef={privacyRef} message={privacyMessage} />
            </div>
          </div>
          <Touchable
            className={styles.joinMeetingButton}
            onPress={() => {
              setReady(true);
            }}
          >
            Rejoindre
          </Touchable>
        </div>
      );
    }
    if (!meeting) return null;
    const sessions = [...(meeting.sessions ?? [])].filter((s) => {
      const me = s.id === session?.id;
      if (pageSettings?.displayMeOnly ?? DEFAULT_PAGE_SETTINGS.displayMeOnly) return me;
      return !isSpectator(s.role);
    });
    sessions.sort((a, b) => (b.id === session?.id ? 1 : 0) - (a.id === session?.id ? 1 : 0));
    const renderSessions = () => {
      if (!sessions) return <div style={{ width: "100%", textAlign: "center" }}>Aucun participant...</div>;
      if (pageSettings?.displayVideoOnlyOnSpectatorPage ?? DEFAULT_PAGE_SETTINGS.displayVideoOnlyOnSpectatorPage) {
        const mySession = sessions.find((s) => s.id === session?.id);
        return (
          <MeetingPlayers
            mode={MeetingPlayersDisplayMode.ONE_MAIN_AND_THE_OTHERS}
            options={{ main: mySession }}
            sessions={sessions}
            className={styles.players}
            renderItem={(_, session) => renderSession(session)}
            aspectVideoRation={pageSettings?.aspectVideoRatioTarget ?? DEFAULT_PAGE_SETTINGS.aspectVideoRatioTarget}
          />
        );
      }
      return (
        <MeetingPlayers
          mode={MeetingPlayersDisplayMode.GRID}
          options={undefined}
          sessions={sessions}
          className={styles.players}
          renderItem={(_, session) => renderSession(session)}
          aspectVideoRation={pageSettings?.aspectVideoRatioTarget ?? DEFAULT_PAGE_SETTINGS.aspectVideoRatioTarget}
        />
      );
    };

    const renderMain = () => {
      const currClasses = [styles.mainContent];
      if (tab === MeetingTab.WHITEBOARD) currClasses.push(styles.mainContentWithWhiteboard);
      return <div className={currClasses.join(" ")}>{renderSessions()}</div>;
    };

    const renderWhiteboard = (whiteboardShortId?: string) => {
      if (!whiteboardShortId) return null;
      if (!(pageSettings?.whiteboard?.enabled ?? DEFAULT_PAGE_SETTINGS.whiteboard.enabled)) return null;

      const currClasses = [styles.whiteboardContainer];
      if (tab !== MeetingTab.WHITEBOARD) {
        if (!displayTab) return null;
        currClasses.push(styles.hideContent);
      }

      return (
        <div className={currClasses.join(" ")}>
          <Whiteboard
            className={styles.whiteboardContent}
            autojoin={pageSettings?.whiteboard?.autojoin ?? [WhiteboardConditionalAutojoin.ONLY_ID_NAME_ARE_DEFINED]}
            id={pageSettings?.whiteboard?.id ?? DEFAULT_PAGE_SETTINGS.whiteboard.id ?? whiteboardShortId}
            name={pageSettings?.whiteboard?.name ?? DEFAULT_PAGE_SETTINGS.whiteboard.name ?? session?.user?.username}
            isMaster={pageSettings?.whiteboard?.isMaster ?? DEFAULT_PAGE_SETTINGS.whiteboard.isMaster}
            followMaster={pageSettings?.whiteboard?.followMasters ?? DEFAULT_PAGE_SETTINGS.whiteboard.followMasters}
            invisible={(isSpectator(session?.role) && isSuperUser(session?.role)) || (pageSettings?.whiteboard?.invisible ?? DEFAULT_PAGE_SETTINGS.whiteboard.invisible)}
            spectator={(isSpectator(session?.role) && !isSuperUser(session?.role)) || (pageSettings?.whiteboard?.spectator ?? DEFAULT_PAGE_SETTINGS.whiteboard.spectator)}
            menuButtons={{
              enableFavorite: false,
              enableReportBug: true,
              enableSwitchWhiteboard: false,
              enableLogout: false,
            }}
            hideNewIndicatorAfterDelay={pageSettings?.whiteboard?.hideNewIndicatorAfterDelay ?? DEFAULT_PAGE_SETTINGS.whiteboard.hideNewIndicatorAfterDelay}
            showNewVersionsOnFirstUse={pageSettings?.whiteboard?.showNewVersionsOnFirstUse ?? DEFAULT_PAGE_SETTINGS.whiteboard.showNewVersionsOnFirstUse}
          />
        </div>
      );
    };

    return (
      <div className={styles.content}>
        {renderWhiteboard(getActiveWhiteboardShortdId())}
        {renderMain()}
      </div>
    );
  };

  const renderFooter = () => {
    const renderTabButton = (icon: React.ReactNode, title: string, currTab: MeetingTab, callback?: () => void) => {
      const currClasses = [styles.footerTabButton];
      if (currTab === tab) currClasses.push(styles.footerTabButtonSelected);
      return (
        <Touchable
          className={currClasses.join(" ")}
          onPress={() => {
            callback?.();
            setTab(currTab);
          }}
        >
          <>
            {icon}
            <div>{title}</div>
          </>
        </Touchable>
      );
    };
    const renderTab = () => {
      if (!displayTab) return null;
      return (
        <div className={[styles.footerTab, BACKGROUND_COMPONENT_CLASSNAME].join(" ")}>
        {renderTabButton(<IconMeeting width={40} height={40} fill={"white"} />, "Meeting", MeetingTab.MAIN, () => {
          if (meetingSourcesIsOpenedOnMainTabRef.current) meetingSourcesRef.current?.open();
        })}
        {renderTabButton(
          <IconWhiteboard width={40} height={40} fill={"white"} />,
          "Tableau blanc",
          MeetingTab.WHITEBOARD,
          () => {
            meetingSourcesRef.current?.close();
          }
        )}
      </div>
      )
    };
    const renderChatButton = () => {
      const chatroom = meeting?.chatrooms?.find((c) => c.status === ChatroomStatus.enabled);
      if (!chatroom || !(pageSettings?.chat?.enabled ?? DEFAULT_PAGE_SETTINGS.chat.enabled)) return null;
      return (
        <Touchable
            className={[styles.footerChatButtonContainer, BACKGROUND_COMPONENT_CLASSNAME].join(' ')}
            onPress={() => {
              setDisplayChat(!displayChat);
            }}
          >
            <ChatButton ref={chatButtonRef} />
          </Touchable>
      );
    };
    const chatButton = renderChatButton();
    return (
      <div className={styles.footerContainer} style={{ minHeight: chatButton ? '55px' : 'unset' }}>
        {renderTab()}
        {chatButton}
      </div>
    );
  };

  const renderViewers = () => {
    if (!meeting) return null;
    const viewSessions = [...(meeting.sessions ?? [])].filter((s) => {
      return s.role === MeetingSessionRole.SPECTATOR;
    });
    if (!viewSessions.length) return null;
    const containerRef = createRef<HTMLDivElement>();
    return (
      <div className={styles.viewerContainer}>
        <div ref={containerRef}>
          <IconViewer width={30} height={20} />
        </div>
        <OverlayHoverMessage targetRef={containerRef} message="Le nombre de spectateur" />
        {viewSessions.length}
      </div>
    );
  };

  const renderChat = () => {
    const chatroom = meeting?.chatrooms?.find((c) => c.status === ChatroomStatus.enabled);
    if (!chatroom || !(pageSettings?.chat?.enabled ?? DEFAULT_PAGE_SETTINGS.chat.enabled)) return null;
    return (
      <div className={[styles.chatContainer].join(" ")} style={{ display: displayChat ? "flex" : "none" }}>
        <div className={styles.chatTitle}>Messagerie</div>
        <Chat
          ref={chatRef}
          inactive={!displayChat}
          adapter={chatAdapter}
          defaultMessages={chatAdapter.convertMessages(chatroom.messages ?? [])}
          moreMessages={true}
          className={styles.chat}
        ></Chat>
      </div>
    );
  };

  const renderMeeting = () => {
    return (
      <>
        {renderHeader()}
        {renderViewers()}
        <div className={styles.body}>
          {renderChat()}
          {renderSources()}
          {renderContent()}
        </div>
        {(Debug.getData()?.pushMedia?.interceptor?.enabled || settings.debug?.pushMedia?.interceptor?.enabled) &&
        (Debug.getData()?.pushMedia?.interceptor?.preview || settings.debug?.pushMedia?.interceptor?.preview) ? (
          <video id="video-test" controls style={{ width: "100%", height: "200px" }}></video>
        ) : null}
        {renderFooter()}
      </>
    );
  };
  const isLoading = ready ? (meeting ? false : true) : meetingBeforeJoin ? false : true;
  const displayMeeting = (ready && meeting) || (!ready && meetingBeforeJoin);
  return (
    <div className={styles.page} style={{ backgroundImage: `url(${ImgBackground})` }}>
      {displayMeeting ? renderMeeting() : null}
      {isLoading ? <Loading className={styles.loading} fullscreen message={"Chargement du meeting..." /* TRANSLATION */} /> : null}
    </div>
  );
};

export default MeetingPage;

import { useElementHovered } from "@kalyzee/kast-app-web-components";
import { Meeting, MeetingSession, MeetingSessionMedia } from "@kalyzee/kast-websocket-module";
import deepEqual from "deep-equal";
import { useCallback, useEffect, useRef, useState } from "react";
import MeetingHeader, { MeetingHeaderRef } from "../../components/MeetingHeader";
import MeetingPlayer from "../../components/MeetingPlayer";
import MeetingSlaveControls from "../../components/MeetingSlaveControls";
import { pageContextIsSessionMode, PageSlaveMode, usePageContextRef } from "../../components/navigation/PageContext";
import Whiteboard, { WhiteboardRef } from "../../components/Whiteboard";
import { useMessagingBridgeManagerSlaveContext } from "../../contexts/messageBridgeManager";
import { isSpectator, isSuperUser } from "../../helpers/meeting";
import { isWhiteboardMessage, MessagingBridgeClientDataMap, WhiteboardMessageAction } from "../../helpers/messagingBridge";
import { RTCBridgeTransmissionMode } from "../../helpers/rtcBridgeMediaSession";
import { DEFAULT_SETTINGS, SettingsWhiteboardSlavePage } from "../../helpers/settings";
import { toastInfo } from "../../helpers/toast";
import { useSettings } from "../../hooks/settings";
import { MeetingSessionMediaExtra } from "../../interfaces/meeting";
import { SessionMode } from "../../store/session/slices";
import { MeetingExtraData } from "../meeting/meeting";
import styles from "./whiteboard.module.css";

export interface WhiteboardPageProps {
  waiting?: boolean;
}

const WhiteboardPage = ({ waiting }: WhiteboardPageProps) => {
  const [settings, saveSettings] = useSettings();
  const pageContextRef = usePageContextRef();
  const id = pageContextRef.current.params?.id;
  let pageSettings: SettingsWhiteboardSlavePage | undefined = undefined;
  const DEFAULT_SLAVE_PAGE_SETTINGS = DEFAULT_SETTINGS.slave.pages.whiteboard;
  if (pageContextRef.current.mode === SessionMode.SLAVE) {
    pageSettings = settings.slave?.pages?.whiteboard;
  }

  const bridgeMessageManager = useMessagingBridgeManagerSlaveContext(pageSettings?.controls ?? DEFAULT_SLAVE_PAGE_SETTINGS.controls);

  const pageContainerDivRef = useRef<HTMLDivElement>(null);
  const meetingHeaderRef = useRef<MeetingHeaderRef>();
  const whiteboardRef = useRef<WhiteboardRef>();

  const [meeting, setMeeting] = useState<Meeting | undefined>(undefined);
  const [session, setSession] = useState<MeetingSession | undefined>(undefined);
  const [sessionToDisplayInBackground, setSessionToDisplayInBackground] = useState<MeetingSession | undefined>(undefined);
  const [extraData, setExtraData] = useState<MeetingExtraData>();
  const dataRef = useRef<MessagingBridgeClientDataMap[SessionMode.SLAVE][PageSlaveMode.WHITEBOARD] | undefined>();

  const [hideMeetingControls, setHideMeetingControls] = useState(false);
  const [hideWhiteboardControls, setHideWhiteboardControls] = useState(false);
  const hideMeetingControlsTimeoutRef = useRef<NodeJS.Timeout>();
  const hideWhiteboardControlsTimeoutRef = useRef<NodeJS.Timeout>();

  const getSessions = useCallback((): MeetingSession[] => {
    if (!meeting) return [];
    let sessions = [...meeting.sessions];
    sessions = sessions.filter((s) => {
      return !isSpectator(s.role);
    });
    sessions.sort((a, b) => (b.id === session?.id ? 1 : 0) - (a.id === session?.id ? 1 : 0));
    return sessions;
  }, [meeting, session]);

  useElementHovered(
    pageContainerDivRef,
    () => {
      if (pageContextRef.current.mode !== SessionMode.SLAVE) return;
      if (hideMeetingControls) setHideMeetingControls(false);
      if (hideMeetingControlsTimeoutRef.current) clearTimeout(hideMeetingControlsTimeoutRef.current);

      if (hideWhiteboardControls) setHideWhiteboardControls(false);
      if (hideWhiteboardControlsTimeoutRef.current) clearTimeout(hideWhiteboardControlsTimeoutRef.current);
      if (!sessionToDisplayInBackground) return;

      let currDelay =
        pageSettings?.displayPlayerAsBackground?.autoHideMeetingControlsAfterDelay ??
        DEFAULT_SLAVE_PAGE_SETTINGS.displayPlayerAsBackground.autoHideMeetingControlsAfterDelay;
      if (currDelay > 0) {
        hideMeetingControlsTimeoutRef.current = setTimeout(() => {
          setHideMeetingControls(true);
        }, currDelay);
      }
      currDelay =
        pageSettings?.displayPlayerAsBackground?.autoHideWhiteboardControlsAfterDelay ??
        DEFAULT_SLAVE_PAGE_SETTINGS.displayPlayerAsBackground.autoHideWhiteboardControlsAfterDelay;
      if (currDelay > 0) {
        hideWhiteboardControlsTimeoutRef.current = setTimeout(() => {
          setHideWhiteboardControls(true);
        }, currDelay);
      }
    },
    { enableTouch: true }
  );

  // ---------------------------------------------------------------- //
  // ---------------------------- EFFECT ----------------------------- //
  // ---------------------------------------------------------------- //

  useEffect(() => {
    const sessionToDisplayInBackground = meeting?.sessions?.find((s) => {
      const me = s.id === session?.id;
      const media: MeetingSessionMedia<MeetingSessionMediaExtra> = s.medias?.[0];
      if (media?.extra?.displayOnWhiteboard?.enabled === false) return false;
      if (me && media?.extra?.displayOnWhiteboard?.localDisplay === false) return false;
      if (!me && media?.extra?.displayOnWhiteboard?.remoteDisplay === false) return false;
      const enablePlayerAsBackground = pageSettings?.displayPlayerAsBackground?.enabled ?? DEFAULT_SLAVE_PAGE_SETTINGS.displayPlayerAsBackground.enabled;
      return enablePlayerAsBackground && media?.extra?.displayOnWhiteboard?.active;
    });
    setSessionToDisplayInBackground(sessionToDisplayInBackground);
  }, [pageSettings, meeting]);

  useEffect(() => {
    if (!bridgeMessageManager) return;

    const readyListener = bridgeMessageManager.addEventListener("ready", () => {
      dataRef.current = undefined; // refresh
      if (id) bridgeMessageManager.postRequestMeeting();
      else bridgeMessageManager.postRequestMeetingId();
    });

    const joinMeetingListener = bridgeMessageManager.addEventListener("joinMeeting", (msg) => {
      const meetingId = msg.content.meetingId;
      setMeeting(undefined);
      if (meetingId !== id) pageContextRef.current.goTo?.(PageSlaveMode.WHITEBOARD, { id: meetingId });
    });

    const leaveMeetingListener = bridgeMessageManager.addEventListener("leaveMeeting", () => {
      setMeeting(undefined);
      pageContextRef.current.goTo?.(PageSlaveMode.WHITEBOARD_WAITING);
    });

    const meetingIdListener = bridgeMessageManager.addEventListener("meetingId", (msg) => {
      const meetingId = msg.content.meetingId;
      setMeeting(undefined);
      if (meetingId !== id) pageContextRef.current.goTo?.(PageSlaveMode.WHITEBOARD, { id: meetingId });
    });

    const meetingListener = bridgeMessageManager.addEventListener("meeting", (msg) => {
      const meeting = msg.content.meeting;
      const session = msg.content.session;
      setMeeting(meeting);
      setSession(session);
    });

    const meetingExtraDataListener = bridgeMessageManager.addEventListener("meetingExtraData", (msg) => {
      const data = msg.content.data;
      setExtraData(data);
    });

    const whiteboardMessage = bridgeMessageManager.addEventListener("whiteboardMessage", (msg) => {
      const content = msg.content;
      if (isWhiteboardMessage(content, WhiteboardMessageAction.INSERT_IMAGE)) {
        whiteboardRef.current?.insertImage(content.data.dataURL, content.data.insertOnCanvasDirectly);
      }
    });

    if (id) bridgeMessageManager.postRequestMeeting();
    else bridgeMessageManager.postRequestMeetingId();

    return () => {
      bridgeMessageManager.removeEventListener(...readyListener);
      bridgeMessageManager.removeEventListener(...joinMeetingListener);
      bridgeMessageManager.removeEventListener(...leaveMeetingListener);
      bridgeMessageManager.removeEventListener(...meetingIdListener);
      bridgeMessageManager.removeEventListener(...meetingListener);
      bridgeMessageManager.removeEventListener(...meetingExtraDataListener);
      bridgeMessageManager.removeEventListener(...whiteboardMessage);
    };
  }, [pageContextRef, bridgeMessageManager, id]);

  useEffect(() => {
    const pageContext = pageContextRef.current;
    if (!pageContextIsSessionMode(SessionMode.SLAVE, pageContext) || !bridgeMessageManager) return;
    const page = pageContext.page;
    if (page && meeting) {
      const sessions = getSessions();
      const medias: string[] = [];
      sessions?.forEach((s) => {
        if (!s?.medias?.length) return;
        medias.push(s?.medias[0].id);
      });
      const enablePlayerAsBackground = pageSettings?.displayPlayerAsBackground?.enabled ?? DEFAULT_SLAVE_PAGE_SETTINGS.displayPlayerAsBackground.enabled;
      const newData = { enablePlayerAsBackground };
      if (dataRef.current === undefined || !deepEqual(dataRef.current, newData)) {
        bridgeMessageManager.postClientData(page, newData);
      }
      dataRef.current = newData;
    }
  }, [bridgeMessageManager, meeting, getSessions, pageSettings]);

  useEffect(() => {
    if (pageContextRef.current.mode !== SessionMode.SLAVE) return;

    if (hideMeetingControlsTimeoutRef.current) clearTimeout(hideMeetingControlsTimeoutRef.current);
    if (hideWhiteboardControlsTimeoutRef.current) clearTimeout(hideWhiteboardControlsTimeoutRef.current);
    if (sessionToDisplayInBackground) {
      let currDelay =
        pageSettings?.displayPlayerAsBackground?.autoHideMeetingControlsAfterDelay ??
        DEFAULT_SLAVE_PAGE_SETTINGS.displayPlayerAsBackground.autoHideMeetingControlsAfterDelay;
      if (currDelay > 0) {
        hideMeetingControlsTimeoutRef.current = setTimeout(() => {
          setHideMeetingControls(true);
        }, currDelay);
      }
      currDelay =
        pageSettings?.displayPlayerAsBackground?.autoHideWhiteboardControlsAfterDelay ??
        DEFAULT_SLAVE_PAGE_SETTINGS.displayPlayerAsBackground.autoHideWhiteboardControlsAfterDelay;
      if (currDelay > 0) {
        hideWhiteboardControlsTimeoutRef.current = setTimeout(() => {
          setHideWhiteboardControls(true);
        }, currDelay);
      }
    } else {
      setHideMeetingControls(false);
      setHideWhiteboardControls(false);
    }
    return () => {
      if (hideMeetingControlsTimeoutRef.current) clearTimeout(hideMeetingControlsTimeoutRef.current);
      if (hideWhiteboardControlsTimeoutRef.current) clearTimeout(hideWhiteboardControlsTimeoutRef.current);
    };
  }, [pageSettings, sessionToDisplayInBackground]);

  // ---------------------------------------------------------------- //
  // ---------------------------- RENDER ----------------------------- //
  // ---------------------------------------------------------------- //

  const renderControls = () => {
    if (!bridgeMessageManager || !meeting || !session) return null;
    if (pageContextRef.current.mode === SessionMode.SLAVE && !(pageSettings?.controls ?? DEFAULT_SLAVE_PAGE_SETTINGS.controls)) return null;
    return (
      <MeetingSlaveControls
        className={hideMeetingControls ? styles.hideElementTransition : styles.displayElementTransition}
        bridge={bridgeMessageManager}
        meeting={meeting}
        session={session}
        extraData={extraData}
      />
    );
  };

  const renderWhiteboard = () => {
    const currClasses = [styles.whiteboardContainer];
    const mediaToDisplayInBackground: MeetingSessionMedia<MeetingSessionMediaExtra> | undefined = sessionToDisplayInBackground?.medias?.[0];
    const renderPlayer = () => {
      if (!sessionToDisplayInBackground || !mediaToDisplayInBackground) return;
      const me = sessionToDisplayInBackground.id === session?.id;
      const user = sessionToDisplayInBackground.user;
      const media = mediaToDisplayInBackground;
      let muted = true;
      let volume = 1;
      let mode: "local" | "distant" = "local";
      if (!me && extraData?.displayVideoOnlyOnSpectatorPage) {
        mode = "distant";
        const audio = extraData?.audio?.[media.id];
        if (audio) {
          muted = audio.muted;
          volume = audio.volume;
        }
      }
      const internalTransmissionMode = RTCBridgeTransmissionMode.WEBRTC;
      return (
        <div style={{ position: "absolute", zIndex: 0, width: "100%", height: "100%", pointerEvents: "none" }}>
          {media ? (
            <>
              <MeetingPlayer
                noRender={{
                  header: true,
                  reactions: true,
                  footer: true,
                }}
                me={me}
                muted={muted}
                volume={volume}
                mode={mode}
                internalTransmissionMode={internalTransmissionMode}
                bridgeMessageManager={bridgeMessageManager}
                reactions={sessionToDisplayInBackground.reactions}
                reactionsEnabled={[]}
                session={sessionToDisplayInBackground}
                mediaSession={media}
                user={user}
                displayVideoStats={pageSettings?.debug ?? DEFAULT_SLAVE_PAGE_SETTINGS.debug}
                audioButtonEnabled={false}
                videoButtonEnabled={false}
                onMediaStream={(m) => {}}
                playerStyle={{ borderRadius: 0, backgroundColor: "#00000011" }}
                playerVideoStyle={{ objectFit: "contain" }}
              />
            </>
          ) : null}
        </div>
      );
    };
    const disableWhiteboardUI = hideWhiteboardControls;
    return (
      <div className={currClasses.join(" ")}>
        {renderPlayer()}
        <Whiteboard
          ref={whiteboardRef}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 1,
            pointerEvents: "auto",
          }}
          autojoin={pageSettings?.whiteboard?.autojoin ?? true}
          id={pageSettings?.whiteboard?.id ?? DEFAULT_SLAVE_PAGE_SETTINGS.whiteboard.id}
          name={pageSettings?.whiteboard?.name ?? DEFAULT_SLAVE_PAGE_SETTINGS.whiteboard.name}
          hideControls={disableWhiteboardUI}
          transparentBackground={sessionToDisplayInBackground ? true : false}
          favorites={settings.whiteboardFavorites}
          invisible={(isSpectator(session?.role) && isSuperUser(session?.role)) || (pageSettings?.whiteboard?.invisible ?? DEFAULT_SLAVE_PAGE_SETTINGS.whiteboard.invisible)}
          spectator={(isSpectator(session?.role) && !isSuperUser(session?.role)) || (pageSettings?.whiteboard?.spectator ?? DEFAULT_SLAVE_PAGE_SETTINGS.whiteboard.spectator)}
          menuButtons={{
            enableFavorite: true,
            enableReportBug: true,
            enableSwitchWhiteboard: true,
            enableLogout: true,
          }}
          hideNewIndicatorAfterDelay={pageSettings?.whiteboard?.hideNewIndicatorAfterDelay ?? DEFAULT_SLAVE_PAGE_SETTINGS.whiteboard.hideNewIndicatorAfterDelay}
          showNewVersionsOnFirstUse={pageSettings?.whiteboard?.showNewVersionsOnFirstUse ?? DEFAULT_SLAVE_PAGE_SETTINGS.whiteboard.showNewVersionsOnFirstUse}
          onJoin={(id: string, name?: string) => {
            if (!settings.whiteboardFavorites?.length) {
              // add first in favorites
              const newFavorites = settings.whiteboardFavorites ?? [];
              newFavorites.push({ id, name });
              saveSettings({
                whiteboardFavorites: newFavorites,
              });
            }
          }}
          onAddFavorite={(id: string, name?: string) => {
            const alreadyExists = settings.whiteboardFavorites?.find((d) => d.id === id && d.name === name);
            if (alreadyExists) {
              toastInfo("Ce tableau blanc est déjà dans vos favoris");
            } else {
              const newFavorites = settings.whiteboardFavorites ?? [];
              newFavorites.push({ id, name });
              saveSettings({
                whiteboardFavorites: newFavorites,
              });
            }
          }}
          onRemoveFavorite={(id: string, name?: string) => {
            const newFavorites = settings.whiteboardFavorites?.filter((d) => d.id !== id && d.name !== name);
            saveSettings({
              whiteboardFavorites: newFavorites,
            });
          }}
        />
        {renderControls()}
      </div>
    );
  };

  const renderHeader = () => {
    if (pageSettings?.header?.hide ?? DEFAULT_SLAVE_PAGE_SETTINGS.header.hide) return null;
    return (
      <MeetingHeader
        ref={meetingHeaderRef}
        meeting={meeting}
        helpUri={pageSettings?.header?.helpUri ?? DEFAULT_SLAVE_PAGE_SETTINGS.header.helpUri}
        hideClock={!(pageSettings?.header?.clock?.enabled ?? DEFAULT_SLAVE_PAGE_SETTINGS.header.clock.enabled)}
        displaySeconds={pageSettings?.header?.clock?.seconds ?? DEFAULT_SLAVE_PAGE_SETTINGS.header.clock.seconds}
      />
    );
  };

  return (
    <div ref={pageContainerDivRef} className={styles.page}>
      {renderHeader()}
      {renderWhiteboard()}
    </div>
  );
};

WhiteboardPage.defaultProps = {};

export default WhiteboardPage;

import { GridList } from "@kalyzee/kast-app-web-components";
import { MeetingSession } from "@kalyzee/kast-websocket-module";
import React, { ForwardedRef, useImperativeHandle } from "react";
import { DEFAULT_ASPECT_VIDEO_RATIO_TARGET } from "../helpers/settings";
import { useSettings } from "../hooks/settings";
import styles from "./MeetingPlayers.module.css";
import { usePageContextRef } from "./navigation/PageContext";

export enum MeetingPlayersDisplayMode {
  GRID = "grid",
  ONE_MAIN_AND_THE_OTHERS = "one_main_and_the_others",
}

export enum MeetingPlayersOrientation {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
  VERTICAL_REVERSE = "vertical_reverse",
  HORIZONTAL_REVERSE = "horizontal_reverse",
}

export interface MeetingPlayersOptionsMap {
  [MeetingPlayersDisplayMode.GRID]: undefined;
  [MeetingPlayersDisplayMode.ONE_MAIN_AND_THE_OTHERS]: {
    main?: MeetingSession;
    orientation?: MeetingPlayersOrientation;
    styles?: {
      main?: React.CSSProperties;
      others?: React.CSSProperties;
    };
    classes?: {
      main?: string;
      others?: string;
    };
  };
}

export interface MeetingPlayersProps<T extends MeetingPlayersDisplayMode> {
  mode: T;
  options: MeetingPlayersOptionsMap[T];
  sessions: MeetingSession[];
  renderItem: (index: number, item: MeetingSession) => React.ReactNode;
  aspectVideoRation?: number;
  gap?: number;
  className?: string;
  style?: React.CSSProperties;
}

export interface MeetingPlayersRef {}

const MeetingPlayers = React.forwardRef(
  <T extends MeetingPlayersDisplayMode>(
    { mode, options, sessions, renderItem, aspectVideoRation, gap, className, style }: MeetingPlayersProps<T>,
    forwardRef: ForwardedRef<MeetingPlayersRef | undefined>
  ) => {
    const [settings] = useSettings();
    const pageContextRef = usePageContextRef();

    useImperativeHandle(forwardRef, () => ({}));

    // ----------------------------------------------- //
    // ------------------ UTILS -------------------- //
    // ----------------------------------------------- //

    const keyExtractor = (index: number) => {
      if (index >= sessions.length) return `${index}`;
      return sessions[index].id ?? `${index}`;
    };

    // ----------------------------------------------- //
    // ------------------ EFFECTS -------------------- //
    // ----------------------------------------------- //

    // ----------------------------------------------- //
    // ------------------ RENDER -------------------- //
    // ----------------------------------------------- //

    const renderGrid = () => {
      const classes: string[] = [styles.grid];
      if (className) classes.push(className);
      return (
        <GridList
          noPages
          targetContentRatio={aspectVideoRation ?? DEFAULT_ASPECT_VIDEO_RATIO_TARGET}
          data={sessions}
          renderItem={(index: number) => {
            if (index >= sessions.length) return null;
            const session = sessions[index];
            return renderItem(index, session);
          }}
          keyExtractor={keyExtractor}
          gap={gap}
          className={classes.join(" ")}
          style={style}
        />
      );
    };

    const renderOnePreviewAndControls = () => {
      const classes: string[] = [styles.oneMainAndTheOthers__Container];
      if (className) classes.push(className);
      const orientationClassesMap: { [key in MeetingPlayersOrientation]: string } = {
        [MeetingPlayersOrientation.VERTICAL]: styles.oneMainAndTheOthers__Container_Vertical,
        [MeetingPlayersOrientation.VERTICAL_REVERSE]: styles.oneMainAndTheOthers__Container_VerticalReverse,
        [MeetingPlayersOrientation.HORIZONTAL]: styles.oneMainAndTheOthers__Container_Horizontal,
        [MeetingPlayersOrientation.HORIZONTAL_REVERSE]: styles.oneMainAndTheOthers__Container_HorizontalReverse,
      };
      const defaulOrientation = MeetingPlayersOrientation.VERTICAL;
      classes.push(orientationClassesMap[options?.orientation ?? defaulOrientation] ?? orientationClassesMap[defaulOrientation]);
      let mainSession: MeetingSession | undefined;
      let mainIndex = -1;
      sessions.forEach((s, i) => {
        if (s.id === options?.main?.id && !mainSession) {
          mainSession = s;
          mainIndex = i;
        }
      });

      const mainClasses = [styles.oneMainAndTheOthers__MainContainer];
      if (options?.classes?.main) mainClasses.push(options.classes.main);
      const othersClasses = [styles.oneMainAndTheOthers__Controls];
      if (options?.classes?.others) othersClasses.push(options.classes.others);
      return (
        <div className={classes.join(" ")} style={style}>
          {mainSession ? (
            <div className={mainClasses.join(" ")} style={options?.styles?.main}>
              {renderItem(mainIndex, mainSession)}
            </div>
          ) : null}
          <div className={styles.oneMainAndTheOthers__ControlsContainer}>
            {sessions.map((s, i) => {
              if (i === mainIndex) return null;

              return (
                <div key={keyExtractor(i)} className={othersClasses.join(" ")} style={options?.styles?.others}>
                  {renderItem(i, s)}
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    const renderMap: { [key in MeetingPlayersDisplayMode]: () => React.JSX.Element } = {
      [MeetingPlayersDisplayMode.GRID]: renderGrid,
      [MeetingPlayersDisplayMode.ONE_MAIN_AND_THE_OTHERS]: renderOnePreviewAndControls,
    };
    return renderMap[mode]();
  }
);

MeetingPlayers.defaultProps = {
  gap: 10,
  className: undefined,
  style: undefined,
};

export default MeetingPlayers;

import { Route, Routes } from "react-router-dom";
import { PageProvider } from "./PageContext";

export interface PageContainerProps {
  element?: React.ReactNode | null;
  children?: React.ReactNode;
}

const PageContainer = ({ element, children }: PageContainerProps) => {
  return (
    <PageProvider>
      <Routes>
        <Route path="/" element={element}>
          {children}
        </Route>
      </Routes>
    </PageProvider>
  );
};

export default PageContainer;

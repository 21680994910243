import React, { useContext } from "react";
import { MessagingBridgeManagerMaster, MessagingBridgeManager, MessagingBridgeManagerSlave } from "../helpers/messagingBridge";

export const MessagingBridgeContext = React.createContext<MessagingBridgeManagerMaster | MessagingBridgeManagerSlave | undefined>(undefined);

export const useMessagingBridgeManagerContext = (enabled: boolean = true): MessagingBridgeManagerMaster | MessagingBridgeManagerSlave | undefined => {
  const bridgeMessageManager = useContext(MessagingBridgeContext);
  if (!enabled) return undefined;
  if (!bridgeMessageManager) return undefined;
  return bridgeMessageManager;
};

export const useMessagingBridgeManagerMasterContext = (enabled: boolean = true): MessagingBridgeManagerMaster | undefined => {
  const bridgeMessageManager = useMessagingBridgeManagerContext(enabled);
  if (!bridgeMessageManager) return undefined;
  if (!MessagingBridgeManager.isMasterManager(bridgeMessageManager)) return undefined;
  return bridgeMessageManager;
};

export const useMessagingBridgeManagerSlaveContext = (enabled: boolean = true): MessagingBridgeManagerSlave | undefined => {
  const bridgeMessageManager = useMessagingBridgeManagerContext(enabled);
  if (!bridgeMessageManager) return undefined;
  if (!MessagingBridgeManager.isSlaveManager(bridgeMessageManager)) return undefined;
  return bridgeMessageManager;
};

import { AudioVolumeAnalyzerData } from "@kalyzee/kast-app-web-components";
import { MeetingSession } from "@kalyzee/kast-websocket-module";
import React, { ForwardedRef, useContext, useImperativeHandle, useRef, useState } from "react";
import { DEFAULT_SETTINGS_PLAYER, SettingsPlayer } from "../helpers/settings";
import { BACKGROUND_COMPONENT_CLASSNAME } from "../helpers/styles";
import { MeetingPlayerEventListenerMap, MeetingPlayerRef } from "./MeetingPlayer";
import styles from "./MeetingSessionContainer.module.css";

export interface MeetingSessionContainerContextState {
  addPlayer?: (ref: MeetingPlayerRef) => void;
  removePlayer?: (ref: MeetingPlayerRef) => void;
}
export const MeetingSessionContainerContext = React.createContext<MeetingSessionContainerContextState>({});

export interface MeetingSessionContainerProps {
  me: boolean;
  session: MeetingSession;
  playerSettings?: SettingsPlayer;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export interface MeetingSessionContainerRef {}

const MeetingSessionContainer = React.forwardRef(
  (
    { me, session, playerSettings, children, className, style }: MeetingSessionContainerProps,
    forwardRef: ForwardedRef<MeetingSessionContainerRef | undefined>
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const volumeDataRef = useRef<{ sum: number; count: number; time: number }>();
    const [playersRef] = useState<{ current: Map<MeetingPlayerRef, Partial<MeetingPlayerEventListenerMap>> }>({ current: new Map() });

    const updatePlayerListeners = () => {
      if (!playersRef.current.size) return;
      const player = Array.from(playersRef.current.keys())[0];
      const volumeEffectsEnabled = playerSettings?.volumeEffects?.enabled ?? DEFAULT_SETTINGS_PLAYER.volumeEffects.enabled;
      const samplingInterval = playerSettings?.volumeEffects?.samplingInterval ?? DEFAULT_SETTINGS_PLAYER.volumeEffects.samplingInterval;
      const volumeThreshold = playerSettings?.volumeEffects?.volumeThreshold ?? DEFAULT_SETTINGS_PLAYER.volumeEffects.volumeThreshold;
      
      const mediaStreamVolumeListener = (_: number, data: AudioVolumeAnalyzerData) => {
        const now = new Date().getTime();
        const voiceVolume = data.volumes.voice;
        if (!volumeDataRef.current) {
          volumeDataRef.current = {
            sum: voiceVolume,
            count: 1,
            time: now,
          };
        } else if (now - volumeDataRef.current.time > samplingInterval) {
          if (containerRef.current && volumeDataRef.current.count) {
            const finalVolume = volumeDataRef.current.sum / volumeDataRef.current.count;
            const div = containerRef.current;
            div.classList.remove(styles.volumeEffect);
            if (player?.audioIsEnabled && volumeEffectsEnabled) {
              if (finalVolume > volumeThreshold) {
                if (!div.classList.contains(styles.volumeEffect)) {
                  div.classList.add(styles.volumeEffect);
                }
              } else {
                if (div.classList.contains(styles.volumeEffect)) {
                  div.classList.remove(styles.volumeEffect);
                }
              }
            } else {
              div.classList.remove(styles.volumeEffect);
            }
          }
          volumeDataRef.current = undefined;
        } else {
          volumeDataRef.current.sum += voiceVolume;
          volumeDataRef.current.count += 1;
        }
      };
      const listeners: Partial<MeetingPlayerEventListenerMap> = {
        mediaStreamVolume: mediaStreamVolumeListener,
      };
      Object.keys(listeners).forEach((k) => {
        player.addEventListener(k as keyof MeetingPlayerEventListenerMap, listeners[k as keyof MeetingPlayerEventListenerMap] as any);
      });
      playersRef.current.set(player, listeners);
    };

    const addPlayer = (ref: MeetingPlayerRef) => {
      playersRef.current.forEach((listeners, player) => {
        Object.keys(listeners).forEach((key) => {
          player.removeEventListener(key as keyof MeetingPlayerEventListenerMap, listeners[key as keyof MeetingPlayerEventListenerMap] as any);
        });
      });
      playersRef.current.set(ref, {});
      updatePlayerListeners();
    };

    const removePlayer = (ref: MeetingPlayerRef) => {
      playersRef.current.forEach((listeners, player) => {
        Object.keys(listeners).forEach((key) => {
          player.removeEventListener(key as keyof MeetingPlayerEventListenerMap, listeners[key as keyof MeetingPlayerEventListenerMap] as any);
        });
      });
      playersRef.current.delete(ref);
      updatePlayerListeners();
    };

    useImperativeHandle(forwardRef, () => ({}), []);

    const classes: string[] = [styles.container, BACKGROUND_COMPONENT_CLASSNAME];
    if (className) classes.push(className);
    if (me) classes.push(styles.me);

    const volumeColorEffect = playerSettings?.volumeEffects?.color ?? DEFAULT_SETTINGS_PLAYER.volumeEffects.color;
    const volumeSizeEffect = playerSettings?.volumeEffects?.size ?? DEFAULT_SETTINGS_PLAYER.volumeEffects.size;

    return (
      <MeetingSessionContainerContext.Provider
        value={{
          addPlayer,
          removePlayer,
        }}
      >
        <div
          ref={containerRef}
          style={
            {
              "--volume-color-effect": volumeColorEffect,
              "--volume-size-effect": `${volumeSizeEffect}px`,
              ...(style ?? {}),
            } as any
          }
          className={classes.join(" ")}
          data-session-id={session.id}
        >
          {children}
        </div>
      </MeetingSessionContainerContext.Provider>
    );
  }
);

MeetingSessionContainer.defaultProps = {
  className: undefined,
  style: undefined,
};

export default MeetingSessionContainer;

export const useMeetingSessionContainerContext = (): MeetingSessionContainerContextState => {
  return useContext(MeetingSessionContainerContext);
};

export const useMeetingSessionContainerContextRef = (): { current: MeetingSessionContainerContextState } => {
  const context = useContext(MeetingSessionContainerContext);
  const ref = useRef<MeetingSessionContainerContextState>(context);
  ref.current = context;
  return ref;
};

import {
  MeetingExcludeRequest,
  MeetingGetByIdRequest,
  MeetingJoinRequest,
  MeetingLeaveRequest, MeetingMessageCreateRequest,
  MeetingMessageDeleteRequest,
  MeetingMessageGetRequest,
  MeetingMessageOnCreateEvent,
  MeetingMessageOnDeleteEvent,
  MeetingMessageOnReactionEvent,
  MeetingMessageOnUpdateEvent,
  MeetingMessageReactionRequest,
  MeetingMessageUpdateRequest, MeetingOnExcludeEvent,
  MeetingOnJoinEvent,
  MeetingOnLeaveEvent,
  MeetingSessionCustomEventRequest,
  MeetingSessionMediaOnUpdateEvent,
  MeetingSessionMediaUpdateRequest,
  MeetingSessionOnCustomEvent,
  MeetingSessionOnStatsEvent,
  MeetingSessionOnUpdateEvent, MeetingSessionUpdateRequest
} from "@kalyzee/kast-websocket-module";
import { createAction } from "@reduxjs/toolkit";
import { RTCStreamStats } from "../../helpers/rtc";
import { MeetingSessionExtra } from "../../interfaces/meeting";

const ACTION_PREFIX = "socket_meeting_";

const socketMeetingActions = {
  getById: createAction<MeetingGetByIdRequest>(`${ACTION_PREFIX}get_by_id`),
  join: createAction<MeetingJoinRequest>(`${ACTION_PREFIX}join`),
  leave: createAction<MeetingLeaveRequest>(`${ACTION_PREFIX}leave`),
  exclude: createAction<MeetingExcludeRequest>(`${ACTION_PREFIX}exclude`),
  udapteSessionById: createAction<{ data: MeetingSessionUpdateRequest<MeetingSessionExtra>; storeResult?: boolean }>(`${ACTION_PREFIX}update_session_by_id`),
  publishCustomEventSession: createAction<{ data: MeetingSessionCustomEventRequest }>(`${ACTION_PREFIX}publish_custom_event_session`),
  sessionStats: createAction<{ meetingId: string; sessionId: string; stats: { [meetingId: string]: RTCStreamStats } }>(`${ACTION_PREFIX}session_stats`),
  udapteSessionMediaById: createAction<{ data: MeetingSessionMediaUpdateRequest; storeResult?: boolean }>(`${ACTION_PREFIX}update_session_media_by_id`),
  getMessages: createAction<{ data: MeetingMessageGetRequest; storeResult?: boolean }>(`${ACTION_PREFIX}get_messages`),
  createMessage: createAction<{ data: MeetingMessageCreateRequest; storeResult?: boolean }>(`${ACTION_PREFIX}create_message`),
  updateMessage: createAction<{ data: MeetingMessageUpdateRequest; storeResult?: boolean }>(`${ACTION_PREFIX}update_mesage`),
  deleteMessage: createAction<{ data: MeetingMessageDeleteRequest; storeResult?: boolean }>(`${ACTION_PREFIX}delete_message`),
  setMessageReaction: createAction<{ data: MeetingMessageReactionRequest; storeResult?: boolean }>(`${ACTION_PREFIX}set_message_reaction`),
  onJoin: createAction<MeetingOnJoinEvent>(`${ACTION_PREFIX}on_join`),
  onLeave: createAction<MeetingOnLeaveEvent>(`${ACTION_PREFIX}on_leave`),
  onExclude: createAction<MeetingOnExcludeEvent>(`${ACTION_PREFIX}on_exclude`),
  onSessionUpdate: createAction<MeetingSessionOnUpdateEvent>(`${ACTION_PREFIX}on_session_update`),
  onSessionCustomEvent: createAction<MeetingSessionOnCustomEvent>(`${ACTION_PREFIX}on_session_custom_event`),
  onSessionStats: createAction<MeetingSessionOnStatsEvent>(`${ACTION_PREFIX}on_session_stats`),
  onSessionMediaUpdate: createAction<MeetingSessionMediaOnUpdateEvent>(`${ACTION_PREFIX}on_session_media_update`),
  onCreateMessage: createAction<MeetingMessageOnCreateEvent>(`${ACTION_PREFIX}on_create_message`),
  onUpdateMessage: createAction<MeetingMessageOnUpdateEvent>(`${ACTION_PREFIX}on_update_message`),
  onDeleteMessage: createAction<MeetingMessageOnDeleteEvent>(`${ACTION_PREFIX}on_delete_message`),
  onMessageReaction: createAction<MeetingMessageOnReactionEvent>(`${ACTION_PREFIX}on_message_reaction`),
};

export default socketMeetingActions;

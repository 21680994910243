import { Loading, useURL } from '@kalyzee/kast-app-web-components';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthStatus } from '../../interfaces/session';
import { NavigateGlobalQuery, getGlobalQuery, getGlobalQueryLabel } from '../../helpers/navigate';
import { PAGE_PATHS } from './PageContext';
import { SessionMode } from '../../store/session/slices';

interface PublicRedirectionRouteProps {
  mode: SessionMode;
}

const PublicRedirectionRoute = ({ mode }: PublicRedirectionRouteProps) => {
  const authStatus = AuthStatus.Out; //useAuthStatus();
  const url = useURL();
  const navigate = useNavigate();
  useEffect(() => {
    const newUrl = new URL(url);
    if (authStatus === AuthStatus.Out) {
      const currentPath = url.pathname.startsWith('/') ? url.pathname.replace('/', '') : url.pathname;
      newUrl.pathname = PAGE_PATHS[mode].login;
      if (currentPath.length && currentPath !== 'logout' && currentPath !== 'login') {
        const redirect = `${currentPath}${encodeURIComponent(url.search)}`;
        newUrl.searchParams.set('redirect', redirect);
      }
      if (getGlobalQuery(url.href, NavigateGlobalQuery.SLAVE)) {
        newUrl.searchParams.set(getGlobalQueryLabel(NavigateGlobalQuery.SLAVE), "true");
      }
      const navigateTo = newUrl.pathname + newUrl.search;
      navigate(navigateTo);
    }
  }, [authStatus, url, navigate]);
  if (authStatus !== AuthStatus.Out) {
    return <Loading fullscreen />;
  }
  return <Outlet />;
};

PublicRedirectionRoute.defaultProps = {
  redirectPath: undefined,
};

export default PublicRedirectionRoute;

import { PayloadAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { ResultEmittedMessage } from "@kalyzee/kast-websocket-module";
import { Axios } from '@kalyzee/kast-app-module';

// To use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;

export const _socketAppDispacth =
  (dispatch: AppDispatch) =>
  <T extends ResultEmittedMessage, P = any>(action: PayloadAction<P>): Promise<T> =>
    dispatch<Promise<T>, PayloadAction<P>>(action) as Promise<T>;

export const socketAppDispacth = <T extends ResultEmittedMessage, P = any>(dispatch: AppDispatch, action: PayloadAction<P>): Promise<T> =>
  _socketAppDispacth(dispatch)(action);

export const useSocketAppDispatch = () => {
  const dispatch = useAppDispatch();
  return _socketAppDispacth(dispatch);
};

const _requestAppDispacth =
  (dispatch: AppDispatch) =>
  <T = any, P = any>(action: PayloadAction<P>): Promise<Axios.AxiosResponse<T, any>> =>
    dispatch<Promise<Axios.AxiosResponse<T, any>>, PayloadAction<P>>(action) as Promise<Axios.AxiosResponse<T, any>>;

export const requestAppDispacth = <T = any, P = any>(dispatch: AppDispatch, action: PayloadAction<P>): Promise<Axios.AxiosResponse<T, any>> =>
  _requestAppDispacth(dispatch)(action);

export const useRequestAppDispatch = () => {
  const dispatch = useAppDispatch();
  return _requestAppDispacth(dispatch);
};
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

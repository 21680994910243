import {
  AudioVolumeAnalyzerData,
  getAudioTrack,
  getVideoTrack,
  Hover,
  MediaCaptureEnableAudioButton,
  MediaCaptureEnableAudioButtonRef,
  MediaCaptureEnableVideoButton,
  OverlayHoverMessage,
  Touchable,
  useEventListenersRef,
  UserAvatar,
  useStateWithRef,
} from "@kalyzee/kast-app-web-components";
import { Player, PlayerRef, VideoControls, VideoDisplayTimeMode } from "@kalyzee/kast-react-player-module";
import { OvenMediaReceiverWebRTCSession, RTCSession, SdpFormatter } from "@kalyzee/kast-webrtc-client-module";
import { MeetingSession, MeetingSessionMedia, MeetingSessionMediaState, MeetingSessionReaction, User } from "@kalyzee/kast-websocket-module";
import React, { createRef, ForwardedRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import ImgClapping from "../assets/icons/reaction-clapping.png";
import ImgOk from "../assets/icons/reaction-ok.png";
import ImgRaisedHand from "../assets/icons/reaction-raised-hand.png";
import ImgThumbDown from "../assets/icons/reaction-thumbdown.png";
import ImgThumbUp from "../assets/icons/reaction-thumbup.png";
import ImgTimeout from "../assets/icons/reaction-timeout.png";
import ImgUnvalidate from "../assets/icons/reaction-unvalidate.png";
import ImgValidate from "../assets/icons/reaction-validate.png";
import { KastWebRTCSession } from "../helpers/kastWebRTCSession";
import { logger } from "../helpers/logger";
import {
  MessagingBridgeManager,
  MessagingBridgeManagerMaster,
  MessagingBridgeManagerSlave,
  MessagingBridgeMessageAction,
  MessagingBridgeMessageExtended,
} from "../helpers/messagingBridge";
import { AverPTZPlayerOverlayAdapter, KastPTZPlayerOverlayAdapter } from "../helpers/ptzPlayerOverlayAdapters";
import {
  RTCBridgeMediaSessionEmitter,
  RTCBridgeMediaSessionMode,
  RTCBridgeMediaSessionReceiver,
  RTCBridgeTransmissionMode,
} from "../helpers/rtcBridgeMediaSession";
import { WhiteboardWebRTCSession } from "../helpers/whiteboardWebRTCSession";
import { MeetingSessionMediaExtra } from "../interfaces/meeting";
import { DisplayOnWhiteboardOptions, DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT } from "../interfaces/whiteboard";
import { KastPlayersRef } from "./KastPlayer";
import "./MeetingPlayer.css";
import styles from "./MeetingPlayer.module.css";
import MeetingPlayerButton, { MeetingPlayerButtonDataMap, MeetingPlayerButtonType } from "./MeetingPlayerButton";
import { useMeetingSessionContainerContext } from "./MeetingSessionContainer";
import {
  ErrorStatus,
  isPTZMode,
  isSourceDeviceType,
  MeetingSourceData,
  MeetingSourceDevicePTZ,
  MeetingSourceDevicePTZMode,
  MeetingSourceDeviceType,
  MeetingSourcePlayerRef,
} from "./MeetingSource";
import { MeetingSourcesRef } from "./MeetingSources";
import { OvenMediaPlayerRef } from "./OvenMediaPlayer";
import PTZPlayerOverlay, { PTZPlayerOverlayAdapter } from "./PTZPlayerOverlay";
import VideoStats from "./VideoStats";
import { WhiteboardPlayerRef } from "./WhiteboardPlayer";
import WhiteboardPlayerOverlay from "./WhiteboardPlayerOverlay";

const TIMEOUT_RETRY_INTERNE_WEBRTC = 5000;
const TIMEOUT_RETRY_OVENMEDIA_WEBRTC = 500;

const DEFAULT_REACTIONS_DATA: {
  [key in MeetingSessionReaction]?: { value: React.ReactNode };
} = {
  [MeetingSessionReaction.RAISED_HAND]: {
    value: <img src={ImgRaisedHand} className={[styles.reaction, styles.animateReaction, styles.animateRaisedHandReaction].join(" ")} alt="raised-hand" />,
  },
  [MeetingSessionReaction.CLAP]: {
    value: <img src={ImgClapping} className={[styles.reaction, styles.animateReaction, styles.animateClapReaction].join(" ")} alt="clapping" />,
  },
  [MeetingSessionReaction.THUMBSDOWN]: {
    value: <img src={ImgThumbDown} className={[styles.reaction, styles.animateReaction, styles.animateThumbsdownReaction].join(" ")} alt="thumb-down" />,
  },
  [MeetingSessionReaction.THUMBSUP]: {
    value: <img src={ImgThumbUp} className={[styles.reaction, styles.animateReaction, styles.animateThumbsupReaction].join(" ")} alt="thumbup" />,
  },
  [MeetingSessionReaction.VALIDATED]: {
    value: <img src={ImgValidate} className={[styles.reaction, styles.animateReaction, styles.animateValidatedReaction].join(" ")} alt="validate" />,
  },
  [MeetingSessionReaction.UNVALIDATED]: {
    value: <img src={ImgUnvalidate} className={[styles.reaction, styles.animateReaction, styles.animateUnvalidatedReaction].join(" ")} alt="unvalidate" />,
  },
  [MeetingSessionReaction.OK]: {
    value: <img src={ImgOk} className={[styles.reaction, styles.animateReaction, styles.animateOkReaction].join(" ")} alt="ok" />,
  },
  [MeetingSessionReaction.TIMEOUT]: {
    value: <img src={ImgTimeout} className={[styles.reaction, styles.animateReaction, styles.animateTimeoutReaction].join(" ")} alt="ok" />,
  },
} as const;

const DEFAULT_AUDIO_BUTTON_ENABLED = true;
const DEFAULT_VIDEO_BUTTON_ENABLED = true;

export enum MeetingPlayerRemoteButton {
  FULLSCREEN = "fullscreen",
}

const playerRefIsKastPlayerRef = (playerRef?: MeetingSourcePlayerRef | undefined): playerRef is KastPlayersRef => {
  if (!playerRef) return false;
  if ("player" in playerRef && playerRef.player === "kast") return true;
  return false;
};

const playerRefIsWhiteboardPlayerRef = (playerRef?: MeetingSourcePlayerRef | undefined): playerRef is WhiteboardPlayerRef => {
  if (!playerRef) return false;
  if ("player" in playerRef && playerRef.player === "whiteboard") return true;
  return false;
};

const playerRefIsOvenMediaPlayerRef = (playerRef?: MeetingSourcePlayerRef | undefined): playerRef is OvenMediaPlayerRef => {
  if (!playerRef) return false;
  if ("player" in playerRef && playerRef.player === "ovenmedia") return true;
  return false;
};

const enableAudio = (m: MediaStream, enable: boolean) => {
  const audioTracks = m.getAudioTracks();
  audioTracks.forEach((t) => (t.enabled = enable));
};

const enableVideo = (m: MediaStream, enable: boolean) => {
  const audioTracks = m.getVideoTracks();
  audioTracks.forEach((t) => (t.enabled = enable));
};

export interface MeetingPlayerEventListenerMap {
  remoteButton: (type: MeetingPlayerRemoteButton, enabled: boolean) => void;
  mediaStream: (media?: MediaStream) => void;
  source: (source?: MeetingSourceData) => void;
  audioEnable: (enable: boolean) => void;
  videoEnable: (enable: boolean) => void;
  reaction: (enable: boolean, reaction: MeetingSessionReaction, reactions: MeetingSessionReaction[]) => void;
  volume: (volume: number, muted: boolean) => void;
  mediaStreamVolume: (volume: number, data: AudioVolumeAnalyzerData) => void;
  displayOnWhiteboardOptions: (options: DisplayOnWhiteboardOptions | undefined) => void;
  onDisplayOnWhiteboardAction: (activate: boolean, data?: MeetingPlayerButtonDataMap[MeetingPlayerButtonType.DISPLAY_WHITEBOARD] | undefined) => void;
}

export interface MeetingPlayerProps {
  children?: React.ReactNode; // Override player
  me: boolean;
  mode: "distant" | "local" | "media" | "no_stream" | "no_stream_with_volum_control";
  internalTransmissionMode?: RTCBridgeTransmissionMode;
  mediaStream?: MediaStream;
  reactions: MeetingSessionReaction[];
  reactionsEnabled: MeetingSessionReaction[];
  session: MeetingSession<false, any>;
  mediaSession: MeetingSessionMedia<MeetingSessionMediaExtra>;
  muted?: boolean;
  volume?: number;
  user?: User;
  bridgeMessageManager?: MessagingBridgeManagerMaster | MessagingBridgeManagerSlave;
  sourcesRef?: React.MutableRefObject<MeetingSourcesRef | undefined>;
  displayVideoStats?: boolean;
  audioButtonEnabled?: boolean;
  videoButtonEnabled?: boolean;
  remoteControls?: {
    fullscreen?: { enabled: boolean; value: boolean };
  };
  noRender?: boolean | { header?: boolean; children?: boolean; info?: boolean; reactions?: boolean; footer?: boolean };
  leftHeaderElement?: React.ReactNode;
  rightHeaderElement?: React.ReactNode;
  displayOnWhiteboardIsEnabled?: boolean;
  onRemoteButton?: (type: MeetingPlayerRemoteButton, enabled: boolean) => void;
  onMediaStream?: (media?: MediaStream) => void;
  onSource?: (source?: MeetingSourceData) => void;
  onAudioEnable?: (enable: boolean) => void;
  onVideoEnable?: (enable: boolean) => void;
  onReaction?: (enable: boolean, reaction: MeetingSessionReaction, reactions: MeetingSessionReaction[]) => void;
  onVolume?: (volume: number, muted: boolean) => void;
  onMediaStreamVolume?: (volume: number, data: AudioVolumeAnalyzerData) => void;
  onDisplayOnWhiteboardOptions?: (options: DisplayOnWhiteboardOptions | undefined) => void;
  onDisplayOnWhiteboardAction?: (activate: boolean, data?: MeetingPlayerButtonDataMap[MeetingPlayerButtonType.DISPLAY_WHITEBOARD] | undefined) => void;
  playerClassName?: string;
  playerStyle?: React.CSSProperties;
  playerVideoStyle?: React.CSSProperties;
  className?: string;
  style?: React.CSSProperties;
}

export interface MeetingPlayerRef {
  videoIsEnabled: boolean;
  audioIsEnabled: boolean;
  enableAudio: (enable: boolean) => void;
  enableVideo: (enable: boolean) => void;
  setDisplayWhiteboardOptions: (options: Partial<DisplayOnWhiteboardOptions> | undefined) => void;
  disableAudioButton: (enable: boolean) => void;
  disableVideoButton: (enable: boolean) => void;
  disableReactionButton: (enable: boolean, reaction: MeetingSessionReaction) => void;
  disableReactionButtons: (enable: boolean) => void;
  audioButtonLoading: (enable: boolean) => void;
  videoButtonLoading: (enable: boolean) => void;
  displayWhiteboardButtonLoading: (enable: boolean) => void;
  reactionButtonLoading: (enable: boolean, reaction?: MeetingSessionReaction) => void;
  getReactions: () => MeetingSessionReaction[];
  setReactions: (reactions: MeetingSessionReaction[]) => void;
  getOvenmediaSessionInputStream: () => OvenMediaReceiverWebRTCSession | undefined;
  addEventListener: <T extends keyof MeetingPlayerEventListenerMap>(event: T, listener: MeetingPlayerEventListenerMap[T]) => void;
  removeEventListener: <T extends keyof MeetingPlayerEventListenerMap>(event: T, listener: MeetingPlayerEventListenerMap[T]) => void;
}

const MeetingPlayer = React.forwardRef(
  (
    {
      children,
      me,
      mode,
      internalTransmissionMode,
      mediaStream,
      muted,
      volume,
      reactions,
      reactionsEnabled,
      session,
      mediaSession,
      user,
      bridgeMessageManager,
      sourcesRef,
      displayVideoStats,
      audioButtonEnabled,
      videoButtonEnabled,
      remoteControls,
      noRender,
      leftHeaderElement,
      rightHeaderElement,
      displayOnWhiteboardIsEnabled,
      onRemoteButton,
      onMediaStream,
      onSource,
      onAudioEnable,
      onVideoEnable,
      onReaction,
      onVolume,
      onMediaStreamVolume,
      onDisplayOnWhiteboardOptions,
      onDisplayOnWhiteboardAction,
      playerClassName,
      playerStyle,
      playerVideoStyle,
      className,
      style,
    }: MeetingPlayerProps,
    forwardRef: ForwardedRef<MeetingPlayerRef | undefined>
  ) => {
    const listenersRef = useEventListenersRef<keyof MeetingPlayerEventListenerMap, MeetingPlayerEventListenerMap>();
    const [mediaId, setMediaId] = useState<string | undefined>(undefined);
    const [audio, setAudio, audioRef] = useStateWithRef<boolean>(mediaSession?.audio?.enabled ?? true);
    const [video, setVideo, videoRef] = useStateWithRef<boolean>(mediaSession?.video?.enabled ?? true);
    const [audioButtonDisabled, setAudioButtonDisabled] = useState<boolean>(!audioButtonEnabled);
    const [videoButtonDisabled, setVideoButtonDisabled] = useState<boolean>(!videoButtonEnabled);
    const [reactionButtonsDisabled, setReactionButtonsDisabled] = useState<{ [key in MeetingSessionReaction]?: boolean }>({});
    const [audioButtonLoading, setAudioButtonLoading] = useState<boolean>(false);
    const [videoButtonLoading, setVideoButtonLoading] = useState<boolean>(false);
    const [displayWhiteboardButtonLoading, setDisplayWhiteboardButtonLoading] = useState<boolean>(false);
    const [reactionButtonsLoading, setReactionButtonsLoading] = useState<{ [key in MeetingSessionReaction]?: boolean }>({});
    const [currReactions, setCurrReactions] = useState<MeetingSessionReaction[]>(reactions);
    const playerRef = useRef<PlayerRef>();
    const ovenMediaSrcRef = useRef<string | undefined>();
    const ovenMediaWebrtcSessionReceiverRef = useRef<OvenMediaReceiverWebRTCSession>();
    const onMediaStreamRef = useRef(onMediaStream);
    const onSourceRef = useRef(onSource);
    const rtcBridgeMediaSessionReceiverRef = useRef<RTCBridgeMediaSessionReceiver | undefined>(undefined);
    const rtcBridgeMediaSessionEmitterRef = useRef<RTCBridgeMediaSessionEmitter | undefined>(undefined);

    const [currMediaStream, setCurrMediaStream, currMediaStreamRef] = useStateWithRef<MediaStream | undefined>();
    const [source, setSource] = useState<MeetingSourceData>();
    const [sourceVideoPlayerRef, setSourceVideoPlayerRef] = useState<MeetingSourceData["video"]["player"]>();
    const componentIsDestroyedRef = useRef(false);
    const mediaCaptureEnableAudioButtonRef = useRef<MediaCaptureEnableAudioButtonRef>();
    const meetingSessionContainer = useMeetingSessionContainerContext();
    const selfRef = useRef<MeetingPlayerRef>();
    const [displayOnWhiteboardOptions, setDisplayOnWhiteboardOptions, displayOnWhiteboardOptionsRef] = useStateWithRef<DisplayOnWhiteboardOptions | undefined>(
      undefined
    );
    const classes: string[] = [styles.container];
    if (className) classes.push(className);

    const updateMediaStream = (media?: MediaStream) => {
      mediaCaptureEnableAudioButtonRef.current?.reload();
      if (media) enableAudio(media, audioRef.current);
      if (media) enableVideo(media, videoRef.current);
      triggerOnMediaStream(media);
      rtcBridgeMediaSessionEmitterRef.current?.updateMediaStream(media);
      rtcBridgeMediaSessionEmitterRef.current?.updateSource(ovenMediaWebrtcSessionReceiverRef.current);
      setCurrMediaStream(media);
    };

    const getForwardedRef = (): MeetingPlayerRef => {
      return {
        videoIsEnabled: video,
        audioIsEnabled: audio,
        enableAudio: (e) => {
          setAudio(e);
        },
        enableVideo: (e) => {
          setVideo(e);
        },
        setDisplayWhiteboardOptions: (options: Partial<DisplayOnWhiteboardOptions> | undefined) => {
          setDisplayOnWhiteboardOptions({ ...DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT, ...(options ?? {}) });
        },
        disableAudioButton: (d) => {
          setAudioButtonDisabled(d);
        },
        disableVideoButton: (d) => {
          setVideoButtonDisabled(d);
        },
        disableReactionButtons: (d) => {
          const disabled: { [key in MeetingSessionReaction]?: boolean } = {};
          reactionsEnabled.forEach((r) => {
            disabled[r] = d;
          });
          setReactionButtonsDisabled(disabled);
        },
        disableReactionButton: (d, r) => {
          setReactionButtonsDisabled({ ...reactionButtonsDisabled, [r]: d });
        },
        audioButtonLoading: (d) => {
          setAudioButtonLoading(d);
        },
        videoButtonLoading: (d) => {
          setVideoButtonLoading(d);
        },
        displayWhiteboardButtonLoading: (d) => {
          setDisplayWhiteboardButtonLoading(d);
        },
        reactionButtonLoading: (d, r) => {
          if (r) setReactionButtonsLoading({ ...reactionButtonsLoading, [r]: d });
          else {
            const allReactions = Object.values(MeetingSessionReaction);
            const data: any = {};
            allReactions.forEach((r) => {
              data[r] = d;
            });
            setReactionButtonsLoading(data);
          }
        },
        getReactions: () => currReactions,
        setReactions: setCurrReactions,
        getOvenmediaSessionInputStream: () => ovenMediaWebrtcSessionReceiverRef.current,
        addEventListener: (...args) => listenersRef.current?.addEventListener(...args),
        removeEventListener: (...args) => listenersRef.current?.removeEventListener(...args),
      };
    };

    useImperativeHandle(forwardRef, getForwardedRef, [currReactions, reactionButtonsDisabled, reactionButtonsLoading, reactionsEnabled, audio, video]);
    selfRef.current = getForwardedRef();

    // ---------------------------------------------------------------- //
    // ---------------------------- EFFECT ----------------------------- //
    // ---------------------------------------------------------------- //

    useEffect(() => {
      triggerDisplayOnWhiteboardOptions(displayOnWhiteboardOptions);
    }, [displayOnWhiteboardOptions]);

    useEffect(() => {
      return () => {
        componentIsDestroyedRef.current = true;
        rtcBridgeMediaSessionReceiverRef.current?.destroy();
        rtcBridgeMediaSessionReceiverRef.current = undefined;
        rtcBridgeMediaSessionEmitterRef.current?.destroy();
        rtcBridgeMediaSessionEmitterRef.current = undefined;
        ovenMediaWebrtcSessionReceiverRef.current?.destroy();
        ovenMediaWebrtcSessionReceiverRef.current = undefined;
      };
    }, []);

    useEffect(() => {
      const ref = selfRef.current;
      if (!ref) return;
      meetingSessionContainer.addPlayer?.(ref);
      return () => {
        meetingSessionContainer.removePlayer?.(ref);
      };
    });

    useEffect(() => {
      if (mode !== "media") return;
      updateMediaStream(mediaStream);
    }, [mode, mediaStream]);

    // Receiver
    useEffect(() => {
      if (mode !== "local") return;
      const session = new RTCBridgeMediaSessionReceiver(internalTransmissionMode);
      session.addEventListener("sdp", (mediaId: string, id: string, sdp: RTCSessionDescriptionInit | null) => {
        bridgeMessageManager?.postSdpWebrtc(mediaId, id, sdp);
      });
      session.addEventListener("candidate", (mediaId: string, id: string, candidate: RTCIceCandidateInit | null) => {
        bridgeMessageManager?.postIceCandidateWebrtc(mediaId, id, candidate);
      });
      session.addEventListener("close", (mediaId: string, id: string) => {
        bridgeMessageManager?.postRequestStopWebrtc(mediaId, id);
      });
      session.addEventListener("stream", (stream: MediaStream) => {
        if (session.mode === RTCBridgeMediaSessionMode.ENCODED_BUFFERS) {
          // Remove audio tracks => video error TODO
          updateMediaStream(new MediaStream(stream.getVideoTracks()));
        } else {
          updateMediaStream(stream);
        }
      });
      rtcBridgeMediaSessionReceiverRef.current = session;
      return () => {
        session.destroy();
      };
    }, [mode, mediaId, rtcBridgeMediaSessionReceiverRef, bridgeMessageManager, internalTransmissionMode]);

    // Emitter
    useEffect(() => {
      const modes = [RTCBridgeMediaSessionMode.MEDIASTREAM];
      if (RTCSession.supportEncodedBufferInterceptor()) modes.push(RTCBridgeMediaSessionMode.ENCODED_BUFFERS);
      const session = new RTCBridgeMediaSessionEmitter(modes);
      session.addEventListener("sdp", (mediaId: string, id: string, sdp: RTCSessionDescriptionInit | null) => {
        bridgeMessageManager?.postSdpWebrtc(mediaId, id, sdp);
      });
      session.addEventListener("candidate", (mediaId: string, id: string, candidate: RTCIceCandidateInit | null) => {
        bridgeMessageManager?.postIceCandidateWebrtc(mediaId, id, candidate);
      });
      session.addEventListener("close", (mediaId: string, id: string) => {
        bridgeMessageManager?.postRequestStopWebrtc(mediaId, id);
      });
      rtcBridgeMediaSessionEmitterRef.current = session;
      return () => {
        session.destroy();
      };
    }, [rtcBridgeMediaSessionEmitterRef, bridgeMessageManager]);

    useEffect(() => {
      setCurrReactions(reactions);
    }, [reactions]);

    useEffect(() => {
      onMediaStreamRef.current = onMediaStream;
    }, [onMediaStream]);
    useEffect(() => {
      onSourceRef.current = onSource;
    }, [onSource]);

    useEffect(() => {
      if (currMediaStream) {
        enableAudio(currMediaStream, audio);
      }
    }, [currMediaStream, audio]);

    useEffect(() => {
      if (currMediaStream) {
        enableVideo(currMediaStream, video);
      }
    }, [currMediaStream, video]);

    useEffect(() => {
      if (!mediaSession) return;
      setAudio(mediaSession.audio?.enabled);
      setVideo(mediaSession.video?.enabled);
    }, [mediaSession, me]);

    useEffect(() => {
      let closed = false;
      const stopOvenMediaSession = () => {
        if (ovenMediaWebrtcSessionReceiverRef.current) {
          ovenMediaWebrtcSessionReceiverRef.current.removeAllEventListeners();
          ovenMediaWebrtcSessionReceiverRef.current.destroy();
          ovenMediaWebrtcSessionReceiverRef.current = undefined;
        }
      };

      const startOvenMediaSession = () => {
        if (closed) return;
        stopOvenMediaSession();
        if (ovenMediaSrcRef.current) {
          let enableEncoderBufferInterceptor = false;
          if (internalTransmissionMode === RTCBridgeTransmissionMode.WEBCODECS || internalTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBCODECS) {
            enableEncoderBufferInterceptor = RTCSession.supportEncodedBufferInterceptor();
          }
          ovenMediaWebrtcSessionReceiverRef.current = new OvenMediaReceiverWebRTCSession({
            autoRetry: true,
            retryAfter: TIMEOUT_RETRY_OVENMEDIA_WEBRTC,
            rtc: {
              enableEncodedAudioBufferInterceptor: enableEncoderBufferInterceptor,
              enableEncodedVideoBufferInterceptor: enableEncoderBufferInterceptor,
            },
          });
          ovenMediaWebrtcSessionReceiverRef.current.addEventListener("stream", (s: MediaStream) => {
            updateMediaStream(s);
          });
          ovenMediaWebrtcSessionReceiverRef.current.start(ovenMediaSrcRef.current);
        }
      };

      if (!mediaSession || me || mode !== "distant") {
        ovenMediaSrcRef.current = undefined;
        stopOvenMediaSession();
      } else if (!me && mediaSession.state === MeetingSessionMediaState.READY && mediaSession.mediaSrc !== ovenMediaSrcRef.current) {
        ovenMediaSrcRef.current = mediaSession.mediaSrc;
        startOvenMediaSession();
      }
      return () => {
        closed = true;
      };
    }, [mediaSession, mode, me]);

    useEffect(() => {
      if (me && sourcesRef?.current) {
        const sources: MeetingSourcesRef = sourcesRef.current;
        const media = sources.getMedia();
        if (media?.getAudioTracks()?.length && media?.getVideoTracks()?.length) {
          updateMediaStream(media);
        }

        setSource(sources.getSource());

        const updateMedia = (source: MeetingSourceData) => {
          const currMedia = source.media;
          const videoTrack = currMedia ? getVideoTrack(currMedia) : undefined;
          const audioTrack = currMedia ? getAudioTrack(currMedia) : undefined;
          if (videoTrack && audioTrack) updateMediaStream(currMedia);
          else updateMediaStream(undefined);
        };

        const mediaListener = (track: MediaStreamTrack | undefined, source: MeetingSourceData) => {
          updateMedia(source);
        };
        const sourceListener = (source: MeetingSourceData) => {
          setSource(source);
          updateMedia(source);
        };
        const playerListener = <T extends "audio" | "video">(type: T, playerRef: MeetingSourceData[T]["player"], source: MeetingSourceData) => {
          if (type === "audio") return;
          setSourceVideoPlayerRef(playerRef);
        };
        sources.addEventListener("media", mediaListener);
        sources.addEventListener("source", sourceListener);
        sources.addEventListener("player", playerListener);
        return () => {
          sources.removeEventListener("media", mediaListener);
          sources.removeEventListener("source", sourceListener);
          sources.removeEventListener("player", playerListener);
        };
      }
      return;
    }, [me, sourcesRef]);

    useEffect(() => {
      triggerOnSource(source);
      if (source?.source?.onSelect?.displayOnWhiteboardOptions)
        setDisplayOnWhiteboardOptions({
          ...DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT,
          active: displayOnWhiteboardOptionsRef.current?.active ?? DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT.active,
          ...source?.source?.onSelect?.displayOnWhiteboardOptions,
        });
      else
        setDisplayOnWhiteboardOptions({
          ...DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT,
          active: displayOnWhiteboardOptionsRef.current?.active ?? DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT.active,
        });
    }, [source]);

    useEffect(() => {
      if (sourceVideoPlayerRef) {
        if (playerRefIsKastPlayerRef(sourceVideoPlayerRef)) {
          const updateScene = (ctx: any | undefined) => {
            const sceneId = ctx?.mixer?.scene_id;
            if (sceneId === undefined) return;
            const playerControlsRef = playerRef.current?.getControlsRef();
            playerControlsRef?.setScene(sceneId);
            // security
            setTimeout(() => {
              playerControlsRef?.setScene(sceneId);
            }, 500);
          };
          const currentContext = sourceVideoPlayerRef.getContext();
          updateScene(currentContext);

          const eventListeners = sourceVideoPlayerRef.getEventListener();
          if (eventListeners) {
            const onContextListener = (context: any | undefined) => updateScene(context);
            eventListeners.addEventListener("context", onContextListener);
            return () => {
              eventListeners.removeEventListener("context", onContextListener);
            };
          }
        }
      }
      return;
    }, [sourceVideoPlayerRef]);

    useEffect(() => {
      if (mediaId !== mediaSession?.id) setMediaId(mediaSession?.id);
    }, [mediaId, mediaSession]);

    useEffect(() => {
      if (audioButtonEnabled !== !audioButtonDisabled) setAudioButtonDisabled(!audioButtonEnabled);
    }, [audioButtonEnabled, audioButtonDisabled]);

    useEffect(() => {
      if (videoButtonEnabled !== !videoButtonDisabled) setVideoButtonDisabled(!videoButtonEnabled);
    }, [videoButtonEnabled, videoButtonDisabled]);

    // --------------
    // ---- BROADCAST - Receiver
    // --------------

    useEffect(() => {
      const bridge = rtcBridgeMediaSessionReceiverRef.current;
      if (mode === "local" && bridgeMessageManager && bridge && mediaId && mediaSession.state === MeetingSessionMediaState.READY) {
        let timeout: NodeJS.Timeout | undefined;
        const start = () => {
          if (timeout) clearTimeout(timeout);
          if (componentIsDestroyedRef.current) return;
          timeout = undefined;
          const id = bridge.prepare(mediaId);
          bridgeMessageManager.postRequestPrepareWebrtc(mediaId, id, bridge.modes);
          timeout = setTimeout(() => {
            start();
          }, TIMEOUT_RETRY_INTERNE_WEBRTC);
        };
        start();
        const webrtcReadyListener = async (
          msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_READY>,
          respond: (start: boolean) => void
        ) => {
          const currMediaId = msg.content.mediaId;
          const currId = msg.content.rtcSessionId;
          const mode = msg.content.mode;
          if (mediaId !== currMediaId) return;
          if (timeout) clearTimeout(timeout);
          // console.log('[RTC - RECEIVER - READY] - ', currId, ' : ', mode);
          const session = await bridge.start(mode, currMediaId, currId);
          if (session) respond(true);
          else respond(false);
        };
        const webrtcSdpListener = (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_SDP>) => {
          const currMediaId = msg.content.mediaId;
          const currId = msg.content.rtcSessionId;
          const sdp = msg.content.sdp;
          // console.log('[RTC - RECEIVER - PROCESS SDP] - ', currMediaId, mediaId, currId, id, ' : ', sdp);
          if (mediaId !== currMediaId) return;
          bridge.processRemoteSdp(currMediaId, currId, sdp);
        };
        const webrtcIceCandidateListener = (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE>) => {
          const currMediaId = msg.content.mediaId;
          const currId = msg.content.rtcSessionId;
          const iceCandidate = msg.content.iceCandidate;
          // console.log('[RTC - RECEIVER - PROCESS CANDIDATE] - ', currMediaId, id, ' : ', iceCandidate);
          if (mediaId !== currMediaId) return;
          bridge.processRemoteIceCandidate(currMediaId, currId, iceCandidate);
        };
        const webrtcStopListener = (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_STOP>) => {
          const currMediaId = msg.content.mediaId;
          const currId = msg.content.rtcSessionId;
          if (mediaId !== currMediaId) return;
          const closed = bridge?.close(currMediaId, currId);
          // RETRY
          if (closed) start();
        };

        if (MessagingBridgeManager.isMasterManager(bridgeMessageManager)) {
          bridgeMessageManager.addEventListener("webrtcIsReady", webrtcReadyListener);
          bridgeMessageManager.addEventListener("sdpWebrtc", webrtcSdpListener);
          bridgeMessageManager.addEventListener("iceCandidateWebrtc", webrtcIceCandidateListener);
          bridgeMessageManager.addEventListener("requestStopWebrtc", webrtcStopListener);
        } else if (MessagingBridgeManager.isSlaveManager(bridgeMessageManager)) {
          bridgeMessageManager.addEventListener("webrtcIsReady", webrtcReadyListener);
          bridgeMessageManager.addEventListener("sdpWebrtc", webrtcSdpListener);
          bridgeMessageManager.addEventListener("iceCandidateWebrtc", webrtcIceCandidateListener);
          bridgeMessageManager.addEventListener("requestStopWebrtc", webrtcStopListener);
        }
        return () => {
          if (timeout) clearTimeout(timeout);
          if (MessagingBridgeManager.isMasterManager(bridgeMessageManager)) {
            bridgeMessageManager.removeEventListener("webrtcIsReady", webrtcReadyListener);
            bridgeMessageManager.removeEventListener("sdpWebrtc", webrtcSdpListener);
            bridgeMessageManager.removeEventListener("iceCandidateWebrtc", webrtcIceCandidateListener);
            bridgeMessageManager.removeEventListener("requestStopWebrtc", webrtcStopListener);
          } else if (MessagingBridgeManager.isSlaveManager(bridgeMessageManager)) {
            bridgeMessageManager.removeEventListener("webrtcIsReady", webrtcReadyListener);
            bridgeMessageManager.removeEventListener("sdpWebrtc", webrtcSdpListener);
            bridgeMessageManager.removeEventListener("iceCandidateWebrtc", webrtcIceCandidateListener);
            bridgeMessageManager.removeEventListener("requestStopWebrtc", webrtcStopListener);
          }
        };
      }
    }, [mediaId, mediaSession.state, mode, bridgeMessageManager, rtcBridgeMediaSessionReceiverRef]);

    // --------------
    // ---- BROADCAST - Emitter
    // --------------

    useEffect(() => {
      if (mode === "distant" && internalTransmissionMode !== RTCBridgeTransmissionMode.DISABLED && bridgeMessageManager && mediaId) {
        const webrtcPrepareListener = async (
          msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_PREPARE>,
          respond: (ready: boolean, mode?: RTCBridgeMediaSessionMode) => void
        ) => {
          const currMediaId = msg.content.mediaId;
          const supportedModes = msg.content.supportedModes;
          if (mediaId !== currMediaId) return;
          if (!rtcBridgeMediaSessionEmitterRef.current) return;
          const mode = me ? RTCBridgeMediaSessionMode.MEDIASTREAM : rtcBridgeMediaSessionEmitterRef.current.getMode(supportedModes, internalTransmissionMode);
          if (!mode) respond(false);
          else respond(true, mode);
        };
        const webrtcStartListener = async (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_START>) => {
          const currMediaId = msg.content.mediaId;
          const currId = msg.content.rtcSessionId;
          const mode = msg.content.mode;
          if (mediaId !== currMediaId) return;
          if (!rtcBridgeMediaSessionEmitterRef.current) return;
          const ovenmediaMedia = ovenMediaWebrtcSessionReceiverRef.current;
          await rtcBridgeMediaSessionEmitterRef.current?.start(currMediaId, currId, mode, me ? undefined : ovenmediaMedia);
          if (me && currMediaStreamRef.current) {
            rtcBridgeMediaSessionEmitterRef.current.updateMediaStream(currMediaStreamRef.current);
          }
        };
        const webrtcStopListener = (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_STOP>) => {
          const currMediaId = msg.content.mediaId;
          const currId = msg.content.rtcSessionId;
          if (mediaId !== currMediaId) return;
          rtcBridgeMediaSessionEmitterRef.current?.close(currMediaId, currId);
        };
        const webrtcSdpListener = (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_SDP>) => {
          const currMediaId = msg.content.mediaId;
          const currId = msg.content.rtcSessionId;
          const sdp = msg.content.sdp;
          if (mediaId !== currMediaId) return;
          // console.log('[RTC - EMITTER - PROCESS SDP] - ', currId, ' : ', sdp);
          rtcBridgeMediaSessionEmitterRef.current?.processRemoteSdp(currMediaId, currId, sdp);
        };
        const webrtcIceCandidateListener = (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE>) => {
          const currMediaId = msg.content.mediaId;
          const currId = msg.content.rtcSessionId;
          const iceCandidate = msg.content.iceCandidate;
          if (mediaId !== currMediaId) return;
          // console.log('[RTC - EMITTER - PROCESS CANDIDATE] - ', currId, ' : ', iceCandidate);
          rtcBridgeMediaSessionEmitterRef.current?.processRemoteIceCandidate(currMediaId, currId, iceCandidate);
        };

        if (MessagingBridgeManager.isMasterManager(bridgeMessageManager)) {
          bridgeMessageManager.addEventListener("requestPrepareWebrtc", webrtcPrepareListener);
          bridgeMessageManager.addEventListener("requestStartWebrtc", webrtcStartListener);
          bridgeMessageManager.addEventListener("sdpWebrtc", webrtcSdpListener);
          bridgeMessageManager.addEventListener("iceCandidateWebrtc", webrtcIceCandidateListener);
          bridgeMessageManager.addEventListener("requestStopWebrtc", webrtcStopListener);
        } else if (MessagingBridgeManager.isSlaveManager(bridgeMessageManager)) {
          bridgeMessageManager.addEventListener("requestPrepareWebrtc", webrtcPrepareListener);
          bridgeMessageManager.addEventListener("requestStartWebrtc", webrtcStartListener);
          bridgeMessageManager.addEventListener("sdpWebrtc", webrtcSdpListener);
          bridgeMessageManager.addEventListener("iceCandidateWebrtc", webrtcIceCandidateListener);
          bridgeMessageManager.addEventListener("requestStopWebrtc", webrtcStopListener);
        }
        return () => {
          if (MessagingBridgeManager.isMasterManager(bridgeMessageManager)) {
            bridgeMessageManager.removeEventListener("requestPrepareWebrtc", webrtcPrepareListener);
            bridgeMessageManager.removeEventListener("requestStartWebrtc", webrtcStartListener);
            bridgeMessageManager.removeEventListener("sdpWebrtc", webrtcSdpListener);
            bridgeMessageManager.removeEventListener("iceCandidateWebrtc", webrtcIceCandidateListener);
            bridgeMessageManager.removeEventListener("requestStopWebrtc", webrtcStopListener);
          } else if (MessagingBridgeManager.isSlaveManager(bridgeMessageManager)) {
            bridgeMessageManager.removeEventListener("requestPrepareWebrtc", webrtcPrepareListener);
            bridgeMessageManager.removeEventListener("requestStartWebrtc", webrtcStartListener);
            bridgeMessageManager.removeEventListener("sdpWebrtc", webrtcSdpListener);
            bridgeMessageManager.removeEventListener("iceCandidateWebrtc", webrtcIceCandidateListener);
            bridgeMessageManager.removeEventListener("requestStopWebrtc", webrtcStopListener);
          }
        };
      }
    }, [mediaId, me, mode, bridgeMessageManager, rtcBridgeMediaSessionEmitterRef, ovenMediaWebrtcSessionReceiverRef, internalTransmissionMode]);

    // ----------------------------------------------- //
    // ------------------ EVENTS -------------------- //
    // ----------------------------------------------- //

    const triggerOnRemoteButton = useCallback(
      (type: MeetingPlayerRemoteButton, enabled: boolean) => {
        if (onRemoteButton) onRemoteButton(type, enabled);
        listenersRef.current?.triggerEvent("remoteButton", type, enabled);
      },
      [listenersRef, onRemoteButton]
    );

    const triggerOnMediaStream = useCallback(
      (media?: MediaStream) => {
        if (onMediaStreamRef.current) onMediaStreamRef.current(media);
        listenersRef.current?.triggerEvent("mediaStream", media);
      },
      [listenersRef]
    );

    const triggerOnSource = useCallback(
      (source?: MeetingSourceData) => {
        if (onSourceRef.current) onSourceRef.current(source);
        listenersRef.current?.triggerEvent("source", source);
      },
      [listenersRef]
    );

    const triggerOnAudioEnable = useCallback(
      (enabled: boolean) => {
        if (onAudioEnable) onAudioEnable(enabled);
        listenersRef.current?.triggerEvent("audioEnable", enabled);
      },
      [listenersRef, onAudioEnable]
    );

    const triggerOnVideoEnable = useCallback(
      (enabled: boolean) => {
        if (onVideoEnable) onVideoEnable(enabled);
        listenersRef.current?.triggerEvent("videoEnable", enabled);
      },
      [listenersRef, onVideoEnable]
    );

    const triggerOnReaction = useCallback(
      (enabled: boolean, reaction: MeetingSessionReaction, reactions: MeetingSessionReaction[]) => {
        if (onReaction) onReaction(enabled, reaction, reactions);
        listenersRef.current?.triggerEvent("reaction", enabled, reaction, reactions);
      },
      [listenersRef, onReaction]
    );

    const triggerOnVolume = useCallback(
      (volume: number, muted: boolean) => {
        if (onVolume) onVolume(volume, muted);
        listenersRef.current?.triggerEvent("volume", volume, muted);
      },
      [listenersRef, onVolume]
    );

    const triggeOnMediaStreamVolume = useCallback(
      (volume: number, data: AudioVolumeAnalyzerData) => {
        if (onMediaStreamVolume) onMediaStreamVolume(volume, data);
        listenersRef.current?.triggerEvent("mediaStreamVolume", volume, data);
      },
      [listenersRef, onMediaStreamVolume]
    );

    const triggerDisplayOnWhiteboardOptions = useCallback(
      (options: DisplayOnWhiteboardOptions | undefined) => {
        if (onDisplayOnWhiteboardOptions) onDisplayOnWhiteboardOptions(options);
        listenersRef.current?.triggerEvent("displayOnWhiteboardOptions", options);
      },
      [listenersRef, onDisplayOnWhiteboardOptions]
    );

    const triggerDisplayOnWhiteboardAction = useCallback(
      (activate: boolean, data?: MeetingPlayerButtonDataMap[MeetingPlayerButtonType.DISPLAY_WHITEBOARD] | undefined) => {
        if (onDisplayOnWhiteboardAction) onDisplayOnWhiteboardAction(activate, data);
        listenersRef.current?.triggerEvent("onDisplayOnWhiteboardAction", activate, data);
      },
      [listenersRef, onDisplayOnWhiteboardAction]
    );

    // ---------------------------------------------------------------- //
    // ---------------------------- RENDERING ----------------------------- //
    // ---------------------------------------------------------------- //

    const renderVideo = () => {
      if (mode === "no_stream" || mode === "no_stream_with_volum_control") return null;
      const style: React.CSSProperties = { ...(playerStyle ?? {}) };
      const hideVideo = mediaSession.state !== MeetingSessionMediaState.READY || !currMediaStream || !video;
      if (hideVideo) {
        style.visibility = "hidden";
        style.width = 0;
        style.height = 0;
        style.flex = 0;
      }
      const enableVolumeControl = !me;
      const enableFullscreenControl = false;
      const whiteboardWebRTCSession: WhiteboardWebRTCSession | undefined = playerRefIsWhiteboardPlayerRef(sourceVideoPlayerRef)
        ? sourceVideoPlayerRef.getWhiteboardWebRTCSession()
        : undefined;

      let ptzOverlay = false;
      let ptzAdapter: PTZPlayerOverlayAdapter | undefined;
      if (isSourceDeviceType(source?.video?.device, MeetingSourceDeviceType.OVENMEDIA)) {
        if (source?.video?.device?.data?.ptz) {
          const ptz = source?.video?.device?.data?.ptz;
          if (isPTZMode(ptz, MeetingSourceDevicePTZMode.AVER)) {
            if (ptz.data.hostname) {
              ptzOverlay = true;
              ptzAdapter = new AverPTZPlayerOverlayAdapter(ptz.data);
            }
          }
        }
      } else if (isSourceDeviceType(source?.video?.device, MeetingSourceDeviceType.KAST)) {
        const kastWebRTCSession: KastWebRTCSession | undefined = playerRefIsKastPlayerRef(sourceVideoPlayerRef)
          ? sourceVideoPlayerRef.getKastWebRTCSession()
          : undefined;
        if (kastWebRTCSession) {
          ptzOverlay = true;
          ptzAdapter = new KastPTZPlayerOverlayAdapter(kastWebRTCSession, {});
        }
      }
      const videoStyle: React.CSSProperties = { objectFit: "cover" };
      let extraVideoStyle: any;
      if (me && source) {
        if (isSourceDeviceType(source?.video?.device, MeetingSourceDeviceType.KAST)) {
          Object.assign(videoStyle, { objectFit: "contain" });
        }
        extraVideoStyle = source?.video?.device?.extra?.style;
      } else {
        if (mediaSession.video.deviceType === MeetingSourceDeviceType.KAST) {
          Object.assign(videoStyle, { objectFit: "contain" });
        }
        extraVideoStyle = mediaSession?.video.extra?.style;
      }
      if (extraVideoStyle && typeof extraVideoStyle === "object") {
        Object.assign(videoStyle, extraVideoStyle);
      }

      if (playerVideoStyle) Object.assign(videoStyle, playerVideoStyle);

      const classes = [styles.video];
      if (playerClassName) classes.push(playerClassName);
      return (
        <>
          <Player
            ref={playerRef}
            style={style}
            className={classes.join(" ")}
            videoStyle={videoStyle}
            mute={muted}
            volume={volume}
            media={currMediaStream}
            autoPlay
            live
            enableZoomKeyboardEvent={false}
            enableMoveKeyboardEvent={false}
            enableSceneKeyboardEvent={false}
            enableAssignViewKeyboardEvent={false}
            enableViewKeyboardEvent={false}
            enableVolume={enableVolumeControl}
            enableFullscreen={enableFullscreenControl}
            enableVideoControls={enableVolumeControl || enableFullscreenControl}
            enableCameraControls={false}
            alwaysDisplayCameraControls={false}
            alwaysDisplayVideoControls={false}
            onVolume={(volume: number) => {
              const videoRef = playerRef.current?.getVideoRef();
              if (!videoRef) return;
              triggerOnVolume(volume, videoRef.getMute());
            }}
            onMute={(muted: boolean) => {
              const videoRef = playerRef.current?.getVideoRef();
              if (!videoRef) return;
              triggerOnVolume(videoRef.getVolume(), muted);
            }}
          />
          {ptzOverlay && ptzAdapter ? (
            <PTZPlayerOverlay
              style={{ position: "absolute", top: 0, left: 0, zIndex: 1, width: "100%", height: "100%", padding: "5px" }}
              adapter={ptzAdapter}
            />
          ) : null}
          {displayVideoStats ? <VideoStats media={currMediaStream} mode={"input"} className={styles.videoStats} /> : null}
          {whiteboardWebRTCSession ? <WhiteboardPlayerOverlay style={{ zIndex: 1 }} session={whiteboardWebRTCSession} /> : null}
        </>
      );
    };

    const renderInfo = () => {
      if (mode === "no_stream") {
        return (
          <div className={styles.info} style={{ padding: "10px 0px" }}>
            <UserAvatar className={styles.avatar} username={user?.username} image={user?.avatar} mode="image" />
          </div>
        );
      }
      if (mode === "no_stream_with_volum_control") {
        let currMutedValue = muted;
        let currVolumeValue = volume;
        return (
          <div className={styles.info} style={{ padding: "10px 0px", gap: "5px" }}>
            <UserAvatar className={styles.avatar} username={user?.username} image={user?.avatar} mode="image" />
            <VideoControls
              className={styles.playerNoStreamVolumControls}
              mute={currMutedValue}
              volume={currVolumeValue}
              onVolume={(volume: number) => {
                currVolumeValue = volume;
                if (currMutedValue !== undefined && currVolumeValue !== undefined) triggerOnVolume(currVolumeValue, currMutedValue);
              }}
              onMute={(muted: boolean) => {
                currMutedValue = muted;
                if (currMutedValue !== undefined && currVolumeValue !== undefined) triggerOnVolume(currVolumeValue, currMutedValue);
              }}
              displayFullscreen={false}
              displayPlay={false}
              displayProgressBar={false}
              displayTime={VideoDisplayTimeMode.NONE}
            />
          </div>
        );
      }
      let message: string | undefined;
      if (!video) {
        message = me ? "Vous avez désactivé la vidéo." : "La vidéo a été désactivée.";
      }
      if (mediaSession.state === MeetingSessionMediaState.READY && !currMediaStream) {
        message = "Récupération du média en cours...";
      }

      if (mediaSession.state === MeetingSessionMediaState.PUBLISHING) {
        message = "Publication du média en cours...";
        return (
          <div className={styles.info}>
            <UserAvatar className={styles.avatar} username={user?.username} image={user?.avatar} mode="image" />
            <div>{"Publication du média en cours..."}</div>
          </div>
        );
      } else if (me && mediaSession.state === MeetingSessionMediaState.NO_AUDIO_TRACK) {
        message = "Impossible de récupérer le flux audio";
      } else if (me && mediaSession.state === MeetingSessionMediaState.NO_VIDEO_TRACK) {
        message = "Impossible de récupérer le flux video";
      } else if (me && mediaSession.state === MeetingSessionMediaState.ERROR) {
        logger.log("Mediastate : ", mediaSession.state);
        message = "Une erreur est apparue.\nVeuillez contacter un administrateur";
      } else if (me && mediaSession.state === MeetingSessionMediaState.WAITING) {
        message = "Récupération du média en cours...";
        if (source?.video?.error || source?.audio?.error) {
          message = "Impossible de récupérer le flux vidéo et/ou audio";
          if (
            source.video.device?.type === MeetingSourceDeviceType.WEB &&
            source.audio.device?.type === MeetingSourceDeviceType.WEB &&
            source?.video?.error === ErrorStatus.PERMISSION_DENIED &&
            source?.audio?.error === ErrorStatus.PERMISSION_DENIED
          ) {
            message = "Veuillez autoriser l'accès à la caméra et au microphone";
          } else if (source.audio.device?.type === MeetingSourceDeviceType.WEB && source?.audio?.error === ErrorStatus.PERMISSION_DENIED) {
            message = "Veuillez autoriser l'accès au micro";
          } else if (source.video.device?.type === MeetingSourceDeviceType.WEB && source?.video?.error === ErrorStatus.PERMISSION_DENIED) {
            message = "Veuillez autoriser l'accès à la caméra";
          }
        }
      } else if (mediaSession.state !== MeetingSessionMediaState.READY) {
        message = me ? "Impossible de récupérer le flux vidéo et/ou audio" : "Aucun flux n'est actuellement diffusé.";
      }

      if (message) {
        return (
          <div className={styles.info}>
            <UserAvatar className={styles.avatar} username={user?.username} image={user?.avatar} mode="image" />
            <div className={styles.message}>{message}</div>
          </div>
        );
      }

      return null;
    };

    const renderHeader = () => {
      const headerCenterRef = createRef<HTMLDivElement>();
      const startedAt = session.startedAt ? new Date(session.startedAt) : undefined;
      const videoLabel = mediaSession?.video?.label?.trim();
      const videoDevice = mediaSession?.video?.deviceType;
      const audioLabel = mediaSession?.audio?.label?.trim();
      const audioDevice = mediaSession?.audio?.deviceType;
      const source = mediaSession?.name;
      const errors = [];
      if (mediaSession?.extra?.source?.video?.error) errors.push("video: " + mediaSession?.extra?.source?.video.error);
      if (mediaSession?.extra?.source?.audio?.error) errors.push("audio: " + mediaSession?.extra?.source?.audio.error);
      return (
        <div className={styles.header}>
          <div className={styles.headerLeft}>{leftHeaderElement}</div>
          <div ref={headerCenterRef} className={styles.headerCenter}>
            {mediaSession.state === MeetingSessionMediaState.READY ? <span className={styles.publishIndicator}>・</span> : null}
            <div className={styles.headerText}>{user?.username ?? "-"}</div>
          </div>
          <div className={styles.headerRight}>{rightHeaderElement}</div>

          <Hover targetRef={headerCenterRef}>
            <div className={styles.headerHoverContainer}>
              <div className={styles.headerHoverRow}>
                <span className={styles.headerHoverTitle}>Role: </span>
                {`${session.role}`}
              </div>
              <div className={styles.headerHoverRow}>
                <span className={styles.headerHoverTitle}>Media: </span>
                {`${mediaSession.state}`}
              </div>
              {startedAt ? (
                <div className={styles.headerHoverRow}>
                  <span className={styles.headerHoverTitle}>Connecté depuis: </span>
                  {`${startedAt.toLocaleDateString()} ${startedAt.toLocaleTimeString()}`}
                </div>
              ) : undefined}
              <div className={styles.headerHoverRow}>
                <span className={styles.headerHoverTitle}>Source: </span>
                {`${source ?? "-"}`}
              </div>
              <div className={styles.headerHoverRow}>
                <span className={styles.headerHoverTitle}>Video: </span>
                {`${videoLabel ?? "-"} (${videoDevice ?? "-"})`}
              </div>
              <div className={styles.headerHoverRow}>
                <span className={styles.headerHoverTitle}>Audio: </span>
                {`${audioLabel ?? "-"} (${audioDevice ?? "-"})`}
              </div>
              {errors.length ? (
                <div className={styles.headerHoverRow}>
                  <span className={styles.headerHoverTitle}>Erreur: </span>
                  {errors.join(", ")}
                </div>
              ) : null}
            </div>
          </Hover>
        </div>
      );
    };

    const renderFooter = () => {
      const renderReactionButton = (reaction: MeetingSessionReaction) => {
        if (!reactionsEnabled.includes(reaction)) return null;
        const currClasses = [styles.reactionButton];
        const enabled = currReactions.includes(reaction);
        const value = DEFAULT_REACTIONS_DATA[reaction]?.value;
        if (!value) return null;
        return (
          <MeetingPlayerButton
            type={MeetingPlayerButtonType.CUSTOM}
            className={enabled ? styles.reactionButtonEnabled : undefined}
            key={reaction}
            loading={reactionButtonsLoading[reaction]}
          >
            <Touchable
              className={currClasses.join(" ")}
              disabled={reactionButtonsDisabled[reaction]}
              onPress={() => {
                const currEnabled = currReactions.includes(reaction);
                // ADD REACTION
                // const newValue = currEnabled ? currReactions.filter((r) => r !== reaction) : [...currReactions, reaction];
                // setCurrReactions(newValue);
                // SET REACTION
                const newValue = currEnabled ? [] : [reaction];
                setCurrReactions(newValue);
                triggerOnReaction(!currEnabled, reaction, newValue);
              }}
            >
              {value}
            </Touchable>
          </MeetingPlayerButton>
        );
      };
      const renderReactionButtons = () => {
        if (!reactionsEnabled?.length) return null;
        const elements = reactionsEnabled.map((r) => renderReactionButton(r as MeetingSessionReaction));
        return elements.filter((el) => el !== null);
      };
      const currDisplayOnWhiteboardOptions = mediaSession?.extra?.displayOnWhiteboard;
      const displayOnWhiteboardRef = createRef<HTMLDivElement>();
      return (
        <div className={styles.footer}>
          <MeetingPlayerButton type={MeetingPlayerButtonType.CUSTOM} loading={audioButtonLoading} className={styles.videoAudioButtonContainer}>
            <MediaCaptureEnableAudioButton
              ref={mediaCaptureEnableAudioButtonRef}
              className={styles.audioButton}
              input={currMediaStream}
              enabled={audio}
              clickEnabled={!audioButtonDisabled}
              onEnable={(e) => {
                if (e === audio) return; // TODO
                setAudio(e);
                triggerOnAudioEnable(e);
              }}
              onAudioVolumeAnalizer={(volume, data) => {
                triggeOnMediaStreamVolume(volume, data);
              }}
            />
          </MeetingPlayerButton>
          <MeetingPlayerButton type={MeetingPlayerButtonType.CUSTOM} loading={videoButtonLoading} className={styles.videoAudioButtonContainer}>
            <MediaCaptureEnableVideoButton
              className={styles.videoButton}
              enabled={video}
              clickEnabled={!videoButtonDisabled}
              onEnable={(e) => {
                if (e === video) return; // TODO
                setVideo(e);
                triggerOnVideoEnable(e);
              }}
              disabled={videoButtonDisabled}
            />
          </MeetingPlayerButton>
          {renderReactionButtons()}
          {remoteControls?.fullscreen?.enabled &&
          !(displayOnWhiteboardIsEnabled && currDisplayOnWhiteboardOptions?.enabled && currDisplayOnWhiteboardOptions?.active) ? (
            <MeetingPlayerButton
              key={"remote_fullscreen"}
              type={MeetingPlayerButtonType.FULLSCREEN}
              enabled={remoteControls.fullscreen.value}
              onEnabled={(e: boolean) => triggerOnRemoteButton(MeetingPlayerRemoteButton.FULLSCREEN, e)}
            />
          ) : null}
          {currDisplayOnWhiteboardOptions?.enabled &&
          (displayOnWhiteboardIsEnabled ||
            (currDisplayOnWhiteboardOptions?.active &&
              ((currDisplayOnWhiteboardOptions?.localDisplay && me) || currDisplayOnWhiteboardOptions?.remoteDisplay))) &&
          !(remoteControls?.fullscreen?.enabled && remoteControls?.fullscreen?.value) ? (
            <div ref={displayOnWhiteboardRef}>
              <MeetingPlayerButton
                buttonDisabled={!displayOnWhiteboardIsEnabled}
                loading={displayWhiteboardButtonLoading}
                key={"display_whiteboard"}
                type={MeetingPlayerButtonType.DISPLAY_WHITEBOARD}
                enabled={currDisplayOnWhiteboardOptions?.active}
                data={undefined /*{ controls: !(displayOnWhiteboardOptions?.hideMeetingControls && displayOnWhiteboardOptions.hideWhiteboard) }*/}
                onEnabled={(e: boolean) => {
                  setDisplayOnWhiteboardOptions({ ...DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT, ...(currDisplayOnWhiteboardOptions ?? {}), active: e });
                  triggerDisplayOnWhiteboardAction(e);
                }}
              />
              {currDisplayOnWhiteboardOptions?.active ? (
                <OverlayHoverMessage targetRef={displayOnWhiteboardRef} message={"Le flux est actuellement partagé sur un autre support"} />
              ) : null}
            </div>
          ) : null}
        </div>
      );
    };

    const renderReactions = () => {
      const renderReaction = (reaction: MeetingSessionReaction) => DEFAULT_REACTIONS_DATA[reaction]?.value;
      const elements = (currReactions ?? []).map((r) => renderReaction(r)).filter((el) => el !== null);
      if (!elements?.length) return null;
      return <div className={styles.reactionsContainer}>{elements}</div>;
    };

    if (typeof noRender === "boolean" && noRender) return null;
    let noRenderHeader = false;
    let noRenderChildren = false;
    let noRenderInfo = false;
    let noRenderReactions = false;
    let noRenderFooter = false;
    if (typeof noRender === "object" && noRender) {
      if (noRender.header) noRenderHeader = true;
      if (noRender.children) noRenderChildren = true;
      if (noRender.info) noRenderInfo = true;
      if (noRender.reactions) noRenderReactions = true;
      if (noRender.footer) noRenderFooter = true;
    }
    return (
      <div className={classes.join(" ")} style={style} data-media-id={mediaId}>
        {noRenderHeader ? null : renderHeader()}
        <div className={styles.content}>
          {noRenderChildren ? null : children ?? renderVideo()}
          {noRenderInfo ? null : renderInfo()}
          {noRenderReactions ? null : renderReactions()}
        </div>
        {noRenderFooter ? null : renderFooter()}
      </div>
    );
  }
);

MeetingPlayer.defaultProps = {
  internalTransmissionMode: RTCBridgeTransmissionMode.PREFER_WEBRTC,
  displayVideoStats: false,
  audioButtonEnabled: DEFAULT_AUDIO_BUTTON_ENABLED,
  videoButtonEnabled: DEFAULT_VIDEO_BUTTON_ENABLED,
  noRender: false,
  muted: true,
  volume: 1,
  className: undefined,
  style: undefined,
};

export default MeetingPlayer;

import KastAppClient, { DeviceCodeTokenStrategy, OAuth2DeviceOutputDTO, OAUTH2_TOKEN_ERROR_TYPE, Tokens } from "@kalyzee/kast-app-module";
import { EventListeners } from "@kalyzee/kast-app-web-components";

const STEP_INTERVAL_MS = 200;

const INVALID_REQUEST_MESSAGE = 'invalid request';
const UNAUTHORIZED_MESSAGE = 'unautorized';
const SERVER_ERROR = 'server error';
const AUTHORIZATION_PENDING = 'authorization pending';
const TIME_IS_UP = 'time is up';

export const TokenErrorToMessage: { [key in OAUTH2_TOKEN_ERROR_TYPE]: string } = {
    [OAUTH2_TOKEN_ERROR_TYPE.INVALID_REQUEST]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.INVALID_CLIENT]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.INVALID_GRANT]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.INVALID_SCOPE]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.UNAUTHORIZED_CLIENT]: UNAUTHORIZED_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.UNSUPPORTED_GRANT_TYPE]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.SERVER_ERROR]: SERVER_ERROR,
    [OAUTH2_TOKEN_ERROR_TYPE.AUTHORIZATION_PENDING]: AUTHORIZATION_PENDING,
    [OAUTH2_TOKEN_ERROR_TYPE.SLOW_DOWN]: INVALID_REQUEST_MESSAGE, // should not be displayed
    [OAUTH2_TOKEN_ERROR_TYPE.ACCESS_DENIED]: UNAUTHORIZED_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.EXPIRED_TOKEN]: TIME_IS_UP,
}

export enum OAuth2CodeSessionStatus {
  IDLE = "idle",
  IN_PROGRESS = "in_progress",
  ERROR = "error",
  SUCCESS = "success"
}

export interface OAuth2CodeSessionData {
  codes?: OAuth2DeviceOutputDTO;
  tokens?: Tokens;
  error?: any;
  errorMessage?: string;
  status: OAuth2CodeSessionStatus;
  /*time?: {
    startAt: number,
    expireAt: number,
    progress: number,
    remaining: number,
  }*/
}

export interface OAuth2CodeSessionEventListenerMap {
  codes: (codes: OAuth2DeviceOutputDTO) => void;
  tokens: (tokens: Tokens) => void;
  error: (error: any) => void;
  timeProgress: (remainingTime: number, progress: number, startTime: number, endTime: number) => void;
  status: (status: OAuth2CodeSessionStatus) => void;
  data: (data: OAuth2CodeSessionData) => void;
}

export class OAuth2CodeSession {
  private eventListeners: EventListeners<keyof OAuth2CodeSessionEventListenerMap, OAuth2CodeSessionEventListenerMap>;
  private status: OAuth2CodeSessionStatus = OAuth2CodeSessionStatus.IDLE;
  private counter: number = 0;
  private data: OAuth2CodeSessionData;
  private progressInterval?: NodeJS.Timer;
  private currentStrategy?: DeviceCodeTokenStrategy; 

  constructor(private client: KastAppClient, private clientId: string) {
    this.eventListeners = new EventListeners<keyof OAuth2CodeSessionEventListenerMap, OAuth2CodeSessionEventListenerMap>();
    this.data = {
      status: OAuth2CodeSessionStatus.IDLE,
    };
  }


  async start() {
    this.counter ++;
    const currentCounter = this.counter;
    this.status = OAuth2CodeSessionStatus.IN_PROGRESS;
    this.eventListeners.triggerEvent("status", this.status);
    const data: OAuth2CodeSessionData = {
      status: this.status
    };
    this.eventListeners.triggerEvent("data", {...data});
    const isCurrentSession = () => currentCounter === this.counter && this.status === OAuth2CodeSessionStatus.IN_PROGRESS;
    const strategy = new DeviceCodeTokenStrategy(this.clientId, (c: OAuth2DeviceOutputDTO) => {
      if(!isCurrentSession()) return;
      this.eventListeners.triggerEvent("codes", c);
      data.codes = c;
      this.eventListeners.triggerEvent("data", {...data});
      const expireAtMs = new Date(c.expires_at).getTime();
      const startMs = new Date().getTime();
      const totalMs = expireAtMs - startMs;
      const interval = setInterval(() => {
        const nowMs = new Date().getTime();
        if (nowMs > expireAtMs) {
          clearInterval(interval);
          return;
        }
        const progress = (nowMs - startMs) / totalMs;
        const remainingMs = Math.max(0, expireAtMs - nowMs);
        this.eventListeners.triggerEvent("timeProgress", remainingMs, progress, startMs, expireAtMs);
        /*data.time = {
          startAt: startMs,
          expireAt: expireAtMs,
          remaining: remainingMs,
          progress,
        }
        this.eventListeners.triggerEvent("data", {...data});*/
      }, STEP_INTERVAL_MS);
      this.progressInterval = interval;
    });
    this.currentStrategy = strategy;
    try {
      const tokens = await this.client.connect(strategy);
      if (!tokens || !isCurrentSession()) return;
      if (this.progressInterval) clearInterval(this.progressInterval);
      this.eventListeners.triggerEvent("tokens", tokens);
      this.status = OAuth2CodeSessionStatus.SUCCESS;
      this.eventListeners.triggerEvent("status", this.status);
      data.status = this.status;
      data.tokens = tokens;
      this.eventListeners.triggerEvent("data", {...data});
    } catch (error: any) {
        const errorMessage = error.type ? TokenErrorToMessage[error.type as OAUTH2_TOKEN_ERROR_TYPE] : error.message;
        this.status = OAuth2CodeSessionStatus.ERROR;
        this.eventListeners.triggerEvent("error", errorMessage);
        this.eventListeners.triggerEvent("status", this.status);
        data.error = error;
        data.errorMessage = errorMessage;
        data.status = this.status;
        this.eventListeners.triggerEvent("data", {...data});
      }
  }

  reset() {
    this.stop();
    this.start();
  }

  stop() {
    if (this.progressInterval) clearInterval(this.progressInterval);
    if (this.currentStrategy) {
      this.currentStrategy.cancel();
      this.currentStrategy = undefined;
    }
    this.status = OAuth2CodeSessionStatus.IDLE;
    this.eventListeners.triggerEvent("status", this.status);
    const data: OAuth2CodeSessionData = {
      status: this.status
    };
    this.eventListeners.triggerEvent("data", {...this.data});
  }


  addEventListener<K extends keyof OAuth2CodeSessionEventListenerMap>(event: K, listener: OAuth2CodeSessionEventListenerMap[K]) {
    this.eventListeners.addEventListener(event, listener);
  }

  removeEventListener<K extends keyof OAuth2CodeSessionEventListenerMap>(event: K, listener: OAuth2CodeSessionEventListenerMap[K]) {
    this.eventListeners.removeEventListener(event, listener);
  }

  removeAllEventListeners() {
    this.eventListeners.removeAllEventListener();
  }
}
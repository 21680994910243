import { Button, destroyMediaStream, EventListeners, Loading, useEventListenersRef } from "@kalyzee/kast-app-web-components";
import { Player, PlayerOvenMedia, Video } from "@kalyzee/kast-react-player-module";
import { OvenMediaReceiverWebRTCSession, SdpFormatter } from "@kalyzee/kast-webrtc-client-module";
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { logger } from "../helpers/logger";
import { WhiteboardWebRTCSession } from "../helpers/whiteboardWebRTCSession";
import styles from "./OvenMediaPlayer.module.css";

const TIMEOUT_CONNECT = 5000;
const RETRY_TIMEOUT = 3000;
const RETRY_LIMIT = -1; // < 0 for infinite (ex: -1)

export interface OvenMediaPlayerProps {
  url: string;
  sdpFormatterPipeline?: string | string[];
  display?: boolean;
  timeoutConnect?: number;
  retryTimeout?: number;
  retryLimit?: number; // < 0 for infinite (ex: -1)
  onMedia?: (media: MediaStream | undefined) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface OvenMediaPlayerListenerMap {
  ovenMediaWebRTCSession: (session: OvenMediaReceiverWebRTCSession | undefined) => void;
  media: (media: MediaStream | undefined) => void;
  context: (context: any | undefined) => void;
}

export interface OvenMediaPlayerRef {
  player: 'ovenmedia',
  getEventListener: () => EventListeners<keyof OvenMediaPlayerListenerMap, OvenMediaPlayerListenerMap> | undefined;
}

const OvenMediaPlayer = React.forwardRef(
  (
    {
      url,
      sdpFormatterPipeline,
      display,
      timeoutConnect,
      retryTimeout,
      retryLimit,
      onMedia,
      className,
      style,
    }: OvenMediaPlayerProps,
    forwardRef: ForwardedRef<OvenMediaPlayerRef | undefined>
  ) => {
    const listenersRef = useEventListenersRef<keyof OvenMediaPlayerListenerMap, OvenMediaPlayerListenerMap>();
    const ovenMediaWebRTCSessionRef = useRef<OvenMediaReceiverWebRTCSession | undefined>(undefined);
    const onMediaRef = useRef(onMedia);
    const [retryCounter, setRetryCounter] = useState(0);
    const [error, setError] = useState<string | undefined>(undefined);
    const [previewMedia, setPreviewMedia] = useState<MediaStream | undefined>(undefined);
    const mediaRef = useRef<MediaStream>();
    const firstConnexionRef = useRef(true);
    const connectedRef = useRef(false);
    const componentIsDestroyedRef = useRef(false);

    const classes: string[] = [styles.container];
    if (className) classes.push(className);


    useImperativeHandle(
      forwardRef,
      () => ({
        player: 'ovenmedia' as const,
        getEventListener: () => listenersRef.current,
      }),
      [listenersRef]
    );

    useEffect(() => {
      return () => {
        componentIsDestroyedRef.current = true;
      };
    }, []);

    useEffect(() => {
      onMediaRef.current = onMedia;
    }, [onMedia]);

    useEffect(() => {
      return () => {
        if (previewMedia) destroyMediaStream(previewMedia);
      }
    }, [previewMedia]);

    useEffect(() => {
      if (mediaRef.current) destroyMediaStream(mediaRef.current);
      mediaRef.current = previewMedia?.clone();
      onMediaRef.current?.(mediaRef.current);
      listenersRef.current?.triggerEvent("media", mediaRef.current);
    }, [previewMedia, listenersRef]);

    useEffect(() => {
      if (retryCounter < 0) {
        setRetryCounter(0);
        return;
      }
      const currentTimeoutConnect = timeoutConnect ?? TIMEOUT_CONNECT;
      const currRetryLimit = retryLimit ?? RETRY_LIMIT;
      const currRetryTimeout = retryTimeout ?? RETRY_TIMEOUT;
      if (currRetryLimit >= 0 && retryCounter >= currRetryLimit) return;
      let session: OvenMediaReceiverWebRTCSession | undefined;
      let retryTimeoutNodeJS: NodeJS.Timeout | undefined;
      let timeoutNodeJS: NodeJS.Timeout | undefined;
      let finished = false;
      const finish = (success: boolean, err?: string) => {
        if (componentIsDestroyedRef.current) return;

        finished = true;
        // console.log('Finished : ', ip, success, err);
        setError(success ? undefined : err ?? "Unknown");
        if (timeoutNodeJS) clearTimeout(timeoutNodeJS);
        // console.log('Finished #1 : ', connectedRef.current, retryCounter, success);
        if (!success) {
          setPreviewMedia(undefined);
          const newRetryCounter = connectedRef.current ? 0 : retryCounter + 1;
          if (currRetryLimit < 0 || newRetryCounter < currRetryLimit) {
            retryTimeoutNodeJS = setTimeout(() => {
              if (retryCounter === newRetryCounter) setRetryCounter(-1);
              else setRetryCounter(newRetryCounter);
            }, currRetryTimeout);
          } else if (newRetryCounter === currRetryLimit) {
            setRetryCounter(newRetryCounter);
          }
          session?.destroy();
        }
        connectedRef.current = success;
      };
      timeoutNodeJS = setTimeout(() => {
        logger.log("OVENMEDIAPLAYER - timeout");
        if (finished) return;
        finish(false, "Impossible to connect...");
      }, currentTimeoutConnect);
      setPreviewMedia(undefined);
      try {
        // console.log('Connect to kast : ', ip, retryCounter);
        session = new OvenMediaReceiverWebRTCSession();
        session.start(url);
        session.addEventListener("stream", (stream: MediaStream) => {
          if (finished) return;
          finish(true);
          setPreviewMedia(stream);
        });
        session.addEventListener("close", () => {
          logger.log("OVENMEDIAPLAYER - DISCONNECT : ", url);
          finish(false, "Disconnected");
        });

        const updateSdp = (description: RTCSessionDescriptionInit | null): RTCSessionDescriptionInit | null => {
          if (!description?.sdp) return null;
          if (!sdpFormatterPipeline) return description;
          const pipelines = Array.isArray(sdpFormatterPipeline) ? sdpFormatterPipeline : [sdpFormatterPipeline];
          let result: RTCSessionDescriptionInit = description;
          const sdpFormatter = new SdpFormatter(description.sdp);
          pipelines.forEach((pipeline) => sdpFormatter.execPipeline(pipeline));
          result.sdp = sdpFormatter.sdp;
          return result;
        };

        // Offer
        session.rtcSession.middlewareRemoteSdp = (description: RTCSessionDescriptionInit | null) => {
          return updateSdp(description);
        };

        // Answer
        session.rtcSession.middlewareLocalSdp = (description: RTCSessionDescriptionInit | null) => {
          return updateSdp(description);
        };
        listenersRef.current?.triggerEvent("ovenMediaWebRTCSession", session);
        ovenMediaWebRTCSessionRef.current = session;
      } catch (err) {
        logger.log("OVENMEDIAPLAYER - error catched : ", err);
        if (finished) return;
        finish(false, (err as any)?.message);
        listenersRef.current?.triggerEvent("ovenMediaWebRTCSession", undefined);
        ovenMediaWebRTCSessionRef.current = undefined;
      }

      return () => {
        if (retryTimeoutNodeJS) clearTimeout(retryTimeoutNodeJS);
        if (timeoutNodeJS) clearTimeout(timeoutNodeJS);
        session?.removeAllEventListeners();
        session?.destroy();
      };
    }, [url, retryCounter, listenersRef, sdpFormatterPipeline, timeoutConnect, retryTimeout, retryLimit]);


    const renderPlayer = () => (
      <Video
        autoPlay
        play
        mute
        media={previewMedia}
        className={`${styles.player}`}
      />
    );

    const renderError = () => (
      <div className={styles.errorContainer}>
        <div className={styles.error}>{error}</div>
        <Button
          className={styles.retryButton}
          title={"Réessayer" /* TRANSLATION */}
          onPress={() => {
            setRetryCounter(0);
          }}
        />
      </div>
    );

    const renderContent = () => {
      if (error && RETRY_LIMIT >= 0 && retryCounter >= RETRY_LIMIT) return renderError();
      if (previewMedia) return renderPlayer();
      return <Loading message={"Connexion en cours..." /* TRANSLATION */} />;
    };

    if (!display) return null;

    return (
      <div className={classes.join(" ")} style={style}>
        {renderContent()}
      </div>
    );
});

OvenMediaPlayer.defaultProps = {
  display: true,
  className: undefined,
  style: undefined,
};

export default OvenMediaPlayer;
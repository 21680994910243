import { MeetingSession, MeetingSessionRole } from "@kalyzee/kast-websocket-module";
import { MeetingSourceDeviceType } from "../components/MeetingSource";
import { PageSlaveMode } from "../components/navigation/PageContext";
import { SessionMode } from "../store/session/slices";
import { isMessagingBridgeClientData, MessagingBridgeClientData } from "./messagingBridge";

export const isSpectator = (role?: MeetingSessionRole): boolean => {
  return role === MeetingSessionRole.SPECTATOR || role === MeetingSessionRole.SUPERSPECTATOR;
};

export const isSuperUser = (role?: MeetingSessionRole): boolean => {
  return role === MeetingSessionRole.SUPERADMINISTRATOR || role === MeetingSessionRole.SUPERSPECTATOR;
};

export const isKast = (device?: string): boolean => {
  return device === MeetingSourceDeviceType.KAST;
};

export const canExcludeUsers = (session: MeetingSession): boolean => {
  const role = session.role;
  return role === MeetingSessionRole.MODERATOR || role === MeetingSessionRole.ADMINISTRATOR || isSuperUser(role);
};

export const messagingBridgeClientDisplayingMedia = (data: MessagingBridgeClientData<any, any>, mediaId: string): boolean => {
  if (!data) return false;
  if (isMessagingBridgeClientData(data, SessionMode.SLAVE, PageSlaveMode.SPECTATOR)) {
    const displayingMedias = data.data?.displayingMedias ?? [];
    return displayingMedias.includes(mediaId);
  }
  return false;
};

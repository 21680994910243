import { Meeting, MeetingSession, MeetingSessionMedia, MeetingSessionMediaState } from "@kalyzee/kast-websocket-module";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MeetingSessionMediaExtra } from "../../interfaces/meeting";

export interface MeetingState {
  meeting: Meeting | undefined;
  session: MeetingSession | undefined;
  mediaDestinations: {[id: string]: string};
}

const initialState: MeetingState = {
  meeting: undefined,
  session: undefined,
  mediaDestinations: {},
};

export const meetingSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    cleanMeeting: (state) => {
      state.meeting = undefined;
      state.session = undefined;
      state.mediaDestinations = {};
    },
    setMeeting: (state, action: PayloadAction<Meeting | undefined>) => {
      state.meeting = action.payload;
    },
    setSession: (state, action: PayloadAction<MeetingSession<true | false>>) => {
      state.session = action.payload;
    },
    updateSession: (state, action: PayloadAction<MeetingSession<true | false>>) => {
      const session = action.payload;
      if (!session) return;
      if (session.id === state.session?.id) {
        // Update media destinations
        const mediaDestinations: {[id: string]: string} = {};
        const session = action.payload;
        if (!session) return;
        const medias = session.medias;
        if (!medias) return;
        medias.forEach((m) => {
          if ("mediaDestination" in m && m.mediaDestination) {
            mediaDestinations[m.id] = m.mediaDestination;
          }
        });
        state.mediaDestinations = mediaDestinations;
        state.session = session;
      }
      const meeting = state.meeting;
      if (!meeting) return;
      meeting.sessions?.forEach((s) => {
        if (s.id === session.id) {
          Object.assign(s, session);
        }
      });
    },
    updateSessionMedia: (state, action: PayloadAction<MeetingSessionMedia<MeetingSessionMediaExtra>>) => {
      const media = action.payload;
      if (!media) return;
      const medias = state.session?.medias;
      medias?.forEach((m) => {
        if (m.id === media.id) {
          Object.assign(m, media);
        }
      });
      const meeting = state.meeting;
      if (!meeting) return;
      meeting.sessions?.forEach((s) => {
        const medias = s?.medias;
        medias?.forEach((m) => {
          if (m.id === media.id) {
            Object.assign(m, media);
          }
        });
      });
    },
    updateSessionMediaState: (state, action: PayloadAction<{id: string, state: MeetingSessionMediaState}>) => {
      const medias = state.session?.medias;
      medias?.forEach((m) => {
        if (m.id === action.payload.id) {
          m.state = action.payload.state;
        }
      });
      const meeting = state.meeting;
      if (!meeting) return;
      meeting.sessions?.forEach((s) => {
        const medias = s?.medias;
        medias?.forEach((m) => {
          if (m.id === action.payload.id) {
            m.state = action.payload.state;
          }
        });
      });
    },
    updateMediaDestinations: (state, action: PayloadAction<MeetingSession<true | false>>) => {
      const mediaDestinations: {[id: string]: string} = {};
      const session = action.payload;
      if (!session) return;
      const medias = session.medias;
      if (!medias) return;
      medias.forEach((m) => {
        if ("mediaDestination" in m && m.mediaDestination) {
          mediaDestinations[m.id] = m.mediaDestination;
        }
      });
      state.mediaDestinations = mediaDestinations;
    },
  },
});

export const { cleanMeeting, setMeeting, setSession, updateSession, updateSessionMedia, updateSessionMediaState, updateMediaDestinations } = meetingSlice.actions;

const meetingReducer = meetingSlice.reducer;
export default meetingReducer;

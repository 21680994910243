import { AppVersion, Touchable, useURL } from "@kalyzee/kast-app-web-components";
import { useEffect } from "react";

import background from "../../assets/backgrounds/kast.png";
import { ReactComponent as IconBack } from "../../assets/icons/back.svg";
import { ReactComponent as IconDisabled } from "../../assets/icons/disabled.svg";
import { ReactComponent as IconBanned } from "../../assets/icons/nospeaker.svg";
import { ReactComponent as IconTrialPeriodIsOver } from "../../assets/icons/trail-period-is-over.svg";
import { usePageContextRef } from "../../components/navigation/PageContext";
import { logger } from "../../helpers/logger";

import styles from "./forbidden.module.css";

const ForbiddenPage = () => {
  const url = useURL();
  const pageContextRef = usePageContextRef();
  const reason = url.searchParams.get("r") ?? "default";

  const detailsReasonMapping: any = {
    ubanned: {
      icon: <IconBanned className={styles.icon} />,
      title: "Banni",
      message: "Vous avez été banni(e).",
    },
    udisabled: {
      icon: <IconDisabled className={styles.icon} />,
      title: "Compte désactivé",
      message: "Votre compte a été désactivé.",
    },
    utrialperiodisover: {
      icon: <IconTrialPeriodIsOver className={styles.icon} />,
      title: "Fin de la période d'essai",
      message: "Votre période d'essai est terminée.",
    },
    odisabled: {
      icon: <IconDisabled className={styles.icon} />,
      title: "Accès désactivée",
      message: "L'accès à votre organisation est désactivé.",
    },
    otrialperiodisover: {
      icon: <IconTrialPeriodIsOver className={styles.icon} />,
      title: "Fin de la période d'essai",
      message: "Votre période d'essai est terminée.",
    },
    sessionexpired: {
      icon: <IconTrialPeriodIsOver className={styles.icon} />,
      title: "La session a expirée",
      message: "Veuillez-vous reconnecter.",
    },
    default: {
      icon: <IconDisabled className={styles.icon} />,
      title: "Erreur",
      message: "Une erreur est survenue.",
    },
  };
  const details = detailsReasonMapping[reason] ?? detailsReasonMapping.default;

  useEffect(() => {
    logger.log("[PAGE:FORBIDDEN] - enter");
    return () => {
      logger.log("[PAGE:FORBIDDEN] - leave");
    };
  }, []);

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${background})` }}>
      <Touchable
        onPress={() => {
          pageContextRef.current.goToPrevious?.();
        }}
        className={styles.backButton}
      >
        <IconBack width="100%" height="100%" />
      </Touchable>

      <div className={styles.contentcontainer}>
        {details.icon}
        <div className={styles.title}>{details.title}</div>
        <div className={styles.subTitle}>{details.message}</div>
        <div className={styles.contact}>{"Contactez votre administrateur."}</div>
      </div>
      <AppVersion />
    </div>
  );
};

export default ForbiddenPage;

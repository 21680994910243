import { generateRandomId, Writeable } from "@kalyzee/kast-app-web-components";
import { RTCStreamSession, SdpFormatter } from "@kalyzee/kast-webrtc-client-module";
import { logger } from "./logger";

export interface WhiteboardWebRTCSessionListenerMap {
  connect: () => void;
  disconnect: () => void;
  stream: (media: MediaStream) => void;
  message: (action: string, params?: any) => void;
  sources: (videos: string[], audios: string[]) => void;
  videoSrc: (source: string) => void;
  audioSrc: (source: string) => void;
};

export interface WhiteboardWebRTCSessionOptions {
  defaultVideoSource?: "screen" | string;
  defaultAudioSource?: "playback" | "microphone" | "mixed";
  defaultPreferVideoSource?: "screen" | string;
  defaultPreferAudioSource?: "playback" | "microphone" | "mixed";
  defaultAudioPlaybackVolume?: number | undefined | null;
  defaultAudioMicrophoneVolume?: number | undefined | null;
}


export class WhiteboardWebRTCSession {
  private listeners: { [key in keyof WhiteboardWebRTCSessionListenerMap]?: Array<WhiteboardWebRTCSessionListenerMap[key]> } = {};
  readonly ip: string;
  readonly rtcSession: RTCStreamSession;
  readonly websocket: WebSocket;
  private promiseMedia: Promise<MediaStream | undefined> | undefined;
  private media: MediaStream | undefined;
  constructor(ip: string, options?: WhiteboardWebRTCSessionOptions | undefined) {
    const websocket = new WebSocket(ip);
    const rtcSession = new RTCStreamSession();
    rtcSession.start([]).then(() => {
      /*const peerConnection = rtcSession.rtcPeerConnection;
      const events = [
        "connectionstatechange",
        "datachannel",
        "icecandidate",
        "icecandidateerror",
        "iceconnectionstatechange",
        "icegatheringstatechange",
        "negotiationneeded",
        "signalingstatechange",
        "track",
      ];
      events.forEach((e) => {
        peerConnection?.addEventListener(e, (...args) => {
          console.log('[KAST - ', e,'] : ', ...args);
        });
      });*/
    });
  
    const send = (data: object) => {
      websocket.send(JSON.stringify(data));
    };
  
    rtcSession.addEventListener('sdp', (description: RTCSessionDescription | null) => {
      if (!description) return null;
      const writableDescription: Writeable<RTCSessionDescription> = description;
      const sdpFormatter = new SdpFormatter(writableDescription.sdp);
      /*
        a=extmap:14 urn:ietf:params:rtp-hdrext:toffset
        a=extmap:2 http://www.webrtc.org/experiments/rtp-hdrext/abs-send-time
        a=extmap:13 urn:3gpp:video-orientation
        a=extmap:3 http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions-01
        a=extmap:5 http://www.webrtc.org/experiments/rtp-hdrext/playout-delay
        a=extmap:6 http://www.webrtc.org/experiments/rtp-hdrext/video-content-type
        a=extmap:7 http://www.webrtc.org/experiments/rtp-hdrext/video-timing
        a=extmap:8 http://www.webrtc.org/experiments/rtp-hdrext/color-space
      */
      
      const videoExtensionsToRemove = [ "a=extmap:13 urn:3gpp:video-orientation" ];
      videoExtensionsToRemove.forEach((ext) => {
        sdpFormatter.execPipeline(`remove_lines_from_media["video", "${ext}"]`);
      });
      // sdpFormatter.execPipeline(`remove_lines_from_media["video", "a=extmap:"]`);
      writableDescription.sdp = sdpFormatter.sdp;
      send({
        action: 'video/send_sdp_answer',
        params: writableDescription
      });
    });
    rtcSession.addEventListener('iceCandidate', (candidate) => {
      send({
        action: 'video/send_ice_candidate',
        params: candidate
      });
    })

    this.promiseMedia = new Promise((resolve, reject) => {
      websocket.onclose = (ev: Event) => {
        // console.error('On close :', ev);
        this.disconnected();
        reject('Connexion lost');
      };

      websocket.onerror = (ev: Event) => {
        // console.error('On error :', ev)
        reject('Connexion error');
      };

      rtcSession.addEventListener('close', () => {
        // console.error('On rtc close');
        this.disconnected();
        reject('RTC Connexion lost');
      })

      rtcSession.addEventListener('error', () => {
        // console.error('On rtc error');
        this.disconnected();
        reject('RTC Connexion error');
      })

      rtcSession.addEventListener('stream', (stream) => {
        // console.log('On stream : ', stream);
        this.media = stream;
        this.listeners.stream?.forEach((listener) => listener(stream));
        resolve(stream);
      })
    })

  
    websocket.onmessage = async (ev: MessageEvent) => {
      let message : any | undefined = undefined;
      try {
        message = JSON.parse(ev.data);
      } catch (err) {
        message = undefined;
      }
      if (!message) return;
      this.listeners.message?.forEach((listener) => listener(message.action, message.params));
      if (message.action === 'video/sdp_offer') {
        const description = message.params;
        rtcSession.processRemoteSdp(description);
      }
      if (message.action === 'video/ice_candidate') {
        rtcSession.processRemoteIceCandidate(message.params);
      }
      if (message.action === 'video/information') {
        const content = message.params;
        const videoSources = content?.camerasAvailable ? content?.camerasAvailable : [];
        const audioSources: string[] = [];
        if (content?.screenCapturerIsAvailable) videoSources.unshift('screen');
        if (content?.audioPlaybackIsRecording) audioSources.push('playback');
        if (content?.audioMicrophoneIsRecording) audioSources.push('microphone');
        if (content?.audioPlaybackIsRecording && content?.audioMicrophoneIsRecording) audioSources.push('mixed');
        this.listeners.sources?.forEach((listener) => listener(videoSources, audioSources));
        if (content?.currentVideoSrc) this.listeners.videoSrc?.forEach((listener) => listener(content.currentVideoSrc));
        if (content?.currentAudioSrc) this.listeners.audioSrc?.forEach((listener) => listener(content.currentAudioSrc));
      }
      if (message.action === 'video/on_video_source') {
        const content = message.params;
        this.listeners.videoSrc?.forEach((listener) => listener(content.source));
      }
      if (message.action === 'video/on_audio_source') {
        const content = message.params;
        this.listeners.audioSrc?.forEach((listener) => listener(content.source));
      }
    };
    websocket.onopen = (ev: Event) => {
      this.listeners.connect?.forEach((listener) => listener());
      this.sendCommand("video/start_preview", {
        sources: {
          videoSrc: options?.defaultVideoSource,
          preferVideoSrc: options?.defaultPreferVideoSource,
          audioSrc: options?.defaultAudioSource,
          preferAudioSrc: options?.defaultPreferAudioSource,
          playbackVolume: options?.defaultAudioPlaybackVolume,
          microphoneVolume: options?.defaultAudioMicrophoneVolume,
        }
      });
      this.getVideoInformation();
    };

    this.websocket = websocket;
    this.rtcSession = rtcSession;
    this.ip = ip;
  }

  getPromiseMedia() : Promise<MediaStream | undefined> | undefined {
    return this.promiseMedia;
  }

  getMedia() : MediaStream | undefined {
    return this.media;
  }

  private disconnected() {
    logger.log('Whiteboard - disconnect');
    this.listeners.disconnect?.forEach((listener) => listener());
    this.destroy();
  }

  destroy() {
    logger.log('Whiteboard - destroy');
    this.removeAllEventListeners();
    if (this.rtcSession) this.rtcSession.destroy();
    if (this.websocket) this.websocket.close();
  }

  sendCommand(action: string, params?: object) : void {
    if (!this.websocket || this.websocket.readyState !== this.websocket.OPEN) return;
    this.websocket.send(JSON.stringify({ action, correlationId: generateRandomId(), params: params ?? {} }));
  }

  // ---------------- Listener ------------------ //

  public addEventListener<T extends keyof WhiteboardWebRTCSessionListenerMap>(
    event: T,
    listener: WhiteboardWebRTCSessionListenerMap[T],
  ): void {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event]!.push(listener as any);
  }

  public removeEventListener<T extends keyof WhiteboardWebRTCSessionListenerMap>(
    event: T,
    listener: WhiteboardWebRTCSessionListenerMap[T],
  ): void {
    if (!this.listeners[event]) return;
    this.listeners[event] = (this.listeners[event] as any).filter((curr: any) => curr !== listener) as any;
  }

  public removeAllEventListeners(): void {
    this.listeners = {};
  }


  // ---------------- Commands ------------------ //

  public updateVideoSource(source: string) {
    if (!source) return;
    this.sendCommand("video/config_sources", { videoSrc: source });
  };


  public updateAudioSource(source: "playback" | "microphone" | "mixed" | undefined, playbackVolume?: number | null, microphoneVolume?: number | null) {
    if (!source) return;
    this.sendCommand("video/config_sources", {
      audioSrc: source,
      playbackVolume,
      microphoneVolume
    });
  };

  public getVideoInformation() {
    this.sendCommand("video/information");
  };
}
import { OvenMediaEmitterWebRTCSession, SdpFormatter } from "@kalyzee/kast-webrtc-client-module";
import { WebSocket } from "@kalyzee/kast-websocket-module";
import { MeetingPlayerRef } from "../components/MeetingPlayer";
import { MessagingBridgeManagerMaster, MessagingBridgeManagerSlave } from "./messagingBridge";
import { websocketClient } from "./request";
import { RTCBridgeMediaSessionReceiver, RTCEmitterSessionWrapped } from "./rtcBridgeMediaSession";

export interface WindowDebugData {
  enabled: boolean;
  bridgeReceivers: RTCBridgeMediaSessionReceiver[];
  bridgeEmitters: { sessions: RTCEmitterSessionWrapped[]}[];
  noCaptureEncodedBuffer: boolean;
  noEmitEncodedBuffer: boolean;
  notDecodeEncodedBuffer: boolean;
  notProcessDecodedBuffer: boolean;
  messagingBridgeManager: MessagingBridgeManagerMaster | MessagingBridgeManagerSlave | null;
  mediasMeetingPlayersRefs: Map<string, MeetingPlayerRef | undefined> | null;
  ovenMediaSessionEmitterRef: OvenMediaEmitterWebRTCSession<{media: MediaStream | undefined; mediaId: string; }> | null;
  ovenMediaSessionEmitterMiddlewareSdpOffer: ((offer: RTCSessionDescriptionInit | null, formatterClass: typeof SdpFormatter) => RTCSessionDescriptionInit | null) | null;
  ovenMediaSessionEmitterMiddlewareSdpAnswer: ((offer: RTCSessionDescriptionInit | null, formatterClass: typeof SdpFormatter) => RTCSessionDescriptionInit | null) | null;
  pushMedia?: {
    interceptor?: {
      enabled?: boolean;
      preview?: boolean;
      video?: {
        simulatePacketLoss?: number;
        logs?: boolean;
      }
      audio?: {
        simulatePacketLoss?: number;
        logs?: boolean;
      }
    }
  },
  websocket: {
    client: WebSocket,
    onMessage?: (event: string, ...args: any) => void,
  };
  [key: string]: any;
}

export class Debug {
  static init(enable: boolean): WindowDebugData {
    const currWindow = (window as any);
    if (!currWindow.__DEBUG__) {
      const data: WindowDebugData = {
        enabled: enable,
        bridgeReceivers: [],
        bridgeEmitters: [],
        noCaptureEncodedBuffer: false,
        noEmitEncodedBuffer: false,
        notDecodeEncodedBuffer: false,
        notProcessDecodedBuffer: false,
        messagingBridgeManager: null,
        mediasMeetingPlayersRefs: null,
        ovenMediaSessionEmitterRef: null,
        ovenMediaSessionEmitterMiddlewareSdpOffer: null,
        ovenMediaSessionEmitterMiddlewareSdpAnswer: null,
        pushMedia: {
          interceptor: {
            enabled: false,
            preview: true,
            video: {
              logs: false,
            },
            audio: {
              logs: false,
            }
          }
        },
        websocket: {
          client: websocketClient,
          onMessage: (event: string, ...args: any) => {/*empty*/},
        }
      };
      websocketClient.addEventListener("message", (event, ...args) => {
        Debug.getData()?.websocket?.onMessage?.(event, ...args);
      });
      currWindow.__DEBUG__ = data;
    }
    return currWindow.__DEBUG__;
  }

  static getData(): WindowDebugData | undefined {
    const currWindow = (window as any);
    const data: WindowDebugData | undefined = currWindow.__DEBUG__;
    return data;
  }

  static isEnabled(): boolean {
    const data = this.getData();
    return data?.enabled === true;
  }

  static enable(): void {
    const data = this.getData();
    if (!data) return;
    data.enabled = true;
  }

  static disable(): void {
    const data = this.getData();
    if (!data) return;
    data.enabled = false;
  }

  static addBridgeReceiver(receiver: RTCBridgeMediaSessionReceiver): void {
    const data = this.getData();
    if (!data || !data.enabled) return;
    if (!data.bridgeReceivers) data.bridgeReceivers = [];
    data.bridgeReceivers.push(receiver);
  }

  static addBridgeEmitter(sessions: { sessions: RTCEmitterSessionWrapped[]}): void {
    const data = this.getData();
    if (!data || !data.enabled) return;
    if (!data.bridgeEmitters) data.bridgeEmitters = [];
    data.bridgeEmitters.push(sessions);
  }
}

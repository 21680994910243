export interface DisplayOnWhiteboardOptions {
  enabled: boolean;
  active: boolean;
  localDisplay: boolean;
  remoteDisplay: boolean;
}

export const DISPLAY_ON_WHITEBOARD_OPTIONS_DEFAULT: DisplayOnWhiteboardOptions = {
  enabled: true,
  active: false,
  localDisplay: true,
  remoteDisplay: true,
} as const;
import { OAuth2DeviceVerifyOutputDTO, OAUTH2_DEVICE_CODE_STATUS } from "@kalyzee/kast-app-module";
import { AppVersion, Button, ButtonColor, Touchable, useURL } from "@kalyzee/kast-app-web-components";
import { useRef, useState } from "react";
import ImgBackground from "../../assets/backgrounds/kast.png";
import { ReactComponent as IconBack } from "../../assets/icons/back.svg";
import { ReactComponent as IconSuccess } from "../../assets/icons/success.svg";
import { usePageContextRef } from "../../components/navigation/PageContext";
import { toastError, toastSuccess } from "../../helpers/toast";
import { useRequestAppDispatch } from "../../hooks/app";
import { requestOauth2AllowDevice } from "../../store/request/actions";
import styles from "./pairing.module.css";

const ParingPage = () => {
  const url = useURL();
  const requestDispatch = useRequestAppDispatch();
  const pageContextRef = usePageContextRef();
  const inputDeviceCodeRef = useRef<HTMLInputElement>(null);
  const [enableButton, setEnableButton] = useState(false);
  const [success, setSuccess] = useState(false);

  const pair = async () => {
    const code = inputDeviceCodeRef.current?.value?.trim()?.toLocaleUpperCase() ?? "";
    try {
      const result = await requestDispatch<OAuth2DeviceVerifyOutputDTO>(requestOauth2AllowDevice({ code }));
      const status = result?.data?.status;
      if (result?.data?.status === OAUTH2_DEVICE_CODE_STATUS.ALLOWED) {
        toastSuccess(`Le device a été appareillé.`);
        setSuccess(true);
      } else {
        toastError(`Une erreur est survenue durant l\'appareillage. Status : ${status}`);
      }
    } catch (err: any) {
      const message = err?.responseData?.message;
      if (message) toastError(`Une erreur est survenue durant l'appareillage : ${message}`);
      else toastError("Une erreur est survenue durant l'appareillage...");
    }
  };

  const renderContent = () => {
    if (success) {
      return (
        <>
          <div className={styles.content}>
            <div className={styles.title}>
              <IconSuccess width={30} height={30} />
              Succès
            </div>
            <div className={styles.description}>Le device a été appareillé avec succès.</div>
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              title={"Retour"}
              color={ButtonColor.PURPLE}
              className={styles.button}
              onPress={() => {
                pageContextRef?.current?.goToPrevious?.();
              }}
            />
            <Button
              title={"Appairer un autre appareil"}
              color={ButtonColor.GREEN}
              className={styles.button}
              onPress={() => {
                if (inputDeviceCodeRef.current) inputDeviceCodeRef.current.value = "";
                setEnableButton(false);
                setSuccess(false);
              }}
            />
          </div>
        </>
      );
    }
    return (
      <>
        <div className={styles.content}>
          <div className={styles.title}>Appareiller un device</div>
          <div className={styles.description}>Saisissez le code de l'appareil:</div>
          <input
            className={styles.input}
            ref={inputDeviceCodeRef}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            onChange={(e) => {
              const id = e.target?.value?.trim();
              setEnableButton(id.length ? true : false);
            }}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            title={"Appareiller"}
            color={ButtonColor.GREEN}
            className={styles.button}
            disabled={!enableButton}
            onPress={() => {
              pair();
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className={styles.page} style={{ backgroundImage: `url(${ImgBackground})` }}>
      <Touchable
        onPress={() => {
          pageContextRef.current.goToPrevious?.();
        }}
        className={styles.backButton}
      >
        <IconBack width="100%" height="100%" />
      </Touchable>
      <div className={styles.container}>{renderContent()}</div>
      <AppVersion />
    </div>
  );
};

export default ParingPage;

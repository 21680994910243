import React, { ForwardedRef, useEffect, useImperativeHandle, useState } from "react";

import styles from "./VideoStats.module.css";

export interface VideoStatistics {
  src: 'media' | 'webrtc'
  frameRate?: number;
  width?: number;
  height?: number;
}

export interface VideoStatsProps {
  mode: 'input' | 'output',
  media?: MediaStream;
  rtc?: RTCPeerConnection;
  className?: string;
  style?: React.CSSProperties;
}

export interface VideoStatsRef {
  setMedia: (media?: MediaStream) => void;
  setRTC: (rtc?: RTCPeerConnection) => void;
}


const VideoStats = React.forwardRef(
  (
    { mode, media, rtc, className, style }: VideoStatsProps,
    forwardRef: ForwardedRef<VideoStatsRef | undefined>
  ) => {
    const [currMedia, setCurrMedia] = useState<MediaStream | undefined>();
    const [currRTC, setCurrRTC] = useState<RTCPeerConnection | undefined>();
    const [stats, setStats] = useState<Partial<VideoStatistics>>({});
    const classes: string[] = [styles.container];
    if (className) classes.push(className);

    useImperativeHandle(forwardRef, () => ({
      setMedia: (m?:MediaStream) => setCurrMedia(m),
      setRTC: (r?:RTCPeerConnection) => setCurrRTC(r),
    }));

    useEffect(() => {
      setCurrMedia(media);
    }, [media]);

    useEffect(() => {
      setCurrRTC(rtc);
    }, [rtc]);

    useEffect(() => {
      const interval = setInterval(async () => {
        try {
          if (currMedia) {
            const videoTracks = currMedia.getVideoTracks();
            const audioTracks = currMedia.getAudioTracks();
            const videoTrack = videoTracks.length ? videoTracks[0] : undefined;
            const audioTrack = audioTracks.length ? audioTracks[0] : undefined;
  
            if (videoTrack) {
              let getFromMedia = true;
              if (currRTC) {
                const stats = await currRTC.getStats(videoTrack);
                const inbound = Array.from(stats.values())?.find((report) => {
                  return report?.type === (mode === 'input' ? 'inbound-rtp' : 'outbound-rtp')
                });
                if (inbound) {
                  getFromMedia = false;
                  setStats({
                    src: 'webrtc',
                    frameRate: Math.ceil(inbound.framesPerSecond ?? 0),
                    width: inbound.frameWidth,
                    height: inbound.frameHeight
                  })
                }              
              } 
              if (getFromMedia) {
                const settings = videoTrack.getSettings();
                setStats({
                  src: 'media',
                  frameRate: Math.ceil(settings.frameRate ?? 0),
                  width: settings?.width,
                  height: settings?.height
                });
              }
  
            }
          }
        } catch (_) {}
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }, [mode, currMedia, currRTC]);

    const renderRow = (title: string, value: string) => {
      return (
        <div key={title} className={styles.row}>
          <div className={styles.title}>{`${title} : `}</div>
          <div className={styles.value}>{value}</div>
        </div>
      );
    };

    return (
      <div className={classes.join(" ")} style={style}>

        {Object.keys(stats).map((title: any) => {
        return renderRow(title, `${(stats as any)[title]}`);
      })}
      </div>
    );
    /*return (
    <div className={classes.join(" ")} style={style}>
      {Object.keys(settings).map((title) => {
        return renderRow(title, `${(settings as any)[title]}`);
      })}
    </div>
  );*/
  }
);

VideoStats.defaultProps = {
  className: undefined,
  style: undefined,
};

export default VideoStats;

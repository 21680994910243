import { AppVersion, Touchable } from "@kalyzee/kast-app-web-components";
import { useEffect } from "react";
import background from "../../assets/backgrounds/kast.png";
import { ReactComponent as IconBack } from "../../assets/icons/back.svg";
import { usePageContextRef } from "../../components/navigation/PageContext";
import { logger } from "../../helpers/logger";
import { useAppDispatch } from "../../hooks/app";
import { requestLogout } from "../../store/request/actions";
import styles from "./logout.module.css";

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const pageContextRef = usePageContextRef();

  const onLogout = () => {
    logger.log("[LOGOUT] : on logout button press");
    dispatch(requestLogout());
  };

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${background})` }}>
      <Touchable
        onPress={() => {
          pageContextRef.current.goToPrevious?.();
        }}
        className={styles.backButton}
      >
        <IconBack width="100%" height="100%" />
      </Touchable>
      <div className={styles.logoutContainer}>
        <div className={styles.title}>{"Logout" /* TRANSLATION */}</div>
        <Touchable onPress={onLogout} className={styles.button}>
          {"Se déconnecter" /* TRANSLATION */}
        </Touchable>
      </div>
      <AppVersion />
    </div>
  );
};

export default LogoutPage;

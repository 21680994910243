import { debounce } from 'lodash';

export const getMeetingTime = (seconds: boolean = false): string => {
  const options: Intl.DateTimeFormatOptions = { hour: "2-digit", minute: "2-digit" };
  if(seconds) {
    options.second = "2-digit";
  }
  return new Date().toLocaleTimeString(undefined, options);
};
export const getMeetingTimeSeconds = (): string => {
  const seconds = `0${new Date().toLocaleTimeString(undefined, { second: "2-digit"})}`;
  return `${seconds.substring(seconds.length - 2, seconds.length)}`;  
};

export const generateRandomId = (digit: number = 16): string => [...Array(digit)].map(() => Math.floor(Math.random() * 16).toString(16)).join("");

export const asyncDebounce = <T = any>(func: (...args: any[]) => Promise<T>, wait: number): (...args: any[]) => Promise<T> => {
  const resolves: ((value: T | PromiseLike<T>) => void)[] = [];
  const rejects: ((reason?: any) => void)[] = [];
  const debounced = debounce(async (args) => {
    try {
      const result = await func(...args);
      resolves.forEach((resolve) => resolve(result));
    } catch (error) {
      rejects.forEach((reject) => reject(error));
    }
  }, wait);

  // This is the function that will be bound by the caller, so it must contain the `function` keyword.
  function returnFunc(...args: any[]): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      resolves.push(resolve);
      rejects.push(reject);
      debounced(args);
    });
  }
  return returnFunc;
};

export const isIp = (value: string): boolean => {
  return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(value);
}

export const isMediaStream = (provider: MediaProvider | null | undefined): provider is MediaStream => {
  if (!provider) return false;
  return 'id' in provider;
}

export const convertMsToHMS = (milliseconds: number): string => {
  const padTo2Digits = (num: number): string => {
    return num.toString().padStart(2, '0');
  }
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = seconds >= 30 ? minutes + 1 : minutes;
  minutes = minutes % 60;
  hours = hours % 24;
  if (!hours) return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

export const isHex = (str: string): boolean => {
  return Boolean(str.match(/^[0-9a-f]+$/i))
}

export const isMongoDBId = (str: string | undefined): boolean => {
  return str?.length === 24 && isHex(str);
}

export const dataURLToFile = (dataURL: string, filename = "") => {
  const dataIndexStart = dataURL.indexOf(",");
  const byteString = atob(dataURL.slice(dataIndexStart + 1));
  const mimeType = dataURL.slice(0, dataIndexStart).split(":")[1].split(";")[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new File([ab], filename, { type: mimeType });
};

export const APP_VERSION = process.env.REACT_APP_APP_VERSION;
import { SocketTokenOutputDTO } from "@kalyzee/kast-app-module";
import { delay } from "@kalyzee/kast-app-web-components";
import {
  ConnectionError,
  ErrorMessage,
  MeetingExcludeRequest,
  MeetingGetByIdRequest,
  MeetingJoinRequest,
  MeetingLeaveRequest,
  MeetingOnExcludeActionHandler,
  MeetingOnJoinActionHandler,
  MeetingOnLeaveActionHandler,
  MeetingSessionCustomEventRequest,
  MeetingSessionMediaOnUpdateActionHandler,
  MeetingSessionMediaUpdateRequest,
  MeetingSessionOnCustomEventActionHandler,
  MeetingSessionOnStatsActionHandler,
  MeetingSessionOnUpdateActionHandler,
  MeetingSessionPushStatsRequest,
  MeetingSessionUpdateRequest,
  ResultEmittedMessage,
  SocketActionHandler,
  WebSocket,
  MeetingMessageOnCreateActionHandler,
  MeetingMessageOnDeleteActionHandler,
  MeetingMessageOnReactionActionHandler,
  MeetingMessageOnUpdateActionHandler,
  MeetingMessageCreateRequest,
  MeetingMessageDeleteRequest,
  MeetingMessageGetRequest,
  MeetingMessageReactionRequest,
  MeetingMessageUpdateRequest,
} from "@kalyzee/kast-websocket-module";
import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, Middleware, PayloadAction } from "@reduxjs/toolkit";
import { logger } from "../../helpers/logger";
import { getCompatibilityInfos, websocketClient } from "../../helpers/request";
import { RTCStreamStats } from "../../helpers/rtc";
import { toastError } from "../../helpers/toast";
import { requestAppDispacth } from "../../hooks/app";
import { MeetingSessionExtra } from "../../interfaces/meeting";
import { SocketStatus } from "../../interfaces/socket";
import socketMeetingActions from "../meeting/actions";
import { cleanMeeting, setMeeting, setSession, updateMediaDestinations, updateSession, updateSessionMedia } from "../meeting/slices";
import requestActions from "../request/actions";
import { setApiRESTCompatibilityInfo, setWebsocketServerCompatibilityInfo } from "../session/slices";
import { AppMiddlewareApi } from "../store";
import socketActions from "./actions";
import { setSocketStatus } from "./slices";

const log = (...args: string[]) => {
  logger.log("[SOCKET] ", ...args);
};

/// Redux Action Handlers ///

interface ActionEntryWithPayload<P = any> {
  actionCreator: ActionCreatorWithPayload<P>;
  handle: (socket: WebSocket, store: AppMiddlewareApi, payload: P) => Promise<ResultEmittedMessage | undefined> | void;
}

interface ActionEntry {
  actionCreator: ActionCreatorWithoutPayload;
  handle: (socket: WebSocket, store: AppMiddlewareApi) => void;
}

const reduxActionHandlers: (ActionEntry | ActionEntryWithPayload)[] = [
  {
    actionCreator: socketActions.socketConnect,
    handle: async (socket: WebSocket, store: AppMiddlewareApi) => {
      store.dispatch(setSocketStatus(SocketStatus.Loading));
      await delay(100);
      try {
        if (socket) socket.disconnect();
      } catch {}
      try {
        const getSocketToken = async () => {
          const socketTokenResponse = await requestAppDispacth<SocketTokenOutputDTO>(store.dispatch, requestActions.requestSocketToken());
          const socketToken = socketTokenResponse.data.token;
          return socketToken;
        };
        await socket.connectWitSocketToken(await getSocketToken(), {
          resetRetries: true,
          retryMiddleware: async () => ({ token: await getSocketToken() }), // regenerate token for each connection
        });
      } catch (err) {
        console.error("Error during connection websocket : ", err);
        toastError(`Error during connection websocket`);
        store.dispatch(setSocketStatus(SocketStatus.Offline));
      }
      return undefined;
    },
  },
  {
    actionCreator: socketActions.socketDisconnect,
    handle: (socket: WebSocket, store: AppMiddlewareApi) => {
      socket.disconnect();
      return undefined;
    },
  },
  {
    actionCreator: socketMeetingActions.getById,
    handle: (socket: WebSocket, store: AppMiddlewareApi, payload: MeetingGetByIdRequest) => socket.emitter.meeting.getById(payload),
  },
  {
    actionCreator: socketMeetingActions.join,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: MeetingJoinRequest) => {
      const result = await socket.emitter.meeting.join(payload);
      if (!result.error && result.response) {
        store.dispatch(setSession(result.response.session));
        store.dispatch(setMeeting(result.response.meeting));
        store.dispatch(updateMediaDestinations(result.response.session));
      }
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.leave,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: MeetingLeaveRequest) => {
      const result = await socket.emitter.meeting.leave(payload);
      if (!result.error && result.response) {
        store.dispatch(cleanMeeting());
      }
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.exclude,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: MeetingExcludeRequest) => {
      return await socket.emitter.meeting.exclude(payload);
    },
  },
  {
    actionCreator: socketMeetingActions.udapteSessionById,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: { data: MeetingSessionUpdateRequest<MeetingSessionExtra>; storeResult?: boolean }) => {
      const result = await socket.emitter.meeting.updateSession(payload.data);
      if (!result.error && result.response && payload.storeResult) {
        store.dispatch(updateSession(result.response.session));
      }
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.publishCustomEventSession,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: MeetingSessionCustomEventRequest) => {
      return await socket.emitter.meeting.publishCustomEventSession(payload.data);
    },
  },
  {
    actionCreator: socketMeetingActions.sessionStats,
    handle: async (
      socket: WebSocket,
      store: AppMiddlewareApi,
      payload: { meetingId: string; sessionId: string; stats: { [meetingId: string]: RTCStreamStats } }
    ) => {
      const data: MeetingSessionPushStatsRequest = {
        id: payload.meetingId,
        sessionId: payload.sessionId,
        stats: {
          medias: Object.keys(payload.stats).reduce<any[]>((p, c) => {
            p.push({
              mediaId: c,
              ...payload.stats[c],
            });
            return p;
          }, []),
        },
      };
      const result = await socket.emitter.meeting.pushStatsSession(data);
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.udapteSessionMediaById,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: { data: MeetingSessionMediaUpdateRequest; storeResult?: boolean }) => {
      const result = await socket.emitter.meeting.updateSessionMedia(payload.data);
      if (!result.error && result.response && payload.storeResult) {
        store.dispatch(updateSessionMedia(result.response.media));
      }
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.getMessages,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: { data: MeetingMessageGetRequest; storeResult?: boolean }) => {
      const result = await socket.emitter.meeting.getMessages(payload.data);
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.createMessage,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: { data: MeetingMessageCreateRequest; storeResult?: boolean }) => {
      const result = await socket.emitter.meeting.createMessage(payload.data);
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.updateMessage,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: { data: MeetingMessageUpdateRequest; storeResult?: boolean }) => {
      const result = await socket.emitter.meeting.updateMessage(payload.data);
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.deleteMessage,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: { data: MeetingMessageDeleteRequest; storeResult?: boolean }) => {
      const result = await socket.emitter.meeting.deleteMessage(payload.data);
      return result;
    },
  },
  {
    actionCreator: socketMeetingActions.setMessageReaction,
    handle: async (socket: WebSocket, store: AppMiddlewareApi, payload: { data: MeetingMessageReactionRequest; storeResult?: boolean }) => {
      const result = await socket.emitter.meeting.setMessageReaction(payload.data);
      return result;
    },
  },
];

const reduxActionMatcher = (socket: WebSocket, store: AppMiddlewareApi, action: PayloadAction): Promise<ResultEmittedMessage | undefined> | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const handler of reduxActionHandlers) {
    if (handler.actionCreator.match(action)) {
      const result = handler.handle(socket, store, action.payload);
      if (result instanceof Promise) return result;
      return undefined;
    }
  }
  return undefined;
};

/// Socket Action Handlers ///

const socketActionHandlers = (store: AppMiddlewareApi): SocketActionHandler[] => {
  const handlers: SocketActionHandler[] = [];

  const meetingOnJoinHandler = new MeetingOnJoinActionHandler();
  meetingOnJoinHandler.onReceive = (message) => {
    store.dispatch(setMeeting(message.content.meeting));
    store.dispatch(socketMeetingActions.onJoin(message.content));
  };
  handlers.push(meetingOnJoinHandler);

  const meetingOnLeaveHandler = new MeetingOnLeaveActionHandler();
  meetingOnLeaveHandler.onReceive = (message) => {
    const state = store.getState().meeting;
    if (state.meeting?.id === message.content.meeting?.id) {
      if (state.session?.id === message.content.session?.id) store.dispatch(cleanMeeting());
      else store.dispatch(setMeeting(message.content.meeting));
      store.dispatch(socketMeetingActions.onLeave(message.content));
    }
  };
  handlers.push(meetingOnLeaveHandler);

  const meetingOnExcludeHandler = new MeetingOnExcludeActionHandler();
  meetingOnExcludeHandler.onReceive = (message) => {
    const state = store.getState().meeting;
    if (state.meeting?.id === message.content.meeting?.id) {
      if (state.session?.id === message.content.session?.id) store.dispatch(cleanMeeting());
      store.dispatch(socketMeetingActions.onExclude(message.content));
    }
  };
  handlers.push(meetingOnExcludeHandler);

  const meetingSessionOnUpdateHandler = new MeetingSessionOnUpdateActionHandler();
  meetingSessionOnUpdateHandler.onReceive = (message) => {
    store.dispatch(updateSession(message.content.session));
    store.dispatch(socketMeetingActions.onSessionUpdate(message.content));
  };
  handlers.push(meetingSessionOnUpdateHandler);

  const meetingSessionOnCustomEventHandler = new MeetingSessionOnCustomEventActionHandler();
  meetingSessionOnCustomEventHandler.onReceive = (message) => {
    store.dispatch(socketMeetingActions.onSessionCustomEvent(message.content));
  };
  handlers.push(meetingSessionOnCustomEventHandler);

  const meetingSessionOnStatsHandler = new MeetingSessionOnStatsActionHandler();
  meetingSessionOnStatsHandler.onReceive = (message) => store.dispatch(socketMeetingActions.onSessionStats(message.content));
  handlers.push(meetingSessionOnStatsHandler);

  const meetingSessionMediaOnUpdateHandler = new MeetingSessionMediaOnUpdateActionHandler();
  meetingSessionMediaOnUpdateHandler.onReceive = (message) => {
    store.dispatch(updateSessionMedia(message.content.media));
    store.dispatch(socketMeetingActions.onSessionMediaUpdate(message.content));
  };
  handlers.push(meetingSessionMediaOnUpdateHandler);

  // messages
  const meetingMessageOnCreateHandler = new MeetingMessageOnCreateActionHandler();
  meetingMessageOnCreateHandler.onReceive = (message) => {
    store.dispatch(socketMeetingActions.onCreateMessage(message.content));
  };
  handlers.push(meetingMessageOnCreateHandler);

  const meetingMessageOnUpdateHandler = new MeetingMessageOnUpdateActionHandler();
  meetingMessageOnUpdateHandler.onReceive = (message) => {
    store.dispatch(socketMeetingActions.onUpdateMessage(message.content));
  };
  handlers.push(meetingMessageOnUpdateHandler);

  const meetingMessageOnDeleteHandler = new MeetingMessageOnDeleteActionHandler();
  meetingMessageOnDeleteHandler.onReceive = (message) => {
    store.dispatch(socketMeetingActions.onDeleteMessage(message.content));
  };
  handlers.push(meetingMessageOnDeleteHandler);

  const meetingMessageOnReactionHandler = new MeetingMessageOnReactionActionHandler();
  meetingMessageOnReactionHandler.onReceive = (message) => {
    store.dispatch(socketMeetingActions.onMessageReaction(message.content));
  };
  handlers.push(meetingMessageOnReactionHandler);

  return handlers;
};

/// Middleware ///
export const socketMiddleware: Middleware = (store: AppMiddlewareApi) => {
  websocketClient.registerActionHandlers(socketActionHandlers(store));
  websocketClient.onConnect = async () => {
    log("Connected");
    store.dispatch(setSocketStatus(SocketStatus.Online));
    const compatibilityInfos = await getCompatibilityInfos();
    if (compatibilityInfos.apiREST) store.dispatch(setApiRESTCompatibilityInfo(compatibilityInfos.apiREST));
    if (compatibilityInfos.websocketServer) store.dispatch(setWebsocketServerCompatibilityInfo(compatibilityInfos.websocketServer));
  };
  websocketClient.onDisconnect = () => {
    log("Disconnected");
    store.dispatch(cleanMeeting());
    store.dispatch(setSocketStatus(SocketStatus.Loading));
  };

  websocketClient.onConnectionFailed = (error: ConnectionError) => {
    log("Connection Failed");
    store.dispatch(setSocketStatus(SocketStatus.Loading));
  };

  websocketClient.onAbandon = () => {
    log("Abandonned");
    store.dispatch(setSocketStatus(SocketStatus.Offline));
  };

  websocketClient.onErrorMessageCallback = (error: ErrorMessage) => {
    log(`Error : ${JSON.stringify(error)}`);
    store.dispatch(setSocketStatus(SocketStatus.Offline));
  };

  // eslint-disable-next-line arrow-body-style
  return (next) => async (action) => {
    const result = reduxActionMatcher(websocketClient, store, action);
    if (result) return result;
    return next(action);
  };
};

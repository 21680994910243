import { PopupButtonType, PopupIconType, showPopup, useURL } from "@kalyzee/kast-app-web-components";
import { createRef, ReactElement, useEffect } from "react";
import { Navigate, Route, useNavigate } from "react-router-dom";
import LoginRedirectionRoute from "../components/navigation/LoginRedirection";
import PublicRedirectionRoute from "../components/navigation/PublicRedirection";
import { NavigateSlave } from "../components/navigation/navigateSlave";
import { generatePage } from "../components/navigation/Page";
import PageContainer from "../components/navigation/PageContainer";
import { getPageUrl, PageMasterMode, PageSlaveMode, PAGE_PATHS } from "../components/navigation/PageContext";
import PrivateRoute from "../components/navigation/PrivateRoute";
import PublicRoute from "../components/navigation/PublicRoute";
import { MessagingBridgeManagerMasterContextProvider, MessagingBridgeManagerSlaveContextProvider } from "../contexts/messagingBridgeManagerProvider";
import { appMessage } from "../helpers/message";
import { getGlobalQuery, getSlaveQuery, NavigateGlobalQuery, NavigateSlaveQuery } from "../helpers/navigate";
import { DEFAULT_SETTINGS_MASTER, DEFAULT_SETTINGS_SLAVE, loadSettings, Settings } from "../helpers/settings";
import { useAppDispatch, useAppSelector } from "../hooks/app";
import { useSettings } from "../hooks/settings";
import { AuthStatus } from "../interfaces/session";
import ControlsPage from "../pages/controls/controls";
import ExcludedPage from "../pages/excluded/excluded";
import ForbiddenPage from "../pages/forbidden/forbidden";
import JoinPage from "../pages/join/join";
import LoginPage, { LoginMethod } from "../pages/login/login";
import LogoutPage from "../pages/logout/logout";
import MeetingPage from "../pages/meeting/meeting";
import ParingPage from "../pages/pairing/pairing";
import RemotePage from "../pages/remote/remote";
import SpectatorPage from "../pages/spectator/spectator";
import WhiteboardPage from "../pages/whiteboard/whiteboard";
import { useMode } from "../store/session/hooks";
import { selectAuthStatus, selectToken } from "../store/session/selectors";
import { SessionMode, setAuthStatus, setMode } from "../store/session/slices";
import "./App.css";
import styles from "./App.module.css";
import { logger } from "../helpers/logger";

function App() {
  const url = useURL();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authStatus = useAppSelector(selectAuthStatus);
  const token = useAppSelector(selectToken);
  const mode = useMode();
  const [settings, saveSettings] = useSettings();

  useEffect(() => {
    if (getGlobalQuery(url.href, NavigateGlobalQuery.SLAVE) && mode !== SessionMode.SLAVE) {
      dispatch(setMode(SessionMode.SLAVE));
    }
  }, [dispatch, url, mode]);
  useEffect(() => {
    if (token) {
      dispatch(setAuthStatus(AuthStatus.In));
    }
  }, [dispatch, token]);

  useEffect(() => {
    navigator.mediaDevices?.enumerateDevices().then((data) => logger.log("devices : ", data));
    return () => {
      appMessage.close();
    };
  }, []);

  useEffect(() => {
    appMessage.ready(settings.secret?.remotePassword ?? undefined);
  }, [settings.secret?.remotePassword]);

  useEffect(() => {
    if (mode === SessionMode.SLAVE && getSlaveQuery(url.href, NavigateSlaveQuery.REMOTE)) {
      if (!settings.slave?.bridgeMessages?.enableWebsocket || !settings.slave?.bridgeMessages?.websocketEndpoint) {
        const currentPath = url.pathname.startsWith('/') ? url.pathname.replace('/', '') : url.pathname;
        let redirect: string | undefined;
        if (currentPath.length) {
          redirect = `${currentPath}${encodeURIComponent(url.search)}`;
        }
        navigate(getPageUrl(mode, PageSlaveMode.REMOTE, undefined, { queries: { redirect } }));
      }
    }
  }, [settings, url, mode]);

  useEffect(() => {
    const getListener = () => appMessage.postSettings(loadSettings());
    const setListener = (data: Partial<Settings>) => {
      const newSettings = saveSettings(data);
      logger.log("localstorageSetSettings : ", newSettings);
      appMessage.postSettings(newSettings);
    };
    appMessage.addEventListener("localstorageGetSettings", getListener);
    appMessage.addEventListener("localstorageSetSettings", setListener);
    return () => {
      appMessage.removeEventListener("localstorageGetSettings", getListener);
      appMessage.removeEventListener("localstorageSetSettings", setListener);
    };
  });

  const renderMasterBridgeMessageManagerProvider = (children: ReactElement) => (
    <MessagingBridgeManagerMasterContextProvider
      options={{
        enableBroadcast: settings.master?.bridgeMessages?.enableBroadcast ?? DEFAULT_SETTINGS_MASTER.bridgeMessages.enableBroadcast,
        password: settings.secret?.localPassword ?? undefined,
      }}
    >
      {children}
    </MessagingBridgeManagerMasterContextProvider>
  );

  const renderSlaveBridgeMessageManagerProvider = (children: ReactElement) => {
    let enableWebsocket = settings.slave?.bridgeMessages?.enableWebsocket ?? DEFAULT_SETTINGS_SLAVE.bridgeMessages.enableWebsocket;
    let websocketEndpoint = settings.slave?.bridgeMessages?.websocketEndpoint  ?? DEFAULT_SETTINGS_SLAVE.bridgeMessages.websocketEndpoint;
    const bridgeWebsocketEndpoint = getSlaveQuery(url.href, NavigateSlaveQuery.BRIDGE_WEBSOCKET_ENDPOINT);
    if (bridgeWebsocketEndpoint) {
      enableWebsocket = true;
      websocketEndpoint = bridgeWebsocketEndpoint;
    }
    let passwordPopupDisplaying = false;
    return (
      <MessagingBridgeManagerSlaveContextProvider
        options={{
          enableBroadcast: settings.slave?.bridgeMessages?.enableBroadcast ?? DEFAULT_SETTINGS_SLAVE.bridgeMessages.enableBroadcast,
          enableWebsocket,
          websocketEndpoint,
          password: settings.secret?.remotePassword ?? undefined,
        }}
        onMessagingBridgeManager={(manager) => {
          manager.addEventListener("unauthorized", (msg) => {
            const inputRef = createRef<HTMLInputElement>();
            const wrongPassword = msg.content.code === -2;
            const title = wrongPassword ? "Mot de passe invalide" : "Mot de passe requis";
            const message = wrongPassword
              ? "Le mot de passe pour se connecter à l'appareil distant est incorrect."
              : "Un mot de passe est nécessaire pour se connecter à l'appareil distant.";
            const showPasswordPopup = () => {
              if (passwordPopupDisplaying) return;
              passwordPopupDisplaying = true;
              showPopup({
                title,
                iconTitle: PopupIconType.INFO,
                content: (
                  <div className={styles.popupPasswordContainer}>
                    <div className={styles.popupPasswordTitle}>{message}</div>
                    <div className={styles.popupPasswordDescription}>Saisissez le mot de passe:</div>
                    <input className={styles.popupPasswordInput} ref={inputRef} type="password" />
                  </div>
                ),
                buttons: [{ type: PopupButtonType.VALIDATE, element: "Valider" /* TRANSLATION */ }],
                enableBackdropDismiss: false,
                enableCloseButton: false,
                onClose: () => {
                  passwordPopupDisplaying = false;
                  const password = inputRef.current?.value;
                  if (!password) {
                    showPasswordPopup();
                    return;
                  }
                  const newSettings: Settings = {
                    ...settings,
                  };
                  if (!newSettings.secret) newSettings.secret = {};
                  newSettings.secret.remotePassword = password;
                  saveSettings(newSettings);
                  window.location.reload();
                },
              });
            };
            showPasswordPopup();
          });
        }}
      >
        {children}
      </MessagingBridgeManagerSlaveContextProvider>
    );
  };

  const privateRoutes = () => {
    if (mode === SessionMode.SLAVE) {
      return renderSlaveBridgeMessageManagerProvider(
        <PageContainer element={<PrivateRoute mode={mode} authStatus={authStatus} />}>
          {generatePage(SessionMode.SLAVE, PageSlaveMode.LOGOUT, <LogoutPage />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.LOGIN, <LoginRedirectionRoute defaultTo={PAGE_PATHS[mode][PageSlaveMode.SPECTATOR_WAITING]} />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.SPECTATOR_WAITING, <SpectatorPage waiting />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.SPECTATOR, <SpectatorPage />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.CONTROLS_WAITING, <ControlsPage waiting />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.CONTROLS, <ControlsPage />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.WHITEBOARD_WAITING, <WhiteboardPage waiting />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.WHITEBOARD, <WhiteboardPage />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.EXCLUDED, <ExcludedPage />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.REMOTE, <RemotePage />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.PAIRING, <ParingPage />)}
          <Route path="" element={<NavigateSlave to={PAGE_PATHS[mode][PageSlaveMode.SPECTATOR_WAITING]} />} />
          <Route path="*" element={<NavigateSlave to={PAGE_PATHS[mode][PageSlaveMode.SPECTATOR_WAITING]} />} />
        </PageContainer>
      );
    }
    return renderMasterBridgeMessageManagerProvider(
      <PageContainer element={<PrivateRoute mode={mode} authStatus={authStatus} />}>
        {generatePage(SessionMode.MASTER, PageMasterMode.LOGOUT, <LogoutPage />)}
        {generatePage(SessionMode.MASTER, PageMasterMode.LOGIN, <LoginRedirectionRoute defaultTo={PAGE_PATHS[mode][PageMasterMode.JOIN]} />)}
        {generatePage(SessionMode.MASTER, PageMasterMode.JOIN, <JoinPage />)}
        {generatePage(SessionMode.MASTER, PageMasterMode.EXCLUDED, <ExcludedPage />)}
        {generatePage(SessionMode.MASTER, PageMasterMode.PAIRING, <ParingPage />)}
        {generatePage(SessionMode.MASTER, PageMasterMode.WHITEBOARD, <WhiteboardPage />)}
        {generatePage(SessionMode.MASTER, PageMasterMode.MEETING, <MeetingPage />)}
        <Route path="" element={<Navigate to={PAGE_PATHS[mode][PageMasterMode.JOIN]} replace />} />
        <Route path="*" element={<Navigate to={PAGE_PATHS[mode][PageMasterMode.JOIN]} replace />} />
      </PageContainer>
    );
  };

  const publicRoutes = () => {
    if (mode === SessionMode.SLAVE || getGlobalQuery(url.href, NavigateGlobalQuery.SLAVE)) {
      return renderSlaveBridgeMessageManagerProvider(
        <PageContainer element={<PublicRoute mode={mode} authStatus={authStatus} />}>
          {generatePage(SessionMode.SLAVE, PageSlaveMode.LOGIN, <LoginPage emailPassword={true} oAuth2Code={true} method={LoginMethod.OAUTH2_CODE} />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.FORBIDDEN, <ForbiddenPage />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.EXCLUDED, <ExcludedPage />)}
          {generatePage(SessionMode.SLAVE, PageSlaveMode.REMOTE, <RemotePage />)}
          <Route path="" element={<PublicRedirectionRoute mode={mode} />} />
          <Route path="*" element={<PublicRedirectionRoute mode={mode} />} />
        </PageContainer>
      );
    }
    return renderMasterBridgeMessageManagerProvider(
      <PageContainer element={<PublicRoute mode={mode} authStatus={authStatus} />}>
        {generatePage(SessionMode.MASTER, PageMasterMode.LOGIN, <LoginPage emailPassword={true} oAuth2Code={true} method={LoginMethod.EMAIL_PASSWORD} />)}
        {generatePage(SessionMode.MASTER, PageMasterMode.FORBIDDEN, <ForbiddenPage />)}
        <Route path="" element={<PublicRedirectionRoute mode={mode} />} />
        <Route path="*" element={<PublicRedirectionRoute mode={mode} />} />
      </PageContainer>
    );
  };
  if (authStatus === AuthStatus.In) return privateRoutes();
  return publicRoutes();
}

export default App;

import { Colors, Hover } from "@kalyzee/kast-app-web-components";
import WhiteboardPackageJson from "@kalyzee/whiteboard-react/package.json";
import React, { ForwardedRef, HTMLProps, useRef } from "react";
import { APP_VERSION } from "../helpers/utils";
import { useCompatibilityInfos } from "../store/session/hooks";

export interface VersionsProps extends HTMLProps<HTMLDivElement> {
  className?: string;
  style?: React.CSSProperties;
}

export interface VersionsRef {}

const Versions = React.forwardRef(({ className, style, ...divProps }: VersionsProps, forwardRef: ForwardedRef<VersionsRef | undefined>) => {
  const backendVersionRef = useRef<HTMLDivElement>(null);
  const compatibilityInfos = useCompatibilityInfos();
  const renderFullBackendVersion = () => (
    <>
      <div
        style={Object.assign({}, !compatibilityInfos?.apiREST?.isSupported ? { color: 'red'} : {})}
      >
        Backend - API REST Version : {compatibilityInfos?.apiREST?.apiVersion}
      </div>
      <div
        style={Object.assign({}, !compatibilityInfos?.websocketServer?.isSupported ? { color: 'red'} : {})}
      >
        Backend - Websocket Server Version : {compatibilityInfos?.websocketServer?.apiVersion}
      </div>
    </>
  );
  return (
    <div {...divProps}>
      <div>Application Version : {APP_VERSION}</div>
      <div>Whiteboard Version : {WhiteboardPackageJson?.version}</div>
      {compatibilityInfos?.apiREST?.apiVersion === compatibilityInfos?.websocketServer?.apiVersion ? (
        <div
          ref={backendVersionRef}
          style={Object.assign({}, !compatibilityInfos?.apiREST?.isSupported || !compatibilityInfos?.websocketServer?.isSupported ? { color: 'red'} : {})}
        >
          Backend Version : {compatibilityInfos?.apiREST?.apiVersion}
          <Hover targetRef={backendVersionRef}>
            <div style={{ backgroundColor: Colors.getBluePrussian(0.8), padding: '10px', borderRadius: '10px', color: "white"}}>
              {renderFullBackendVersion()}
            </div>
          </Hover>
        </div>
      ) : renderFullBackendVersion()}
    </div>
  );
});

Versions.defaultProps = {
  className: undefined,
  style: undefined,
};

export default Versions;

import { Badge } from "@kalyzee/kast-app-web-components";
import React, { ForwardedRef, HTMLProps, useImperativeHandle, useState } from "react";
import { ReactComponent as IconChat } from "../assets/icons/icon-chat-white.svg";
import styles from "./ChatButton.module.css";

export interface ChatButtonProps extends HTMLProps<HTMLDivElement> {
  maxNumber?: number;
  className?: string;
  style?: React.CSSProperties;
}

export interface ChatButtonRef {
  setBadgeValue: (value?: string | number) => void;
}

const ChatButton = React.forwardRef(({ maxNumber, className, style, ...htmlProps }: ChatButtonProps, forwardRef: ForwardedRef<ChatButtonRef | undefined>) => {
  const [badge, setBadge] = useState<string | number | undefined>();
  useImperativeHandle(forwardRef, () => ({
    setBadgeValue: setBadge,
  }));

  return (
    <div className={styles.container} {...htmlProps}>
      <IconChat width={25} height={25} strokeWidth={2}/>
      <Badge value={badge} maxNumber={maxNumber ?? 9} />
    </div>
  );
});

ChatButton.defaultProps = {
  className: undefined,
  style: undefined,
};

export default ChatButton;

import { useURL } from '@kalyzee/kast-app-web-components';
import { Navigate, Outlet } from 'react-router-dom';

interface LoginRedirectionRouteProps {
  defaultTo: string;
}

const LoginRedirectionRoute = ({ defaultTo }: LoginRedirectionRouteProps) => {
  const url = useURL();
  const redirection = decodeURIComponent(url.searchParams.get("redirect") ?? '');
  if (redirection) return <Navigate to={`/${redirection}`} replace />;
  return <Navigate to={defaultTo} replace />;
};

LoginRedirectionRoute.defaultProps = {};

export default LoginRedirectionRoute;

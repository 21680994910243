import { EventListeners } from "@kalyzee/kast-app-web-components";
import {
  RTCSessionEncodedBuffersEmitter,
  RTCSessionEncodedBuffersEmitterListenerMap,
  RTCSessionEncodedBuffersEmitterOptions,
  RTCSessionEncodedBuffersReceiver,
  RTCSessionEncodedBuffersReceiverListenerMap,
  RTCSessionEncodedBuffersReceiverOptions,
  RTCStreamSession,
  RTCStreamSessionListenerMap,
  RTCStreamSessionOptions,
  SdpFormatter,
  WebRTCSession
} from "@kalyzee/kast-webrtc-client-module";
import { Debug } from "./debug";
import { logger } from "./logger";
import { refreshMediaOnRTCPeerConnection } from "./rtc";
import { generateRandomId } from "./utils";

const RTC_CONNEXION_TIMEOUT = 10000;
const RTC_RETRY_AFTER = 1000;

export enum RTCBridgeMediaSessionMode {
  MEDIASTREAM = "mediastream",
  ENCODED_BUFFERS = "encoded_buffers",
}

export enum RTCBridgeMediaSessionState {
  WAITING = "waiting",
  READY = "ready",
  CONNECTING = "connecting",
  CONNECTED = "connected",
}

export enum RTCBridgeTransmissionMode {
  DISABLED = 'disabled',
  WEBRTC = "webrtc",
  PREFER_WEBRTC = "prefer-webrtc",
  WEBCODECS = "webcodecs",
  PREFER_WEBCODECS = "prefer-webcodecs",
}

// ---------------------------------------------------------------- //
// -------------------------- RECEIVER ---------------------------- //
// ---------------------------------------------------------------- //

interface RTCReceiverSessionData {
  mediaId: string;
  id: string;
  mode: RTCBridgeMediaSessionMode;
  connexionTimeout?: NodeJS.Timeout;
}
class RTCEncodedBuffersReceiver extends RTCSessionEncodedBuffersReceiver<
  RTCSessionEncodedBuffersReceiverListenerMap,
  RTCSessionEncodedBuffersReceiverOptions,
  RTCReceiverSessionData
> {}
class RTCStreamReceiver extends RTCStreamSession<RTCStreamSessionListenerMap, RTCStreamSessionOptions, RTCReceiverSessionData> {}

export interface RTCReceiverSessionMap {
  ["unconfigured"]: undefined;
  [RTCBridgeMediaSessionMode.MEDIASTREAM]: RTCStreamReceiver;
  [RTCBridgeMediaSessionMode.ENCODED_BUFFERS]: RTCEncodedBuffersReceiver;
}

export interface RTCBridgeMediaSessionReceiverEventMap {
  sdp: (mediaId: string, id: string, sdp: RTCSessionDescriptionInit | null) => void;
  candidate: (mediaId: string, id: string, candidate: RTCIceCandidateInit | null) => void;
  stream: (steam: MediaStream) => void;
  close: (mediaId: string, id: string) => void;
}

export class RTCBridgeMediaSessionReceiver {
  protected eventListeners: EventListeners<keyof RTCBridgeMediaSessionReceiverEventMap, RTCBridgeMediaSessionReceiverEventMap>;
  readonly modes: RTCBridgeMediaSessionMode[];
  private _mode?: RTCBridgeMediaSessionMode;
  public get mode(): RTCBridgeMediaSessionMode | undefined { return this._mode };
  private session?: RTCEncodedBuffersReceiver | RTCStreamReceiver;
  private id?: string;
  private mediaId?: string;
  private state: RTCBridgeMediaSessionState;
  private retryTimeout?: NodeJS.Timeout;
  constructor(transmissionMode?: RTCBridgeTransmissionMode) {
    this.eventListeners = new EventListeners<keyof RTCBridgeMediaSessionReceiverEventMap, RTCBridgeMediaSessionReceiverEventMap>();
    const modes: RTCBridgeMediaSessionMode[] = [];
    const currTransmissionMode = transmissionMode ?? RTCBridgeTransmissionMode.PREFER_WEBCODECS;
    if (currTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBRTC) modes.push(RTCBridgeMediaSessionMode.MEDIASTREAM);
    if (currTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBCODECS) modes.push(RTCBridgeMediaSessionMode.ENCODED_BUFFERS);
    if (currTransmissionMode === RTCBridgeTransmissionMode.WEBRTC || currTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBCODECS)
      modes.push(RTCBridgeMediaSessionMode.MEDIASTREAM);
    if (currTransmissionMode === RTCBridgeTransmissionMode.WEBCODECS || currTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBRTC)
      modes.push(RTCBridgeMediaSessionMode.ENCODED_BUFFERS);
    this.modes = modes;
    this.state = RTCBridgeMediaSessionState.WAITING;
    Debug.addBridgeReceiver(this);
  }

  clean() {
    if (this.retryTimeout) clearTimeout(this.retryTimeout);
    if (this.session?.data?.connexionTimeout) clearTimeout(this.session.data.connexionTimeout);
    this.session?.removeAllEventListeners();
    this.session?.destroy();
    this.session = undefined;
    this._mode = undefined;
    this.state = RTCBridgeMediaSessionState.WAITING;
  }

  destroy() {
    this.clean();
    this.eventListeners.removeAllEventListener();
  }

  prepare(mediaId: string): string {
    this.clean(); // destroy current session if exist
    this.id = generateRandomId();
    this.mediaId = mediaId;
    this.state = RTCBridgeMediaSessionState.READY;
    return this.id;
  }

  async start<T extends RTCBridgeMediaSessionMode>(mode: T, mediaId: string, id: string): Promise<RTCReceiverSessionMap[T] | undefined> {
    if (this.state !== RTCBridgeMediaSessionState.READY) return undefined;
    if (this.mediaId !== mediaId) return undefined;
    if (this.id !== id) return undefined;
    let session: RTCReceiverSessionMap[T];
    if (mode === RTCBridgeMediaSessionMode.MEDIASTREAM) session = new RTCStreamReceiver() as RTCReceiverSessionMap[T];
    else if (mode === RTCBridgeMediaSessionMode.ENCODED_BUFFERS) {
      const newSession = new RTCEncodedBuffersReceiver({ buildMediaStream: true });
      newSession.addEventListener('videoDecoderConfig', (config) => logger.log('[DECODER] Video config : ', config));
      newSession.addEventListener('audioDecoderConfig', (config) => logger.log('[DECODER] Audio config : ', config));
      newSession.middlewareVideoDecoderInputData = (d) => {
        const debugData = Debug.getData();
        if (debugData) debugData.receiverEncodedVideoLastFrame = d;
        if (debugData?.notDecodeEncodedBuffer === true) return null;
        return d;
      };
      newSession.middlewareAudioDecoderInputData = (d) => {
        const debugData = Debug.getData();
        if (debugData) debugData.receiverEncodedAudioLastFrame = d;
        if (debugData?.notDecodeEncodedBuffer === true) return null;
        return d;
      };
      if (newSession.mediaStreamVideoDecoder) {
        newSession.mediaStreamVideoDecoder.middlewareWriteFrame = (d) => {
          const debugData = Debug.getData();
          // console.log('color space : ', d.colorSpace.toJSON());
          if (debugData) debugData.receiverDecodedVideoLastFrame = d;
          if (debugData?.notProcessDecodedBuffer === true) return null;
          return d;
        };
      }
      if (newSession.mediaStreamAudioDecoder) {
        newSession.mediaStreamAudioDecoder.middlewareWriteData = (d) => {
          const debugData = Debug.getData();
          if (debugData) debugData.receiverDecodedAudioLastData = d;
          if (debugData?.notProcessDecodedBuffer === true) return null;
          return d;
        };
      }

      session = newSession as RTCReceiverSessionMap[T];
    } else {
      return undefined;
    }
    this.session = session;
    this._mode = mode;

    const connexionTimeout = setTimeout(() => {
      this.clean();
      this.start(mode, mediaId, id);
    }, RTC_CONNEXION_TIMEOUT);
    session.data = { mediaId, id, mode, connexionTimeout };
    const clearConnectionTimeout = () => {
      clearTimeout(connexionTimeout);
    };

    const errorListener = (error: any) => {
      // console.log('[RTC - RECEIVER - Error] - ', mediaId, id, ' : ', error);
      session?.destroy();
    };

    const closeListener = () => {
      // console.log('[RTC - RECEIVER - Close] - ',  mediaId, id);
      clearConnectionTimeout();
      this.clean();
      this.retryTimeout = setTimeout(() => this.start(mode, mediaId, id), RTC_RETRY_AFTER); // retry after 1s
      this.eventListeners.triggerEvent("close", mediaId, id);
    };

    const sdpListener = (description: RTCSessionDescription | null) => {
      // console.log('[RTC - RECEIVER - SDP] - ',  mediaId, id, sdp);
      clearConnectionTimeout();

      const newDescription = description ? {
        sdp: description.sdp,
        type: description.type,
      } : null;
      if (newDescription) {
        const sdpFormatter = new SdpFormatter(newDescription.sdp);
        /*
          a=extmap:14 urn:ietf:params:rtp-hdrext:toffset
          a=extmap:2 http://www.webrtc.org/experiments/rtp-hdrext/abs-send-time
          a=extmap:13 urn:3gpp:video-orientation
          a=extmap:3 http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions-01
          a=extmap:5 http://www.webrtc.org/experiments/rtp-hdrext/playout-delay
          a=extmap:6 http://www.webrtc.org/experiments/rtp-hdrext/video-content-type
          a=extmap:7 http://www.webrtc.org/experiments/rtp-hdrext/video-timing
          a=extmap:8 http://www.webrtc.org/experiments/rtp-hdrext/color-space
        */
        sdpFormatter.execPipeline(`remove_lines_from_media["video", "http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions-01"]`);
        sdpFormatter.execPipeline(`set_preferred_format["video", "h264"]`);
        sdpFormatter.execPipeline(`set_codec_param["h264", "x-google-start-bitrate", 1000]|set_codec_param["h264", "x-google-min-bitrate", 1000]`);
        newDescription.sdp = sdpFormatter.sdp;
      }
      this.eventListeners.triggerEvent("sdp", mediaId, id, newDescription ?? null);
    };

    const candidateListener = (candidate: RTCIceCandidate | null) => {
      // console.log('[RTC - RECEIVER - CANDIDATE] - ',  mediaId, id, candidate);
      clearConnectionTimeout();
      this.eventListeners.triggerEvent("candidate", mediaId, id, candidate?.toJSON() ?? null);
    };

    if (mode === RTCBridgeMediaSessionMode.ENCODED_BUFFERS) {
      const curr = session as RTCEncodedBuffersReceiver;
      curr.addEventListener("error", errorListener);
      curr.addEventListener("close", closeListener);
      curr.addEventListener("sdp", sdpListener);
      curr.addEventListener("iceCandidate", candidateListener);
      curr.addEventListener("track", (track: any, s: MediaStream) => {
        // console.log("ENCODED BUFFERS - MEDIA STREAM : ", s);
        this.eventListeners.triggerEvent("stream", s);
      });
    }

    if (mode === RTCBridgeMediaSessionMode.MEDIASTREAM) {
      const curr = session as RTCStreamReceiver;
      curr.addEventListener("error", errorListener);
      curr.addEventListener("close", closeListener);
      curr.addEventListener("sdp", sdpListener);
      curr.addEventListener("iceCandidate", candidateListener);
      curr.addEventListener("stream", (s: MediaStream) => {
        // console.log("MEDIA STREAM : ", s);
        this.eventListeners.triggerEvent("stream", s);
      });
    }

    await session.start();
    return session;
  }

  processRemoteSdp(mediaId: string, id: string, sdp: any) {
    if (!this.session) return;
    if (this.session.data!.mediaId !== mediaId || this.session.data!.id !== id) return;
    this.session.processRemoteSdp(sdp);
  }

  processRemoteIceCandidate(mediaId: string, id: string, candidate: any) {
    if (!this.session) return;
    if (this.session.data!.mediaId !== mediaId || this.session.data!.id !== id) return;
    this.session.processRemoteIceCandidate(candidate);
  }

  close(mediaId: string, id: string): boolean {
    // console.log('Close : ', mediaId, id);
    if (!this.session) return false;
    if (this.session.data!.mediaId !== mediaId || this.session.data!.id !== id) return false;
    this.clean();
    return true;
  }

  // -------------------- LISTENERS -------------------- //

  addEventListener<K extends keyof RTCBridgeMediaSessionReceiverEventMap>(event: K, listener: RTCBridgeMediaSessionReceiverEventMap[K]) {
    this.eventListeners.addEventListener(event, listener);
  }

  removeEventListener<K extends keyof RTCBridgeMediaSessionReceiverEventMap>(event: K, listener: RTCBridgeMediaSessionReceiverEventMap[K]) {
    this.eventListeners.removeEventListener(event, listener);
  }
}

// ---------------------------------------------------------------- //
// -------------------------- EMITTER ---------------------------- //
// ---------------------------------------------------------------- //

interface RTCEmitterSessionData {
  mediaId: string;
  id: string;
  mediaStream?: MediaStream;
  mode: RTCBridgeMediaSessionMode;
}

class RTCEncodedBuffersEmitter extends RTCSessionEncodedBuffersEmitter<
  RTCSessionEncodedBuffersEmitterListenerMap,
  RTCSessionEncodedBuffersEmitterOptions,
  RTCEmitterSessionData
> {}
class RTCStreamEmitter extends RTCStreamSession<RTCStreamSessionListenerMap, RTCStreamSessionOptions, RTCEmitterSessionData> {}

export interface RTCEmitterSessionMap {
  [RTCBridgeMediaSessionMode.MEDIASTREAM]: RTCStreamEmitter;
  [RTCBridgeMediaSessionMode.ENCODED_BUFFERS]: RTCEncodedBuffersEmitter;
}

export interface RTCBridgeMediaSessionEmitterEventMap {
  sdp: (mediaId: string, id: string, sdp: RTCSessionDescriptionInit | null) => void;
  candidate: (mediaId: string, id: string, candidate: RTCIceCandidateInit | null) => void;
  close: (mediaId: string, id: string) => void;
}

export interface RTCEmitterSessionWrapped<T extends RTCBridgeMediaSessionMode = any> {
  mode: T;
  session: RTCEmitterSessionMap[T];
}

export class RTCBridgeMediaSessionEmitter {
  protected eventListeners: EventListeners<keyof RTCBridgeMediaSessionEmitterEventMap, RTCBridgeMediaSessionEmitterEventMap>;
  readonly modes: RTCBridgeMediaSessionMode[];
  private wrappedSessions: RTCEmitterSessionWrapped[] = [];
  constructor(modes: RTCBridgeMediaSessionMode[]) {
    this.eventListeners = new EventListeners<keyof RTCBridgeMediaSessionReceiverEventMap, RTCBridgeMediaSessionReceiverEventMap>();
    this.modes = modes;
    Debug.addBridgeEmitter({ sessions: this.wrappedSessions });
  }

  private isSession<T extends RTCBridgeMediaSessionMode>(wrappedSession: RTCEmitterSessionWrapped, mode: T): wrappedSession is RTCEmitterSessionWrapped<T> {
    return wrappedSession?.mode === mode;
  }

  getMode(supportedModes: RTCBridgeMediaSessionMode[], transmissionMode?: RTCBridgeTransmissionMode): RTCBridgeMediaSessionMode | undefined {
    if (!supportedModes) return undefined;
    const currTransmissionMode = transmissionMode ?? RTCBridgeTransmissionMode.PREFER_WEBCODECS;
    const orderedModes: RTCBridgeMediaSessionMode[] = [];
    if (currTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBRTC) orderedModes.push(RTCBridgeMediaSessionMode.MEDIASTREAM);
    if (currTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBCODECS) orderedModes.push(RTCBridgeMediaSessionMode.ENCODED_BUFFERS);
    if (currTransmissionMode === RTCBridgeTransmissionMode.WEBRTC || currTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBCODECS)
      orderedModes.push(RTCBridgeMediaSessionMode.MEDIASTREAM);
    if (currTransmissionMode === RTCBridgeTransmissionMode.WEBCODECS || currTransmissionMode === RTCBridgeTransmissionMode.PREFER_WEBRTC)
      orderedModes.push(RTCBridgeMediaSessionMode.ENCODED_BUFFERS);
    for (let i = 0; i < orderedModes.length; i += 1) {
      const m = orderedModes[i];
      if (this.modes.includes(m) && supportedModes.includes(m)) {
        return m;
      }
    }
    return undefined;
  }

  clean() {
    this.wrappedSessions.forEach((s) => {
      s.session?.removeAllEventListeners();
      s.session?.destroy();
    });
  }

  destroy() {
    this.clean();
    this.eventListeners.removeAllEventListener();
  }

  updateSource(source?: WebRTCSession<any>) {
    // console.log('Source updated : ', source);
    this.wrappedSessions.forEach((s) => {
      if (this.isSession(s, RTCBridgeMediaSessionMode.ENCODED_BUFFERS)) {
        const session = s.session;
        session.cleanSource();
        if (source && Debug.getData()?.noCaptureEncodedBuffer !== true) session.useSource(source);
      }
    });
  }

  updateMediaStream(media?: MediaStream) {
    // console.log('Media updated : ', media);
    this.wrappedSessions.forEach((s) => {
      if (this.isSession(s, RTCBridgeMediaSessionMode.MEDIASTREAM)) {
        const session = s.session;
        if (session.data) {
          if (session.data.mediaStream) {
            const peerConnection = session.rtcPeerConnection;
            session.data.mediaStream = media;
            if (peerConnection) refreshMediaOnRTCPeerConnection(peerConnection, media);
          } else if (media) {
            session.data.mediaStream = media;
            session.addStream(media);
          }
        }
      }
    });
  }

  private getSession(mediaId: string, id: string): RTCEmitterSessionWrapped | undefined {
    return this.wrappedSessions.find((ws) => ws.session.data?.mediaId === mediaId && ws.session.data?.id === id);
  }

  async start(mediaId: string, id: string, mode: RTCBridgeMediaSessionMode, source?: WebRTCSession<any>): Promise<RTCEmitterSessionWrapped | undefined> {
    if (this.getSession(mediaId, id)) return undefined; // session already created
    if (!this.modes.includes(mode)) throw new Error(`Unsupported mode : ${mode}`);

    // console.log("Start : ", mediaId, id, mode, source);
    let result: RTCEmitterSessionWrapped | undefined;

    const errorListener = (error: any) => {
      // console.log('[RTC - EMITTER - Error] - ', id, ' : ', error);
      if (!result) return;
      result.session.destroy();
    };

    const closeListener = () => {
      // console.log('[RTC - EMITTER - Close] - ', id);
      if (!result) return;
      result.session.destroy();
      this.wrappedSessions = this.wrappedSessions.filter((ws) => ws !== result);
      this.eventListeners.triggerEvent("close", mediaId, id);
    };

    const sdpListener = (description: RTCSessionDescription | null) => {
      // console.log('[RTC - EMITTER - SDP] - ', id, sdp);
      const newDescription = description ? {
        sdp: description.sdp,
        type: description.type,
      } : null;
      if (newDescription) {
        const sdpFormatter = new SdpFormatter(newDescription.sdp);
        /*
          a=extmap:14 urn:ietf:params:rtp-hdrext:toffset
          a=extmap:2 http://www.webrtc.org/experiments/rtp-hdrext/abs-send-time
          a=extmap:13 urn:3gpp:video-orientation
          a=extmap:3 http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions-01
          a=extmap:5 http://www.webrtc.org/experiments/rtp-hdrext/playout-delay
          a=extmap:6 http://www.webrtc.org/experiments/rtp-hdrext/video-content-type
          a=extmap:7 http://www.webrtc.org/experiments/rtp-hdrext/video-timing
          a=extmap:8 http://www.webrtc.org/experiments/rtp-hdrext/color-space
        */
        sdpFormatter.execPipeline(`remove_lines_from_media["video", "http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions-01"]`);
        sdpFormatter.execPipeline(`set_preferred_format["video", "h264"]`);
        sdpFormatter.execPipeline(`set_codec_param["h264", "x-google-start-bitrate", 1000]|set_codec_param["h264", "x-google-min-bitrate", 1000]`);
        newDescription.sdp = sdpFormatter.sdp;
      }
      this.eventListeners.triggerEvent("sdp", mediaId, id, newDescription ?? null);
    };

    const candidateListener = (candidate: RTCIceCandidate | null) => {
      // console.log('[RTC - EMITTER - CANDIDATE] - ', id, candidate);
      this.eventListeners.triggerEvent("candidate", mediaId, id, candidate?.toJSON() ?? null);
    };

    if (mode === RTCBridgeMediaSessionMode.ENCODED_BUFFERS) {
      const session = new RTCEncodedBuffersEmitter();
      session.data = { mediaId, id, mode };
      session.addEventListener("error", errorListener);
      session.addEventListener("close", closeListener);
      session.addEventListener("sdp", sdpListener);
      session.addEventListener("iceCandidate", candidateListener);
      if (source && Debug.getData()?.noCaptureEncodedBuffer !== true) session.useSource(source);
      session.middlewareEncodedVideoFrame = (fr) => {
        const debugData = Debug.getData();
        if (debugData) debugData.emitterEncodedVideoLastFrame = fr.timestamp;
        if (debugData?.noEmitEncodedBuffer === true) return null;
        return fr;
      }
      session.middlewareEncodedAudioFrame = (fr) => {
        const debugData = Debug.getData();
        if (debugData) debugData.emitterEncodedAudioLastFrame = fr.timestamp;
        if (debugData?.noEmitEncodedBuffer === true) return null;
        return fr;
      }
      await session.start();
      result = {
        mode,
        session,
      };
      this.wrappedSessions.push(result);
    } else if (mode === RTCBridgeMediaSessionMode.MEDIASTREAM) {
      const session = new RTCStreamEmitter();
      session.data = { mediaId, id, mode };
      session.addEventListener("error", errorListener);
      session.addEventListener("close", closeListener);
      session.addEventListener("sdp", sdpListener);
      session.addEventListener("iceCandidate", candidateListener);
      await session.start();
      if (source?.outputStream) {
        session.data!.mediaStream = source.outputStream;
        // console.log('[OVENMEDIA - EMIT - ADD STREAM] #1 : ', mediaStreamRef.current, mediaStreamRef.current.getTracks())
        session.addStream(source.outputStream);
      }
      result = {
        mode,
        session,
      };
      this.wrappedSessions.push(result);
    }
    // console.log('NEW BRIDGE : ',mediaId, id, mode, source, this.wrappedSessions);
    return result;
  }

  processRemoteSdp(mediaId: string, id: string, sdp: any) {
    this.wrappedSessions.forEach((ws) => {
      const session = ws.session;
      if (session.data.mediaId === mediaId && session.data.id === id) {
        session.processRemoteSdp(sdp);
      }
    });
  }

  processRemoteIceCandidate(mediaId: string, id: string, candidate: any) {
    this.wrappedSessions.forEach((ws) => {
      const session = ws.session;
      if (session.data.mediaId === mediaId && session.data.id === id) {
        session.processRemoteIceCandidate(candidate);
      }
    });
  }

  close(mediaId: string, id: string) {
    // console.log('Close : ', mediaId, id);
    this.wrappedSessions.forEach((ws) => {
      const session = ws.session;
      if (session.mediaId === mediaId && session.id === id) {
        session.destroy();
      }
    });
  }

  // -------------------- LISTENERS -------------------- //

  addEventListener<K extends keyof RTCBridgeMediaSessionEmitterEventMap>(event: K, listener: RTCBridgeMediaSessionEmitterEventMap[K]) {
    this.eventListeners.addEventListener(event, listener);
  }

  removeEventListener<K extends keyof RTCBridgeMediaSessionEmitterEventMap>(event: K, listener: RTCBridgeMediaSessionEmitterEventMap[K]) {
    this.eventListeners.removeEventListener(event, listener);
  }
}

import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import "./KastPlayer.css";
import styles from "./KastPlayer.module.css";

import { Button, EventListeners, Loading, destroyMediaStream, useEventListenersRef } from "@kalyzee/kast-app-web-components";
import { Player } from "@kalyzee/kast-react-player-module";
import { KastWebRTCSession } from "../helpers/kastWebRTCSession";
import { logger } from "../helpers/logger";

const TIMEOUT_CONNECT = 5000;
const RETRY_TIMEOUT = 3000;
const RETRY_LIMIT = -1; // < 0 for infinite (ex: -1)

export interface KastPlayersProps {
  ip: string;
  port?: number;
  defaultScene?: number;
  defaultView?: number;
  display?: boolean;
  timeoutConnect?: number;
  retryTimeout?: number;
  retryLimit?: number; // < 0 for infinite (ex: -1)
  onMedia?: (media: MediaStream | undefined) => void;
  onMessage?: (action: string, params?: any) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface KastPlayersRef {
  player: 'kast';
  getMedia: () => MediaStream | undefined;
  getContext: () => any | undefined;
  getKastWebRTCSession: () => KastWebRTCSession | undefined;
  getEventListener: () => EventListeners<keyof KastPlayerListenerMap, KastPlayerListenerMap> | undefined;
}
export interface KastPlayerListenerMap {
  kastWebRTCSession: (session: KastWebRTCSession | undefined) => void;
  media: (media: MediaStream | undefined) => void;
  context: (context: any | undefined) => void;
}

const KastPlayer = React.forwardRef(
  (
    { ip, port, defaultScene, defaultView, display, timeoutConnect, retryTimeout, retryLimit, onMedia, onMessage, className, style }: KastPlayersProps,
    forwardRef: ForwardedRef<KastPlayersRef | undefined>
  ) => {
    const listenersRef = useEventListenersRef<keyof KastPlayerListenerMap, KastPlayerListenerMap>();
    const kastWebRTCSessionRef = useRef<KastWebRTCSession | undefined>(undefined);
    const onMediaRef = useRef(onMedia);
    const onMessageRef = useRef(onMessage);
    const [retryCounter, setRetryCounter] = useState(0);
    const [error, setError] = useState<string | undefined>(undefined);
    const [previewMedia, setPreviewMedia] = useState<MediaStream | undefined>(undefined);
    const mediaRef = useRef<MediaStream>();
    const [context, setContext] = useState<any>(undefined);
    const currentSceneRef = useRef<number | undefined>(defaultScene);
    const firstConnexionRef = useRef(true);
    const connectedRef = useRef(false);
    const componentIsDestroyedRef = useRef(false);

    const classes: string[] = [styles.container];
    if (className) classes.push(className);

    useImperativeHandle(
      forwardRef,
      () => ({
        player: 'kast' as const,
        getMedia: () => mediaRef.current,
        getContext: () => context,
        getKastWebRTCSession: () => kastWebRTCSessionRef.current,
        getEventListener: () => listenersRef.current,
      }),
      [context, listenersRef]
    );

    useEffect(() => {
      return () => {
        componentIsDestroyedRef.current = true;
      };
    }, []);

    useEffect(() => {
      onMessageRef.current = onMessage;
    }, [onMessage]);

    useEffect(() => {
      if (retryCounter < 0) {
        setRetryCounter(0);
        return;
      }
      const currentTimeoutConnect = timeoutConnect ?? TIMEOUT_CONNECT;
      const currRetryLimit = retryLimit ?? RETRY_LIMIT;
      const currRetryTimeout = retryTimeout ?? RETRY_TIMEOUT;
      if (currRetryLimit >= 0 && retryCounter >= currRetryLimit) return;
      let session: KastWebRTCSession | undefined;
      let retryTimeoutNodeJS: NodeJS.Timeout | undefined;
      let timeoutNodeJS: NodeJS.Timeout | undefined;
      let finished = false;
      const finish = (success: boolean, err?: string) => {
        if (componentIsDestroyedRef.current) return;

        finished = true;
        // console.log('Finished : ', ip, success, err);
        setError(success ? undefined : err ?? "Unknown");
        if (timeoutNodeJS) clearTimeout(timeoutNodeJS);
        // console.log('Finished #1 : ', connectedRef.current, retryCounter, success);
        if (!success) {
          setPreviewMedia(undefined);
          const newRetryCounter = connectedRef.current ? 0 : retryCounter + 1;
          if (currRetryLimit < 0 || newRetryCounter < currRetryLimit) {
            retryTimeoutNodeJS = setTimeout(() => {
              if (retryCounter === newRetryCounter) setRetryCounter(-1);
              else setRetryCounter(newRetryCounter);
            }, currRetryTimeout);
          } else if (newRetryCounter === currRetryLimit) {
            setRetryCounter(newRetryCounter);
          }
          session?.destroy();
        }
        connectedRef.current = success;
      };
      timeoutNodeJS = setTimeout(() => {
        logger.log("Kast - timeout");
        if (finished) return;
        finish(false, "Impossible to connect...");
      }, currentTimeoutConnect);
      setPreviewMedia(undefined);
      try {
        // console.log('Connect to kast : ', ip, retryCounter);
        session = new KastWebRTCSession(`ws://${ip}:${port ?? 8000}`);
        session.addEventListener("message", (action: string, params: any) => {
          onMessageRef.current?.(action, params);
          if (action === "context/updated") {
            setContext(params);
          }
        });
        session.addEventListener("connect", () => {
          logger.log("KAST - CONNECT : ", ip);
          if (currentSceneRef.current !== undefined) session?.setScene(currentSceneRef.current);
          if (firstConnexionRef.current && defaultView !== undefined) session?.setView(defaultView);
          firstConnexionRef.current = false;
        });
        session.addEventListener("disconnect", () => {
          logger.log("KAST - DISCONNECT : ", ip);
          finish(false, "Disconnected");
        });
        const promiseMedia = session.getPromiseMedia();
        if (promiseMedia) {
          promiseMedia
            .then((m) => {
              if (finished) return;
              finish(true);
              setPreviewMedia(m);
            })
            .catch((reason) => {
              if (finished) return;
              logger.log("Kast - error : ", reason);
              finish(false, reason);
            });
          listenersRef.current?.triggerEvent("kastWebRTCSession", session);
          kastWebRTCSessionRef.current = session;
        } else {
          if (finished) return;
          logger.log("Kast - no media");
          finish(false, "No media");
          session.destroy();
        }
      } catch (err) {
        logger.log("Kast - error catched : ", err);
        if (finished) return;
        finish(false, (err as any)?.message);
        listenersRef.current?.triggerEvent("kastWebRTCSession", undefined);
        kastWebRTCSessionRef.current = undefined;
      }

      return () => {
        if (retryTimeoutNodeJS) clearTimeout(retryTimeoutNodeJS);
        if (timeoutNodeJS) clearTimeout(timeoutNodeJS);
        session?.removeAllEventListeners();
        session?.destroy();
      };
    }, [ip, port, defaultView, retryCounter, listenersRef, timeoutConnect, retryTimeout, retryLimit]);

    useEffect(() => {
      onMediaRef.current = onMedia;
    }, [onMedia]);

    useEffect(() => {
      return () => {
        if (previewMedia) destroyMediaStream(previewMedia);
      }
    }, [previewMedia]);

    useEffect(() => {
      if (mediaRef.current) destroyMediaStream(mediaRef.current);
      mediaRef.current = previewMedia?.clone();
      onMediaRef.current?.(mediaRef.current);
      listenersRef.current?.triggerEvent("media", mediaRef.current);
    }, [previewMedia, listenersRef]);

    useEffect(() => {
      listenersRef.current?.triggerEvent("context", context);
    }, [context, listenersRef]);

    const scene = context?.mixer?.scene_id ?? undefined;
    const renderPlayer = () => (
      <Player
        autoPlay
        play
        mute
        enableCameraControls
        media={previewMedia}
        className={`${styles.kastPlayer} kastplayer`}
        enableZoomKeyboardEvent={false}
        enableMoveKeyboardEvent={false}
        enableSceneKeyboardEvent={false}
        enableAssignViewKeyboardEvent={false}
        enableViewKeyboardEvent={false}
        alwaysDisplayCameraControls
        alwaysDisplayVideoControls
        scene={scene}
        onMove={(direction) => kastWebRTCSessionRef.current?.move(direction)}
        onStopMove={() => kastWebRTCSessionRef.current?.stopMove()}
        onZoom={(direction) => kastWebRTCSessionRef.current?.zoom(direction)}
        onStopZoom={() => kastWebRTCSessionRef.current?.stopZoom()}
        onScene={(scene) => kastWebRTCSessionRef.current?.setScene(scene)}
        onView={(view) => kastWebRTCSessionRef.current?.setView(view)}
        onAssignView={(view) => kastWebRTCSessionRef.current?.assignView(view)}
      />
    );

    const renderError = () => (
      <div className={styles.errorContainer}>
        <div className={styles.error}>{error}</div>
        <Button
          className={styles.retryButton}
          title={"Réessayer" /* TRANSLATION */}
          onPress={() => {
            setRetryCounter(0);
          }}
        />
      </div>
    );

    const renderContent = () => {
      if (error && RETRY_LIMIT >= 0 && retryCounter >= RETRY_LIMIT) return renderError();
      if (previewMedia) return renderPlayer();
      return <Loading message={"Connexion en cours..." /* TRANSLATION */} />;
    };

    if (!display) return null;

    return (
      <div className={classes.join(" ")} style={style}>
        {renderContent()}
      </div>
    );
  }
);

KastPlayer.defaultProps = {
  display: true,
  className: undefined,
  style: undefined,
};

export default KastPlayer;

import { useStateWithRef } from "@kalyzee/kast-app-web-components";
import { ReactElement, useEffect, useRef } from "react";
import { Debug } from "../helpers/debug";
import {
  MessagingBridgeManagerMaster,
  MessagingBridgeManagerMasterOptions,
  MessagingBridgeManagerSlave,
  MessagingBridgeManagerSlaveOptions,
} from "../helpers/messagingBridge";
import { MessagingBridgeContext } from "./messageBridgeManager";

// ----------------------------------------- //
// ------------  MASTER --------------------- //
// ----------------------------------------- //

export interface MessagingBridgeManagerMasterContextProviderProps {
  options?: MessagingBridgeManagerMasterOptions;
  children?: ReactElement;
  onMessagingBridgeManager?: (manager: MessagingBridgeManagerMaster) => void;
}

export const MessagingBridgeManagerMasterContextProvider = ({
  options,
  children,
  onMessagingBridgeManager,
}: MessagingBridgeManagerMasterContextProviderProps) => {
  const [manager, setManager, managerRef] = useStateWithRef<MessagingBridgeManagerMaster>();
  const onBridgeRef = useRef(onMessagingBridgeManager);

  useEffect(() => {
    onBridgeRef.current = onMessagingBridgeManager;
  }, [onMessagingBridgeManager]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const manager = new MessagingBridgeManagerMaster(options, managerRef.current?.getClients());
    if (Debug.isEnabled()) {
      const debugData = Debug.getData();
      if (debugData) debugData.messagingBridgeManager = manager;
    }
    onBridgeRef.current?.(manager);
    setManager(manager);
    return () => {
      manager.destroy();
    };
  }, [options]);

  return <MessagingBridgeContext.Provider value={manager}>{children}</MessagingBridgeContext.Provider>;
};

// ----------------------------------------- //
// ------------  SLAVE --------------------- //
// ----------------------------------------- //

export interface MessagingBridgeManagerSlaveContextProviderProps {
  options?: MessagingBridgeManagerSlaveOptions;
  children?: ReactElement;
  onMessagingBridgeManager?: (manager: MessagingBridgeManagerSlave) => void;
}

export const MessagingBridgeManagerSlaveContextProvider = ({
  options,
  children,
  onMessagingBridgeManager,
}: MessagingBridgeManagerSlaveContextProviderProps) => {
  const [manager, setManager, managerRef] = useStateWithRef<MessagingBridgeManagerSlave>();
  const onBridgeRef = useRef(onMessagingBridgeManager);

  useEffect(() => {
    onBridgeRef.current = onMessagingBridgeManager;
  }, [onMessagingBridgeManager]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const manager = new MessagingBridgeManagerSlave(options);
    if (Debug.isEnabled()) {
      const debugData = Debug.getData();
      if (debugData) debugData.messagingBridgeManager = manager;
    }
    onBridgeRef.current?.(manager);
    setManager(manager);
    return () => {
      manager.destroy();
    };
  }, [options]);

  return <MessagingBridgeContext.Provider value={manager}>{children}</MessagingBridgeContext.Provider>;
};

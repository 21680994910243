import React, { ForwardedRef, SVGProps, useImperativeHandle, useRef, useState } from "react";

import { MeetingSessionMediaStats } from "@kalyzee/kast-websocket-module";
import { ReactComponent as IconNetworkLevelEmpty } from "../assets/icons/icon-network-empty.svg";
import { ReactComponent as IconNetworkLevel0 } from "../assets/icons/icon-network-bad.svg";
import { ReactComponent as IconNetworkLevel3 } from "../assets/icons/icon-network-good.svg";
import { ReactComponent as IconNetworkLevel1 } from "../assets/icons/icon-network-low.svg";
import { ReactComponent as IconNetworkLevel2 } from "../assets/icons/icon-network-medium.svg";
import { ReactComponent as IconNetworkLevel4 } from "../assets/icons/icon-network-very-good.svg";
import { RTCStreamStats } from "../helpers/rtc";

import styles from "./NetworkLevel.module.css";
import { Colors, Hover, Overlay } from "@kalyzee/kast-app-web-components";

const OUTPUT_BITRATE_THRESHOLD_NETWORK_LEVEL = [
  20_000, // >= LOW & < BAD
  600_000, // >= MEDIUM
  1_000_000, // >= GOOD
  1_500_000, // >= VERY GOOD
] as const;

const INPUT_PERCENTAGE_PACKETS_LOST_THRESHOLD_NETWORK_LEVEL = [
  1/100., // >= LOW & < BAD
  0.5/100., // >= MEDIUM
  0.25/100., // >= GOOD
  0.05/100., // >= VERY GOOD
] as const;


export type NetworkLevelStats = (RTCStreamStats & { mediaId: string }) | MeetingSessionMediaStats;

export interface NetworkLevelProps extends SVGProps<any> {
  mode: 'input' | 'output';
  stats: NetworkLevelStats[];
  className?: string;
  style?: React.CSSProperties;
}

export interface NetworkLevelRef {
  updateStats: (stats: NetworkLevelStats[]) => void;
}

const NetworkLevel = React.forwardRef(({ mode, stats, className, style, ...svgProps }: NetworkLevelProps, forwardRef: ForwardedRef<NetworkLevelRef | undefined>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currStats, setCurrStats] = useState<NetworkLevelStats[]>(stats);
  useImperativeHandle(forwardRef, () => ({
    updateStats: (s: NetworkLevelStats[]) => setCurrStats(s),
  }));

  let networkLevel = -1;
  let allVideoDisabled: boolean = false;
  let count = 0;

  if (mode === 'input') {
    let avgPercentagePacketsLost: number = -1;
    const inputStats = currStats.filter((s) => s.mode === 'input');
    if (inputStats.length) allVideoDisabled = true;
    inputStats.forEach((curr) => {
      const currPercentagePacketsLost = curr?.videoPercentagePacketsLostSnap;
      if (currPercentagePacketsLost !== undefined) {
        avgPercentagePacketsLost = (count * avgPercentagePacketsLost + currPercentagePacketsLost) / (count + 1);
        count++;
      }
      if (curr.videoEnabled) allVideoDisabled = false;
    });
    if (avgPercentagePacketsLost >= 0) {
      networkLevel = 5
      for (let i = 0; i < INPUT_PERCENTAGE_PACKETS_LOST_THRESHOLD_NETWORK_LEVEL.length; i++) {
        if (avgPercentagePacketsLost >= INPUT_PERCENTAGE_PACKETS_LOST_THRESHOLD_NETWORK_LEVEL[i]) {
          networkLevel = i;
          break;
        }
      }
    }
  } else if (mode === 'output') {
    let avgTargetBitrate: number = -1;
    const outputStats = currStats.filter((s) => s.mode === 'output');
    if (outputStats.length) allVideoDisabled = true;
    outputStats.forEach((curr) => {
      if(curr.mode !== 'output') return;
      const currVideoTargetBitrate = curr?.videoTargetBitrate;
      if (currVideoTargetBitrate !== undefined) {
        avgTargetBitrate = (count * avgTargetBitrate + currVideoTargetBitrate) / (count + 1);
        count++;
      }
      if (curr.videoEnabled) allVideoDisabled = false;
    });
    if (avgTargetBitrate >= 0) {
      networkLevel = 5;
      for (let i = 0; i < OUTPUT_BITRATE_THRESHOLD_NETWORK_LEVEL.length; i++) {
        if (avgTargetBitrate < OUTPUT_BITRATE_THRESHOLD_NETWORK_LEVEL[i]) {
          networkLevel = i;
          break;
        }
      }
    }
  }
  

  const toBits = (value?: number): string => {
    if (value === undefined) return "-";
    if (value > 1000000) return `${(value / 1000000).toFixed(2)}Mbits`;
    if (value > 1000) return `${(value / 1000).toFixed(0)}kbits`;
    return `${value.toFixed(0)}bits`;
  };

  const renderStatsDetails = (stats: NetworkLevelStats) => {
    return (
      <>
        <div className={styles.detailsTrackStatsContainer}>
          <div className={styles.detailsTrackStatsTitle}>video</div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>activé :</div>
            <div className={styles.detailsValue} style={{ color: stats.videoEnabled ? Colors.getMainGreen() : Colors.getTorchRed() }}>
              {stats.videoEnabled ? "✓" : "✗"}
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>width :</div>
            <div className={styles.detailsValue}>{stats.videoFrameWidth ? stats.videoFrameWidth : "-"}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>height :</div>
            <div className={styles.detailsValue}>{stats.videoFrameHeight ? stats.videoFrameHeight : "-"}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>fps :</div>
            <div className={styles.detailsValue}>{stats.videoFramePerSecond ? stats.videoFramePerSecond : "-"}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>packets lost (snap) :</div>
            <div className={styles.detailsValue}>
              {stats.videoPercentagePacketsLostSnap !== undefined
                ? `${(stats.videoPercentagePacketsLostSnap * 100).toFixed(2)}%`
                : "-"}
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>packets lost :</div>
            <div className={styles.detailsValue}>
              {stats.videoPercentagePacketsLost !== undefined
                ? `${(stats.videoPercentagePacketsLost * 100).toFixed(2)}%`
                : "-"}
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>bitrate :</div>
            <div className={styles.detailsValue}>{toBits(stats.videoBitrate)}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>jitter :</div>
            <div className={styles.detailsValue}>{stats.videoJitter ? stats.videoJitter.toFixed(4) : "-"}</div>
          </div>
        </div>
        <div style={{ height: "5px" }}></div>
        <div className={styles.detailsTrackStatsContainer}>
          <div className={styles.detailsTrackStatsTitle}>audio</div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>activé :</div>
            <div className={styles.detailsValue} style={{ color: stats.audioEnabled ? Colors.getMainGreen() : Colors.getTorchRed() }}>
              {stats.audioEnabled ? "✓" : "✗"}
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>packets lost (snap) :</div>
            <div className={styles.detailsValue}>
              {stats.audioPercentagePacketsLostSnap !== undefined
                ? `${(stats.audioPercentagePacketsLostSnap * 100).toFixed(2)}%`
                : "-"}
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>packets lost :</div>
            <div className={styles.detailsValue}>
              {stats.audioPercentagePacketsLost !== undefined
                ? `${(stats.audioPercentagePacketsLost * 100).toFixed(2)}%`
                : "-"}
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>bitrate :</div>
            <div className={styles.detailsValue}>{toBits(stats.audioBitrate)}</div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsTitle}>jitter :</div>
            <div className={styles.detailsValue}>{stats.audioJitter ? stats.audioJitter.toFixed(4) : "-"}</div>
          </div>
        </div>
      </>
    );
  };

  const renderDetails = () => {
    const inputStats = currStats.filter((s) => s.mode === "input");
    const outputStats = currStats.filter((s) => s.mode === "output");
    const renderStats = (stats: NetworkLevelStats[]) =>
      stats.map((s, i) => {
        return (
          <div className={styles.detailsStats} key={`${s.mediaId}_${s.mode}`}>
            {renderStatsDetails(s)}
            {i < stats.length - 1 ? <div className={styles.detailsStatsSeparator} /> : null}
          </div>
        );
      });
    return (
      <div className={styles.detailsContainer}>
        {inputStats.length ? (
          <div className={styles.detailsColumnContainer}>
            <div className={styles.detailsColumnTitle}>
              Réception <span style={{ color: Colors.getMainGreen() }}>▼</span>
            </div>
            {renderStats(inputStats)}
          </div>
        ) : null}
        {outputStats.length ? (
          <div className={styles.detailsColumnContainer}>
            <div className={styles.detailsColumnTitle}>
              Émission <span style={{ color: Colors.getTorchRed() }}>▲</span>
            </div>
            {renderStats(outputStats)}
          </div>
        ) : null}
        {!inputStats.length && !outputStats.length ? (
          'Aucune donnée reçue'
        ) : null}
      </div>
    );
  };
  const renderIcon = () => {
    if (allVideoDisabled) return <IconNetworkLevel4 {...svgProps} />;
    if (networkLevel < 0) return <IconNetworkLevelEmpty {...svgProps} />;
    if (networkLevel === 0) return <IconNetworkLevel0 {...svgProps} />;
    if (networkLevel === 1) return <IconNetworkLevel1 {...svgProps} />;
    if (networkLevel === 2) return <IconNetworkLevel2 {...svgProps} />;
    if (networkLevel === 3) return <IconNetworkLevel3 {...svgProps} />;
    return <IconNetworkLevel4 {...svgProps} />;
  };
  return (
    <div ref={containerRef}>
      {renderIcon()}
      <Overlay>
        <Hover targetRef={containerRef}>{renderDetails()}</Hover>
      </Overlay>
    </div>
  );
});

NetworkLevel.defaultProps = {
  className: undefined,
  style: undefined,
};

export default NetworkLevel;

import { Loading, PopupButtonType, PopupIconType, showPopup, Touchable } from "@kalyzee/kast-app-web-components";
import { CommunicationAdapterState, CommunicationAdapterStatus } from "@kalyzee/whiteboard-react";
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ReactComponent as IconClose } from "../assets/icons/close.svg";
import styles from "./WhiteboardOverlay.module.css";

export interface WhiteboardOverlayProps {
  loading: boolean;
  state: CommunicationAdapterState;
  error?: string;
  forceDisplay?: boolean;
  id?: string;
  name?: string;
  favorites?: { id: string; name?: string }[];
  onRemoveFavorite?: (id: string, name?: string) => void;
  onValidate?: (id: string, name?: string) => void;
  onRetry?: () => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface WhiteboardOverlayRef {}

const WhiteboardOverlay = React.forwardRef(
  (
    { loading, state, error, forceDisplay, id, name, favorites, onRemoveFavorite, onValidate, onRetry, className, style }: WhiteboardOverlayProps,
    forwardRef: ForwardedRef<WhiteboardOverlayRef | undefined>
  ) => {
    const idInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const [enabledButton, setEnabledButton] = useState(false);
    const [updatedFromLastError, setUpdatedFromLastError] = useState(true);
    const previousState = useRef<CommunicationAdapterState>();

    useImperativeHandle(forwardRef, () => ({}), []);

    // ---------------------------------------------------------------- //
    // ---------------------------- EFFECT ----------------------------- //
    // ---------------------------------------------------------------- //

    useEffect(() => {
      if (!idInputRef.current || !id) return;
      idInputRef.current.value = id;
      setEnabledButton(idInputRef.current.value?.trim().length ? true : false);
    }, [id, forceDisplay, state]);

    useEffect(() => {
      if (!nameInputRef.current || !name) return;
      nameInputRef.current.value = name;
    }, [name]);

    useEffect(() => {
      setUpdatedFromLastError(error ? false : true);
      if (idInputRef.current) setEnabledButton(idInputRef.current.value?.trim().length ? true : false);
    }, [error]);

    useEffect(() => {
      setEnabledButton(id?.trim().length ? true : false);
    }, []);

    useEffect(() => {
      return () => {
        previousState.current = state;
      }
    }, [state]);

    // ---------------------------------------------------------------- //
    // ---------------------------- RENDER ----------------------------- //
    // ---------------------------------------------------------------- //

    const classes = [styles.container];
    if (className) classes.push(className);
    const renderFavorites = () => {
      const renderFavorite = (id: string, name?: string) => {
        if (!id) return null;
        return (
          <div key={id} className={styles.favoriteContainer}>
            <Touchable
              className={styles.favoriteButton}
              onPressOut={() => {
                if (id) onValidate?.(id, name);
                if (idInputRef.current) idInputRef.current.value = id;
                if (nameInputRef.current) nameInputRef.current.value = name ?? "";
                setEnabledButton(idInputRef.current?.value.length ? true : false);
              }}
            >
              <div className={styles.favoriteButtonContainer}>
                <div className={styles.favoriteId}>{`${id}`}</div>
                {name ? <span className={styles.favoriteName}>{`name: ${name}`}</span> : null}
              </div>
            </Touchable>
            <Touchable
              className={styles.favoriteRemoveButton}
              onPressOut={() => {
                showPopup({
                  title: "Retirer de vos favoris",
                  iconTitle: PopupIconType.INFO,
                  content: `Êtes-vous sûr de vouloir retirer ce tableau blanc de vos favoris?`,
                  buttons: [
                    { type: PopupButtonType.CANCEL, element: "Non" },
                    {
                      type: PopupButtonType.VALIDATE,
                      element: "Oui",
                      onClick: () => {
                        onRemoveFavorite?.(id, name);
                        return true;
                      },
                    },
                  ],
                  enableBackdropDismiss: true,
                  enableCloseButton: true,
                });
              }}
            >
              <IconClose width={10} strokeWidth={3} />
            </Touchable>
          </div>
        );
      };
      return (
        <>
          <div className={styles.favoriteDescription}>Choisissez un tableau blanc parmis vos favoris:</div>
          <div className={styles.favoritesContainer}>{favorites?.map((d) => renderFavorite(d?.id, d?.name))}</div>
          <div className={styles.orContainer}>
            <div className={styles.orSeparator} />
            <div className={styles.orText}>OU</div>
            <div className={styles.orSeparator} />
          </div>
        </>
      );
    };

    const renderContent = () => {
      if (loading) {
        return (
          <div className={styles.content}>
            <div className={styles.title}>Chargement en cours</div>
            <Loading style={{ width: '100%' }}/>
          </div>
        );
      }
      if (state.isLoading) {
        return (
          <div className={styles.content}>
            <div className={styles.title}>Chargement du tableau blanc</div>
            <Loading style={{ width: '100%' }}/>
          </div>
        );
      }
      if (state.status === CommunicationAdapterStatus.Connecting) {
        if (previousState.current?.status === CommunicationAdapterStatus.Joined) {
          return (
            <div className={styles.content}>
              <div className={styles.title}>Reconnexion en cours</div>
              <Loading style={{ width: '100%' }}/>
            </div>
          );
        }
        return (
          <div className={styles.content}>
            <div className={styles.title}>Connexion en cours</div>
            <Loading style={{ width: '100%' }}/>
          </div>
        );
      }
      if (state.status === CommunicationAdapterStatus.ConnectionError) {
        return (
          <div className={styles.content}>
            <div className={styles.title}>Une erreur est apparue durant la connexion</div>
              <Touchable
                className={`${styles.button}${enabledButton ? "" : ` ${styles.disabled}`}`}
                disabled={!enabledButton}
                onPress={() => {
                  onRetry?.();
                }}
              >
              Réessayer
            </Touchable>
          </div>
        );
      }
      return (
        <>
          <div className={styles.content}>
            <div className={styles.title}>Rejoindre un tableau blanc</div>
            {favorites?.length ? renderFavorites() : null}
            <div className={styles.inputContainer}>
              <div className={styles.inputColumn}>
                <div className={styles.description}>Identifiant du tableau:</div>
                <input
                  className={styles.input}
                  ref={idInputRef}
                  type="text"
                  defaultValue={id}
                  onChange={(e) => {
                    setUpdatedFromLastError(true);
                    const id = e.target?.value?.trim();
                    setEnabledButton(id.length ? true : false);
                  }}
                />
              </div>
              <div className={styles.inputColumn}>
                <div className={styles.description}>Votre nom:</div>
                <input className={styles.input} ref={nameInputRef} type="text" defaultValue={name} onChange={(e) => {
                  setUpdatedFromLastError(true);
                }} />
              </div>
            </div>
            {error ? <div className={styles.error}>{error}</div> : null}
          </div>

          <Touchable
            className={`${styles.button}${enabledButton ? "" : ` ${styles.disabled}`}`}
            disabled={!enabledButton}
            onPress={() => {
              const id = idInputRef.current?.value?.trim();
              const name = nameInputRef.current?.value?.trim();
              if (id) onValidate?.(id, name);
            }}
          >
            {updatedFromLastError ? "Rejoindre" : "Réessayer"}
          </Touchable>
        </>
      );
    };

    if (state.status === CommunicationAdapterStatus.Joined && !forceDisplay) return null; 
    return (
      <div className={styles.support}>
        <div className={styles.container}>{renderContent()}</div>
      </div>
    );
  }
);

WhiteboardOverlay.defaultProps = {
  className: undefined,
  style: undefined,
};

export default WhiteboardOverlay;

import { useEffect } from "react";
import { useStore } from "react-redux";
import { ActionState } from "../store/action";
import { StoreState } from "../store/interface";
import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from "@reduxjs/toolkit";

/**
 * Subscribes to redux store events
 *
 * @param effect
 * @param type
 * @param deps
 */
 export function useReduxAction<P = any>(effect : (action: ActionState<P>) => void, actionType: string | ActionCreatorWithPayload<any> | ActionCreatorWithoutPayload, deps: any[] = []) {
  const store = useStore<StoreState>();

  const handleChange = () => {
    const state = store.getState();
    const action = state.action;
    let type: string;
    if (typeof actionType === 'string') type = actionType;
    else type = actionType.type;
    if (action.type === type) {
      effect(action);
    }
  };

  useEffect(() => {
    const unsubscribe = store.subscribe(handleChange);
    return () => unsubscribe();
  }, deps);
}
import { EventListenerMap, EventListeners } from "@kalyzee/kast-app-web-components";
import { Meeting, MeetingSession, MeetingSessionReaction } from "@kalyzee/kast-websocket-module";
import { io, ManagerOptions, Socket, SocketOptions } from "socket.io-client";
import { PageMap, PageMasterMode, PageSlaveMode } from "../components/navigation/PageContext";
import { DisplayOnWhiteboardOptions } from "../interfaces/whiteboard";
import { MeetingExtraData } from "../pages/meeting/meeting";
import { SessionMode } from "../store/session/slices";
import { logger } from "./logger";
import { AppBroadcastChannel, AppMessage, appMessage, AppMessageEventMap } from "./message";
import { RTCStreamStats } from "./rtc";
import { RTCBridgeMediaSessionMode } from "./rtcBridgeMediaSession";
import { toastError } from "./toast";
import { generateRandomId } from "./utils";


const MASTER_PING_INTERVAL = 2000;
const SLAVE_PING_TIMEOUT = 5000;
const SLAVE_WEBSOCKET_RECONNECTION_DELAY = 500;
const LOG = false;

export enum MessagingBridgeMessageAction {
  WELCOME = "welcome", // ONLY FOR BROADCAST CHANNEL
  AUTHORIZED = "authorized",
  UNAUTHORIZED = "unauthorized",
  PING = "ping",
  PONG = "pong",
  JOIN_MEETING = "join",
  LEAVE_MEETING = "leave",
  GET_MEETING_ID = "get_meeting_id",
  SET_MEETING_ID = "set_meeting_id",
  GET_MEETING = "get_meeting",
  SET_MEETING = "set_meeting",
  SET_MEETING_EXTRA_DATA = "set_meeting_extra_data",
  SET_MEETING_STATS = "set_meeting_stats",
  SET_CLIENT_DATA = "set_client_data",
  CLEAR_CLIENT_DATA = 'clear_client_data',
  MEETING_CONTROL = "meeting_control",
  MEETING_CONTROL_RESPONSE = "meeting_control_response",
  WEBRTC_PREPARE = "webrtc_prepare",
  WEBRTC_READY = "webrtc_ready",
  WEBRTC_START = "webrtc_start",
  WEBRTC_STOP = "webrtc_stop",
  WEBRTC_SDP = "webrtc_sdp",
  WEBRTC_ICE_CANDIDATE = "webrtc_ice_candidate",
  WHITEBOARD_MESSAGE = 'whiteboard_message',
}

export enum MeetingControlAction {
  AUDIO = "audio",
  MEDIA = "media",
  REACTIONS = "reactions",
  EXCLUDE = "exclude",
  FULLSCREEN = "fullscreen",
  SET_SOURCE = "set_source",
  DISPLAY_WHITEBOARD = 'display_whiteboard',
}

export interface MeetingControlDataMap {
  [MeetingControlAction.AUDIO]: { sessionId: string; mediaId: string; muted: boolean; volume: number };
  [MeetingControlAction.MEDIA]: { sessionId: string; mediaId: string; audio?: boolean; video?: boolean };
  [MeetingControlAction.REACTIONS]: { sessionId: string; mediaId: string; reactions: MeetingSessionReaction[] };
  [MeetingControlAction.EXCLUDE]: { sessionId: string };
  [MeetingControlAction.FULLSCREEN]: { sessionId: string; mediaId?: string };
  [MeetingControlAction.SET_SOURCE]: { id: number };
  [MeetingControlAction.DISPLAY_WHITEBOARD]: { sessionId: string; mediaId: string, options?: Partial<DisplayOnWhiteboardOptions> };
}

export interface MeetingControlContent<T extends MeetingControlAction> {
  action: T;
  data: MeetingControlDataMap[T];
}

export const isMeetingControl = <T extends MeetingControlAction>(content: MeetingControlContent<any>, action: T): content is MeetingControlContent<T> => {
  if (!content) return false;
  return content.action === action;
};

export enum WhiteboardMessageAction {
  INSERT_IMAGE = "insert-image"
}

export interface WhiteboardMessageDataMap {
  [WhiteboardMessageAction.INSERT_IMAGE]: { dataURL: string, insertOnCanvasDirectly: true };
}

export interface WhiteboardMessageContent<T extends WhiteboardMessageAction> {
  action: T;
  data: WhiteboardMessageDataMap[T];
}


export const isWhiteboardMessage = <T extends WhiteboardMessageAction>(msg: WhiteboardMessageContent<any>, action: T): msg is WhiteboardMessageContent<T> => {
  return msg?.action === action;
};

export interface MessagingBridgeClientDataMap {
  [SessionMode.MASTER]: {

  },
  [SessionMode.SLAVE]: {
    [PageSlaveMode.SPECTATOR]: { 
      displayingMedias: string[];  // mediaId
    },
    [PageSlaveMode.WHITEBOARD]: {
      enablePlayerAsBackground?: boolean;
    },
  }
  
}

export interface MessagingBridgeClientData<M extends SessionMode, T extends PageMap[M]> {
  mode: M;
  page: T;
  data: T extends keyof MessagingBridgeClientDataMap[M] ?MessagingBridgeClientDataMap[M][T] : undefined,
}

export const isMessagingBridgeClientData = <M extends SessionMode, T extends PageMap[M]>(content?: MessagingBridgeClientData<any, any>, mode?: M | undefined, page?: T | undefined): content is MessagingBridgeClientData<M, T> => {
  if (!content || !page) return false;
  return content.mode === mode && content.page === page;
};


export interface MessagingBridgeMessageContentMap {
  [MessagingBridgeMessageAction.WELCOME]: { masterId: string };
  [MessagingBridgeMessageAction.AUTHORIZED]: undefined;
  [MessagingBridgeMessageAction.UNAUTHORIZED]: { code: number };
  [MessagingBridgeMessageAction.PING]: { counter: number };
  [MessagingBridgeMessageAction.PONG]: { counter: number };
  [MessagingBridgeMessageAction.JOIN_MEETING]: { meetingId: string };
  [MessagingBridgeMessageAction.LEAVE_MEETING]: undefined;
  [MessagingBridgeMessageAction.GET_MEETING_ID]: undefined;
  [MessagingBridgeMessageAction.SET_MEETING_ID]: { meetingId: string };
  [MessagingBridgeMessageAction.GET_MEETING]: undefined;
  [MessagingBridgeMessageAction.SET_MEETING]: { meeting?: Meeting; session?: MeetingSession<false> };
  [MessagingBridgeMessageAction.SET_MEETING_EXTRA_DATA]: { data: MeetingExtraData };
  [MessagingBridgeMessageAction.SET_MEETING_STATS]: { stats?: { [mediaId: string]: RTCStreamStats } };
  [MessagingBridgeMessageAction.SET_CLIENT_DATA]: MessagingBridgeClientData<any,any>
  [MessagingBridgeMessageAction.CLEAR_CLIENT_DATA]: {mode: SessionMode, page: PageMap[SessionMode] }
  [MessagingBridgeMessageAction.MEETING_CONTROL]: MeetingControlContent<any>;
  [MessagingBridgeMessageAction.MEETING_CONTROL_RESPONSE]: { id: string; success: boolean };
  [MessagingBridgeMessageAction.WEBRTC_PREPARE]: { mediaId: string; rtcSessionId: string; supportedModes: RTCBridgeMediaSessionMode[] };
  [MessagingBridgeMessageAction.WEBRTC_READY]: { mediaId: string; rtcSessionId: string; mode: RTCBridgeMediaSessionMode };
  [MessagingBridgeMessageAction.WEBRTC_START]: { mediaId: string; rtcSessionId: string; mode: RTCBridgeMediaSessionMode };
  [MessagingBridgeMessageAction.WEBRTC_STOP]: { mediaId: string; rtcSessionId: string };
  [MessagingBridgeMessageAction.WEBRTC_SDP]: { mediaId: string; rtcSessionId: string; sdp: any };
  [MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE]: { mediaId: string; rtcSessionId: string; iceCandidate: any };
  [MessagingBridgeMessageAction.WHITEBOARD_MESSAGE]: WhiteboardMessageContent<any>;
}

export interface MessagingBridgeMessage<T extends MessagingBridgeMessageAction> {
  id: string;
  action: T;
  content: MessagingBridgeMessageContentMap[T];
}

export interface MessagingBridgeMessageExtended<T extends MessagingBridgeMessageAction> extends MessagingBridgeMessage<T> {
  fromId: string;
  from: SessionMode;
}

export interface MessagingBridgeBroadcastChannelMessage<T extends MessagingBridgeMessageAction> extends MessagingBridgeMessageExtended<T> {
  toId?: string;
}

const isMessageExtented = <T extends MessagingBridgeMessageAction>(
  msg: MessagingBridgeMessageExtended<any>,
  action: T
): msg is MessagingBridgeMessageExtended<T> => {
  if (!msg) return false;
  return msg.action === action;
};

export interface MessagingBridgeManagerOptions {
  enableBroadcast?: boolean;
  password?: string;
}

const defaultBridgeMessageManagerOptions = {
  enableBroadcast: false,
} as const;

export abstract class MessagingBridgeManager<T extends SessionMode, K extends string, M extends EventListenerMap<K>> {
  protected events: EventListeners<K, M>;

  public logsEnabled: boolean = LOG;

  readonly id: string;
  readonly mode: SessionMode;
  readonly broadcastEnabled: boolean;

  readonly appMessage?: AppMessage;
  readonly appBroadcastChannel?: AppBroadcastChannel;

  protected appListener: [keyof AppMessageEventMap, AppMessageEventMap[keyof AppMessageEventMap]][] = [];

  protected currentMeetingId?: string;

  protected password?: string;

  constructor(mode: T, options?: MessagingBridgeManagerOptions) {
    this.events = new EventListeners();
    this.id = generateRandomId(16);
    this.mode = mode;
    this.broadcastEnabled = options?.enableBroadcast ?? defaultBridgeMessageManagerOptions.enableBroadcast;
    this.appMessage = appMessage;
    if (this.broadcastEnabled) {
      this.appBroadcastChannel = new AppBroadcastChannel();
    }
    this.password = options?.password;
    this.log("CREATE BRIDGE : ", mode);
  }

  static isMasterManager(manager?: MessagingBridgeManager<any, any, any>): manager is MessagingBridgeManagerMaster {
    return manager && manager.mode === SessionMode.MASTER ? true : false;
  }
  static isSlaveManager(manager?: MessagingBridgeManager<any, any, any>): manager is MessagingBridgeManagerSlave {
    return manager && manager.mode === SessionMode.SLAVE ? true : false;
  }

  protected buildBridgeMessage<T extends MessagingBridgeMessageAction>(action: T, content: MessagingBridgeMessageContentMap[T]): MessagingBridgeMessage<T> {
    return { id: generateRandomId(8), action, content };
  }

  public destroy() {
    this.appListener.forEach((listener) => {
      this.appMessage?.removeEventListener(...listener);
    });
    this.appBroadcastChannel?.close();
  }

  public log(...args: any[]) {
    if (!this.logsEnabled) return;
    const style = "color:green;font-weight:bold";
    const data = new Date();
    console.log(`%c[MESSAGING-BRIDGE -- ${this.mode.toUpperCase()} -- ${data.toLocaleTimeString()}:${data.getMilliseconds()}] `, style, ...args);
  }

  protected abstract post(msg: MessagingBridgeMessage<any>, toId?: string): void;
  protected abstract proccess(msg: MessagingBridgeMessage<any>, toId?: string): void;

  // ---------------------------- LISTENER -----------------------//
  addEventListener<T extends K>(event: T, listener: M[T]): [T, M[T]] {
    this.events.addEventListener(event, listener);
    return [event, listener];
  }

  removeEventListener<T extends K>(event: T, listener: M[T]) {
    this.events.removeEventListener(event, listener);
  }
}

// -------------------------------------------------- //
// ------------------  MASTER ----------------------- //
// -------------------------------------------------- //

export interface MessagingBridgeManagerMasterOptions extends MessagingBridgeManagerOptions {}

export interface MessagingBridgeManagerMasterEventMap {
  clientJoin: (client: MessagingBridgeManagerMasterClient<any, any>) => void;
  clientLeave: (client: MessagingBridgeManagerMasterClient<any, any>) => void;
  clientChange: (client: MessagingBridgeManagerMasterClient<any, any>) => void;
  clientData: (client: MessagingBridgeManagerMasterClient<any, any>) => void;
  requestMeetingId: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.GET_MEETING_ID>, respond: (meetingId: string) => void) => void;
  requestMeeting: (
    msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.GET_MEETING>,
    respond: (meeting?: Meeting, session?: MeetingSession<false>, extraData?: MeetingExtraData) => void
  ) => void;
  requestPrepareWebrtc: (
    msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_PREPARE>,
    respond: (ready: boolean, mode?: RTCBridgeMediaSessionMode) => void
  ) => void;
  meetingControl: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.MEETING_CONTROL>, respond: (success: boolean) => void) => void;
  webrtcIsReady: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_READY>, respond: (start: boolean) => void) => void;
  requestStartWebrtc: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_START>) => void;
  requestStopWebrtc: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_STOP>) => void;
  sdpWebrtc: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_SDP>) => void;
  iceCandidateWebrtc: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE>) => void;
  whiteboardMessage: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WHITEBOARD_MESSAGE>) => void;
}



export interface MessagingBridgeManagerMasterClient<M extends SessionMode, T extends PageMap[M]> {
  id: string;
  currPingCounter: number;
  active: boolean;
  authorized: boolean;
  data?: MessagingBridgeClientData<M, T>,
}

export class MessagingBridgeManagerMaster extends MessagingBridgeManager<
  SessionMode.MASTER,
  keyof MessagingBridgeManagerMasterEventMap,
  MessagingBridgeManagerMasterEventMap
> {
  static pingCounter: number = 0;

  protected clients: Map<string, MessagingBridgeManagerMasterClient<any, any>> = new Map();

  private pingInterval?: NodeJS.Timer;

  constructor(options?: MessagingBridgeManagerOptions, clients?: MessagingBridgeManagerMasterClient<any, any>[]) {
    super(SessionMode.MASTER, options);

    this.appListener = [
      [
        "ready",
        () => {
          // Give the id to all slave (if master disconnect and reconnect)
          this.post(this.buildBridgeMessage(MessagingBridgeMessageAction.WELCOME, { masterId: this.id }));
        },
      ],
      [
        "applicationJoin",
        (id: string, password?: string) => {
          this.log("APPLICATION JOIN : ", id, password);
          const noPassword = password?.trim() ? false : true;
          const authorized = !this.password || (!noPassword && password?.trim() === this.password?.trim());
          this.addClient({ id, authorized: authorized, currPingCounter: MessagingBridgeManagerMaster.pingCounter, active: true });
          if (authorized) {
            this.post(this.buildBridgeMessage(MessagingBridgeMessageAction.AUTHORIZED, undefined), id);
            this.post(this.buildBridgeMessage(MessagingBridgeMessageAction.WELCOME, { masterId: this.id }), id);
          } else {
            this.post(this.buildBridgeMessage(MessagingBridgeMessageAction.UNAUTHORIZED, { code: noPassword ? -1 : -2 }), id);
          }
        },
      ],
      [
        "applicationLeave",
        (id: string) => {
          this.removeClient(id);
        },
      ],
      [
        "applicationMessage",
        (id: string, msg: MessagingBridgeMessageExtended<any>) => {
          // if (id !== msg.fromId) return;
          this.proccess(msg);
        },
      ],
    ];
    this.appListener.forEach((listener) => this.appMessage?.addEventListener(...listener));

    this.appBroadcastChannel?.addEventListener("applicationJoin", (id: string) => {
      // always authorized because it's a broadcast channel
      this.addClient({ id, authorized: true, currPingCounter: MessagingBridgeManagerMaster.pingCounter, active: true });
      this.post(this.buildBridgeMessage(MessagingBridgeMessageAction.AUTHORIZED, undefined), id);
      this.post(this.buildBridgeMessage(MessagingBridgeMessageAction.WELCOME, { masterId: this.id }), id);
    });
    this.appBroadcastChannel?.addEventListener("applicationLeave", (id: string) => {
      this.removeClient(id);
    });
    this.appBroadcastChannel?.addEventListener("applicationMessage", (id: string, msg: MessagingBridgeBroadcastChannelMessage<any>) => {
      if (id !== msg.fromId) return;
      this.proccess(msg, msg.toId);
    });

    // Init Clients
    if (clients) {
      clients.forEach((client) => {
        const newClient = { ...client };
        newClient.currPingCounter = MessagingBridgeManagerMaster.pingCounter;
        this.addClient(newClient);
      });
    }

    // Give the id to all slave (if master disconnect and reconnect)
    this.post(this.buildBridgeMessage(MessagingBridgeMessageAction.WELCOME, { masterId: this.id }));

    // Init meeting
    this.postMeeting(undefined, undefined);
    this.postMeetingStats(undefined, undefined);

    if (MASTER_PING_INTERVAL > 0) {
      this.pingInterval = setInterval(() => {
        this.clients.forEach((data) => {
          if (MessagingBridgeManagerMaster.pingCounter >= data.currPingCounter + 2) {
            const lastActiveValue = data.active;
            data.active = false;
            if (lastActiveValue) {
              this.events.triggerEvent("clientChange", data);
            }
          }
        });
        this.postPing();
      }, MASTER_PING_INTERVAL);
    }
  }

  post<T extends MessagingBridgeMessageAction>(msg: MessagingBridgeMessage<T>, toId?: string): MessagingBridgeMessage<T> | undefined {
    this.log("POST message : ", msg, " toId : ", toId);
    const extendedMsg: MessagingBridgeMessageExtended<T> = {
      ...msg,
      fromId: this.id,
      from: this.mode,
    };
    if (this.appBroadcastChannel) {
      const broadcastMessage: MessagingBridgeBroadcastChannelMessage<T> = {
        ...extendedMsg,
        toId,
      };
      this.appBroadcastChannel.postApplicationMessage(this.id, broadcastMessage);
    }
    this.appMessage?.postApplicationMessage(extendedMsg, toId);

    return msg;
  }

  protected proccess(msg: MessagingBridgeMessageExtended<any>, toId?: string): void {
    this.log("RECEIVE message : ", msg, " toId : ", toId, this.id);
    if (toId && toId !== this.id) return;
    const clientId = msg.fromId;
    if (!clientId) return;
    const client = this.clients.get(clientId);
    this.log("RECEIVE message CLIENT : ", clientId, client, this.clients);
    if (!client) {
      this.appBroadcastChannel?.requestJoinApplication(clientId);
      return;
    } else if (!client.authorized) {
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.PONG)) {
      const lastActiveValue = client.active;
      client.active = true;
      client.currPingCounter = msg.content.counter;
      if (!lastActiveValue) {
        this.events.triggerEvent("clientChange", client);
      }
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.SET_CLIENT_DATA)) {
      const data: MessagingBridgeClientData<any, any> = {
        mode: msg.content.mode,
        page: msg.content.page,
        data: msg.content.data,
      };
      client.data = data;
      this.events.triggerEvent("clientData", client);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.CLEAR_CLIENT_DATA)) {
      if (client.data?.page !== msg.content.page) return;
      client.data = undefined;
      this.events.triggerEvent("clientData", client);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.GET_MEETING_ID)) {
      this.events.triggerEvent("requestMeetingId", msg, (meetingId: string) => {
        this.postMeetingId(meetingId, msg.fromId);
      });
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.GET_MEETING)) {
      this.events.triggerEvent("requestMeeting", msg, (meeting?: Meeting, session?: MeetingSession<false>, extraData?: MeetingExtraData) => {
        this.postMeeting(meeting, session, msg.fromId);
        if (extraData) this.postMeetingExtraData(extraData, msg.fromId);
      });
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.MEETING_CONTROL)) {
      this.events.triggerEvent("meetingControl", msg, (success: boolean) => {
        this.postMeetingControlResponse(success, msg, msg.fromId);
      });
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_PREPARE)) {
      this.events.triggerEvent("requestPrepareWebrtc", msg, (ready: boolean, mode?: RTCBridgeMediaSessionMode) => {
        if (ready) {
          if (!mode) throw new Error('"mode" is needed');
          this.postWebrtcIsReady(msg.content.mediaId, msg.content.rtcSessionId, mode, msg.fromId);
        } else this.postRequestStopWebrtc(msg.content.mediaId, msg.content.rtcSessionId, msg.fromId);
      });
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_READY)) {
      this.events.triggerEvent("webrtcIsReady", msg, (start: boolean) => {
        if (start) this.postRequestStartWebrtc(msg.content.mediaId, msg.content.rtcSessionId, msg.content.mode);
        else this.postRequestStopWebrtc(msg.content.mediaId, msg.content.rtcSessionId);
      });
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_START)) {
      this.events.triggerEvent("requestStartWebrtc", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_STOP)) {
      this.events.triggerEvent("requestStopWebrtc", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_SDP)) {
      this.events.triggerEvent("sdpWebrtc", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE)) {
      this.events.triggerEvent("iceCandidateWebrtc", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WHITEBOARD_MESSAGE)) {
      this.events.triggerEvent('whiteboardMessage', msg);
      return;
    }
  }

  public destroy() {
    if (this.pingInterval) clearInterval(this.pingInterval);
    super.destroy();
  }

  // --------------------------- CLIENT --------------------- //

  private addClient<M extends SessionMode, T extends PageMap[M]>(data: MessagingBridgeManagerMasterClient<M, T>) {
    this.clients.set(data.id, data);
    this.events.triggerEvent("clientJoin", data);
  }

  private removeClient(clientId: string) {
    const data = this.clients.get(clientId);
    this.clients.delete(clientId);
    if (!data) return;
    this.events.triggerEvent("clientLeave", data);
  }

  public getClients(onlyAuthorized: boolean = true): MessagingBridgeManagerMasterClient<any, any>[] {
    const clients = Array.from(this.clients.values());
    if (!onlyAuthorized) return clients.filter((c) => c.authorized);
    return clients;
  }

  public getActiveClients(onlyAuthorized: boolean = true): MessagingBridgeManagerMasterClient<any, any>[] {
    return this.getClients(onlyAuthorized).filter((c) => c.active);
  }

  // --------------------------- POST --------------------- //

  private postPing() {
    MessagingBridgeManagerMaster.pingCounter += 1;
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.PING, {
      counter: MessagingBridgeManagerMaster.pingCounter,
    });
    return this.post(msg);
  }

  postJoinMeeting(meetingId: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.JOIN_MEETING, {
      meetingId,
    });
    return this.post(msg);
  }

  postLeaveMeeting() {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.LEAVE_MEETING, undefined);
    return this.post(msg);
  }

  postMeetingId(meetingId: string, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.SET_MEETING_ID, {
      meetingId,
    });
    return this.post(msg, toId);
  }

  postMeeting(meeting?: Meeting, session?: MeetingSession<false>, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.SET_MEETING, {
      meeting,
      session,
    });
    return this.post(msg, toId);
  }

  postMeetingExtraData(data: MeetingExtraData, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.SET_MEETING_EXTRA_DATA, {
      data,
    });
    return this.post(msg, toId);
  }

  postMeetingStats(stats?: { [mediaId: string]: RTCStreamStats }, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.SET_MEETING_STATS, {
      stats,
    });
    return this.post(msg, toId);
  }

  postMeetingControlResponse(success: boolean, message: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.MEETING_CONTROL>, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.MEETING_CONTROL_RESPONSE, {
      id: message.id,
      success,
    });
    return this.post(msg, toId);
  }

  postRequestPrepareWebrtc(mediaId: string, rtcSessionId: string, supportedModes: RTCBridgeMediaSessionMode[], toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_PREPARE, {
      mediaId,
      rtcSessionId,
      supportedModes,
    });
    return this.post(msg, toId);
  }

  postRequestStartWebrtc(mediaId: string, rtcSessionId: string, mode: RTCBridgeMediaSessionMode, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_START, {
      mediaId,
      rtcSessionId,
      mode,
    });
    return this.post(msg, toId);
  }

  postWebrtcIsReady(mediaId: string, rtcSessionId: string, mode: RTCBridgeMediaSessionMode, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_READY, {
      mediaId,
      rtcSessionId,
      mode,
    });
    return this.post(msg, toId);
  }

  postRequestStopWebrtc(mediaId: string, rtcSessionId: string, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_STOP, {
      mediaId,
      rtcSessionId,
    });
    return this.post(msg, toId);
  }

  postSdpWebrtc(mediaId: string, rtcSessionId: string, sdp: any, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_SDP, {
      mediaId,
      rtcSessionId,
      sdp,
    });
    return this.post(msg, toId);
  }

  postIceCandidateWebrtc(mediaId: string, rtcSessionId: string, iceCandidate: any, toId?: string) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE, {
      mediaId,
      rtcSessionId,
      iceCandidate,
    });
    return this.post(msg, toId);
  }
}

// -------------------------------------------------- //
// ------------------  SLAVE ----------------------- //
// -------------------------------------------------- //

export interface MessagingBridgeManagerSlaveOptions extends MessagingBridgeManagerOptions {
  websocketEndpoint?: string;
  enableWebsocket?: boolean;
}

const defaultBridgeSlaveMessageManagerOptions = {
  enableWebsocket: false,
  websocketEndpoint: undefined,
} as const;

export interface MessagingBridgeManagerSlaveEventMap {
  ready: () => void;
  unauthorized: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.UNAUTHORIZED>) => void;
  joinMeeting: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.JOIN_MEETING>) => void;
  leaveMeeting: (msg?: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.LEAVE_MEETING>) => void;
  meetingId: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.SET_MEETING_ID>) => void;
  meeting: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.SET_MEETING>) => void;
  meetingExtraData: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.SET_MEETING_EXTRA_DATA>) => void;
  requestPrepareWebrtc: (
    msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_PREPARE>,
    respond: (ready: boolean, mode?: RTCBridgeMediaSessionMode) => void
  ) => void;
  meetingControlResponse: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.MEETING_CONTROL_RESPONSE>) => void;
  webrtcIsReady: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_READY>, respond: (start: boolean) => void) => void;
  requestStartWebrtc: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_START>) => void;
  requestStopWebrtc: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_STOP>) => void;
  sdpWebrtc: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_SDP>) => void;
  iceCandidateWebrtc: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE>) => void;
  whiteboardMessage: (msg: MessagingBridgeMessageExtended<MessagingBridgeMessageAction.WHITEBOARD_MESSAGE>) => void;
}

export class MessagingBridgeManagerSlave extends MessagingBridgeManager<
  SessionMode.SLAVE,
  keyof MessagingBridgeManagerSlaveEventMap,
  MessagingBridgeManagerSlaveEventMap
> {
  readonly websocketEnabled: boolean;
  readonly websocketEnpoint?: string;

  protected masterId?: string;
  private socket?: Socket;

  private pingTimeout?: NodeJS.Timer;
  private reconnectTimeout?: NodeJS.Timeout;

  constructor(options?: MessagingBridgeManagerSlaveOptions) {
    super(SessionMode.SLAVE, options);
    this.websocketEnabled = options?.enableWebsocket ?? defaultBridgeSlaveMessageManagerOptions.enableWebsocket;
    this.websocketEnpoint = options?.websocketEndpoint ?? defaultBridgeSlaveMessageManagerOptions.websocketEndpoint;
    if (this.websocketEnabled) {
      this.connectWebsocket();
    }

    this.appListener = [
      [
        "applicationMessage",
        (id: string, msg: MessagingBridgeMessageExtended<any>) => {
          // if (id !== msg.fromId) return;
          this.proccess(msg);
        },
      ],
    ];
    this.appListener.forEach((listener) => this.appMessage?.addEventListener(...listener));

    this.appBroadcastChannel?.addEventListener("applicationMessage", (id: string, msg: MessagingBridgeBroadcastChannelMessage<any>) => {
      if (id !== msg.fromId) return;
      this.proccess(msg, msg.toId);
    });
    this.appBroadcastChannel?.addEventListener("applicationRequestJoin", (toId: string) => {
      if (toId === this.id) {
        this.appBroadcastChannel?.joinApplication(this.id);
      }
    });
    this.appBroadcastChannel?.joinApplication(this.id);
  }

  private connectWebsocket() : boolean{
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = undefined;
    }
    if (this.socket) {
      this.socket.off();
      this.socket.disconnect();
      this.socket = undefined;
    }
    if (!this.websocketEnpoint) throw new Error('"websocketEnpoint" need to be defined if the websocket is enabled');
    const options: Partial<ManagerOptions & SocketOptions> = {
      forceNew: true,
      transports: ["websocket"],
      reconnectionDelay: SLAVE_WEBSOCKET_RECONNECTION_DELAY,
      autoConnect: true,
    };
    let endpoint: URL | undefined;
    try {
      endpoint = new URL(this.websocketEnpoint);
    } catch(err) {
      toastError(`Remote URL is NOT valid : ${this.websocketEnpoint}`);
    }
    if(!endpoint) return false;
    const password = endpoint.searchParams.get("password") ?? this.password;
    if (password) endpoint.searchParams.set("password", password);
    const socket = io(endpoint.href, options);
    this.socket = socket;
    socket.on("message", (...args: any[]) => {
      if (!args?.length) return;
      const msg = args[0];
      this.proccess(msg);
    });
    socket.on("error", (...args: any[]) => {
      logger.log("Error : ", ...args);
    });
    socket.on("disconnect", (...args: any[]) => {
      logger.log("Disconnect : ", ...args);
      if (this.reconnectTimeout) clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = undefined;
      this.reconnectTimeout = setTimeout(() => {
        this.reconnectTimeout = undefined;
        this.connectWebsocket();
      }, SLAVE_WEBSOCKET_RECONNECTION_DELAY);
    });
    return true;
  }

  private isMasterId(id: string): boolean {
    return this.masterId === id;
  }

  private clearTimeout() {
    if (this.pingTimeout) clearTimeout(this.pingTimeout);
    this.pingTimeout = undefined;
  }

  private restartPingTimeout() {
    this.clearTimeout();
    this.pingTimeout = setTimeout(() => {
      this.events.triggerEvent("leaveMeeting");
    }, SLAVE_PING_TIMEOUT);
  }

  post<T extends MessagingBridgeMessageAction>(msg: MessagingBridgeMessage<T>, toId: string | undefined = this.masterId): MessagingBridgeMessage<T> | undefined {
    this.log("POST message : ", msg, " toId : ", toId);

    const extendedMsg: MessagingBridgeMessageExtended<T> = {
      ...msg,
      fromId: this.id,
      from: this.mode,
    };
    if (this.appBroadcastChannel) {
      const broadcastMessage: MessagingBridgeBroadcastChannelMessage<T> = {
        ...extendedMsg,
        toId,
      };
      this.appBroadcastChannel.postApplicationMessage(this.id, broadcastMessage);
    }
    this.appMessage?.postApplicationMessage(extendedMsg, toId);
    if (this.websocketEnabled && this.socket?.id) {
      extendedMsg.fromId = this.socket.id;
      this.socket.emit("message", extendedMsg);
    }
    return msg;
  }

  postToMaster<T extends MessagingBridgeMessageAction>(msg: MessagingBridgeMessage<T>) {
    if (!this.masterId) return false;
    return this.post(msg, this.masterId);
  }

  protected proccess(msg: MessagingBridgeMessageExtended<any>, toId?: string): void {
    this.log("RECEIVE message : ", msg, " toId : ", toId);
    if (toId && toId !== this.id) return;
    if (isMessageExtented(msg, MessagingBridgeMessageAction.UNAUTHORIZED)) {
      this.events.triggerEvent("unauthorized", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WELCOME)) {
      this.masterId = msg.content.masterId;
      this.events.triggerEvent("ready");
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.PING)) {
      /*if (this.isMasterId(msg.fromId)) {

      }*/
      this.restartPingTimeout();
      this.postPong(msg.content.counter, msg.fromId);
      return;
    }

    if (isMessageExtented(msg, MessagingBridgeMessageAction.JOIN_MEETING)) {
      this.events.triggerEvent("joinMeeting", msg);
      return;
    }

    if (isMessageExtented(msg, MessagingBridgeMessageAction.LEAVE_MEETING)) {
      this.events.triggerEvent("leaveMeeting", msg);
      return;
    }

    if (isMessageExtented(msg, MessagingBridgeMessageAction.SET_MEETING_ID)) {
      this.events.triggerEvent("meetingId", msg);
      return;
    }

    if (isMessageExtented(msg, MessagingBridgeMessageAction.SET_MEETING)) {
      this.events.triggerEvent("meeting", msg);
      return;
    }

    if (isMessageExtented(msg, MessagingBridgeMessageAction.SET_MEETING_EXTRA_DATA)) {
      this.events.triggerEvent("meetingExtraData", msg);
      return;
    }

    if (isMessageExtented(msg, MessagingBridgeMessageAction.MEETING_CONTROL_RESPONSE)) {
      this.events.triggerEvent("meetingControlResponse", msg);
      return;
    }

    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_PREPARE)) {
      this.events.triggerEvent("requestPrepareWebrtc", msg, (ready: boolean, mode?: RTCBridgeMediaSessionMode) => {
        if (ready) {
          if (!mode) throw new Error('"mode" is needed');
          this.postWebrtcIsReady(msg.content.mediaId, msg.content.rtcSessionId, mode);
        } else this.postRequestStopWebrtc(msg.content.mediaId, msg.content.rtcSessionId);
      });
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_READY)) {
      this.events.triggerEvent("webrtcIsReady", msg, (start: boolean) => {
        if (start) this.postRequestStartWebrtc(msg.content.mediaId, msg.content.rtcSessionId, msg.content.mode);
        else this.postRequestStopWebrtc(msg.content.mediaId, msg.content.rtcSessionId);
      });
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_START)) {
      this.events.triggerEvent("requestStartWebrtc", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_STOP)) {
      this.events.triggerEvent("requestStopWebrtc", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_SDP)) {
      this.events.triggerEvent("sdpWebrtc", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE)) {
      this.events.triggerEvent("iceCandidateWebrtc", msg);
      return;
    }
    if (isMessageExtented(msg, MessagingBridgeMessageAction.WHITEBOARD_MESSAGE)) {
      this.events.triggerEvent('whiteboardMessage', msg);
      return;
    }
  }

  public async destroy() {
    if (this.appBroadcastChannel) await this.appBroadcastChannel.leaveApplication(this.id);
    if (this.socket) {
      this.socket.off();
      this.socket.disconnect();
    }
    super.destroy();
  }

  // --------------------------- POST --------------------- //

  postPong(counter: number, toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.PONG, {
      counter,
    });
    return this.post(msg, toId);
  }

  postClientData<T extends PageSlaveMode>(page: T, data?: T extends keyof MessagingBridgeClientDataMap[SessionMode.SLAVE] ? MessagingBridgeClientDataMap[SessionMode.SLAVE][T] : undefined, toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.SET_CLIENT_DATA, {
      mode: SessionMode.SLAVE,
      page,
      data,
    } as MessagingBridgeClientData<SessionMode.SLAVE, T>);
    return this.post(msg, toId);
  }

  postClearClientData(page: PageSlaveMode, toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.CLEAR_CLIENT_DATA, {
      mode: SessionMode.SLAVE,
      page,
    } as any);
    return this.post(msg, toId);
  }

  postRequestMeetingId(toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.GET_MEETING_ID, undefined);
    return this.post(msg, toId);
  }

  postRequestMeeting(toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.GET_MEETING, undefined);
    return this.post(msg, toId);
  }

  postRequestPrepareWebrtc(mediaId: string, rtcSessionId: string, supportedModes: RTCBridgeMediaSessionMode[], toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_PREPARE, {
      mediaId,
      rtcSessionId,
      supportedModes,
    });
    return this.post(msg, toId);
  }

  postMeetingControl<T extends MeetingControlAction>(action: T, data: MeetingControlDataMap[T], toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.MEETING_CONTROL, {
      action,
      data,
    });
    return this.post(msg, toId);
  }

  postWebrtcIsReady(mediaId: string, rtcSessionId: string, mode: RTCBridgeMediaSessionMode, toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_READY, {
      mediaId,
      rtcSessionId,
      mode,
    });
    return this.post(msg, toId);
  }

  postRequestStartWebrtc(mediaId: string, rtcSessionId: string, mode: RTCBridgeMediaSessionMode, toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_START, {
      mediaId,
      rtcSessionId,
      mode,
    });
    return this.post(msg, toId);
  }

  postRequestStopWebrtc(mediaId: string, rtcSessionId: string, toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_STOP, {
      mediaId,
      rtcSessionId,
    });
    return this.post(msg, toId);
  }

  postSdpWebrtc(mediaId: string, rtcSessionId: string, sdp: any, toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_SDP, {
      mediaId,
      rtcSessionId,
      sdp,
    });
    return this.post(msg, toId);
  }

  postIceCandidateWebrtc(mediaId: string, rtcSessionId: string, iceCandidate: any, toId: string | undefined = this.masterId) {
    const msg = this.buildBridgeMessage(MessagingBridgeMessageAction.WEBRTC_ICE_CANDIDATE, {
      mediaId,
      rtcSessionId,
      iceCandidate,
    });
    return this.post(msg, toId);
  }
}

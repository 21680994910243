import { useForm } from "react-hook-form";
import { ReactComponent as KalyzeeSvg } from "../../assets/logo/logo-animated.svg";
import background from "../../assets/backgrounds/kast.png";
import {
  AppVersion,
  Loading,
  awaitWithMinimumDelay,
  Button,
  Slider,
  SliderThumbDisplay,
  Colors,
  SliderRef,
  ProgressBar,
  ProgressBarRef,
} from "@kalyzee/kast-app-web-components";
import { useAppDispatch } from "../../hooks/app";
import { requestLoginLocal } from "../../store/request/actions";
import styles from "./login.module.css";
import { useEffect, useRef, useState } from "react";
import { OAuth2CodeSession, OAuth2CodeSessionData, OAuth2CodeSessionStatus } from "../../helpers/oauth2Code";
import { apiClient, getOAuth2ClientId } from "../../helpers/request";
import { convertMsToHMS } from "../../helpers/utils";
import { login } from "../../store/session/slices";

export enum LoginMethod {
  EMAIL_PASSWORD,
  OAUTH2_CODE,
}

export interface LoginPageProps {
  emailPassword: boolean;
  oAuth2Code: boolean;
  method?: LoginMethod;
}

const OAUTH2ClientId = getOAuth2ClientId();

const LoginPage = ({ emailPassword, oAuth2Code, method }: LoginPageProps) => {
  const [loading, setLoading] = useState(false);
  const [currMethod, setCurrMethod] = useState<LoginMethod>();
  const [oAuth2CodeSessionData, setOAuth2CodeSessionData] = useState<OAuth2CodeSessionData>();
  const oAuth2CodeSessionRef = useRef<OAuth2CodeSession>();
  const progressBarOAuth2CodeSessionRef = useRef<ProgressBarRef>(null);
  const remainingTimeOAuth2CodeSessionRef = useRef<HTMLSpanElement>(null);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const renderRequiredField = () => <span className={styles.fieldError}>{"Ce champ est requis" /* TRANSLATION */}</span>;

  const onSubmit = async (data: any) => {
    setLoading(true);
    await awaitWithMinimumDelay(dispatch(requestLoginLocal(data)) as any, 500);
    setLoading(false);
  };

  useEffect(() => {
    return () => {
      oAuth2CodeSessionRef.current?.stop();
      oAuth2CodeSessionRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    if (method) {
      setCurrMethod(method);
      return;
    }
    if (oAuth2Code && !emailPassword) setCurrMethod(LoginMethod.OAUTH2_CODE);
    else setCurrMethod(LoginMethod.EMAIL_PASSWORD);
  }, [emailPassword, oAuth2Code, method]);

  useEffect(() => {
    if (currMethod === LoginMethod.OAUTH2_CODE) {
      if (!OAUTH2ClientId) return;
      const session = new OAuth2CodeSession(apiClient, OAUTH2ClientId);
      session.start();
      session.addEventListener("data", (data) => {
        setOAuth2CodeSessionData(data);
        if (data.status === OAuth2CodeSessionStatus.SUCCESS && data.tokens?.token) {
          const token = data.tokens.token;
          const refreshToken = data.tokens.refreshToken;
          setTimeout(() => {
            dispatch(login({ token, refreshToken }));
          }, 2000); // Just so we can see the "Success!" for two second ...
        }
      });
      session.addEventListener("timeProgress", (remaining: number, progress: number) => {
        progressBarOAuth2CodeSessionRef.current?.setValue(progress * 100);
        if (remainingTimeOAuth2CodeSessionRef.current) {
          remainingTimeOAuth2CodeSessionRef.current.textContent = `${convertMsToHMS(remaining)}`;
        }
      });
      oAuth2CodeSessionRef.current = session;
      return () => {
        session.stop();
        session.removeAllEventListeners();
      };
    }
    return () => {
      oAuth2CodeSessionRef.current?.stop();
      oAuth2CodeSessionRef.current = undefined;
    };
  }, [currMethod]);

  const renderEmailPassowrd = () => {
    if (currMethod === LoginMethod.OAUTH2_CODE) {
      return (
        <Button className={styles.button} title={"S'identifier avec son email" /* TRANSLATION */} onPress={() => setCurrMethod(LoginMethod.EMAIL_PASSWORD)} />
      );
    }
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.title}>{"Login" /* TRANSLATION */}</div>
        <input className={styles.input} placeholder={"Email" /* TRANSLATION */} {...register("email", { required: true })} />
        {errors.email ? renderRequiredField() : null}
        <input className={styles.input} placeholder={"Password" /* TRANSLATION */} type="password" {...register("password", { required: true })} />
        {errors.password ? renderRequiredField() : null}
        {loading ? (
          <Loading className={styles.loading} />
        ) : (
          <input className={styles.button} style={{ marginTop: "20px" }} type="submit" value={"Se connecter" /* TRANSLATION */} />
        )}
      </form>
    );
  };

  const renderHorizontalSeparator = () => <div style={{ height: 1, width: "80%", backgroundColor: "#88888888" }} />;

  const renderOAuth2Code = () => {
    if (currMethod === LoginMethod.EMAIL_PASSWORD) {
      return <Button className={styles.button} title={"S'appairer avec un code" /* TRANSLATION */} onPress={() => setCurrMethod(LoginMethod.OAUTH2_CODE)} />;
    }
    if (!OAUTH2ClientId) return <div>Internal error - no client id</div>;
    if (!oAuth2CodeSessionData?.codes) {
      return <Loading />;
    }
    const renderContent = () => {
      if (oAuth2CodeSessionData?.status === OAuth2CodeSessionStatus.ERROR) {
        return (
          <>
            <div className={styles.textRow}>Une erreur est apparue</div>
            <div className={styles.textRow} style={{ fontSize: "1.5rem", color: Colors.getTorchRed() }}>
              {oAuth2CodeSessionData.errorMessage || "time is up"}
            </div>
            <br />
          </>
        );
      }
      if (oAuth2CodeSessionData?.status === OAuth2CodeSessionStatus.SUCCESS) {
        return (
          <>
            <div className={styles.textRow} style={{ fontSize: "1.5rem", color: Colors.getMainGreen() }}>
              SUCCÈS
            </div>
            <div className={styles.textRow}>L'appareil a été appairé.</div>
          </>
        );
      }
      if (!oAuth2CodeSessionData?.codes) return null;
      return (
        <>
          <div className={styles.textRow}>Appairer votre appareil avec le code</div>
          <div className={styles.textRow} style={{ fontSize: "2rem", color: Colors.getMainGreen() }}>
            {oAuth2CodeSessionData.codes.user_code}
          </div>
          <br />
          {oAuth2CodeSessionData?.status === OAuth2CodeSessionStatus.IN_PROGRESS ? (
            <>
              <div className={styles.smallTextRow}>
                Temps restant:{" "}
                <span ref={remainingTimeOAuth2CodeSessionRef} style={{ fontSize: "0.9rem" }}>
                  {"-"}
                </span>
              </div>
              <ProgressBar
                style={{ width: "100%" }}
                ref={progressBarOAuth2CodeSessionRef}
                value={0}
                minimumTrackColor={Colors.getMainGreen()}
                maximumTrackColor={Colors.getClayEbony(0.5)}
              />
            </>
          ) : null}
        </>
      );
    };
    return (
      <div className={styles.oauth2CodeContainer}>
        {renderContent()}
        {oAuth2CodeSessionData?.status !== OAuth2CodeSessionStatus.SUCCESS ? (
          <Button
            className={styles.button}
            style={{ backgroundColor: Colors.getGrayDusty() }}
            title={"Réinitialiser" /* TRANSLATION */}
            onPress={() => oAuth2CodeSessionRef.current?.reset()}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${background})` }}>
      <div className={styles.logoContainer}>
        <KalyzeeSvg className={styles.logoKalyzee} />
      </div>
      {oAuth2Code && oAuth2CodeSessionData?.status === OAuth2CodeSessionStatus.SUCCESS ? (
        <div className={styles.loginContainer}>{renderOAuth2Code()}</div>
      ) : (
        <div className={styles.loginContainer}>
          {emailPassword ? renderEmailPassowrd() : null}
          {emailPassword && oAuth2Code ? renderHorizontalSeparator() : null}
          {oAuth2Code ? renderOAuth2Code() : null}
        </div>
      )}
      <AppVersion />
    </div>
  );
};

export default LoginPage;

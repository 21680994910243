import React from 'react';
import { toast as reactToastify } from 'react-toastify';
import { ApiError } from './apiError';

export enum ToastMode {
  NORMAL = 'NORMAL',
};

const TOAST_DEFAULT_OPTIONS = {
  className: 'toast',
};

const TOAST_OPTIONS = {
  [ToastMode.NORMAL]: {
    default: TOAST_DEFAULT_OPTIONS,
    info: TOAST_DEFAULT_OPTIONS,
    success: TOAST_DEFAULT_OPTIONS,
    warning: TOAST_DEFAULT_OPTIONS,
    error: TOAST_DEFAULT_OPTIONS,
  },
};

export const toast = (content: React.ReactNode, mode = ToastMode.NORMAL) =>
  reactToastify(content, TOAST_OPTIONS[mode]?.default ?? TOAST_DEFAULT_OPTIONS);
export const toastInfo = (content: React.ReactNode, mode = ToastMode.NORMAL) =>
  reactToastify.info(content, TOAST_OPTIONS[mode]?.info ?? TOAST_DEFAULT_OPTIONS);
export const toastSuccess = (content: React.ReactNode, mode = ToastMode.NORMAL) =>
  reactToastify.success(content, TOAST_OPTIONS[mode]?.success ?? TOAST_DEFAULT_OPTIONS);
export const toastWarning = (content: React.ReactNode, mode = ToastMode.NORMAL) =>
  reactToastify.warning(content, TOAST_OPTIONS[mode]?.warning ?? TOAST_DEFAULT_OPTIONS);
export const toastError = (content: React.ReactNode, mode = ToastMode.NORMAL) =>
  reactToastify.error(content, TOAST_OPTIONS[mode]?.error ?? TOAST_DEFAULT_OPTIONS);
export const toastApiError = (content: ApiError, mode = ToastMode.NORMAL) =>
  reactToastify.error(content.message, TOAST_OPTIONS[mode]?.error ?? TOAST_DEFAULT_OPTIONS);

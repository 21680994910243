import { Colors, Loading, Touchable, useStateWithRef } from "@kalyzee/kast-app-web-components";
import { Meeting, MeetingSession, MeetingSessionMedia } from "@kalyzee/kast-websocket-module";
import deepEqual from "deep-equal";
import { useCallback, useEffect, useRef, useState } from "react";
import ImgBackground from "../../assets/backgrounds/kast.png";
import { ReactComponent as IconMeeting } from "../../assets/icons/meeting.svg";
import { ReactComponent as IconWhiteboard } from "../../assets/icons/whiteboard.svg";
import MeetingHeader from "../../components/MeetingHeader";
import MeetingPlayer from "../../components/MeetingPlayer";
import MeetingPlayers, { MeetingPlayersDisplayMode, MeetingPlayersOrientation } from "../../components/MeetingPlayers";
import MeetingSessionContainer from "../../components/MeetingSessionContainer";
import { PageSlaveMode, usePageContextRef } from "../../components/navigation/PageContext";
import Whiteboard from "../../components/Whiteboard";
import { useMessagingBridgeManagerSlaveContext } from "../../contexts/messageBridgeManager";
import { isSpectator, isSuperUser } from "../../helpers/meeting";
import { MessagingBridgeClientDataMap } from "../../helpers/messagingBridge";
import { RTCBridgeTransmissionMode } from "../../helpers/rtcBridgeMediaSession";
import { DEFAULT_SETTINGS } from "../../helpers/settings";
import { getMeetingTime } from "../../helpers/utils";
import { useAppSelector } from "../../hooks/app";
import { useSettings } from "../../hooks/settings";
import { MeetingSessionMediaExtra } from "../../interfaces/meeting";
import { selectRefreshToken, selectToken } from "../../store/session/selectors";
import { SessionMode } from "../../store/session/slices";
import { MeetingExtraData, MeetingTab } from "../meeting/meeting";
import styles from "./spectator.module.css";

export interface SpectatorPageProps {
  waiting?: boolean;
}

export enum SpectatorScene {
  NORMAL = "normal",
  FULLSCREEN = "fullscren",
}

const SpectatorPage = ({ waiting }: SpectatorPageProps) => {
  const timeDivRef = useRef<HTMLDivElement>(null);
  const [meeting, setMeeting] = useState<Meeting | undefined>(undefined);
  const [session, setSession] = useState<MeetingSession | undefined>(undefined);
  const [mediaStreams, setMediaStream] = useState<{ [mediaId: string]: MediaStream | undefined }>({});
  const pageContextRef = usePageContextRef();
  const [settings] = useSettings();
  const pageSettings = settings.slave?.pages?.spectator;
  const DEFAULT_PAGE_SETTINGS = DEFAULT_SETTINGS.slave.pages.spectator;
  const [tab, setTab] = useState<MeetingTab>(pageSettings?.whiteboard?.enabled ? MeetingTab.WHITEBOARD : MeetingTab.MAIN);
  const bridgeMessageManager = useMessagingBridgeManagerSlaveContext();
  const [extraData, setExtraData] = useState<MeetingExtraData>();
  const [scene, setScene, sceneRef] = useStateWithRef(SpectatorScene.NORMAL);
  const dataRef = useRef<MessagingBridgeClientDataMap[SessionMode.SLAVE][PageSlaveMode.SPECTATOR]>();
  const id = pageContextRef.current.params?.id ?? "";

  const getSessions = useCallback((): MeetingSession[] => {
    if (!meeting) return [];
    let sessions = [...meeting.sessions];
    let whiteboardIsPresent = false;
    if (extraData) {
      const clients = Object.values(extraData.clients);
      clients.forEach((c) => {
        if (c?.page === PageSlaveMode.WHITEBOARD) whiteboardIsPresent = true;
      });
    }
    sessions = sessions.filter((s) => {
      if (s.id === session?.id && !(pageSettings?.displayMe ?? DEFAULT_PAGE_SETTINGS.displayMe)) return false;
      const mediaIsDisplayingOnWhiteboard = s.medias.filter((m: MeetingSessionMedia<MeetingSessionMediaExtra>) => m.extra?.displayOnWhiteboard?.enabled && m.extra?.displayOnWhiteboard?.active).length
      if (whiteboardIsPresent && mediaIsDisplayingOnWhiteboard)
        return false;
      return !isSpectator(s.role);
    });
    sessions.sort((a, b) => (b.id === session?.id ? 1 : 0) - (a.id === session?.id ? 1 : 0));
    return sessions;
  }, [meeting, session, settings, extraData]);

  // ---------------------------------------------------------------- //
  // ---------------------------- EFFECT ----------------------------- //
  // ---------------------------------------------------------------- //

  useEffect(() => {
    const currSessions = getSessions();
    const fullscreen = extraData?.fullscreenForMediaId;
    let currScene = SpectatorScene.NORMAL;
    if (fullscreen && currSessions.find((s) => s.medias?.find((m) => m.id === fullscreen))) {
      currScene = SpectatorScene.FULLSCREEN;
    }
    if (currScene !== sceneRef.current) setScene(currScene);
  }, [extraData, getSessions]);

  useEffect(() => {
    if (!bridgeMessageManager) return;

    const page = pageContextRef.current.page;

    const readyListener = bridgeMessageManager.addEventListener("ready", () => {
      dataRef.current = undefined; // refresh
      if (id) bridgeMessageManager.postRequestMeeting();
      else bridgeMessageManager.postRequestMeetingId();
    });

    const joinMeetingListener = bridgeMessageManager.addEventListener("joinMeeting", (msg) => {
      const meetingId = msg.content.meetingId;
      setMeeting(undefined);
      if (id !== meetingId) pageContextRef.current.goTo?.(PageSlaveMode.SPECTATOR, { id: meetingId });
    });

    const leaveMeetingListener = bridgeMessageManager.addEventListener("leaveMeeting", () => {
      setMeeting(undefined);
      pageContextRef.current.goTo?.(PageSlaveMode.SPECTATOR_WAITING);
    });

    const meetingIdListener = bridgeMessageManager.addEventListener("meetingId", (msg) => {
      const meetingId = msg.content.meetingId;
      setMeeting(undefined);
      if (id !== meetingId) pageContextRef.current.goTo?.(PageSlaveMode.SPECTATOR, { id: meetingId });
    });

    const meetingListener = bridgeMessageManager.addEventListener("meeting", (msg) => {
      const meeting = msg.content.meeting;
      const session = msg.content.session;
      setMeeting(meeting);
      setSession(session);
    });

    const meetingExtraDataListener = bridgeMessageManager.addEventListener("meetingExtraData", (msg) => {
      const data = msg.content.data;
      setExtraData(data);
    });

    if (id) bridgeMessageManager.postRequestMeeting();
    else bridgeMessageManager.postRequestMeetingId();

    return () => {
      if (page) bridgeMessageManager.postClearClientData(page);
      bridgeMessageManager.removeEventListener(...readyListener);
      bridgeMessageManager.removeEventListener(...joinMeetingListener);
      bridgeMessageManager.removeEventListener(...leaveMeetingListener);
      bridgeMessageManager.removeEventListener(...meetingIdListener);
      bridgeMessageManager.removeEventListener(...meetingListener);
      bridgeMessageManager.removeEventListener(...meetingExtraDataListener);
    };
  }, [pageContextRef, bridgeMessageManager, id]);

  // Clear medias
  useEffect(() => {
    let mediaStreamsUpdated = false;
    const sessions = meeting?.sessions;
    const mediaIds: string[] = [];
    sessions?.forEach((s) => s.medias?.forEach((m) => mediaIds.push(m.id)));

    const currentMediaStreams = { ...mediaStreams };
    const currentMediaIds = Object.keys(currentMediaStreams);
    currentMediaIds.forEach((currId) => {
      if (!mediaIds.includes(currId)) {
        const currMedia = currentMediaStreams[currId];
        delete currentMediaStreams[currId];
        mediaStreamsUpdated = true;
      }
    });
    if (mediaStreamsUpdated) setMediaStream(currentMediaStreams);
  }, [meeting, mediaStreams]);

  useEffect(() => {
    if (!bridgeMessageManager) return;
    const page = pageContextRef.current.page;
    if (page && meeting) {
      const sessions = getSessions();
      const medias: string[] = [];
      sessions?.forEach((s) => {
        if (!s?.medias?.length) return;
        medias.push(s?.medias[0].id);
      });
      const newData = { displayingMedias: medias };
      if (dataRef.current === undefined || !deepEqual(dataRef.current, newData)) {
        bridgeMessageManager.postClientData(page, newData);
      }
      dataRef.current = newData;
    }
  }, [bridgeMessageManager, getSessions]);

  useEffect(() => {
    const interval = setInterval(() => {
      const time = getMeetingTime();
      if (timeDivRef.current && timeDivRef.current.textContent !== time) {
        timeDivRef.current.textContent = time;
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // ---------------------------------------------------------------- //
  // ---------------------------- RENDERING ----------------------------- //
  // ---------------------------------------------------------------- //

  const renderSessionFromMedia = (s: MeetingSession, classNames: string[] = []) => {
    const me = s.id === session?.id;
    const user = s.user;
    const media = s.medias?.[0];
    let muted = true;
    let volume = 1;
    if (extraData?.displayVideoOnlyOnSpectatorPage) {
      const audio = extraData?.audio?.[media.id];
      if (audio) {
        muted = audio.muted;
        volume = audio.volume;
      }
    }
    return (
      <MeetingSessionContainer me={me} session={s} playerSettings={pageSettings?.player} style={{ pointerEvents: "none" }}>
        <MeetingPlayer
          me={me}
          muted={muted}
          volume={volume}
          mode={"media"}
          mediaStream={mediaStreams[media.id]}
          internalTransmissionMode={RTCBridgeTransmissionMode.DISABLED}
          bridgeMessageManager={bridgeMessageManager}
          reactions={s.reactions}
          reactionsEnabled={[]}
          session={s}
          mediaSession={media}
          user={user}
          displayVideoStats={pageSettings?.debug ?? DEFAULT_PAGE_SETTINGS.debug}
          audioButtonEnabled={false}
          videoButtonEnabled={false}
        />
      </MeetingSessionContainer>
    );
  };

  const renderSession = (render: boolean, s: MeetingSession) => {
    const me = s.id === session?.id;
    const user = s.user;
    const media = s.medias?.[0];
    let muted = true;
    let volume = 1;
    let mode: "distant" | "local" = !(pageSettings?.loadMediaLocally ?? DEFAULT_PAGE_SETTINGS.loadMediaLocally) ? "distant" : "local";
    if (!me && extraData?.displayVideoOnlyOnSpectatorPage) {
      mode = "distant";
      const audio = extraData?.audio?.[media.id];
      if (audio) {
        muted = audio.muted;
        volume = audio.volume;
      }
    }
    const internalTransmissionMode =
      (mode as any) === "local"
        ? pageSettings?.media?.internalTransmission ?? DEFAULT_PAGE_SETTINGS.media.internalTransmission
        : RTCBridgeTransmissionMode.DISABLED;
    return (
      <MeetingSessionContainer me={me} session={s} playerSettings={pageSettings?.player} style={{ pointerEvents: "none" }}>
        {media ? (
          <MeetingPlayer
            noRender={!render}
            me={me}
            muted={muted}
            volume={volume}
            mode={mode}
            internalTransmissionMode={internalTransmissionMode}
            bridgeMessageManager={bridgeMessageManager}
            reactions={s.reactions}
            reactionsEnabled={[]}
            session={s}
            mediaSession={media}
            user={user}
            displayVideoStats={pageSettings?.debug ?? DEFAULT_PAGE_SETTINGS.debug}
            audioButtonEnabled={false}
            videoButtonEnabled={false}
            onMediaStream={(m) => {
              setMediaStream({
                ...mediaStreams,
                [media.id]: m,
              });
            }}
          />
        ) : null}
      </MeetingSessionContainer>
    );
  };

  const renderHeader = () => {
    if (!meeting) return null;
    if (scene === SpectatorScene.FULLSCREEN && tab === MeetingTab.MAIN) return null;
    if (pageSettings?.header?.hide ?? DEFAULT_PAGE_SETTINGS.header.hide) return null;
    return (
      <MeetingHeader
        meeting={meeting}
        helpUri={pageSettings?.header?.helpUri ?? DEFAULT_PAGE_SETTINGS.header.helpUri}
        hideClock={!(pageSettings?.header?.clock?.enabled ?? DEFAULT_PAGE_SETTINGS.header.clock.enabled)}
        displaySeconds={pageSettings?.header?.clock?.seconds ?? DEFAULT_PAGE_SETTINGS.header.clock.seconds}
      />
    );
  };

  const getActiveWhiteboardShortdId = (): string | undefined => {
    if (!meeting) return undefined;
    const activeWhiteboardResult = meeting.whiteboards?.filter((w) => w.id === meeting.activeWhiteboardId);
    const activeWhiteboard = activeWhiteboardResult?.length ? activeWhiteboardResult[0] : undefined;
    return activeWhiteboard?.shortId;
  };

  const renderContent = () => {
    if (waiting) {
      return <div className={[styles.contentMessage, styles.waiting].join(" ")}>En attente du meeting</div>;
    }
    if (!meeting) {
      return <Loading className={[styles.contentMessage, styles.loading].join(" ")} fullscreen message={"Chargement du meeting..." /* TRANSLATION */} />;
    }

    const sessions = getSessions();
    if (!sessions.length && tab === MeetingTab.MAIN) {
      return <div className={[styles.contentMessage, styles.nobody].join(" ")}>Aucun participant...</div>;
    }

    const renderMain = () => {
      const currClasses = [styles.mainContent];
      if (tab !== MeetingTab.MAIN) currClasses.push(styles.hideContent);
      const fullscreenSession = sessions.find((s) => s.medias?.find((m) => m.id === extraData?.fullscreenForMediaId));
      const remainingSessions = sessions.filter((s) => s.id !== fullscreenSession?.id);
      return (
        <>
          {fullscreenSession ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                width: "100%",
                height: "100%",
                padding: "5px",
              }}
            >
              <MeetingPlayers
                mode={MeetingPlayersDisplayMode.ONE_MAIN_AND_THE_OTHERS}
                options={{
                  main: fullscreenSession,
                  orientation: MeetingPlayersOrientation.HORIZONTAL,
                  styles: {
                    others: { width: remainingSessions.length <= 3 ? "400px" : "250px", height: "calc(33% - 5px)" },
                  },
                }}
                sessions={sessions}
                className={currClasses.join(" ")}
                renderItem={(_, session) => renderSessionFromMedia(session)}
                aspectVideoRation={pageSettings?.aspectVideoRatioTarget ?? DEFAULT_PAGE_SETTINGS.aspectVideoRatioTarget}
              />
            </div>
          ) : null}
          <MeetingPlayers
            mode={MeetingPlayersDisplayMode.GRID}
            options={undefined}
            sessions={sessions}
            className={currClasses.join(" ")}
            renderItem={(_, session) => renderSession(fullscreenSession ? false : true, session)}
            aspectVideoRation={pageSettings?.aspectVideoRatioTarget ?? DEFAULT_PAGE_SETTINGS.aspectVideoRatioTarget}
          />
        </>
      );
    };

    const renderWhiteboard = (whiteboardShortId?: string) => {
      if (!whiteboardShortId) return null;
      if (!(pageSettings?.whiteboard?.enabled ?? DEFAULT_PAGE_SETTINGS.whiteboard.enabled)) return null;

      const currClasses = [styles.whiteboardContainer];
      if (tab !== MeetingTab.WHITEBOARD) currClasses.push(styles.hideContent);

      return (
        <Whiteboard
          className={currClasses.join(" ")}
          autojoin={pageSettings?.whiteboard?.autojoin ?? true}
          id={pageSettings?.whiteboard?.id ?? DEFAULT_PAGE_SETTINGS.whiteboard.id ?? whiteboardShortId}
          name={pageSettings?.whiteboard?.name ?? DEFAULT_PAGE_SETTINGS.whiteboard.name ?? session?.user?.username}
          isMaster={pageSettings?.whiteboard?.isMaster ?? DEFAULT_PAGE_SETTINGS.whiteboard.isMaster}
          followMaster={pageSettings?.whiteboard?.followMasters ?? DEFAULT_PAGE_SETTINGS.whiteboard.followMasters}
          invisible={(isSpectator(session?.role) && isSuperUser(session?.role)) || (pageSettings?.whiteboard?.invisible ?? DEFAULT_PAGE_SETTINGS.whiteboard.invisible)}
          spectator={(isSpectator(session?.role) && !isSuperUser(session?.role)) || (pageSettings?.whiteboard?.spectator ?? DEFAULT_PAGE_SETTINGS.whiteboard.spectator)}
          menuButtons={{
            enableFavorite: false,
            enableReportBug: true,
            enableSwitchWhiteboard: false,
            enableLogout: false,
          }}
          hideNewIndicatorAfterDelay={pageSettings?.whiteboard?.hideNewIndicatorAfterDelay ?? DEFAULT_PAGE_SETTINGS.whiteboard.hideNewIndicatorAfterDelay}
          showNewVersionsOnFirstUse={pageSettings?.whiteboard?.showNewVersionsOnFirstUse ?? DEFAULT_PAGE_SETTINGS.whiteboard.showNewVersionsOnFirstUse}
        />
      );
    };

    return (
      <div className={styles.contentContainer}>
        {renderMain()}
        {renderWhiteboard(getActiveWhiteboardShortdId())}
      </div>
    );
  };

  const renderFooter = () => {
    const activeWhiteboardShortId = getActiveWhiteboardShortdId();
    if (!session || isSpectator(session.role) || !(pageSettings?.whiteboard?.enabled ?? DEFAULT_PAGE_SETTINGS.whiteboard.enabled) || !activeWhiteboardShortId)
      return null;
    if (scene === SpectatorScene.FULLSCREEN && tab === MeetingTab.MAIN) return null;
    const renderTabButton = (icon: React.ReactNode, title: string, currTab: MeetingTab) => (
      <Touchable
        className={`${styles.footerButton}${currTab === tab ? ` ${styles.footerButtonSelected}` : ""}`}
        onPress={() => {
          setTab(currTab);
        }}
      >
        <>
          {icon}
          <div>{title}</div>
        </>
      </Touchable>
    );
    const getIconFillColor = (targetTab: MeetingTab) => {
      return tab === targetTab ? Colors.getBlueCornflower() : "white";
    };

    if (tab === MeetingTab.WHITEBOARD) {
      return (
        <div className={styles.whiteboardFooterContainer}>
          <div className={styles.whiteboardFooter}>
            {renderTabButton(<IconWhiteboard width={40} height={40} fill={getIconFillColor(MeetingTab.WHITEBOARD)} />, "Tableau blanc", MeetingTab.WHITEBOARD)}
            {renderTabButton(<IconMeeting width={40} height={40} fill={getIconFillColor(MeetingTab.MAIN)} />, "Meeting", MeetingTab.MAIN)}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.footerContainer}>
        <div className={styles.footer}>
          {renderTabButton(<IconWhiteboard width={40} height={40} fill={getIconFillColor(MeetingTab.WHITEBOARD)} />, "Tableau blanc", MeetingTab.WHITEBOARD)}
          {renderTabButton(<IconMeeting width={40} height={40} fill={getIconFillColor(MeetingTab.MAIN)} />, "Meeting", MeetingTab.MAIN)}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.page} style={{ backgroundImage: `url(${ImgBackground})` }}>
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
    </div>
  );
};

SpectatorPage.defaultProps = {
  waiting: false,
};

export default SpectatorPage;

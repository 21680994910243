import { EventListeners, delay } from "@kalyzee/kast-app-web-components";
import { Settings } from "./settings";

const LOG = false;

export enum CommonMessageDataAction {
  APPLCIATION_PING = "ping",
  APPLCIATION_PONG = "pong",
  APPLICATION_MESSAGE = "application_message",
  APPLICATION_JOIN = "application_join",
  APPLICATION_REQUEST_JOIN = "application_request_join",
  APPLICATION_LEAVE = "application_leave",
}

export interface CommonMessageDataContentMap {
  [CommonMessageDataAction.APPLCIATION_PING]: { id?: string; counter: number };
  [CommonMessageDataAction.APPLCIATION_PONG]: { id?: string; counter: number };
  [CommonMessageDataAction.APPLICATION_MESSAGE]: { id?: string; data: any };
  [CommonMessageDataAction.APPLICATION_JOIN]: { id?: string; password?: string,  };
  [CommonMessageDataAction.APPLICATION_REQUEST_JOIN]: { toId: string; };
  [CommonMessageDataAction.APPLICATION_LEAVE]: { id?: string };
}

export interface CommonMessageData<T extends CommonMessageDataAction = any> {
  action: T;
  content: CommonMessageDataContentMap[T];
  acceptSelfAsEmitter?: boolean;
}

export interface CommonMessageEventMap {
  applicationPing: (id: string, counter: number) => void;
  applicationPong: (id: string, counter: number) => void;
  applicationMessage: (id: string, data: any) => void;
  applicationJoin: (id: string, password?: string) => void;
  applicationRequestJoin: (toId: string) => void;
  applicationLeave: (id: string) => void;
}

// ------------------------------------------------------------------- //
// -------------------- WINDOW - MESSAGE ----------------------------- //
// ------------------------------------------------------------------- //

export enum _AppMessageDataAction {
  READY = "ready",
  LOCALSTORAGE_GET_SETTINGS = "localstorage_get_settings",
  LOCALSTORAGE_SET_SETTINGS = "localstorage_set_settings",
}
// HACK to extend enum
type AppMessageDataAction = _AppMessageDataAction | CommonMessageDataAction;
// eslint-disable-next-line @typescript-eslint/no-redeclare
const AppMessageDataAction = { ..._AppMessageDataAction, ...CommonMessageDataAction } as const;

export interface AppMessageDataContentMap extends CommonMessageDataContentMap {
  [AppMessageDataAction.READY]: { password?: string };
  [AppMessageDataAction.LOCALSTORAGE_GET_SETTINGS]: undefined;
  [AppMessageDataAction.LOCALSTORAGE_SET_SETTINGS]: Partial<Settings>;
}

export interface AppMessageData<T extends AppMessageDataAction = any> {
  action: T;
  content: AppMessageDataContentMap[T];
}

export interface AppMessageEventMap extends CommonMessageEventMap {
  ready: () => void;
  localstorageGetSettings: () => void;
  localstorageSetSettings: (settings: Partial<Settings>) => void;
}

export class AppMessage {
  public logsEnabled: boolean = LOG;
  protected eventListeners: EventListeners<keyof AppMessageEventMap, AppMessageEventMap>;
  protected queue: any[] = [];
  private running: boolean = false;
  readonly listenOn: Window;
  readonly emitOn: Window;
  readonly msgListener: (ev: MessageEvent) => void;
  constructor(emitOn: Window = window.parent, listenOn: Window = window) {
    this.eventListeners = new EventListeners<keyof AppMessageEventMap, AppMessageEventMap>();
    this.listenOn = listenOn;
    this.emitOn = emitOn;
    this.msgListener = (ev: MessageEvent) => {
      const data = ev.data;
      this.log("on message : ", JSON.stringify(data));
      if (this.emitOn === ev.target && !data.acceptSelfAsEmitter) return;
      if (AppMessage.isMessageWithAction(data, AppMessageDataAction.LOCALSTORAGE_GET_SETTINGS)) this.eventListeners.triggerEvent("localstorageGetSettings");
      if (AppMessage.isMessageWithAction(data, AppMessageDataAction.LOCALSTORAGE_SET_SETTINGS))
        this.eventListeners.triggerEvent("localstorageSetSettings", data.content);
      if (AppMessage.isMessageWithAction(data, AppMessageDataAction.APPLCIATION_PING) && data.content.id)
        this.eventListeners.triggerEvent("applicationPing", data.content.id, data.content.counter);
      if (AppMessage.isMessageWithAction(data, AppMessageDataAction.APPLCIATION_PONG) && data.content.id)
        this.eventListeners.triggerEvent("applicationPong", data.content.id, data.content.counter);
      if (AppMessage.isMessageWithAction(data, AppMessageDataAction.APPLICATION_MESSAGE) && data.content.id)
        this.eventListeners.triggerEvent("applicationMessage", data.content.id, data.content.data);
      if (AppMessage.isMessageWithAction(data, AppMessageDataAction.APPLICATION_JOIN) && data.content.id)
        this.eventListeners.triggerEvent("applicationJoin", data.content.id, data.content.password);
      if (AppMessage.isMessageWithAction(data, AppMessageDataAction.APPLICATION_LEAVE) && data.content.id)
        this.eventListeners.triggerEvent("applicationLeave", data.content.id);
    };
    this.listenOn.addEventListener("message", this.msgListener);
    this.log("ready : ", emitOn, listenOn);
  }

  static isMessageWithAction<T extends AppMessageDataAction>(msg: any, action: T): msg is AppMessageData<T> {
    if (!msg) return false;
    return msg.action === action;
  }

  public log(...args: any[]) {
    if (!this.logsEnabled) return;
    const style = "color:blue;font-weight:bold";
    const data = new Date();
    console.log(`%c[APP MESSAGE -- ${data.toLocaleTimeString()}:${data.getMilliseconds()}] `, style, ...args);
  }

  protected async postMessage<T extends AppMessageDataAction>(newMsg: AppMessageData<T>) {
    this.log("post message : ", newMsg);
    this.queue.push(newMsg);
    if (this.running) return;
    this.running = true;
    while (this.queue.length) {
      const msg = this.queue.shift();
      this.emitOn.postMessage(msg, "*");
      await delay(20);
    }
    this.running = false;
  }

  public ready(password?: string) {
    this.postReady({ password });
  }

  async postReady(data: AppMessageDataContentMap[typeof AppMessageDataAction.READY]) {
    await this.postMessage({
      action: AppMessageDataAction.READY,
      content: data,
    });
  }

  async postSettings(data: AppMessageDataContentMap[typeof AppMessageDataAction.LOCALSTORAGE_SET_SETTINGS]) {
    await this.postMessage({
      action: AppMessageDataAction.LOCALSTORAGE_SET_SETTINGS,
      content: data,
    });
  }

  async requestSettings() {
    await this.postMessage({
      action: AppMessageDataAction.LOCALSTORAGE_GET_SETTINGS,
      content: undefined,
    });
  }

  async ping(counter: number, id?: string) {
    await this.postMessage({
      action: AppMessageDataAction.APPLCIATION_PING,
      content: { id, counter },
    });
  }

  async pong(counter: number, id?: string) {
    await this.postMessage({
      action: AppMessageDataAction.APPLCIATION_PONG,
      content: { id, counter },
    });
  }

  async postApplicationMessage(data: any, id?: string) {
    await this.postMessage({
      action: CommonMessageDataAction.APPLICATION_MESSAGE,
      content: { id, data },
    });
  }

  addEventListener<K extends keyof AppMessageEventMap>(event: K, listener: AppMessageEventMap[K]) {
    this.eventListeners.addEventListener(event, listener);
  }

  removeEventListener<K extends keyof AppMessageEventMap>(event: K, listener: AppMessageEventMap[K]) {
    this.eventListeners.removeEventListener(event, listener);
  }

  close() {
    this.log('close');
    this.listenOn.removeEventListener("message", this.msgListener);
    this.eventListeners.removeAllEventListener();
  }
}

export const appMessage = new AppMessage();

// ------------------------------------------------------------------- //
// ------------------------- BROADCAST --------------------------------- //
// ------------------------------------------------------------------- //

export enum _AppBroadcastChannelMessageDataAction {
  _ = "_", // HACK to extends empty enum
}
// HACK to extend enum
type AppBroadcastChannelMessageDataAction = _AppBroadcastChannelMessageDataAction | CommonMessageDataAction;
// eslint-disable-next-line @typescript-eslint/no-redeclare
const AppBroadcastChannelMessageDataAction = { ..._AppBroadcastChannelMessageDataAction, ...CommonMessageDataAction } as const;

export interface AppBroadcastChannelMessageDataContentMap extends CommonMessageDataContentMap {
  _: {}; // HACK to extends empty enum
}

export interface AppBroadcastChannelMessageData<T extends AppBroadcastChannelMessageDataAction = any> {
  action: T;
  content: AppBroadcastChannelMessageDataContentMap[T];
}

export interface AppBroadcastChannelEventMap extends CommonMessageEventMap {
  message: (ev: MessageEvent) => void;
  applicationMessage: (id: string, data: any) => void;
  applicationJoin: (id: string) => void;
  applicationLeave: (id: string) => void;
}

export class AppBroadcastChannel {
  public logsEnabled: boolean = LOG;
  protected eventListeners: EventListeners<keyof AppBroadcastChannelEventMap, AppBroadcastChannelEventMap>;
  protected broadcastChannel: BroadcastChannel;
  protected queue: any[] = [];
  protected closed: boolean = false;
  private running: boolean = false;
  constructor(name: string = "kalyzee-meeting-app") {
    this.eventListeners = new EventListeners();
    this.broadcastChannel = new BroadcastChannel(name);
    this.broadcastChannel.onmessage = (ev: MessageEvent) => {
      this.onMessage(ev);
      const data = ev.data;
      if (!data) return;
      this.log("on message - ", this.broadcastChannel.name, " : ", ev.data);
      if (AppBroadcastChannel.isMessageWithAction(data, AppBroadcastChannelMessageDataAction.APPLCIATION_PING) && data.content.id)
        this.eventListeners.triggerEvent("applicationPing", data.content.id, data.content.counter);
      if (AppBroadcastChannel.isMessageWithAction(data, AppBroadcastChannelMessageDataAction.APPLCIATION_PONG) && data.content.id)
        this.eventListeners.triggerEvent("applicationPong", data.content.id, data.content.counter);
      if (AppBroadcastChannel.isMessageWithAction(data, AppBroadcastChannelMessageDataAction.APPLICATION_MESSAGE) && data.content.id)
        this.eventListeners.triggerEvent("applicationMessage", data.content.id, data.content.data);
      if (AppBroadcastChannel.isMessageWithAction(data, AppBroadcastChannelMessageDataAction.APPLICATION_JOIN) && data.content.id)
        this.eventListeners.triggerEvent("applicationJoin", data.content.id);
      if (AppBroadcastChannel.isMessageWithAction(data, AppBroadcastChannelMessageDataAction.APPLICATION_REQUEST_JOIN) && data.content.toId)
        this.eventListeners.triggerEvent("applicationRequestJoin", data.content.toId);
      if (AppBroadcastChannel.isMessageWithAction(data, AppBroadcastChannelMessageDataAction.APPLICATION_LEAVE) && data.content.id)
        this.eventListeners.triggerEvent("applicationLeave", data.content.id);
    };
    this.log("ready");
  }

  static isMessageWithAction<T extends AppBroadcastChannelMessageDataAction>(msg: any, action: T): msg is AppBroadcastChannelMessageData<T> {
    if (!msg) return false;
    return msg.action === action;
  }

  public log(...args: any[]) {
    if (!this.logsEnabled) return;
    const style = "color:purple;font-weight:bold";
    const data = new Date();
    console.log(`%c[BROADCAST MESSAGE -- ${data.toLocaleTimeString()}:${data.getMilliseconds()}] `, style, ...args);
  }

  public async postMessage<T extends AppBroadcastChannelMessageDataAction>(newMsg: AppBroadcastChannelMessageData<T>) {
    if (this.closed) return;
    this.log("post message - ", this.broadcastChannel.name, " : ", newMsg);
    this.queue.push(newMsg);
    if (this.running) return;
    this.running = true;
    while (this.queue.length) {
      const msg = this.queue.shift();
      if (!this.closed) {
        this.broadcastChannel.postMessage(msg);
        await delay(20);
      }      
    }
    this.running = false;
  }

  async ping(id: string, counter: number) {
    await this.postMessage({
      action: AppBroadcastChannelMessageDataAction.APPLCIATION_PING,
      content: { id, counter },
    });
  }

  async pong(id: string, counter: number) {
    await this.postMessage({
      action: AppBroadcastChannelMessageDataAction.APPLCIATION_PONG,
      content: { id, counter },
    });
  }

  async joinApplication(id: string) {
    await this.postMessage({
      action: AppBroadcastChannelMessageDataAction.APPLICATION_JOIN,
      content: { id },
    });
  }

  async requestJoinApplication(toId: string) {
    await this.postMessage({
      action: AppBroadcastChannelMessageDataAction.APPLICATION_REQUEST_JOIN,
      content: { toId },
    });
  }

  async leaveApplication(id: string) {
    await this.postMessage({
      action: AppBroadcastChannelMessageDataAction.APPLICATION_LEAVE,
      content: { id },
    });
  }

  async postApplicationMessage(id: string, data: any) {
    await this.postMessage({
      action: AppBroadcastChannelMessageDataAction.APPLICATION_MESSAGE,
      content: { id, data },
    });
  }

  addEventListener<T extends keyof AppBroadcastChannelEventMap>(event: T, listener: AppBroadcastChannelEventMap[T]) {
    this.eventListeners.addEventListener(event, listener);
  }

  removeEventListener<T extends keyof AppBroadcastChannelEventMap>(event: T, listener: AppBroadcastChannelEventMap[T]) {
    this.eventListeners.removeEventListener(event, listener);
  }

  close() {
    this.log('close')
    this.closed = true;
    this.broadcastChannel.close();
    this.eventListeners.removeAllEventListener();
  }

  protected onMessage(ev: MessageEvent) {
    this.eventListeners.triggerEvent("message", ev);
  }
}

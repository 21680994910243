import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import ImgBackground from "../../assets/backgrounds/kast.png";
import { useAppDispatch, useAppSelector } from "../../hooks/app";
import { SocketStatus } from "../../interfaces/socket";
import { socketConnect } from "../../store/socket/actions";
import { selectSocketStatus } from "../../store/socket/selectors";

import { Button, Loading } from "@kalyzee/kast-app-web-components";
import { useMode } from "../../store/session/hooks";
import { SessionMode } from "../../store/session/slices";
import styles from "./home.module.css";
import { logger } from "../../helpers/logger";

const RETRY_AUTO = true;
const RETRY_AFTER = 15; // Seconds

const HomePage = () => {
  const dispatch = useAppDispatch();
  const socketStatus = useAppSelector(selectSocketStatus);
  const socketFirstConnectionRef = useRef<boolean>(true);
  const elementRef = useRef<HTMLDivElement>(null);
  const [counterRetry, setCounterRetry] = useState<number>(-1);
  const mode = useMode();

  const retryConnectSocket = useCallback(() => {
    setCounterRetry(-1);
    dispatch(socketConnect());
  }, [dispatch]);

  useEffect(() => {
    logger.log('socketStatus : ', socketStatus);
    if (socketStatus === SocketStatus.Online) {
      socketFirstConnectionRef.current = false;
    } else if (socketStatus === SocketStatus.Offline) {
      if (RETRY_AUTO) setCounterRetry(RETRY_AFTER);
    }
  }, [socketStatus]);

  useEffect(() => {
    if (socketStatus === SocketStatus.Offline) {
      if (counterRetry > 0) {
        const timeout = setTimeout(() => {
          setCounterRetry(counterRetry - 1);
        }, 1000);
        return () => clearTimeout(timeout);
      } else if (counterRetry === 0) {
        retryConnectSocket();
      }
    }
  }, [retryConnectSocket, counterRetry, socketStatus]);

  const renderContent = () => {
    if (socketStatus === SocketStatus.Online || mode === SessionMode.SLAVE) {
      return (
        <div className={styles.main}>
          <Outlet />
        </div>
      );
    }
    if (socketStatus === SocketStatus.Offline) {
      return (
        <div className={styles.message} style={{ backgroundImage: `url(${ImgBackground})` }}>
          <div className={styles.errorDescription}>{"Une erreur est survenue durant la connexion.\nVérifiez votre connexion internet."}</div>
          {counterRetry > 0 ? <div className={styles.errorRetry}>{`Reconnexion dans ${counterRetry} secondes...`}</div> : null}
          <Button className={styles.retryButton} title={counterRetry > 0 ? "Réessayer maintenant" : "Réessayer"} onPressOut={() => retryConnectSocket()} />
        </div>
      );
    }
    if (socketStatus === SocketStatus.Loading) {
      return (
        <div className={styles.message} style={{ backgroundImage: `url(${ImgBackground})` }}>
          <Loading message="Connexion en cours.." />
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.mainContainer} ref={elementRef}>
      {renderContent()}
    </div>
  );
};

export default HomePage;

interface NavigateQueryDetails<D = any> {
  readonly query: string;
  readonly type: 'boolean' | 'number' | 'string' | 'json';
  readonly isArray?: boolean;
  readonly default?: D;
}
type NavigateQueriesDetails<T extends string, D = any> = {[key in T]: D}

const hasQuery = (url: string, key: string): boolean => {
  const u = new URL(url);
  const result = u.searchParams.has(key);
  return result;
}

const getQueryAsString = (url: string, key: string, defaultValue?: string): string | undefined => {
  const u = new URL(url);
  const result = u.searchParams.get(key);
  if (!result) return defaultValue;
  return result;
}

const getQueryAsBoolean = (url: string, key: string, defaultValue?: boolean): boolean | undefined => {
  const result = getQueryAsString(url, key, undefined);
  if (result === undefined) {
    if (hasQuery(url, key)) return true;
    return defaultValue;
  }
  return result === 'true' || result === '1';
}

const getQueryAsNumber = (url: string, key: string, defaultValue: number): number => {
  const result = getQueryAsString(url, key, undefined);
  let num : number = Number(result);
  if (Number.isNaN(num)) return defaultValue;
  return num;
}

const getQueryAsJsonObject = <T = any>(url: string, key: string, defaultValue?: T, isArray?: boolean): T | undefined => {
  const result = getQueryAsString(url, key, undefined);
  if (!result) return defaultValue;
  let json: any | undefined;
  try {
    json = JSON.parse(result);
    if (isArray === true && !Array.isArray(json)) {
      json = defaultValue;
    }
  } catch(err) {
    json = defaultValue;
  }
  return json;
}

const getQueryAsStringArray = (url: string, key: string, defaultValue?: string[]): string[] | undefined => {
  const result = getQueryAsJsonObject(url, key, undefined, true);
  if (result === undefined) return defaultValue;
  const array: string[] = [];
  array.forEach((value: any) => {
    if (typeof value === 'string') array.push(value);
  });
  return array;
}

const getQueryAsBooleanArray = (url: string, key: string, defaultValue?: boolean[]): boolean[] | undefined => {
  const result = getQueryAsJsonObject(url, key, undefined, true);
  if (result === undefined) return defaultValue;
  const array: boolean[] = [];
  array.forEach((value: any) => {
    if (typeof value === 'boolean') array.push(value);
  });
  return array;
}

const getQueryAsNumberArray = (url: string, key: string, defaultValue: number[]): number[] => {
  const result = getQueryAsJsonObject(url, key, undefined, true);
  if (result === undefined) return defaultValue;
  const array: number[] = [];
  array.forEach((value: any) => {
    if (typeof value === 'number') array.push(value);
  });
  return array;
}

export const getQuery = <T extends string, M extends any>(url: string, key: T, details: NavigateQueriesDetails<T, NavigateQueryDetails<M>>) : M | undefined => {
  const queryDetails = details[key];
  if (queryDetails.type === 'string') {
    if (queryDetails.isArray) return getQueryAsStringArray(url, queryDetails.query, queryDetails.default as any) as any;
    return getQueryAsString(url, queryDetails.query, queryDetails.default as any) as any;
  }
  if (queryDetails.type === 'boolean') {
    if (queryDetails.isArray) return getQueryAsBooleanArray(url, queryDetails.query, queryDetails.default as any) as any;
    return getQueryAsBoolean(url, queryDetails.query, queryDetails.default as any) as any;
  }
  if (queryDetails.type === 'number') {
    if (queryDetails.isArray) return getQueryAsNumberArray(url, queryDetails.query, queryDetails.default as any) as any;
    return getQueryAsNumber(url, queryDetails.query, queryDetails.default as any) as any;
  }
  if (queryDetails.type === 'json') {
    return getQueryAsJsonObject(url, queryDetails.query, queryDetails.default as any) as any;
  }
}

export const getQueryLabel =  <T extends string, M extends any>(key: T, details: NavigateQueriesDetails<T, NavigateQueryDetails<M>>) : string => {
  return details[key].query;
}

// ---------------------- MASTER -------------------- //
export enum NavigateGlobalQuery {
  SLAVE = 'SLAVE',
  SETTINGS = 'SETTINGS',
  SETTINGS_DEFAULT = 'SETTINGS_DEFAULT',
  SETTINGS_NO_SAVE = 'SETTINGS_NO_SAVE',
}

export interface NavigateGlobalQueryTypeMap {
  [NavigateGlobalQuery.SLAVE]: boolean,
  [NavigateGlobalQuery.SETTINGS]: string,
  [NavigateGlobalQuery.SETTINGS_DEFAULT]: string,
  [NavigateGlobalQuery.SETTINGS_NO_SAVE]: boolean,
}

interface NavigateGlobalQueryDetails<T extends NavigateGlobalQuery> extends NavigateQueryDetails<NavigateGlobalQueryTypeMap[T]> {}

const NAVIGATE_GLOBAL_QUERIES: NavigateQueriesDetails<NavigateGlobalQuery, NavigateGlobalQueryDetails<NavigateGlobalQuery>> = {
  [NavigateGlobalQuery.SLAVE]: {
    query: 'slave',
    type: 'boolean',
  },
  [NavigateGlobalQuery.SETTINGS]: {
    query: 'settings',
    type: 'string',
  },
  [NavigateGlobalQuery.SETTINGS_DEFAULT]: {
    query: 'settings_default',
    type: 'string',
  },
  [NavigateGlobalQuery.SETTINGS_NO_SAVE]: {
    query: 'settings_no_save',
    type: 'boolean',
  }
} as const;

export const getGlobalQuery = <T extends NavigateGlobalQuery>(url: string, key: T): NavigateGlobalQueryTypeMap[T] | undefined => {
  return getQuery(url, key, NAVIGATE_GLOBAL_QUERIES) as NavigateGlobalQueryTypeMap[T];
}

export const getGlobalQueryLabel =  <T extends NavigateGlobalQuery>(key: T) : string => {
  return getQueryLabel(key, NAVIGATE_GLOBAL_QUERIES);
}

// ---------------------- MASTER -------------------- //

/*
export enum NavigateMasterQuery {
}

export interface NavigateMasterQueryTypeMap {
}

interface NavigateMasterQueryDetails<T extends NavigateMasterQuery> extends NavigateQueryDetails<NavigateMasterQueryTypeMap[T]> {}

const NAVIGATE_MASTER_QUERIES: NavigateQueriesDetails<NavigateMasterQuery, NavigateMasterQueryDetails<NavigateMasterQuery>> = {
} as const;

export const getMasterQuery = <T extends NavigateMasterQuery>(url: string, key: T): NavigateMasterQueryTypeMap[T] | undefined => {
  return getQuery(url, key, NAVIGATE_MASTER_QUERIES);
}

export const getMasterQueryLabel =  <T extends NavigateMasterQuery>(key: T) : string => {
  return getQueryLabel(key, NAVIGATE_MASTER_QUERIES);
}
*/

// ---------------------- SLAVE -------------------- //
export enum NavigateSlaveQuery {
  BRIDGE_WEBSOCKET_ENDPOINT = 'BRIDGE_WEBSOCKET_ENDPOINT',
  REMOTE = 'REMOTE'
}

export interface NavigateSlaveQueryTypeMap {
  [NavigateSlaveQuery.BRIDGE_WEBSOCKET_ENDPOINT]: string,
  [NavigateSlaveQuery.REMOTE]: boolean,
}

interface NavigateSlaveQueryDetails<T extends NavigateSlaveQuery> extends NavigateQueryDetails<NavigateSlaveQueryTypeMap[T]> {}

const NAVIGATE_SLAVE_QUERIES: NavigateQueriesDetails<NavigateSlaveQuery, NavigateSlaveQueryDetails<NavigateSlaveQuery>> = {
  [NavigateSlaveQuery.BRIDGE_WEBSOCKET_ENDPOINT]: {
    query: 'bridgeWebsocketEndpoint',
    type: 'string',
  },
  [NavigateSlaveQuery.REMOTE]: {
    query: 'remote',
    type: 'boolean',
  }
} as const;

export const getSlaveQuery = <T extends NavigateSlaveQuery>(url: string, key: T): NavigateSlaveQueryTypeMap[T] | undefined => {
  return getQuery(url, key, NAVIGATE_SLAVE_QUERIES) as any;
}

export const getSlaveQueryLabel =  <T extends NavigateSlaveQuery>(key: T) : string => {
  return getQueryLabel(key, NAVIGATE_SLAVE_QUERIES);
}

import { RootState } from '../store';

export const selectUser = (state: RootState) => state.session.user;
export const selectToken = (state: RootState) => state.session.token;
export const selectRefreshToken = (state: RootState) => state.session.refreshToken;
export const selectIsRefreshingToken = (state: RootState) => state.session.isRefreshingToken;
export const selectAuthStatus = (state: RootState) => state.session.authStatus;
export const selectScreen = (state: RootState) => state.session.screen;
export const selectMode = (state: RootState) => state.session.mode;
export const selectCompatibilityInfos = (state: RootState) => state.session.compatibilityInfos;

export default {
  selectUser,
  selectToken,
  selectRefreshToken,
  selectIsRefreshingToken,
  selectAuthStatus,
  selectScreen,
  selectMode,
  selectCompatibilityInfos,
};

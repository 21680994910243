import { AsyncOrNot, delay, useEventListenersRef, useRender } from "@kalyzee/kast-app-web-components";
import { CameraControls, CameraControlsRef, DeviceMoveDirection, DeviceScene, DeviceView, DeviceZoomDirection } from "@kalyzee/kast-react-player-module";
import React, { ForwardedRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";
// import styles from "./PTZPlayerOverlay.module.css";


export interface PTZPlayerOverlayEventMap {
  move: (direction: 'left' | 'right' | 'up' | 'down') => AsyncOrNot<void>;
  stopMove: (direction: 'any') => AsyncOrNot<void>;
  zoom: (zoom: 'in' | 'out') => AsyncOrNot<void>;
  stopZoom: (zoom: 'any') => AsyncOrNot<void>;
  setPreset: (index: number) => AsyncOrNot<void>;
  loadPreset: (index: number) => AsyncOrNot<void>;
  scene: (index: number) => AsyncOrNot<void>;
}

const PTZAdpaterMap = {
  move: 'onMove',
  stopMove: 'onStopMove',
  zoom: 'onZoom',
  stopZoom: 'onStopZoom',
  setPreset: 'onSetPreset',
  loadPreset: 'onLoadPreset',
  scene: 'onScene',
} as const;


export class PTZPlayerOverlayAdapter {
  protected targets: PTZPlayerOverlayRef[] = [];

  public addTarget(target: PTZPlayerOverlayRef) {
    if (this.targets.find((t) => t === target)) return;
    this.targets.push(target);
  }

  public removeTarget(target: PTZPlayerOverlayRef) {
    this.targets.filter((t) => t !== target);
  }

  public setScene(scene: DeviceScene) {
    this.targets.forEach((t) => t.controls.current?.setScene(scene));
  }

  onMove?: PTZPlayerOverlayEventMap["move"];
  onStopMove?: PTZPlayerOverlayEventMap["stopMove"];
  onZoom?: PTZPlayerOverlayEventMap["zoom"];
  onStopZoom?: PTZPlayerOverlayEventMap["stopZoom"];
  onSetPreset?: PTZPlayerOverlayEventMap["setPreset"];
  onLoadPreset?: PTZPlayerOverlayEventMap["loadPreset"];
  onScene?: PTZPlayerOverlayEventMap["scene"];
}


export interface PTZPlayerOverlayProps {
  adapter: PTZPlayerOverlayAdapter;
  asynchronous?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export interface PTZPlayerOverlayRef {
  controls: React.MutableRefObject<CameraControlsRef | undefined>;
  reload: () => void;
}

const PTZPlayerOverlay = React.forwardRef(
  ({ adapter, asynchronous, className, style }: PTZPlayerOverlayProps, forwardRef: ForwardedRef<PTZPlayerOverlayRef | undefined>) => {
    const listenersRef = useEventListenersRef<keyof PTZPlayerOverlayEventMap, PTZPlayerOverlayEventMap>();
    const destroyedRef = useRef(false);
    const queueRef = useRef<{event: keyof PTZPlayerOverlayEventMap, params: Parameters<PTZPlayerOverlayEventMap[keyof PTZPlayerOverlayEventMap]>}[]>([]);
    const rerender = useRender();
    const cameraControlsRef = useRef<CameraControlsRef>();

    const getSelfRef = useCallback(() => ({
      controls: cameraControlsRef,
      reload: () => rerender()
    }), [adapter]);

    useImperativeHandle(forwardRef, getSelfRef, [getSelfRef]);

    // ----------------------------------------------- //
    // ------------------ EFFECT -------------------- //
    // ----------------------------------------------- //

    useEffect(() => {
      return () => {
        destroyedRef.current = true;
      }
    }, []);

    useEffect(() => {
      queueRef.current = [];
    }, [adapter]);

    useEffect(() => {
      if (!adapter) return;
      const self = getSelfRef();
      adapter.addTarget(self);
      return () => adapter.removeTarget(self);
    }, [adapter, getSelfRef]);

    // ----------------------------------------------- //
    // ------------------ EVENTS -------------------- //
    // ----------------------------------------------- //

    const triggerEvent = async <T extends keyof PTZPlayerOverlayEventMap>(event: T, ...params: Parameters<PTZPlayerOverlayEventMap[T]>) => {
      listenersRef.current.triggerEvent(event, ...params);
      return await (adapter[PTZAdpaterMap[event]] as any)?.(...params);
    };

    const enqueueEvent = useCallback(async <T extends keyof PTZPlayerOverlayEventMap>(event: T, ...params: Parameters<PTZPlayerOverlayEventMap[T]>) => {
      if (destroyedRef.current) return; 
      if (asynchronous) {
        triggerEvent(event, ...params);
        return;
      }
      const queueWasEmpty = queueRef.current.length ? false : true;
      queueRef.current.push({event, params});
      if (!queueWasEmpty) return;
      while(queueRef.current.length) {
        const data = queueRef.current.shift();
        if (data) {
          await triggerEvent(data.event, ...data.params);
          await delay(20);
        }
      }
    }, [asynchronous, adapter]);

    // ----------------------------------------------- //
    // ------------------ RENDER -------------------- //
    // ----------------------------------------------- //

    return (
      <CameraControls
        ref={cameraControlsRef}
        className={className}
        style={style}
        enableZoomKeyboardEvent={false}
        enableViewKeyboardEvent={false}
        enableAssignViewKeyboardEvent={false}
        enableSceneKeyboardEvent={false}
        enableMoveKeyboardEvent={false}
        enableMove={adapter.onMove ? true : false}
        onMove={(direction) => {
          if (direction === DeviceMoveDirection.DOWN) enqueueEvent("move", "down");
          else if (direction === DeviceMoveDirection.UP) enqueueEvent("move", "up");
          else if (direction === DeviceMoveDirection.LEFT) enqueueEvent("move", "left");
          else if (direction === DeviceMoveDirection.RIGHT) enqueueEvent("move", "right");
        }}
        onStopMove={() => {
          enqueueEvent('stopMove', 'any');
        }}
        enableZoom={adapter.onZoom ? true : false}
        onZoom={(zoom) => {
          if (zoom === DeviceZoomDirection.ZOOM_IN) enqueueEvent('zoom', 'in');
          else if (zoom === DeviceZoomDirection.ZOOM_OUT) enqueueEvent('zoom', 'out');
        }}
        onStopZoom={() => {
          enqueueEvent('stopZoom', 'any');
        }}
        enableView={adapter.onLoadPreset ? true : false}
        onView={(view) => {
          if (view === DeviceView.VIEW_1) enqueueEvent('loadPreset', 1);
          else if (view === DeviceView.VIEW_2) enqueueEvent('loadPreset', 2);
          else if (view === DeviceView.VIEW_3) enqueueEvent('loadPreset', 3);
        }}
        onAssignView={(view) => {
          if (view === DeviceView.VIEW_1) enqueueEvent('setPreset', 1);
          else if (view === DeviceView.VIEW_2) enqueueEvent('setPreset', 2);
          else if (view === DeviceView.VIEW_3) enqueueEvent('setPreset', 3);
        }}
        enableScene={adapter.onScene ? true : false}
        onScene={(scene) => {
          if (scene === DeviceScene.MIXED) enqueueEvent('scene', 1);
          else if (scene === DeviceScene.CAMERA_ONLY) enqueueEvent('scene', 2);
          else if (scene === DeviceScene.INPUT_HDMI_ONLY) enqueueEvent('scene', 3);
          else if (scene === DeviceScene.HALF_MODE) enqueueEvent('scene', 4);
        }}
      />
    );
  }
);

PTZPlayerOverlay.defaultProps = {
  className: undefined,
  style: undefined,
};

export default PTZPlayerOverlay;

import { UserSettings as WhiteboardUserSettings, VersionsSeenAt } from "@kalyzee/whiteboard-react";

export enum StorageCategory {
  TOKEN = 'token',
  SETTINGS = 'settings',
  WHITEBOARD_SETTINGS = 'whiteboard_settings',
  WHITEBOARD_NEWS = 'whiteboard_news',
}

export enum TokenType {
  Normal = 'normal',
  Refresh = 'refreshToken',
}
export interface StorageKeysMap {
  [StorageCategory.TOKEN]: TokenType,
  [StorageCategory.SETTINGS]: undefined,
  [StorageCategory.WHITEBOARD_SETTINGS]: undefined,
  [StorageCategory.WHITEBOARD_NEWS]: undefined,
}

const storage = localStorage;

// ---------------- NATIVE ------------------ //

const generateKey = (category : StorageCategory, key?: string) => (
  key ? `${category}_${key}` : category
);

const setItem = <T extends StorageCategory>(
  category : T,
  key: StorageKeysMap[T],
  value: string,
) => storage.setItem(generateKey(category, key), value);

const getItem = <T extends StorageCategory>(
  category : T,
  key?: StorageKeysMap[T],
) => storage.getItem(generateKey(category, key));

const removeItem = <T extends StorageCategory>(
  category : T,
  key?: StorageKeysMap[T],
) => storage.removeItem(generateKey(category, key));

// ---------------- TOKEN ------------------ //

export const setToken = (
  token: string,
  tokenType: TokenType = TokenType.Normal,
) => setItem(StorageCategory.TOKEN, tokenType, token);

export const getToken = (
  tokenType: TokenType = TokenType.Normal,
) => getItem(StorageCategory.TOKEN, tokenType);

export const removeToken = (
  tokenType: TokenType = TokenType.Normal,
) => removeItem(StorageCategory.TOKEN, tokenType);

// ---------------- REFRESH TOKEN ------------------ //

// ---------------- SETTINGS ------------------ //

export const setSettings = (
  settings: string
) => setItem(StorageCategory.SETTINGS, undefined, settings);

export const getSettings = () => getItem(StorageCategory.SETTINGS);

export const removeSettings = () => removeItem(StorageCategory.SETTINGS);

// ---------------- WHITEBOARD SETTINGS ------------------ //
export const setWhiteboardSettings = (
  settings: Partial<WhiteboardUserSettings>
) => {
  const current = {...getWhiteboardSettings(), ...settings};
  setItem(StorageCategory.WHITEBOARD_SETTINGS, undefined, JSON.stringify(current));
};

export const getWhiteboardSettings = (): Partial<WhiteboardUserSettings> | undefined => {
  const value = getItem(StorageCategory.WHITEBOARD_SETTINGS);
  let parsed: WhiteboardUserSettings | undefined = undefined;
  try {
    parsed = JSON.parse(value!);
  } catch {}
  return parsed;
};

export const removeWhiteboardSettings = () => removeItem(StorageCategory.WHITEBOARD_SETTINGS);

// ---------------- WHITEBOARD NEWS ------------------ //
export const setWhiteboardNews = (
  news: VersionsSeenAt
) => {
  const current = {...getWhiteboardNews(), ...news};
  setItem(StorageCategory.WHITEBOARD_NEWS, undefined, JSON.stringify(current));
};

export const getWhiteboardNews = (): VersionsSeenAt | undefined => {
  const value = getItem(StorageCategory.WHITEBOARD_NEWS);
  let parsed: VersionsSeenAt | undefined = undefined;
  try {
    parsed = JSON.parse(value!);
  } catch {}
  return parsed;
};

export const removeWhiteboardNews = () => removeItem(StorageCategory.WHITEBOARD_NEWS);

const Storage = {
  // TOKEN
  setToken,
  getToken,
  removeToken,
  // REFRESH TOKEN
  // SETTINGS
  setSettings,
  getSettings,
  removeSettings,
  // WHITEBOARD SETTINGS
  setWhiteboardSettings,
  getWhiteboardSettings,
  removeWhiteboardSettings,
  // WHITEBOARD NEWS
  setWhiteboardNews,
  getWhiteboardNews,
  removeWhiteboardNews
};
export default Storage;

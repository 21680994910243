import React, { ForwardedRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

import { useEventListenersRef } from "@kalyzee/kast-app-web-components";

import { ReactComponent as IconChevron } from "../assets/icons/chevron.svg";
import { ReactComponent as IconCamera } from "../assets/icons/icon-camera-enable.svg";

import MeetingSourceElement, { MeetingSource, MeetingSourceData } from "./MeetingSource";
import styles from "./MeetingSources.module.css";
import { BACKGROUND_COMPONENT_CLASSNAME } from "../helpers/styles";

const DEFAULT_IS_OPEN = true;

export interface MediaSourceVideoExtra {
  style?: object;
  [key: string]: any;
}

export interface MeetingSourceEventListenerMap {
  media: (track: MediaStreamTrack | undefined, source: MeetingSourceData) => void;
  source: (source: MeetingSourceData) => void;
  sources: (sources: MeetingSourceData[], current?: MeetingSourceData) => void;
  open: (value: boolean) => void;
  player: <T extends "audio" | "video">(type: T, playerRef: MeetingSourceData[T]["player"], source: MeetingSourceData) => void;
}

export interface MeetingSourcesProps {
  sources: MeetingSource[];
  defaultIsOpen?: boolean;
  displayVideoStats?: boolean;
  onMedia?: (track: MediaStreamTrack | undefined, source: MeetingSourceData) => void;
  onSource?: (source: MeetingSourceData) => void;
  onSources?: (sources: MeetingSourceData[], current?: MeetingSourceData) => void;
  onPlayer?: <T extends "audio" | "video">(type: T, playerRef: MeetingSourceData[T]["player"], source: MeetingSourceData) => void;
  onOpen?: (value: boolean) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface MeetingSourcesRef {
  setSource: (source: MeetingSource) => boolean;
  getMedia: () => MediaStream | undefined;
  getSource: () => MeetingSourceData | undefined;
  getSources: () => MeetingSourceData[];
  open: () => void;
  close: () => void;
  addEventListener: <T extends keyof MeetingSourceEventListenerMap>(event: T, listener: MeetingSourceEventListenerMap[T]) => void;
  removeEventListener: <T extends keyof MeetingSourceEventListenerMap>(event: T, listener: MeetingSourceEventListenerMap[T]) => void;
}

const MeetingSources = React.forwardRef(
  (
    { sources, displayVideoStats, defaultIsOpen, onMedia, onSource, onSources, onPlayer, onOpen, className, style }: MeetingSourcesProps,
    forwardRef: ForwardedRef<MeetingSourcesRef | undefined>
  ) => {
    const listenersRef = useEventListenersRef<keyof MeetingSourceEventListenerMap, MeetingSourceEventListenerMap>();
    const currentSourceDataRef = useRef<MeetingSourceData>();
    const [currentSourcesDataRef] = useState<{ current: Map<MeetingSource, MeetingSourceData> }>({ current: new Map<MeetingSource, MeetingSourceData>() });
    const [isOpened, setIsOpened] = useState(defaultIsOpen ?? DEFAULT_IS_OPEN);
    const [selected, setSelected] = useState(0);
    const [used, setUsed] = useState(0);

    const classes: string[] = [styles.container];
    if (className) classes.push(className);

    useImperativeHandle(
      forwardRef,
      () => ({
        setSource: (source: MeetingSource): boolean => {
          if (currentSourceDataRef.current?.source === source) return true;
          const index = sources.findIndex((s) => s === source);
          if (index < 0) return false;
          setSelected(index);
          setUsed(index);
          return true;
        },
        getMedia: () => currentSourceDataRef.current?.media,
        getSource: () => currentSourceDataRef.current,
        getSources: () => Array.from(currentSourcesDataRef.current.values()),
        open: () => setIsOpened(true),
        close: () => setIsOpened(false),
        addEventListener: (...args) => listenersRef.current?.addEventListener(...args),
        removeEventListener: (...args) => listenersRef.current?.removeEventListener(...args),
      }),
      [listenersRef, sources, selected]
    );

    // ----------------------------------------------- //
    // ------------------ EVENTS -------------------- //
    // ----------------------------------------------- //

    const triggerOnSource = useCallback(
      (source: MeetingSourceData) => {
        // console.log('TRIGGER ON SOURCE : ', source, source.video.device?.type, source.audio.device?.type);
        if (onSource) onSource(source);
        listenersRef.current?.triggerEvent("source", source);
      },
      [listenersRef, onSource]
    );

    const triggerOnSources = useCallback(
      () => {
        const curr = Array.from(currentSourcesDataRef.current.values());        
        if (onSources) onSources(curr, currentSourceDataRef.current);
        listenersRef.current?.triggerEvent("sources", curr, currentSourceDataRef.current);
      },
      [listenersRef, onSources, currentSourcesDataRef]
    );

    const triggerOnMedia = useCallback(
      (track: MediaStreamTrack | undefined, source: MeetingSourceData) => {
        // console.log('TRIGGER ON MEDIA : ', source);
        if (onMedia) onMedia(track, source);
        listenersRef.current?.triggerEvent("media", track, source);
      },
      [listenersRef, onMedia]
    );

    const triggerOnPlayerRef = useCallback(
      <T extends "audio" | "video">(type: T, playerRef: MeetingSourceData[T]["player"], source: MeetingSourceData) => {
        // console.log('TRIGGER ON PLAYER : ', type, playerRef, source);
        if (onPlayer) onPlayer(type, playerRef, source);
        listenersRef.current?.triggerEvent("player", type, playerRef, source);
      },
      [listenersRef, onPlayer]
    );

    const triggerOnOpenRef = useCallback(
      (value: boolean) => {
        // console.log('TRIGGER ON OPEN : ', value);
        if (onOpen) onOpen(value);
        listenersRef.current?.triggerEvent("open", value);
      },
      [listenersRef, onOpen]
    );

    // ----------------------------------------------- //
    // ------------------ EFFETCS -------------------- //
    // ----------------------------------------------- //

    useEffect(() => {
      const eventListener = listenersRef.current;
      return () => eventListener?.removeAllEventListener();
    }, [listenersRef]);

    useEffect(() => {
      triggerOnOpenRef(isOpened);
    }, [isOpened]);

    // ----------------------------------------------- //
    // ------------------ RENDER -------------------- //
    // ----------------------------------------------- //

    const renderSource = (source: MeetingSource, index: number) => {
      const isSelected = index === selected;
      const isUsed = index === used;
      const switchSource = () => {
        // Source is hidden => change the source
        if (index > 0) setUsed(index - 1);
        else if (index < sources.length - 1) setUsed(index + 1);
      };
      return (
        <MeetingSourceElement
          source={source}
          using={isUsed}
          displayVideoStats={displayVideoStats}
          key={`source_${index}`}
          onHide={(data) => {
            if (data.hidden) switchSource();
            else if (isSelected) setUsed(index);
          }}
          onUpdate={(data: MeetingSourceData) => {
            currentSourcesDataRef.current.set(source, data);
            triggerOnSources();
            if (data.using) {
              if (data.hidden) switchSource();
              currentSourceDataRef.current = { ...data };
              triggerOnSource(currentSourceDataRef.current);
            }
          }}
          onMedia={(track: MediaStreamTrack | undefined, data: MeetingSourceData) => {
            if (data.using) {
              triggerOnMedia(track, data);
            }
          }}
          onPlayer={<T extends "audio" | "video">(type: T, playerRef: MeetingSourceData[T]["player"], data: MeetingSourceData) => {
            if (data.using) triggerOnPlayerRef(type, playerRef, data);
          }}
          onSelect={(data: MeetingSourceData) => {
            setSelected(index);
            if (data.hidden) switchSource();
            else setUsed(index);
          }}
          onDestroy={() => {
            currentSourcesDataRef.current.delete(source);
            triggerOnSources();
          }}
        />
      );
    };

    const renderSources = () => {
      const sourcesSorted = sources.sort((s1, s2) => {
        return (s2.priority ?? 0) - (s1.priority ?? 0);
      });
      return (
        <div className={styles.sourcesContent}>{sourcesSorted.map((s, i) => renderSource(s, i))}</div>
      );
    };

    const contentClasses = [styles.content, BACKGROUND_COMPONENT_CLASSNAME];
    if (!isOpened) contentClasses.push(styles.closed);
    return (
      <div className={classes.join(" ")} style={style}>
        <div className={contentClasses.join(' ')}>
          <div className={styles.header}>Vos caméras</div>
          {renderSources()}
        </div>
        <div className={[styles.selector, BACKGROUND_COMPONENT_CLASSNAME].join(' ')} onClick={() => setIsOpened(!isOpened)}>
          {isOpened ? (
            <IconChevron className={styles.iconIsOpened} />
          ) : (
            <div className={styles.iconContainer}>
              <IconChevron className={styles.iconIsClosed} />
              <IconCamera className={styles.iconCamera} />
            </div>
          )}
        </div>
      </div>
    );
  }
);

MeetingSources.defaultProps = {
  defaultIsOpen: DEFAULT_IS_OPEN,
  displayVideoStats: false,
  className: undefined,
  style: undefined,
};

export default MeetingSources;

import { DeviceMoveDirection, DeviceScene, DeviceView, DeviceZoomDirection } from "@kalyzee/kast-react-player-module";
import { MeetingSourceDevicePTZCustom, MeetingSourceDevicePTZCustomData } from "../components/MeetingSource";
import { PTZPlayerOverlayAdapter } from "../components/PTZPlayerOverlay";
import { KastWebRTCSession } from "./kastWebRTCSession";

// ------------------------------------------------------------ //
// --------------------------  CUSTOM --------------------------- //
// ------------------------------------------------------------ //

export interface CustomPTZPlayerOverlayAdapterOptions {
  custom?: MeetingSourceDevicePTZCustom;
}

export class CustomPTZPlayerOverlayAdapter extends PTZPlayerOverlayAdapter {
  onMove?: (direction: "left" | "right" | "up" | "down") => void;
  onStopMove?: (direction: "any") => void;
  onZoom?: (zoom: "in" | "out") => void;
  onStopZoom?: (zoom: "any") => void;
  onSetPreset?: (index: number) => void;
  onLoadPreset?: (index: number) => void;
  onScene?: (index: number) => void;

  constructor(protected options: CustomPTZPlayerOverlayAdapterOptions) {
    super();
    const enableMove = this.options.custom?.move?.down || this.options.custom?.move?.up || this.options.custom?.move?.right || this.options.custom?.move?.left;
    const enableZoom = this.options.custom?.zoom?.in || this.options.custom?.zoom?.out;
    const enablePreset = this.options.custom?.setPreset?.[1] || this.options.custom?.setPreset?.[2] || this.options.custom?.setPreset?.[3];
    const enableScene =
      this.options.custom?.scene?.[1] || this.options.custom?.scene?.[2] || this.options.custom?.scene?.[3] || this.options.custom?.scene?.[4];

    if (enableMove) {
      this.onMove = (direction: "left" | "right" | "up" | "down") => {
        this.onMoveCustom(direction);
      };
      this.onStopMove = (direction: "any") => {
        this.onStopMoveCustom(direction);
      };
    }
    if (enableZoom) {
      this.onZoom = (zoom: "in" | "out") => {
        this.onZoomCustom(zoom);
      };
      this.onStopZoom = (zoom: "any") => {
        this.onStopZoomCustom(zoom);
      };
    }
    if (enablePreset) {
      this.onSetPreset = (index: number) => {
        this.onSetPresetCustom(index);
      };
      this.onLoadPreset = (index: number) => {
        this.onLoadPresetCustom(index);
      };
    }
    if (enableScene) {
      this.onScene = (index: number) => {
        this.onSceneCustom(index);
      };
    }
  }

  private async executeCustomCommand<T extends keyof MeetingSourceDevicePTZCustom>(action: T, params: keyof MeetingSourceDevicePTZCustom[T]): Promise<boolean> {
    const data: MeetingSourceDevicePTZCustomData | undefined = this.options.custom?.[action]?.[params] as any;
    if (!data) return false;
    await fetch(data.url, data.init);
    return true;
  }

  onMoveCustom = async (direction: "left" | "right" | "up" | "down"): Promise<boolean> => {
    return this.executeCustomCommand("move", direction);
  };
  onStopMoveCustom = async (direction: "any"): Promise<boolean> => {
    if (direction !== "any") return false;
    const values = await Promise.all([
      this.executeCustomCommand("stopMove", "left"),
      this.executeCustomCommand("stopMove", "right"),
      this.executeCustomCommand("stopMove", "up"),
      this.executeCustomCommand("stopMove", "down"),
    ]);
    return values[0] || values[1] || values[2] || values[3];
  };
  onZoomCustom = async (zoom: "in" | "out"): Promise<boolean> => {
    return this.executeCustomCommand("zoom", zoom);
  };
  onStopZoomCustom = async (zoom: "any"): Promise<boolean> => {
    if (zoom !== "any") return false;
    const values = await Promise.all([this.executeCustomCommand("stopZoom", "in"), this.executeCustomCommand("stopZoom", "out")]);
    return values[0] || values[1];
  };
  onSetPresetCustom = async (index: number): Promise<boolean> => {
    return this.executeCustomCommand("setPreset", index as any);
  };
  onLoadPresetCustom = async (index: number): Promise<boolean> => {
    return this.executeCustomCommand("loadPreset", index as any);
  };
  onSceneCustom = async (index: number): Promise<boolean> => {
    return this.executeCustomCommand("scene", index as any);
  };

  destroy() {}
}

// ------------------------------------------------------------ //
// --------------------------  AVER --------------------------- //
// ------------------------------------------------------------ //

export interface AverPTZPlayerOverlayAdapterOptions extends CustomPTZPlayerOverlayAdapterOptions {
  hostname: string;
  username?: string;
  password?: string;
}

export class AverPTZPlayerOverlayAdapter extends CustomPTZPlayerOverlayAdapter {
  private static MOVE_COMMANDS = {
    up: "1,0,1",
    down: "1,1,1",
    left: "0,1,1",
    right: "0,0,1",
  } as const;
  private static STOP_MOVE_COMMANDS = {
    up: "1,0,2",
    down: "1,1,2",
    left: "0,1,2",
    right: "0,0,2",
  } as const;
  private static ZOOM_COMMANDS = {
    in: "2,0,1",
    out: "2,1,1",
  } as const;
  private static STOP_ZOOM_COMMANDS = {
    in: "2,0,2",
    out: "2,1,2",
  } as const;

  private baseUrl: string;
  private requestInit: RequestInit;

  constructor(protected options: AverPTZPlayerOverlayAdapterOptions) {
    super(options);
    this.baseUrl = `http://${this.options.hostname}/cgi-bin`;
    this.requestInit = {};
    if (options.username && options.password) {
      this.requestInit.headers = new Headers({
        Authorization: `Basic ${btoa(options.username + ":" + options.password)}`,
      });
    }

    this.onMove = async (direction: "left" | "right" | "up" | "down"): Promise<void> => {
      if (await this.onMoveCustom(direction)) return;
      fetch(`${this.baseUrl}?SetPtzf=${AverPTZPlayerOverlayAdapter.MOVE_COMMANDS[direction]}`, this.requestInit);
    };
    this.onStopMove = async (direction: "any"): Promise<void> => {
      if (await this.onStopMoveCustom(direction)) return;
      await Promise.all([
        fetch(`${this.baseUrl}?SetPtzf=${AverPTZPlayerOverlayAdapter.STOP_MOVE_COMMANDS["left"]}`, this.requestInit),
        fetch(`${this.baseUrl}?SetPtzf=${AverPTZPlayerOverlayAdapter.STOP_MOVE_COMMANDS["right"]}`, this.requestInit),
        fetch(`${this.baseUrl}?SetPtzf=${AverPTZPlayerOverlayAdapter.STOP_MOVE_COMMANDS["up"]}`, this.requestInit),
        fetch(`${this.baseUrl}?SetPtzf=${AverPTZPlayerOverlayAdapter.STOP_MOVE_COMMANDS["down"]}`, this.requestInit),
      ]);
    };
    this.onZoom = async (zoom: "in" | "out"): Promise<void> => {
      if (await this.onZoomCustom(zoom)) return;
      fetch(`${this.baseUrl}?SetPtzf=${AverPTZPlayerOverlayAdapter.ZOOM_COMMANDS[zoom]}`, this.requestInit);
    };
    this.onStopZoom = async (zoom: "any"): Promise<void> => {
      if (await this.onStopZoomCustom(zoom)) return;
      await Promise.all([
        fetch(`${this.baseUrl}?SetPtzf=${AverPTZPlayerOverlayAdapter.STOP_ZOOM_COMMANDS["in"]}`, this.requestInit),
        fetch(`${this.baseUrl}?SetPtzf=${AverPTZPlayerOverlayAdapter.STOP_ZOOM_COMMANDS["out"]}`, this.requestInit),
      ]);
    };
    this.onSetPreset = async (index: number): Promise<void> => {
      if (await this.onSetPresetCustom(index)) return;
      fetch(`${this.baseUrl}?ActPreset=1,${index}`, this.requestInit);
    };
    this.onLoadPreset = async (index: number): Promise<void> => {
      if (await this.onLoadPresetCustom(index)) return;
      fetch(`${this.baseUrl}?ActPreset=0,${index}`, this.requestInit);
    };
  }
}

// ------------------------------------------------------------ //
// --------------------------  KAST --------------------------- //
// ------------------------------------------------------------ //

export interface KastPTZPlayerOverlayAdapterOptions extends CustomPTZPlayerOverlayAdapterOptions {}

export class KastPTZPlayerOverlayAdapter extends CustomPTZPlayerOverlayAdapter {
  private context: any;

  constructor(private kastWebRTCSession: KastWebRTCSession, protected options: KastPTZPlayerOverlayAdapterOptions) {
    super(options);
    this.updateContext(kastWebRTCSession.getContext());
    kastWebRTCSession.addEventListener("message", (action: string, params: any) => {
      if (action === "context/updated") {
        this.updateContext(kastWebRTCSession.getContext());
      }
    });
    this.onMove = async (direction: "left" | "right" | "up" | "down"): Promise<void> => {
      if (await this.onMoveCustom(direction)) return;
      const mapping = {
        up: DeviceMoveDirection.UP,
        down: DeviceMoveDirection.DOWN,
        right: DeviceMoveDirection.RIGHT,
        left: DeviceMoveDirection.LEFT,
      };
      this.kastWebRTCSession.move(mapping[direction]);
    };
    this.onStopMove = async (direction: "any"): Promise<void> => {
      if (await this.onStopMoveCustom(direction)) return;
      if (direction !== "any") return;
      this.kastWebRTCSession.stopMove();
    };
    this.onZoom = async (zoom: "in" | "out"): Promise<void> => {
      if (await this.onZoomCustom(zoom)) return;
      const mapping = {
        in: DeviceZoomDirection.ZOOM_IN,
        out: DeviceZoomDirection.ZOOM_OUT,
      };
      this.kastWebRTCSession.zoom(mapping[zoom]);
    };
    this.onStopZoom = async (zoom: "any"): Promise<void> => {
      if (await this.onStopZoomCustom(zoom)) return;
      if (zoom !== "any") return;
      this.kastWebRTCSession.stopZoom();
    };
    this.onSetPreset = async (index: number): Promise<void> => {
      if (await this.onSetPresetCustom(index)) return;
      const mapping: { [key in number]: DeviceView | undefined } = {
        1: DeviceView.VIEW_1,
        2: DeviceView.VIEW_2,
        3: DeviceView.VIEW_3,
      };
      const view = mapping[index];
      if (!view) return;
      this.kastWebRTCSession.setView(view);
    };
    this.onLoadPreset = async (index: number): Promise<void> => {
      if (await this.onLoadPresetCustom(index)) return;
      const mapping: { [key in number]: DeviceView | undefined } = {
        1: DeviceView.VIEW_1,
        2: DeviceView.VIEW_2,
        3: DeviceView.VIEW_3,
      };
      const view = mapping[index];
      if (!view) return;
      this.kastWebRTCSession.assignView(view);
    };
    this.onScene = async (index: number): Promise<void> => {
      if (await this.onSceneCustom(index)) return;
      const mapping: { [key in number]: DeviceScene | undefined } = {
        1: DeviceScene.MIXED,
        2: DeviceScene.CAMERA_ONLY,
        3: DeviceScene.INPUT_HDMI_ONLY,
        4: DeviceScene.HALF_MODE,
      };
      const scene = mapping[index];
      if (scene === undefined) return;
      this.kastWebRTCSession.setScene(scene);
    };
  }

  private updateContext(context: any) {
    this.context = context;

    const sceneId = this.context?.mixer?.scene_id;
    if (sceneId !== undefined) {
      this.setScene(sceneId);
      // security
      setTimeout(() => {
        this.setScene(sceneId);
      }, 500);
    }
  }

  public getContext() {
    return this.context;
  }
}

import { DeviceMoveDirection, DeviceScene, DeviceView, DeviceZoomDirection } from "@kalyzee/kast-react-player-module";
import { RTCStreamSession } from "@kalyzee/kast-webrtc-client-module";
import { logger } from "./logger";

export interface KastWebRTCSessionListenerMap {
  connect: () => void;
  disconnect: () => void;
  stream: (media: MediaStream) => void;
  message: (action: string, params?: any) => void;
};

export class KastWebRTCSession {
  private listeners: { [key in keyof KastWebRTCSessionListenerMap]?: Array<KastWebRTCSessionListenerMap[key]> } = {};
  readonly ip: string;
  readonly rtcSession: RTCStreamSession;
  readonly websocket: WebSocket;
  private promiseMedia: Promise<MediaStream | undefined> | undefined;
  private media: MediaStream | undefined;
  private context: any;
  constructor(ip: string) {
    const websocket = new WebSocket(ip);
    const rtcSession = new RTCStreamSession();
    rtcSession.start([]).then(() => {
      /*const peerConnection = rtcSession.rtcPeerConnection;
      const events = [
        "connectionstatechange",
        "datachannel",
        "icecandidate",
        "icecandidateerror",
        "iceconnectionstatechange",
        "icegatheringstatechange",
        "negotiationneeded",
        "signalingstatechange",
        "track",
      ];
      events.forEach((e) => {
        peerConnection?.addEventListener(e, (...args) => {
          console.log('[KAST - ', e,'] : ', ...args);
        });
      });*/
    });
  
    const send = (data: object) => {
      websocket.send(JSON.stringify(data));
    };
  
    rtcSession.addEventListener('sdp', (sdp) => {
      send({
        action: 'video/send_sdp_answer',
        params: sdp
      });
    });
    rtcSession.addEventListener('iceCandidate', (candidate) => {
      send({
        action: 'video/send_ice_candidate',
        params: candidate
      });
    })

    this.promiseMedia = new Promise((resolve, reject) => {
      websocket.onclose = (ev: Event) => {
        // console.error('On close :', ev);
        this.disconnected();
        reject('Connexion lost');
      };

      websocket.onerror = (ev: Event) => {
        // console.error('On error :', ev)
        reject('Connexion error');
      };

      rtcSession.addEventListener('close', () => {
        // console.error('On rtc close');
        this.disconnected();
        reject('RTC Connexion lost');
      })

      rtcSession.addEventListener('error', () => {
        // console.error('On rtc error');
        this.disconnected();
        reject('RTC Connexion error');
      })

      rtcSession.addEventListener('stream', (stream) => {
        // console.log('On stream : ', stream);
        this.media = stream;
        this.listeners.stream?.forEach((listener) => listener(stream));
        resolve(stream);
      })
    })

  
    websocket.onmessage = async (ev: MessageEvent) => {
      // console.log('Ev : ', ev);
      let message : any | undefined = undefined;
      try {
        message = JSON.parse(ev.data);
      } catch (err) {
        message = undefined;
      }
      if (!message) return;
      this.listeners.message?.forEach((listener) => listener(message.action, message.params));
      if (message.action === 'video/sdp_offer') {
        rtcSession.processRemoteSdp(message.params);
      }
      if (message.action === 'video/ice_candidate') {
        rtcSession.processRemoteIceCandidate(message.params);
      }
      if (message.action === 'context/updated') {
        this.context = message.params;
      }
    };
    websocket.onopen = (ev: Event) => {
      // console.log('On open :', ev)
      this.listeners.connect?.forEach((listener) => listener());
      send({"action":"video/start_preview","params":{}});
    };

    this.websocket = websocket;
    this.rtcSession = rtcSession;
    this.ip = ip;
  }

  getPromiseMedia() : Promise<MediaStream | undefined> | undefined {
    return this.promiseMedia;
  }

  getMedia() : MediaStream | undefined {
    return this.media;
  }

  getContext() : any | undefined {
    return this.context;
  }

  private disconnected() {
    logger.log('Kast - disconnect');
    this.listeners.disconnect?.forEach((listener) => listener());
    this.destroy();
  }

  destroy() {
    logger.log('KAST - destroy');
    this.removeAllEventListeners();
    if (this.rtcSession) this.rtcSession.destroy();
    if (this.websocket) this.websocket.close();
  }

  sendCommand(action: string, params?: object) : void {
    if (!this.websocket) return;
    this.websocket.send(JSON.stringify({ action, params: params ?? {} }));
  }

  // ---------------- Listener ------------------ //

  public addEventListener<T extends keyof KastWebRTCSessionListenerMap>(
    event: T,
    listener: KastWebRTCSessionListenerMap[T],
  ): void {
    if (!this.listeners[event]) this.listeners[event] = [];
    this.listeners[event]!.push(listener as any);
  }

  public removeEventListener<T extends keyof KastWebRTCSessionListenerMap>(
    event: T,
    listener: KastWebRTCSessionListenerMap[T],
  ): void {
    if (!this.listeners[event]) return;
    this.listeners[event] = (this.listeners[event] as any).filter((curr: any) => curr !== listener) as any;
  }

  public removeAllEventListeners(): void {
    this.listeners = {};
  }

  // ---------------- Commands ------------------ //

  move(move: DeviceMoveDirection): void {
    const mapping = {
      [DeviceMoveDirection.UP]: "camera/start_up",
      [DeviceMoveDirection.DOWN]: "camera/start_down",
      [DeviceMoveDirection.LEFT]: "camera/start_left",
      [DeviceMoveDirection.RIGHT]: "camera/start_right",
    };
    this.sendCommand(mapping[move]);
  }

  stopMove(): void {
    this.sendCommand("camera/stop");
  }

  zoom(zoom: DeviceZoomDirection): void {
    const mapping = {
      [DeviceZoomDirection.ZOOM_IN]: "camera/zoom_in",
      [DeviceZoomDirection.ZOOM_OUT]: "camera/zoom_out",
    };
    this.sendCommand(mapping[zoom]);
  }

  stopZoom(): void {
    this.sendCommand("camera/zoom_stop");
  }

  setScene(scene: DeviceScene): void {
    this.sendCommand("mixer/switch_scene", {
      scene_id: scene,
    });
  }

  setView(view: DeviceView): void {
    const mapping = {
      [DeviceView.VIEW_1]: "camera/go_to_speaker_view",
      [DeviceView.VIEW_2]: "camera/go_to_medium_view",
      [DeviceView.VIEW_3]: "camera/go_to_full_view",
    };
    this.sendCommand(mapping[view]);
  }

  assignView(view: DeviceView): void {
    const mapping = {
      [DeviceView.VIEW_1]: "camera/set_speaker_view",
      [DeviceView.VIEW_2]: "camera/set_medium_view",
      [DeviceView.VIEW_3]: "camera/set_full_view",
    };
    this.sendCommand(mapping[view]);
  }
}
import { OverlayContainer, PopupContainer, init as initKastAppWebComponents } from '@kalyzee/kast-app-web-components';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import setupStore from './store/store';
import "@kalyzee/kast-app-web-components/index.css";
import { init as initKastReactPlayerModule } from '@kalyzee/kast-react-player-module';
import { Debug } from './helpers/debug';
import { loadSettings } from './helpers/settings';

initKastAppWebComponents();
initKastReactPlayerModule();

Debug.init(true);

const settings = loadSettings();
if (typeof settings.extra?.debug === 'object') {
  const debugData = Debug.getData();
  if (debugData) Object.assign(debugData, settings.extra.debug);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const store = setupStore();

// FOR DEBUG
// EN -> https://en.reactjs.org/docs/strict-mode.html
// FR -> https://fr.reactjs.org/docs/strict-mode.html
const ENABLE_STRICT_MODE = false;
const renderApp = () => (
  <Provider store={store}>
    <ToastContainer
      position="top-right"
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
    />
    <PopupContainer />
    <OverlayContainer />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

root.render(
  <Suspense fallback>
    {
      ENABLE_STRICT_MODE ? (
        <React.StrictMode>
          {renderApp()}
        </React.StrictMode>
      ) : (
        renderApp()
      )
    }
  </Suspense>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

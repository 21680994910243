import { UserOutputDTO } from "@kalyzee/kast-app-module";
import { awaitWithMinimumDelay, ChatAdapter, ChatMessageData, ChatReaction, ChatReactionType, ChatRef, ChatUser } from "@kalyzee/kast-app-web-components";
import { Meeting, Message, MessageAuthor, MessageReaction, MessageReactionType, ResultEmittedMessage } from "@kalyzee/kast-websocket-module";
import {
  MeetingMessageCreateResult,
  MeetingMessageDeleteResult,
  MeetingMessageGetResult,
  MeetingMessageOnCreateEvent,
  MeetingMessageOnDeleteEvent,
  MeetingMessageOnReactionEvent,
  MeetingMessageOnUpdateEvent,
  MeetingMessageReactionResult,
  MessageReactionStatus,
} from "@kalyzee/kast-websocket-module";
import { useEffect, useState } from "react";
import { ChatButtonRef } from "../components/ChatButton";
import { Required } from "../interfaces/utils";
import { ActionState } from "../store/action";
import socketMeetingActions from "../store/meeting/actions";
import { useUser } from "../store/session/hooks";
import { useSocketAppDispatch } from "./app";
import { useReduxAction } from "./store";
import SoundMessage from "../assets/sounds/message.mp3";


const REACTION_TYPE_MAPPING: [MessageReactionType, ChatReactionType][] = [
  [MessageReactionType.thumbsUp, ChatReactionType.THUMBS_UP],
  [MessageReactionType.thumbsDown, ChatReactionType.THUMBS_DOWN],
  [MessageReactionType.raisedHand, ChatReactionType.RAISED_HAND],
  [MessageReactionType.clap, ChatReactionType.CLAP],
];

export interface CustomChatAdpaterOptions {
  soundEffect?: boolean;
}
export const DEFAULT_CUSTOM_CHAT_ADAPTER_OPTIONS: Required<CustomChatAdpaterOptions> = {
  soundEffect: true,
} as const;

type SocketDispatchType = <T extends ResultEmittedMessage<any, any>, P = any>(action: { payload: P; type: string }) => Promise<T>;
export class CustomChatAdpater implements ChatAdapter {
  private socketDispatch: SocketDispatchType | undefined;
  public meetingId: string | undefined;
  public chatroomId: string | undefined;
  public user: UserOutputDTO | undefined;

  public onConvertUserMiddleware?: (src: MessageAuthor, result: ChatUser) => ChatUser;
  public onConvertReactionMiddleware?: (src: MessageReaction, result: ChatReaction) => ChatReaction;
  public onConvertMessageMiddleware?: (src: Message, result: ChatMessageData) => ChatMessageData;

  public chatRef: React.RefObject<ChatRef | undefined> | undefined;
  public chatButtonRef: React.RefObject<ChatButtonRef | undefined> | undefined;

  public unreadMessages: number = 0;

  public options?: CustomChatAdpaterOptions;

  public soundEffect?: HTMLAudioElement;

  constructor(options?: CustomChatAdpaterOptions) {
    this.options = options;
  }

  public setSocketDispatch(socketDispatch?: SocketDispatchType) {
    this.socketDispatch = socketDispatch;
  }

  public convertUser(author: MessageAuthor): ChatUser {
    const result: ChatUser = {
      id: author.id,
      username: author.username,
      avatar: author.avatar,
      metadata: {
        isMe: this.user?.id === author.id,
      },
    };
    return this.onConvertUserMiddleware?.(author, result) ?? result;
  }

  public convertReaction(reaction: MessageReaction): ChatReaction | undefined {
    const type = REACTION_TYPE_MAPPING.find(([m, c]) => m === reaction.type)?.[1];
    if (!type) return undefined;
    const result: ChatReaction = {
      id: reaction.id,
      user: this.convertUser(reaction.author),
      type,
    };
    return this.onConvertReactionMiddleware?.(reaction, result) ?? result;
  }

  public convertMessage(message: Message): ChatMessageData {
    const user = this.convertUser(message.author);
    const result: ChatMessageData = {
      id: message.id,
      status: message.status,
      user,
      message: message.content ?? "",
      date: new Date(message.datetime),
      reactions: message.reactions?.map((r) => this.convertReaction(r)).filter((r) => r) as ChatReaction[],
      metadata: {
        enableToolbar: user.metadata?.isMe,
      },
    };
    return this.onConvertMessageMiddleware?.(message, result) ?? result;
  }

  public convertMessages(messages: Message[]): ChatMessageData[] {
    const result = messages.map((m) => this.convertMessage(m));
    return result;
  }

  async onLoadMessages(options: { skip: number; beforeDate?: Date | undefined }): Promise<boolean> {
    if (!this.socketDispatch || !this.meetingId || !this.chatroomId) return false;
    const result = await awaitWithMinimumDelay(
      this.socketDispatch<MeetingMessageGetResult>(
        socketMeetingActions.getMessages({
          data: {
            meetingId: this.meetingId,
            chatroomId: this.chatroomId,
            options,
          },
        })
      ),
      500
    );
    if (result.error || !result.response) return false;
    const response = result.response;
    const messages = this.convertMessages(response.messages);
    this.chatRef?.current?.addMessages(messages);

    if (messages.length < response.limit || messages.length === 0) {
      this.chatRef?.current?.hasMoreMessages(false);
    }
    return true;
  }

  async onSendMessage(text: string): Promise<boolean> {
    if (!this.socketDispatch || !this.meetingId || !this.chatroomId) return false;
    const result = await this.socketDispatch<MeetingMessageCreateResult>(
      socketMeetingActions.createMessage({
        data: {
          meetingId: this.meetingId,
          chatroomId: this.chatroomId,
          content: text,
        },
      })
    );
    if (result.error || !result.response) return false;
    const message = this.convertMessage(result.response.message);
    this.chatRef?.current?.addMessage(message);
    return true;
  }
  async onAddReaction(msg: ChatMessageData, reaction: ChatReactionType): Promise<boolean> {
    if (!this.socketDispatch || !this.meetingId || !this.chatroomId) return false;
    const type = REACTION_TYPE_MAPPING.find(([m, c]) => c === reaction)?.[0];
    if (!type) return false;
    const result = await this.socketDispatch<MeetingMessageReactionResult>(
      socketMeetingActions.setMessageReaction({
        data: {
          meetingId: this.meetingId,
          chatroomId: this.chatroomId,
          messageId: msg.id,
          status: MessageReactionStatus.enabled,
          reaction: type,
        },
      })
    );
    if (result.error || !result.response) return false;
    const message = this.convertMessage(result.response.message);
    this.chatRef?.current?.updateMessage(message);
    return true;
  }
  async onRemoveReaction(msg: ChatMessageData, reaction: ChatReaction): Promise<boolean> {
    if (!this.socketDispatch || !this.meetingId || !this.chatroomId) return false;
    const type = REACTION_TYPE_MAPPING.find(([m, c]) => c === reaction.type)?.[0];
    if (!type) return false;
    const result = await this.socketDispatch<MeetingMessageReactionResult>(
      socketMeetingActions.setMessageReaction({
        data: {
          meetingId: this.meetingId,
          chatroomId: this.chatroomId,
          messageId: msg.id,
          status: MessageReactionStatus.disabled,
          reaction: type,
        },
      })
    );
    if (result.error || !result.response) return false;
    const message = this.convertMessage(result.response.message);
    this.chatRef?.current?.updateMessage(message);
    return true;
  }
  async onDeleteMessage(msg: ChatMessageData): Promise<boolean> {
    if (!this.socketDispatch || !this.meetingId || !this.chatroomId) return false;
    const result = await this.socketDispatch<MeetingMessageDeleteResult>(
      socketMeetingActions.deleteMessage({
        data: {
          meetingId: this.meetingId,
          chatroomId: this.chatroomId,
          messageId: msg.id,
        },
      })
    );
    if (result.error || !result.response) return false;
    const message = this.convertMessage(result.response.message);
    this.chatRef?.current?.updateMessage(message);
    return true;
  }
  async onUnreadMessages(msg: ChatMessageData[]): Promise<boolean> {
    this.chatButtonRef?.current?.setBadgeValue(msg.length);
    if (this.unreadMessages < msg.length) {
      if (this.options?.soundEffect ?? DEFAULT_CUSTOM_CHAT_ADAPTER_OPTIONS.soundEffect) {
        if (!this.soundEffect) {
          this.soundEffect = new Audio(SoundMessage);
        }
        try {
          this.soundEffect.play();
        } catch(_err) {}
      }
    }
    this.unreadMessages = msg.length;
    return true;
  }
}

export const useChat = (
  meeting?: Meeting,
  chatRef?: React.RefObject<ChatRef | undefined>,
  chatButtonRef?: React.RefObject<ChatButtonRef | undefined>,
  options?: CustomChatAdpaterOptions
) => {
  const socketDispatch = useSocketAppDispatch();
  const user = useUser();
  const [chatAdapterRef] = useState({
    current: (() => {
      const adapter = new CustomChatAdpater();
      adapter.chatRef = chatRef;
      adapter.chatButtonRef = chatButtonRef;
      return adapter;
    })(),
  });

  useEffect(() => {
    chatAdapterRef.current.user = user;
  }, [user]);

  useEffect(() => {
    chatAdapterRef.current.meetingId = meeting?.id;
    chatAdapterRef.current.chatroomId = meeting?.chatrooms?.length ? meeting.chatrooms[0].id : undefined;
  }, [meeting]);

  useEffect(() => {
    chatAdapterRef.current.options = options;
  }, [options]);

  useEffect(() => {
    chatAdapterRef.current.setSocketDispatch(socketDispatch);
  }, [socketDispatch]);

  useReduxAction<MeetingMessageOnCreateEvent>(
    (action: ActionState<MeetingMessageOnCreateEvent>) => {
      const payload = action.payload;
      if (chatAdapterRef.current.meetingId === payload.meetingId && chatAdapterRef.current.chatroomId === payload.chatroomId) {
        chatRef?.current?.addMessage(chatAdapterRef.current.convertMessage(payload.message));
      }
    },
    socketMeetingActions.onCreateMessage,
    []
  );

  useReduxAction<MeetingMessageOnUpdateEvent>(
    (action: ActionState<MeetingMessageOnUpdateEvent>) => {
      const payload = action.payload;
      if (chatAdapterRef.current.meetingId === payload.meetingId && chatAdapterRef.current.chatroomId === payload.chatroomId) {
        chatRef?.current?.updateMessage(chatAdapterRef.current.convertMessage(payload.message));
      }
    },
    socketMeetingActions.onUpdateMessage,
    []
  );

  useReduxAction<MeetingMessageOnDeleteEvent>(
    (action: ActionState<MeetingMessageOnDeleteEvent>) => {
      const payload = action.payload;
      if (chatAdapterRef.current.meetingId === payload.meetingId && chatAdapterRef.current.chatroomId === payload.chatroomId) {
        chatRef?.current?.updateMessage(chatAdapterRef.current.convertMessage(payload.message));
      }
    },
    socketMeetingActions.onDeleteMessage,
    []
  );

  useReduxAction<MeetingMessageOnReactionEvent>(
    (action: ActionState<MeetingMessageOnReactionEvent>) => {
      const payload = action.payload;
      if (chatAdapterRef.current.meetingId === payload.meetingId && chatAdapterRef.current.chatroomId === payload.chatroomId) {
        chatRef?.current?.updateMessage(chatAdapterRef.current.convertMessage(payload.message));
      }
    },
    socketMeetingActions.onMessageReaction,
    []
  );

  return chatAdapterRef.current;
};

import { useURL } from "@kalyzee/kast-app-web-components";
import { Navigate } from "react-router-dom";
import { NavigateGlobalQuery, NavigateSlaveQuery, getGlobalQuery, getGlobalQueryLabel, getSlaveQuery, getSlaveQueryLabel } from "../../helpers/navigate";

export interface NavigateSlaveProps {
  to?: string;
}

export const NavigateSlave = ({ to }: NavigateSlaveProps) => {
  const url = useURL();
  const searchParamsToKeep: any = {};
  const spectatorQuery = getGlobalQuery(url.href, NavigateGlobalQuery.SLAVE);
  const bridgeWesocketEndpointQuery = getSlaveQuery(url.href, NavigateSlaveQuery.BRIDGE_WEBSOCKET_ENDPOINT);
  if (spectatorQuery) searchParamsToKeep[getGlobalQueryLabel(NavigateGlobalQuery.SLAVE)] = `${spectatorQuery}`;
  if (bridgeWesocketEndpointQuery) searchParamsToKeep[getSlaveQueryLabel(NavigateSlaveQuery.BRIDGE_WEBSOCKET_ENDPOINT)] = `${bridgeWesocketEndpointQuery}`;
  return (
    <Navigate to={{
      pathname: to ?? '/',
      search: `?${new URLSearchParams(searchParamsToKeep).toString()}`,
    }} replace/>
  );
};

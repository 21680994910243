import { MediaCaptureEnableAudioButton, MediaCaptureEnableVideoButton, Touchable, useEventListenersRef } from "@kalyzee/kast-app-web-components";
import { Meeting, MeetingSession, MeetingSessionMedia } from "@kalyzee/kast-websocket-module";
import React, { createRef, ForwardedRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { ReactComponent as IconChevron } from "../assets/icons/chevron.svg";
import { isSpectator } from "../helpers/meeting";
import { isMessagingBridgeClientData, MeetingControlAction, MeetingControlDataMap, MessagingBridgeManagerMaster, MessagingBridgeManagerSlave } from "../helpers/messagingBridge";
import { MeetingSessionMediaExtra } from "../interfaces/meeting";
import { MeetingExtraData } from "../pages/meeting/meeting";
import { SessionMode } from "../store/session/slices";
import MeetingPlayerButton, { MeetingPlayerButtonRef, MeetingPlayerButtonType } from "./MeetingPlayerButton";
import styles from "./MeetingSlaveControls.module.css";
import { PageSlaveMode } from "./navigation/PageContext";

const TIMEOUT_MEETING_CONTROL_RESPONSE = 3000;

export interface MeetingSlaveControlsProps {
  bridge: MessagingBridgeManagerSlave;
  meeting: Meeting;
  session: MeetingSession;
  extraData?: MeetingExtraData;
  className?: string;
  style?: React.CSSProperties;
}

export interface MeetingSlaveControlsRef {
  setDisplay: (value: boolean) => void;
}

interface MeetingSlaveControlsEventListeners {
  meetingControlResponse: (id: string, success: boolean) => void;
}

const MeetingSlaveControls = React.forwardRef(
  ({ bridge, meeting, session, extraData, className, style }: MeetingSlaveControlsProps, forwardRef: ForwardedRef<MeetingSlaveControlsRef | undefined>) => {
    const eventListeners = useEventListenersRef<keyof MeetingSlaveControlsEventListeners, MeetingSlaveControlsEventListeners>();
    const [display, setDisplay] = useState(false);

    useImperativeHandle(forwardRef, () => ({
      setDisplay,
    }));

    const getSessions = useCallback((): MeetingSession[] => {
      if (!meeting) return [];
      let sessions = [...meeting.sessions];
      sessions = sessions.filter((s) => {
        return !isSpectator(s.role);
      });
      sessions.sort((a, b) => (b.id === session?.id ? 1 : 0) - (a.id === session?.id ? 1 : 0));
      return sessions;
    }, [meeting, session]);

    const postMeetingControlMessageAndAwaitResponse = async <T extends MeetingControlAction>(action: T, data: MeetingControlDataMap[T]): Promise<boolean> => {
      const msg = bridge?.postMeetingControl(action, data);
      if (!msg) return false;
      return new Promise<boolean>((resolve) => {
        let listener: [key: "meetingControlResponse", callback: (id: string, success: boolean) => void] | undefined;
        let timeout = setTimeout(() => {
          if (listener) eventListeners.current.removeEventListener(...listener);
          resolve(false);
        }, TIMEOUT_MEETING_CONTROL_RESPONSE);
        listener = eventListeners.current.addEventListener("meetingControlResponse", (id, success) => {
          if (id !== msg.id) return;
          clearTimeout(timeout);
          resolve(success);
          if (listener) eventListeners.current.addEventListener(...listener);
        });
      });
    };

    // ---------------------------------------------------------------- //
    // ---------------------------- EFFECTS ----------------------------- //
    // ---------------------------------------------------------------- //

    useEffect(() => {
      return () => {
        eventListeners.current.removeAllEventListener();
      };
    }, []);

    useEffect(() => {
      const meetingControlResponse = bridge.addEventListener("meetingControlResponse", (msg) => {
        const { id, success } = msg.content;
        eventListeners.current.triggerEvent("meetingControlResponse", id, success);
      });

      return () => {
        bridge.removeEventListener(...meetingControlResponse);
      };
    }, [bridge]);

    // ---------------------------------------------------------------- //
    // ---------------------------- RENDER ----------------------------- //
    // ---------------------------------------------------------------- //
    const sessions = getSessions();
    const sessionDisplayingInBackground = sessions?.find((s) => {
      const me = s.id === session?.id;
      const media: MeetingSessionMedia<MeetingSessionMediaExtra> = s.medias?.[0];
      if (media?.extra?.displayOnWhiteboard?.enabled === false) return false;
      if (me && media?.extra?.displayOnWhiteboard?.localDisplay === false) return false;
      if (!me && media?.extra?.displayOnWhiteboard?.remoteDisplay === false) return false;
      return media?.extra?.displayOnWhiteboard?.active;
    });

    const renderSession = (s: MeetingSession) => {
      const me = s.id === session?.id;
      const name = s?.user?.username ?? "Unknown";
      const media: MeetingSessionMedia<MeetingSessionMediaExtra> = s?.medias?.[0];
      const audio = media?.audio?.enabled ?? false;
      const video = media?.video?.enabled ?? false;

      const audioButtonRef = createRef<MeetingPlayerButtonRef>();
      const videoButtonRef = createRef<MeetingPlayerButtonRef>();
      const fullscreenButtonRef = createRef<MeetingPlayerButtonRef>();
      const displayOnWhiteboardButtonRef = createRef<MeetingPlayerButtonRef>();

      const renderContent = () => {
        const clients = extraData?.clients ?? {};
        const clientIds = Object.keys(clients);
        const mediaIsDisplayingByAnother = clientIds.find((id) => {
          if (id === bridge?.id) return false;
          const data = clients[id];
          if (!data) return false;
          if (isMessagingBridgeClientData(data, SessionMode.SLAVE, PageSlaveMode.SPECTATOR)) {
            const displayingMedias = data.data?.displayingMedias ?? [];
            return displayingMedias.includes(media.id);
          }
          return false;
        })
          ? true
          : false;
        const displayOnWhiteboardButton = s.medias?.find((m) => extraData?.canBeDisplayedOnWhiteboard?.includes(m.id)) && (!sessionDisplayingInBackground || sessionDisplayingInBackground.id === s.id);
        if (!media) return null;
        return (
          <>
            <MeetingPlayerButton type={MeetingPlayerButtonType.CUSTOM} ref={audioButtonRef} className={styles.videoAudioButtonContainer}>
              <MediaCaptureEnableAudioButton
                className={styles.audioButton}
                enabled={audio}
                onEnable={async (e) => {
                  if (e === audio) return;
                  const ref = audioButtonRef.current;
                  ref?.setLoading(true);
                  const success = await postMeetingControlMessageAndAwaitResponse(MeetingControlAction.MEDIA, { sessionId: s.id, mediaId: media.id, audio: e });
                  ref?.setLoading(false);
                }}
              />
            </MeetingPlayerButton>
            <MeetingPlayerButton type={MeetingPlayerButtonType.CUSTOM} ref={videoButtonRef} className={styles.videoAudioButtonContainer}>
              <MediaCaptureEnableVideoButton
                className={styles.videoButton}
                enabled={video}
                onEnable={async (e) => {
                  if (e === video) return;
                  const ref = videoButtonRef.current;
                  ref?.setLoading(true);
                  const success = await postMeetingControlMessageAndAwaitResponse(MeetingControlAction.MEDIA, { sessionId: s.id, mediaId: media.id, video: e });
                  ref?.setLoading(false);
                }}
              />
            </MeetingPlayerButton>
            {mediaIsDisplayingByAnother && !media.extra?.displayOnWhiteboard?.active ? (
              <MeetingPlayerButton
                ref={fullscreenButtonRef}
                key={"remote_fullscreen"}
                type={MeetingPlayerButtonType.FULLSCREEN}
                enabled={extraData?.fullscreenForMediaId === media.id}
                onEnabled={(e: boolean) => {
                  const ref = fullscreenButtonRef.current;
                  bridge?.postMeetingControl(MeetingControlAction.FULLSCREEN, {
                    sessionId: s.id,
                    mediaId: e ? media.id : undefined,
                  });
                }}
              />
            ) : null}
            {displayOnWhiteboardButton && extraData?.fullscreenForMediaId !== media.id ? (
              <MeetingPlayerButton
                ref={displayOnWhiteboardButtonRef}
                key={"display_whiteboard"}
                type={MeetingPlayerButtonType.DISPLAY_WHITEBOARD}
                enabled={media.extra?.displayOnWhiteboard?.active}
                onEnabled={async (e: boolean) => {
                  const ref = displayOnWhiteboardButtonRef.current;
                  ref?.setLoading(true);
                  const success = await postMeetingControlMessageAndAwaitResponse(MeetingControlAction.DISPLAY_WHITEBOARD, {
                    sessionId: s.id,
                    mediaId: media.id,
                    options: {
                      active: e
                    },
                  });
                  ref?.setLoading(false);
                }}
              />
            ) : null}
          </>
        );
      };
      const currClasses = [styles.sessionContainer];
      if (me) currClasses.push(styles.mysession);
      return (
        <div key={s.id} className={currClasses.join(" ")}>
          <div className={styles.sessionName}>{name}</div>
          <div className={styles.sessionContent}>{renderContent()}</div>
        </div>
      );
    };

    if (!sessions?.length) return null;
    const currClasses = [styles.container];
    if (!display) currClasses.push(styles.reduce);
    if (className) currClasses.push(className);
    return (
      <div className={currClasses.join(" ")}>
        <Touchable
          className={styles.displayButtonContainer}
          onPress={() => {
            setDisplay(!display);
          }}
        >
          <IconChevron className={styles.displayButton} />
        </Touchable>
        {sessions.map((s) => renderSession(s))}
      </div>
    );
  }
);

MeetingSlaveControls.defaultProps = {
  className: undefined,
  style: undefined,
};

export default MeetingSlaveControls;

import { HoverMessage, Loading, OverlayHoverMessage, PopupButtonType, PopupIconType, showPopup, Touchable, useEventListenersRef } from "@kalyzee/kast-app-web-components";
import { Meeting, MeetingSession, MeetingSessionRole } from "@kalyzee/kast-websocket-module";
import { debounce } from "lodash";
import { createRef, useCallback, useEffect, useState } from "react";
import ImgBackground from "../../assets/backgrounds/kast.png";
import { ReactComponent as IconClose } from "../../assets/icons/close.svg";
import { ReactComponent as IconViewer } from "../../assets/icons/icon-view.svg";
import MeetingHeader from "../../components/MeetingHeader";
import MeetingPlayer, { MeetingPlayerRef, MeetingPlayerRemoteButton } from "../../components/MeetingPlayer";
import MeetingPlayers, { MeetingPlayersDisplayMode } from "../../components/MeetingPlayers";
import { PageSlaveMode, usePageContextRef } from "../../components/navigation/PageContext";
import { useMessagingBridgeManagerSlaveContext } from "../../contexts/messageBridgeManager";
import { isSpectator } from "../../helpers/meeting";
import { isMessagingBridgeClientData, MeetingControlAction, MeetingControlDataMap, MessagingBridgeManagerMaster } from "../../helpers/messagingBridge";
import { DEFAULT_SETTINGS } from "../../helpers/settings";
import { BACKGROUND_COMPONENT_CLASSNAME } from "../../helpers/styles";
import { toastError, toastSuccess } from "../../helpers/toast";
import { useSettings } from "../../hooks/settings";
import { SessionMode } from "../../store/session/slices";
import { MeetingExtraData } from "../meeting/meeting";
import styles from "./controls.module.css";

export interface ControlsPageProps {
  waiting?: boolean;
}

interface ControlEventListeners {
  meetingControlResponse: (id: string, success: boolean) => void;
}

const TIMEOUT_MEETING_CONTROL_RESPONSE = 3000;

const ControlsPage = ({ waiting }: ControlsPageProps) => {
  const eventListeners = useEventListenersRef<keyof ControlEventListeners, ControlEventListeners>();
  const [meeting, setMeeting] = useState<Meeting | undefined>(undefined);
  const [session, setSession] = useState<MeetingSession | undefined>(undefined);
  const [extraData, setExtraData] = useState<MeetingExtraData>();
  const [settings] = useSettings();
  const pageContextRef = usePageContextRef();
  const bridgeMessageManager = useMessagingBridgeManagerSlaveContext();
  const id = pageContextRef.current.params?.id ?? "";
  const pageSettings = settings.slave?.pages?.controls;
  const DEFAULT_PAGE_SETTINGS = DEFAULT_SETTINGS.slave.pages.controls;


  const getSessions = useCallback((): MeetingSession[] => {
    if (!meeting) return [];
    let sessions = [...meeting.sessions];
    sessions = sessions.filter((s) => {
      return !isSpectator(s.role);
    });
    sessions.sort((a, b) => (b.id === session?.id ? 1 : 0) - (a.id === session?.id ? 1 : 0));
    return sessions;
  }, [meeting, session, settings]);

  const postMeetingControlMessageAndAwaitResponse = async <T extends MeetingControlAction>(action: T, data: MeetingControlDataMap[T]): Promise<boolean> => {
    const msg = bridgeMessageManager?.postMeetingControl(action, data);
    if (!msg) return false;
    return new Promise<boolean>((resolve) => {
      let listener: [key: "meetingControlResponse", callback: (id: string, success: boolean) => void] | undefined;
      let timeout = setTimeout(() => {
        if (listener) eventListeners.current.removeEventListener(...listener);
        resolve(false);
      }, TIMEOUT_MEETING_CONTROL_RESPONSE);
      listener = eventListeners.current.addEventListener('meetingControlResponse', (id, success) => {
        if (id !== msg.id) return;
        clearTimeout(timeout);
        resolve(success);
        if (listener) eventListeners.current.addEventListener(...listener);
      });
    });
  };

  // ---------------------------------------------------------------- //
  // ---------------------------- EFFECT ----------------------------- //
  // ---------------------------------------------------------------- //

  useEffect(() => {
    return () => {
      eventListeners.current.removeAllEventListener();
    }
  }, []);

  useEffect(() => {
    if (!bridgeMessageManager) return;

    const readyListener = bridgeMessageManager.addEventListener("ready", () => {
      if (id) bridgeMessageManager.postRequestMeeting();
      else bridgeMessageManager.postRequestMeetingId();
    });

    const joinMeetingListener = bridgeMessageManager.addEventListener("joinMeeting", (msg) => {
      const meetingId = msg.content.meetingId;
      setMeeting(undefined);
      if (meetingId !== id) pageContextRef.current.goTo?.(PageSlaveMode.CONTROLS, { id: meetingId });
    });

    const leaveMeetingListener = bridgeMessageManager.addEventListener("leaveMeeting", () => {
      setMeeting(undefined);
      pageContextRef.current.goTo?.(PageSlaveMode.CONTROLS_WAITING);
    });

    const meetingIdListener = bridgeMessageManager.addEventListener("meetingId", (msg) => {
      const meetingId = msg.content.meetingId;
      setMeeting(undefined);
      if (meetingId !== id) pageContextRef.current.goTo?.(PageSlaveMode.CONTROLS, { id: meetingId });
    });

    const meetingListener = bridgeMessageManager.addEventListener("meeting", (msg) => {
      const meeting = msg.content.meeting;
      const session = msg.content.session;
      setMeeting(meeting);
      setSession(session);
    });

    const meetingExtraDataListener = bridgeMessageManager.addEventListener("meetingExtraData", (msg) => {
      const data = msg.content.data;
      setExtraData(data);
    });

    const meetingControlResponse = bridgeMessageManager.addEventListener("meetingControlResponse", (msg) => {
      const { id, success } = msg.content;
      eventListeners.current.triggerEvent('meetingControlResponse', id, success);
    });

    if (id) bridgeMessageManager.postRequestMeeting();
    else bridgeMessageManager.postRequestMeetingId();

    return () => {
      bridgeMessageManager.removeEventListener(...readyListener);
      bridgeMessageManager.removeEventListener(...joinMeetingListener);
      bridgeMessageManager.removeEventListener(...leaveMeetingListener);
      bridgeMessageManager.removeEventListener(...meetingIdListener);
      bridgeMessageManager.removeEventListener(...meetingListener);
      bridgeMessageManager.removeEventListener(...meetingExtraDataListener);
      bridgeMessageManager.removeEventListener(...meetingControlResponse);
    };
  }, [pageContextRef, bridgeMessageManager, id]);

  const renderSession = (s: MeetingSession) => {
    const me = s.id === session?.id;
    const classes = [styles.session, BACKGROUND_COMPONENT_CLASSNAME];
    if (me) classes.push(styles.mysession);
    const user = s.user;
    const media = s.medias?.[0];
    let muted = true;
    let volume = 1;
    const audio = extraData?.audio?.[media.id];
    if (audio) {
      muted = audio.muted;
      volume = audio.volume;
    }

    const renderHeader = () => {
      const canExcludeUser = !me && extraData?.canExcludeUsers;
      const excludeButtonRef = createRef<HTMLDivElement>();
      if (!canExcludeUser) return null;
      return (
        <div className={styles.sessionHeader}>
          <div ref={excludeButtonRef}>
            <Touchable
              onPress={() => {
                /* */
                showPopup({
                  title: "Exclure du meeting",
                  iconTitle: PopupIconType.WARNING,
                  content: `Êtes-vous sûr de vouloir exclure ${user?.username} ?`,
                  buttons: [
                    { type: PopupButtonType.CANCEL, element: "Non" },
                    {
                      type: PopupButtonType.VALIDATE,
                      element: "Oui",
                      onClick: async () => {
                        postMeetingControlMessageAndAwaitResponse(MeetingControlAction.EXCLUDE, { sessionId: s.id }).then((result) => {
                          if (result) toastSuccess(`${user?.username} a été exclu du meeting.`);
                          else toastError("Une erreur est survenue.");
                        });
                        return true;
                      },
                    },
                  ],
                  enableBackdropDismiss: true,
                  enableCloseButton: true,
                });
              }}
            >
              <IconClose width={20} />
            </Touchable>
            <HoverMessage targetRef={excludeButtonRef} message={"Exclure du meeting"} />
          </div>
        </div>
      );
    };
    let ref: MeetingPlayerRef | undefined;
    const clients = extraData?.clients ?? {};
    const clientIds = Object.keys(clients);
    const mediaIsDisplayingByAnother = clientIds.find((id) => {
      if (id === bridgeMessageManager?.id) return false;
      const data = clients[id];
      if (!data) return false;
      if (isMessagingBridgeClientData(data, SessionMode.SLAVE, PageSlaveMode.SPECTATOR)) {
        const displayingMedias = data.data?.displayingMedias ?? [];
        return displayingMedias.includes(media.id);
      }
      return false;
    }) ? true : false;
    return (
      <div className={classes.join(" ")} key={s.id}>
        <div className={styles.sessionContainer}>
          {media ? (
            <MeetingPlayer
              ref={(r) => {
                ref = r ?? undefined;
              }}
              me={me}
              muted={muted}
              volume={volume}
              mode={me ? "no_stream" : "no_stream_with_volum_control"}
              reactions={s.reactions}
              reactionsEnabled={me ? meeting?.reactionsEnabled ?? [] : []}
              session={s}
              mediaSession={media}
              user={user}
              rightHeaderElement={renderHeader()}
              displayVideoStats={false}
              audioButtonEnabled={true}
              videoButtonEnabled={true}
              remoteControls={{
                fullscreen: {
                  enabled: mediaIsDisplayingByAnother,
                  value: extraData?.fullscreenForMediaId === media.id,
                },
              }}
              onRemoteButton={async (type, enabled) => {
                if (type === MeetingPlayerRemoteButton.FULLSCREEN) {
                  bridgeMessageManager?.postMeetingControl(MeetingControlAction.FULLSCREEN, {
                    sessionId: s.id,
                    mediaId: enabled ? media.id : undefined,
                  });
                }
              }}
              onVolume={debounce(
                (volume, muted) => {
                  bridgeMessageManager?.postMeetingControl(MeetingControlAction.AUDIO, { sessionId: s.id, mediaId: media.id, volume, muted });
                },
                50,
                { maxWait: 100 }
              )}
              onReaction={async (e, r, reactions) => {
                const currentRef = ref;
                currentRef?.reactionButtonLoading(true);
                const success = await postMeetingControlMessageAndAwaitResponse(MeetingControlAction.REACTIONS, {
                  sessionId: s.id,
                  mediaId: media.id,
                  reactions,
                });
                currentRef?.reactionButtonLoading(false);
              }}
              onAudioEnable={async (e) => {
                const currentRef = ref;
                currentRef?.audioButtonLoading(true);
                const success = await postMeetingControlMessageAndAwaitResponse(MeetingControlAction.MEDIA, { sessionId: s.id, mediaId: media.id, audio: e });
                currentRef?.audioButtonLoading(false);
              }}
              onVideoEnable={async (e) => {
                const currentRef = ref;
                currentRef?.videoButtonLoading(true);
                const success = await postMeetingControlMessageAndAwaitResponse(MeetingControlAction.MEDIA, { sessionId: s.id, mediaId: media.id, video: e });
                currentRef?.videoButtonLoading(false);
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const renderSources = () => {
    if (!extraData?.sources) return;
    const sources = extraData.sources.filter((s) => !s.hidden);
    if (sources.length <= 1) return;
    return (
      <div className={styles.sourcesContainer}>
        <div className={styles.sourcesTitle}>Vos caméras</div>
        <div className={styles.sourcesContent}>
          {sources.map((s) => {
            const classes = [styles.sourceButton];
            if (s.using) classes.push(styles.sourceButtonSelected);
            return (
              <Touchable
                key={s.id}
                onPressOut={() => {
                  bridgeMessageManager?.postMeetingControl(MeetingControlAction.SET_SOURCE, {
                    id: s.id
                  });
                }}
                className={classes.join(' ')}
              >
                {s.name}
              </Touchable>
            )
          })}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    // return <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>Coming soon</div>;
    if (waiting) {
      return <div className={[styles.contentMessage, styles.waiting].join(" ")}>En attente du meeting</div>;
    }
    if (!meeting) {
      return <Loading className={[styles.contentMessage, styles.loading].join(" ")} fullscreen message={"Chargement du meeting..." /* TRANSLATION */} />;
    }

    const sessions = getSessions();
    if (!sessions.length) {
      return <div className={[styles.contentMessage, styles.nobody].join(" ")}>Aucun participant...</div>;
    }

    return (
      <>
        {renderSources()}
        <MeetingPlayers
          mode={MeetingPlayersDisplayMode.ONE_MAIN_AND_THE_OTHERS}
          options={{
            main: session,
            styles: {
              main: { minHeight: "150px", minWidth: "250px", maxWidth: "600px" },
              others: { minWidth: "250px" },
            },
          }}
          sessions={sessions}
          className={styles.contentContainer}
          renderItem={(_, session) => renderSession(session)}
        />
      </>
    );
  };

  const renderHeader = () => {
    if (!meeting) return null;
    if (pageSettings?.header?.hide ?? DEFAULT_PAGE_SETTINGS.header.hide) return null;
    return (
      <MeetingHeader
        meeting={meeting}
        helpUri={pageSettings?.header?.helpUri ?? DEFAULT_PAGE_SETTINGS.header.helpUri}
        hideClock={!(pageSettings?.header?.clock?.enabled ?? DEFAULT_PAGE_SETTINGS.header.clock.enabled)}
        displaySeconds={pageSettings?.header?.clock?.seconds ?? DEFAULT_PAGE_SETTINGS.header.clock.seconds}
      />
    );
  };

  const renderViewers = () => {
    if (!meeting) return null;
    const viewSessions = [...(meeting.sessions ?? [])].filter((s) => {
      return s.role === MeetingSessionRole.SPECTATOR;
    });
    if (!viewSessions.length) return null;
    const containerRef = createRef<HTMLDivElement>();
    return (
      <div className={styles.viewerContainer}>
        <div ref={containerRef}>
          <IconViewer width={30} height={20} />
        </div>
        <OverlayHoverMessage targetRef={containerRef} message="Le nombre de spectateur" />
        {viewSessions.length}
      </div>
    );
  };

  return (
    <div className={styles.page} style={{ backgroundImage: `url(${ImgBackground})` }}>
      {renderHeader()}
      {renderViewers()}
      {renderContent()}
    </div>
  );
};

ControlsPage.defaultProps = {};

export default ControlsPage;

import { CustomCheckBox, Loading, Touchable } from "@kalyzee/kast-app-web-components";
import React, { ForwardedRef, useEffect, useImperativeHandle, useState } from "react";
import { ReactComponent as IconExitFullscreen } from "../assets/icons/icon-fullscreen-disable.svg";
import { ReactComponent as IconEnterFullscreen } from "../assets/icons/icon-fullscreen-enable.svg";
import { ReactComponent as IconDisplayWhiteboardDisable } from "../assets/icons/whiteboard-display-disable.svg";
import { ReactComponent as IconDisplayWhiteboardEnable } from "../assets/icons/whiteboard-display-enable.svg";
import styles from "./MeetingPlayerButton.module.css";

export enum MeetingPlayerButtonType {
  FULLSCREEN = "fullscreen",
  DISPLAY_WHITEBOARD = "display_whiteboard",
  CUSTOM = "custom",
}

export interface MeetingPlayerButtonDataMap {
  [MeetingPlayerButtonType.FULLSCREEN]: undefined;
  [MeetingPlayerButtonType.DISPLAY_WHITEBOARD]: { controls: boolean };
  [MeetingPlayerButtonType.CUSTOM]: undefined;
}

export interface MeetingPlayerButtonProps<T extends MeetingPlayerButtonType> {
  type: MeetingPlayerButtonType;
  enabled?: boolean;
  data?: MeetingPlayerButtonDataMap[T];
  buttonDisabled?: boolean;
  onEnabled?: (enabled: boolean) => void;
  onData?: (data: MeetingPlayerButtonDataMap[T], enabled: boolean) => void;
  loading?: boolean;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode; // if type is undefined
}

export interface MeetingPlayerButtonRef {
  setLoading: (value: boolean) => void;
}

const MeetingPlayerButtonInner = (
  { type, loading, enabled, data, buttonDisabled, onEnabled, onData, children, className, style }: MeetingPlayerButtonProps<any>,
  forwardRef: ForwardedRef<MeetingPlayerButtonRef | undefined>
) => {
  const [currLoading, setCurrLoading] = useState(loading ?? false);

  useEffect(() => {
    if (loading !== undefined) setCurrLoading(loading);
  }, [loading]);

  useImperativeHandle(forwardRef, () => ({
    setLoading: (v) => setCurrLoading(v),
  }));

  const renderButton = (content: React.ReactNode, additionnal?: React.ReactNode) => {
    const classes = [styles.buttonContainer];
    if (additionnal) classes.push(styles.buttonWithAdditionnalContent);
    if (className) classes.push(className);
    return (
      <div className={classes.join(" ")} style={style}>
        {content}
        {currLoading ? <Loading className={styles.loadingButton} /> : null}
        {currLoading ? null: additionnal}
      </div>
    );
  };

  if (type === MeetingPlayerButtonType.FULLSCREEN) {
    const value = enabled ?? false;
    const size = 20;
    return renderButton(
      <Touchable
        disabled={buttonDisabled}
        onPressIn={() => {
          onEnabled?.(!value);
        }}
      >
        <IconExitFullscreen display={!value ? "none" : "block"} width={size} height={size} />
        <IconEnterFullscreen display={value ? "none" : "block"} width={size} height={size} />
      </Touchable>
    );
  }
  if (type === MeetingPlayerButtonType.DISPLAY_WHITEBOARD) {
    const value = enabled ?? false;
    const size = 20;
    return renderButton(
      <Touchable
      disabled={buttonDisabled}
        onPressIn={() => {
          onEnabled?.(!value);
        }}
      >
        {value ? (
          <IconDisplayWhiteboardDisable width={size} height={size} />
        ) : (
          <IconDisplayWhiteboardEnable width={size} height={size} />
        )}
      </Touchable>,
      value && data ? (
        <div className={styles.additionnalDataDisplayWhiteboardContent}>
          <CustomCheckBox
            className={styles.additionnalDataDisplayWhiteboardCheckBox}
            isSelected={(data as MeetingPlayerButtonDataMap[MeetingPlayerButtonType.DISPLAY_WHITEBOARD]).controls}
            setSelected={(v) => onData?.({ controls: v }, value)}
          />
          Afficher les contrôls
        </div>
      ) : null
    );
  }

  return renderButton(children);
};

const MeetingPlayerButton = React.forwardRef(MeetingPlayerButtonInner) as <T extends MeetingPlayerButtonType>(
  props: MeetingPlayerButtonProps<T> & { ref?: React.ForwardedRef<MeetingPlayerButtonRef> }
) => ReturnType<typeof MeetingPlayerButtonInner>;

export default MeetingPlayerButton;

import { useURL } from "@kalyzee/kast-app-web-components";
import { useEffect, useState } from "react";
import { logger } from "../helpers/logger";
import { getGlobalQuery, NavigateGlobalQuery } from "../helpers/navigate";
import { loadSettings, mergeSettings, saveSettings, setSettingsAsDefault, Settings } from "../helpers/settings";

export const useSettings = (useUrlQuery: boolean = true): [settings: Settings, setSettings: (settings: Partial<Settings>) => Settings] => {
  const url = useURL();
  const [settings, updateSettings] = useState(loadSettings);

  const setSettings = (st: Partial<Settings>, save: boolean = true) => {
    const newSettings = { ...(save ? saveSettings(st) : mergeSettings(st)) };
    updateSettings(newSettings);
    return newSettings;
  };

  useEffect(() => {
    if (!useUrlQuery) return;
    const abortControllers: AbortController[] = [];

    const loadJsonSettings = (query: NavigateGlobalQuery.SETTINGS | NavigateGlobalQuery.SETTINGS_DEFAULT, callback: (json: any) => void): AbortController => {
      const controller = new AbortController();
      const signal = controller.signal;
      
      const settingsQuery = getGlobalQuery(url.href, query);
      const settingsUrl = settingsQuery;
      let settingsJson: { [key: string]: any } | undefined;
  
      if (settingsQuery) {
        try {
          settingsJson = JSON.parse(settingsQuery);
        } catch (_) {}
  
        if (settingsJson) {
          callback(settingsJson);
        } else if (settingsUrl) {
          fetch(settingsUrl, { signal })
            .then(async (response) => {
              const data = await response.json();
              if (data) {
                callback(settingsJson);
              }
            })
            .catch((err) => {
              logger.log("Impossible to load settings from URL : ", settingsUrl, " - Error : ", err);
            });
        }
      }
      return controller;
    }

    // NavigateGlobalQuery.SETTINGS
    abortControllers.push(loadJsonSettings(NavigateGlobalQuery.SETTINGS, (json) => {
      if (!json) return;
      const saveInLocalStorage = getGlobalQuery(url.href, NavigateGlobalQuery.SETTINGS_NO_SAVE);
      setSettings(json, saveInLocalStorage);
    }));

    // NavigateGlobalQuery.SETTINGS_DEFAULT
    abortControllers.push(loadJsonSettings(NavigateGlobalQuery.SETTINGS_DEFAULT, (json) => {
      if (!json) return;
      setSettingsAsDefault(json);
    }));

    return () => {
      abortControllers.forEach((ac) => ac?.abort());
    };
  }, [url, useUrlQuery]);

  return [settings, setSettings];
};

import { HoverMessage, HoverMessageIcon, Touchable } from "@kalyzee/kast-app-web-components";
import { Meeting } from "@kalyzee/kast-websocket-module";
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef } from "react";
import { ReactComponent as IconHelp } from "../assets/icons/help.svg";
import { ReactComponent as IconKalyzee } from "../assets/logo/small-logo-animated.svg";
import { BACKGROUND_COMPONENT_CLASSNAME } from "../helpers/styles";
import { getMeetingTime, getMeetingTimeSeconds } from "../helpers/utils";
import styles from "./MeetingHeader.module.css";
import SettingsPopup, { SettingsPopupRef } from "./SettingsPopup";

export interface MeetingHeaderProps {
  meeting?: Meeting;
  helpUri?: string;
  disableKalyzeeButton?: boolean;
  hideClock?: boolean;
  displaySeconds?: boolean;
  customLeftElements?: React.ReactNode | React.ReactNode[];
  customRightElements?: React.ReactNode | React.ReactNode[];
  className?: string;
  style?: React.CSSProperties;
}

export interface MeetingHeaderRef {
  openPopup: () => void;
}

const MeetingHeader = React.forwardRef(
  (
    { meeting, helpUri, disableKalyzeeButton, hideClock, displaySeconds, customLeftElements, customRightElements, className, style }: MeetingHeaderProps,
    forwardRef: ForwardedRef<MeetingHeaderRef | undefined>
  ) => {
    const settingsPopupRef = useRef<SettingsPopupRef>(null);
    const timeDivRef = useRef<HTMLDivElement>(null);
    const secondsDivRef = useRef<HTMLDivElement>(null);
    const helpButtonContainerRef = useRef<HTMLDivElement>(null);
    const classes: string[] = [styles.container, BACKGROUND_COMPONENT_CLASSNAME];
    if (className) classes.push(className);

    // ---------------------------------------------------------------- //
    // ---------------------------- UTILS ----------------------------- //
    // ---------------------------------------------------------------- //

    const updateTime = () => {
      const time = getMeetingTime();
      if (timeDivRef.current && timeDivRef.current.textContent !== time) {
        timeDivRef.current.textContent = time;
      }
      if (displaySeconds) {
        const seconds = getMeetingTimeSeconds();
        if (secondsDivRef.current && secondsDivRef.current.textContent !== seconds) {
          secondsDivRef.current.textContent = `:${seconds}`;
        }
      }
    };

    // ---------------------------------------------------------------- //
    // ---------------------------- EFFECT ----------------------------- //
    // ---------------------------------------------------------------- //

    useImperativeHandle(forwardRef, () => ({
      openPopup: () => settingsPopupRef.current?.openPopup(meeting),
    }));

    useEffect(() => {
      let timeout: NodeJS.Timeout | undefined;
      let interval: NodeJS.Timer | undefined;
      const ms = new Date().getMilliseconds();
      const margeMs = 200;
      // Wait next second
      timeout = setTimeout(() => {
        interval = setInterval(() => {
          // Update every seconds
          updateTime();
        }, 1000);
      }, 1000 - ms + margeMs);
      return () => {
        if (timeout) clearTimeout(timeout);
        if (interval) clearInterval(interval);
      };
    }, []);

    // ---------------------------------------------------------------- //
    // ---------------------------- RENDER ----------------------------- //
    // ---------------------------------------------------------------- //
    const title = meeting?.name?.trim();

    const renderCustomElements = (prefixKey: string, elements: React.ReactNode | React.ReactNode[] | undefined) => {
      if (!elements) return null;
      const array = Array.isArray(elements) ? elements : [elements];
      return (
        <>
          {array.map((a, i) => {
            return <div key={`${prefixKey}_${i}`}>{a}</div>;
          })}
        </>
      );
    };

    const renderClock = () => {
      if (hideClock) return null;
      return (
        <div className={styles.largeTime}>
          <span ref={timeDivRef}>{getMeetingTime()}</span>
          {displaySeconds ? (
            <span ref={secondsDivRef} className={styles.largeTimeSeconds}>
              :{getMeetingTimeSeconds()}
            </span>
          ) : null}
        </div>
      );
    };

    const renderLeft = () => {
      return (
        <div className={styles.leftContainer}>
          {title?.length ? renderClock() : null}
          {renderCustomElements("custom_elements_left", customLeftElements)}
        </div>
      );
    };

    const renderMiddle = () => {
      return <div className={styles.middleContainer}>{title?.length ? title : (renderClock() ?? <div></div>)}</div>;
    };

    const renderRight = () => {
      return (
        <div className={styles.rightContainer}>
          {renderCustomElements("custom_elements_right", customRightElements)}
          {helpUri ? (
            <div ref={helpButtonContainerRef}>
              <Touchable
                onPressOut={() => {
                  window.open(helpUri, "_blank");
                }}
              >
                <IconHelp height={30} />
              </Touchable>
              <HoverMessage targetRef={helpButtonContainerRef} icon={HoverMessageIcon.INFO} message={"Aide"} />
            </div>
          ) : null}
          <Touchable disabled={disableKalyzeeButton} onPressOut={() => settingsPopupRef.current?.openPopup(meeting)}>
            <IconKalyzee height={30} />
          </Touchable>
        </div>
      );
    };

    return (
      <div className={classes.join(" ")} style={style}>
        {renderLeft()}
        {renderMiddle()}
        {renderRight()}
        <SettingsPopup ref={settingsPopupRef} />
      </div>
    );
  }
);

MeetingHeader.defaultProps = {
  className: undefined,
  style: undefined,
};

export default MeetingHeader;

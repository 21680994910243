import { AppVersion, Touchable, useURL } from "@kalyzee/kast-app-web-components";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ImgBackground from "../../assets/backgrounds/kast.png";
import { ReactComponent as IconBack } from "../../assets/icons/back.svg";
import { usePageContextRef } from "../../components/navigation/PageContext";
import { toastError } from "../../helpers/toast";
import { isIp } from "../../helpers/utils";
import { useSettings } from "../../hooks/settings";
import styles from "./remote.module.css";

const RemotePage = () => {
  const url = useURL();
  const navigate = useNavigate();
  const pageContextRef = usePageContextRef();
  const inputRef = useRef<HTMLInputElement>(null);
  const [settings, saveSettings] = useSettings();
  const [enableButton, setEnableButton] = useState(settings.slave?.bridgeMessages?.websocketEndpoint?.length ? true : false);

  const renderContent = () => {
    return (
      <>
        <div className={styles.content}>
          <div className={styles.title}>Se connecter à un appareil distant</div>
          <div className={styles.description}>Saisissez l'URL de l'appareil:</div>
          <input
            className={styles.input}
            ref={inputRef}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            defaultValue={settings.slave?.bridgeMessages?.websocketEndpoint ?? ''}
            placeholder={'ws://192.168.1.5:8888'}
            onChange={(e) => {
              const id = e.target?.value?.trim();
              setEnableButton(id.length ? true : false);
            }}
          />
        </div>

        <Touchable
          className={`${styles.button}${enableButton ? "" : ` ${styles.disabled}`}`}
          disabled={!enableButton}
          onPress={() => {
            const value = inputRef.current?.value?.trim();
            let valid = false;
            try {
              new URL(value!);
              valid = true;
            } catch(err) {
              toastError('La valeur saisie est invalide ');
            }

            if (valid) {
              saveSettings({
                slave: {
                  bridgeMessages: {
                    enableWebsocket: true,
                    websocketEndpoint: value,
                  },
                },
              });
              const redirection = url.searchParams.get("redirect");
              if (redirection) navigate(`/${redirection}`);
              else navigate("/");
            }
          }}
        >
          Valider
        </Touchable>
      </>
    );
  };

  const settingsAlreadyValid = settings.slave?.bridgeMessages?.enableWebsocket && settings.slave?.bridgeMessages?.websocketEndpoint;
  return (
    <div className={styles.page} style={{ backgroundImage: `url(${ImgBackground})` }}>
      {settingsAlreadyValid ? (
        <Touchable
          onPress={() => {
            pageContextRef.current.goToPrevious?.();
          }}
          className={styles.backButton}
        >
          <IconBack width="100%" height="100%" />
        </Touchable>
      ) : null}
      <div className={styles.container}>{renderContent()}</div>
      <AppVersion />
    </div>
  );
};

export default RemotePage;

import KastAppClient, { ReportCreateDTO, REPORT_SCOPE } from "@kalyzee/kast-app-module";
import { PopupButtonType, PopupIconType, PopupOnCloseData, showPopup, TextLimitationTextarea } from "@kalyzee/kast-app-web-components";
import { createRef } from "react";
import { useSettings } from "./settings";

export const useRequestAdminPin = (): ((callback: (success: boolean) => void, title?: string, message?: string, retryMessage?: string) => void) => {
  const [settings] = useSettings();
  const show = (
    callback: (success: boolean) => void,
    title: string = "Mot de passe",
    message: string = "Veuillez saisir le mot de passe administrateur",
    retryMessage: string = "Mot de passe invalide.\nVeuillez saisir le mot de passe administrateur"
  ) => {
    if (!settings.adminPin) {
      callback(true);
      return;
    }
    const inputRef = createRef<HTMLInputElement>();
    showPopup({
      title,
      iconTitle: PopupIconType.INFO,
      content: (
        <div style={{ minWidth: "300px" }}>
          <div style={{ fontSize: "1rem", color: "lightgrey", marginBottom: "15px" }}>{message}</div>
          <br />
          <div style={{ marginBottom: "5px" }}>Saisissez le mot de passe:</div>
          <input autoFocus style={{ width: "100%", height: "25px", border: "unset", borderRadius: "5px" }} ref={inputRef} type="password" />
        </div>
      ),
      buttons: [
        {
          type: PopupButtonType.VALIDATE,
          element: "Valider" /* TRANSLATION */,
        },
      ],
      enableBackdropDismiss: true,
      enableCloseButton: true,
      onClose: (data: PopupOnCloseData) => {
        if (data.closeFromButtonIndex === 0) {
          const password = inputRef.current?.value;
          if (password === `${settings.adminPin}`) {
            callback(true);
          } else {
            show(callback, title, retryMessage, retryMessage);
          }
        } else {
          callback(false);
        }
      },
    });
  };
  return show;
};

export const useBugReporter = (
  client: KastAppClient,
  scope: REPORT_SCOPE
): ((onState: (state: "loading" | "error" | "success" | "canceled", error?: string) => void, onExtraData?: () => string) => void) => {
  return (onState: (state: "loading" | "error" | "success" | "canceled", error?: string) => void, onExtraData?: () => string) => {
    const descriptionRef: { current: null | string } = { current: null };
    showPopup({
      title: "Signaler un bug",
      iconTitle: PopupIconType.INFO,
      content: (
        <div style={{ minWidth: "500px" }}>
          <div style={{ marginBottom: "5px" }}>Aidez-nous en laissant un petit message décrivant votre problème:</div>
          <TextLimitationTextarea
            style={{ marginTop: "10px" }}
            hideIndicatorIfEmpty={false}
            textElementStyle={{ width: "100%", resize: "none", height: "80px", border: "unset", borderRadius: "5px" }}
            limit={250}
            placeholder={"Bonjour! Je souhaite signaler un bug..."}
            onChange={(e) => {
              const description = (e.target as any)?.value?.trim();
              descriptionRef.current = description;
            }}
          />
        </div>
      ),
      buttons: [
        {
          type: PopupButtonType.VALIDATE,
          element: "Envoyer" /* TRANSLATION */,
        },
      ],
      enableBackdropDismiss: true,
      enableCloseButton: true,
      onClose: async (data: PopupOnCloseData) => {
        if (data.closeFromButtonIndex === 0) {
          const description = descriptionRef.current;
          const createDTO: ReportCreateDTO = {
            userMessage: description ?? undefined,
            scope,
          };
          if (onExtraData) {
            createDTO.data = onExtraData();
          }
          onState("loading");
          try {
            await client.report.create(createDTO);
            onState("success");
          } catch (err) {
            onState("error", err ? `${err}` : undefined);
          }
          return true;
        } else {
          onState("canceled");
        }
      },
    });
  };
};

export interface ApplyVideoTrackConstraintsOptions {
  videoMediaContentHint?: "" | "motion" | "detail" | "text";
  videoMediaConstrains?: Partial<MediaTrackConstraints>;
}

export const applyVideoTrackContraints = async (track: MediaStreamTrack, options: ApplyVideoTrackConstraintsOptions) => {
  if (!track || track.kind !== 'video') return;
  if (options.videoMediaContentHint) track.contentHint = options.videoMediaContentHint;
  try {
    if (options.videoMediaConstrains) await track.applyConstraints(options.videoMediaConstrains);
  } catch (err) {
    /**/
  }
};

export interface ApplyAudioTrackConstraintsOptions {
  audioMediaContentHint?: "" | "speech" | "speech-recognition" | "music";
  audioMediaConstrains?: Partial<MediaTrackConstraints>;
}

export const applyAudioTrackContraints = async (track: MediaStreamTrack, options: ApplyAudioTrackConstraintsOptions) => {
  if (!track || track.kind !== 'audio') return;
  if (options.audioMediaContentHint) track.contentHint = options.audioMediaContentHint;
  try {
    if (options.audioMediaConstrains) await track.applyConstraints(options.audioMediaConstrains);
  } catch (err) {
    /**/
  }
};

export interface ApplyMediaConstraintsOptions extends ApplyVideoTrackConstraintsOptions, ApplyAudioTrackConstraintsOptions {}

export const applyMediaConstraints = async (media: MediaStream, options: ApplyMediaConstraintsOptions) => {
  if (!media) return;

  const videoTracks = media.getVideoTracks();
  const audioTracks = media.getAudioTracks();
  const videoTrack = videoTracks.length ? videoTracks[0] : undefined;
  const audioTrack = audioTracks.length ? audioTracks[0] : undefined;
  if (videoTrack)  await applyVideoTrackContraints(videoTrack, options);
  if (audioTrack)  await applyAudioTrackContraints(audioTrack, options);
};

import {
  AppVersion,
  Button,
  ButtonColor,
  closePopup,
  Colors,
  getMediaDevices,
  PopupButtonType,
  PopupIconType,
  PopupOnCloseData,
  PopupRef,
  showPopup,
  Touchable
} from "@kalyzee/kast-app-web-components";
import { Meeting } from "@kalyzee/kast-websocket-module";
import React, { createRef, ForwardedRef, useImperativeHandle, useRef } from "react";
import { ReactComponent as IconFavoriteOff } from "../assets/icons/favorite-off.svg";
import { ReactComponent as IconFavoriteOn } from "../assets/icons/favorite-on.svg";
import { PageMasterMode, usePageContextRef } from "../components/navigation/PageContext";
import { Settings } from "../helpers/settings";
import { toastSuccess } from "../helpers/toast";
import { useSettings } from "../hooks/settings";
import { useRequestAdminPin } from "../hooks/utils";
import styles from "./SettingsPopup.module.css";
import Versions from "./Versions";

export interface SettingsPopupProps {}

export interface SettingsPopupRef {
  openPopup: (meeting?: Meeting) => void;
}

const SettingsPopup = React.forwardRef(({}: SettingsPopupProps, forwardRef: ForwardedRef<SettingsPopupRef | undefined>) => {
  const [settings, saveSettings] = useSettings();
  const requestAdminPin = useRequestAdminPin();
  const pageContextRef = usePageContextRef();
  const isOpened = useRef<boolean>(false);

  const switchFavorite = (meeting?: Meeting) => {
    if (!meeting) return;
    const isFavorite = settings.favorites?.find((d) => d.id === meeting?.shortId);
    if (isFavorite) {
      const newFavorites = settings.favorites?.filter((d) => d.id !== meeting?.shortId);
      showPopup({
        title: "Retirer de vos favoris",
        iconTitle: PopupIconType.INFO,
        content: `Êtes-vous sûr de vouloir retirer ce meeting de vos favoris?`,
        buttons: [
          { type: PopupButtonType.CANCEL, element: "Non" },
          {
            type: PopupButtonType.VALIDATE,
            element: "Oui",
            onClick: () => {
              saveSettings({
                favorites: newFavorites,
              });
              return true;
            },
          },
        ],
        enableBackdropDismiss: true,
        enableCloseButton: true,
      });
    } else {
      const inputRef = createRef<HTMLInputElement>();
      showPopup({
        title: "Ajouter ce meeting aux favoris ?",
        iconTitle: PopupIconType.INFO,
        content: (
          <div className={styles.popupFavoriteContainer}>
            <div className={styles.popupFavoriteTitle}>Ce meeting va être ajouté à vos favoris.</div>
            <div className={styles.popupFavoriteDescription}>Nommez ce meeting:</div>
            <input className={styles.popupFavoriteInput} ref={inputRef} autoFocus />
          </div>
        ),
        buttons: [
          {
            type: PopupButtonType.VALIDATE,
            element: "Valider" /* TRANSLATION */,
            onClick: () => {
              const favorites = settings.favorites ?? [];
              favorites.push({
                id: meeting.shortId,
                name: inputRef.current?.value,
              });
              saveSettings({
                favorites,
              });
              toastSuccess("Vous avez ajouté ce meeting à vos favoris.");
              return true;
            },
          },
        ],
        enableBackdropDismiss: true,
        enableCloseButton: true,
      });
    }
  };

  const tryOpenPopup = (meeting?: Meeting) => {
    requestAdminPin((success) => {
      if (success) openPopup(meeting);
    });
  };

  const openPopup = (meeting?: Meeting) => {
    if (isOpened.current) return;
    isOpened.current = true;
    const textareaRef = createRef<HTMLTextAreaElement>();
    const advancedSettingsContainerRef = createRef<HTMLDivElement>();
    const isFavorite = settings.favorites?.find((d) => d.id === meeting?.shortId);
    const popupRef = createRef<PopupRef>();
    const popupIndex = showPopup({
      title: (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          Paramètres
          {meeting ? (
            <Touchable
              onPressOut={() => {
                closePopup(popupIndex);
                switchFavorite(meeting);
              }}
            >
              {isFavorite ? <IconFavoriteOn width={30} height={30} /> : <IconFavoriteOff width={30} height={30} />}
            </Touchable>
          ) : null}
        </div>
      ),
      style: { width: "70%" },
      content: (
        <div className={styles.popupSettingsContent}>
          <div className={styles.popupSettingsButtonsContainer}>
            <Button
              color={ButtonColor.GREEN}
              title="Appareiller un device"
              className={styles.button}
              onPress={async () => {
                closePopup(popupIndex);
                pageContextRef?.current?.goTo?.(PageMasterMode.PAIRING);
              }}
            />
            <Button
              color={ButtonColor.PURPLE}
              title="Relancer"
              className={styles.button}
              onPress={() => {
                closePopup(popupIndex);
                window.location.reload();
              }}
            />
            <Button
              color={ButtonColor.PURPLE}
              title="Media Devices"
              className={styles.button}
              onPress={async () => {
                const devices = await getMediaDevices("both", "input");
                showPopup({
                  title: "Devices",
                  content: <div className={styles.popupDevicesContent}>{JSON.stringify(devices, null, 4)}</div>,
                  buttons: [{ type: PopupButtonType.OK, element: "OK" }],
                });
              }}
            />
          </div>
          <div className={styles.popupSettingsButtonsContainer}>
            <Button
              color={ButtonColor.RED}
              title="Quitter le meeting"
              className={styles.button}
              onPress={() => {
                closePopup(popupIndex);
                pageContextRef.current.goTo?.(PageMasterMode.JOIN, undefined, { queries: { autojoin: "false" } });
              }}
            />
            <Button
              color={ButtonColor.RED}
              title="Se déconnecter"
              className={styles.button}
              onPress={() => {
                closePopup(popupIndex);
                pageContextRef.current.goTo?.(PageMasterMode.LOGOUT);
              }}
            />
          </div>
          <textarea
            ref={textareaRef}
            style={{ display: "none" }}
            className={styles.popupSettingsTextArea}
            defaultValue={JSON.stringify(settings, null, 4)}
            spellCheck={false}
          />
          <div ref={advancedSettingsContainerRef} className={styles.popupAdvancedSettingsContainer}>
            <Button
              title="Afficher les paramètres avancés"
              style={{ backgroundColor: "grey" }}
              className={styles.button}
              onPress={() => {
                if (textareaRef.current) textareaRef.current.style.display = "flex";
                if(advancedSettingsContainerRef.current) advancedSettingsContainerRef.current.style.display = 'none';
                popupRef.current?.updateButtons([
                  { type: PopupButtonType.CANCEL, element: "Annuler" },
                  {
                    type: PopupButtonType.VALIDATE,
                    element: <div style={{ color: Colors.getMainGreen() }}>Valider</div>,
                    onClick: () => {
                      let newSettings: object | undefined;
                      let error: string | undefined;
                      try {
                        newSettings = JSON.parse(textareaRef.current?.value ?? "");
                      } catch (err) {
                        error = (err as any)?.message;
                      }
                      if (!newSettings) {
                        alert("Impossible de sauvegarder les paramètres\nUne erreur est apparue durant la sauvegarde des paramètres : " + error);
                      }
                      return newSettings ? true : false;
                    },
                  },
                ]);
              }}
            />
          </div>
          <Versions />
          <AppVersion />
        </div>
      ),
      buttons: [{ type: PopupButtonType.OK, element: 'OK'}],
      onClose: (data: PopupOnCloseData) => {
        isOpened.current = false;
        if (data.closeFromButton?.type === PopupButtonType.VALIDATE) {
          // save settings
          let newSettings: Settings | undefined;
          let error: string | undefined;
          try {
            newSettings = saveSettings(JSON.parse(textareaRef.current?.value ?? ""));
          } catch (err) {
            error = (err as any)?.message;
          }

          if (newSettings) {
            showPopup({
              title: "Appliquer les changements",
              iconTitle: PopupIconType.SUCCESS,
              content: "Vous avez modifié les paramétres. Voulez-vous rafraîchir la page pour appliquer tout de suite vos modifications ?",
              buttons: [
                { type: PopupButtonType.CANCEL, element: "Non" },
                { type: PopupButtonType.VALIDATE, element: <div style={{ color: Colors.getMainGreen() }}>Oui</div> },
              ],
              onClose: (data: PopupOnCloseData) => {
                if (data.closeFromButton?.type === PopupButtonType.VALIDATE) {
                  window.location.reload();
                }
              },
            });
          } else {
            showPopup({
              title: "Impossible de sauvegarder les paramètres",
              iconTitle: PopupIconType.ERROR,
              content: "Une erreur est apparue durant la sauvegarde des paramètres : " + error,
              buttons: [{ type: PopupButtonType.OK, element: "OK" }],
            });
          }
        }
      },
    }, popupRef);
  };

  useImperativeHandle(forwardRef, () => ({
    openPopup: tryOpenPopup,
  }));

  return null;
});

export default SettingsPopup;

import { Loading, useURL } from "@kalyzee/kast-app-web-components";
import { useNavigate } from "react-router-dom";
import { useSocketConnexion } from "../../hooks/socket";
import { AuthStatus } from "../../interfaces/session";
import { useEffect } from "react";
import HomePage from "../../pages/home/home";
import { PAGE_PATHS } from "./PageContext";
import { SessionMode } from "../../store/session/slices";
import { useRequestAppDispatch } from "../../hooks/app";
import { requestGetMe } from "../../store/request/actions";

export interface PrivateRouteProps {
  authStatus: AuthStatus;
  mode: SessionMode;
}

const PrivateRoute = ({ authStatus, mode }: PrivateRouteProps) => {
  const url = useURL();
  const navigate = useNavigate();
  const requestDispatch = useRequestAppDispatch();

  useSocketConnexion();

  useEffect(() => {
    requestDispatch(requestGetMe());
  }, []);

  useEffect(() => {
    const redirection = url.searchParams.get("redirect");
    if (redirection && url.pathname === PAGE_PATHS[mode].login) navigate(redirection);
  }, [url, mode, navigate]);

  if (authStatus !== AuthStatus.In) {
    return <Loading fullscreen />;
  }
  return <HomePage />;
};

export default PrivateRoute;

import { Colors, OverlayHoverMessage, Touchable } from "@kalyzee/kast-app-web-components";
import React, { createRef, ForwardedRef, useEffect, useImperativeHandle, useState } from "react";
import { ReactComponent as IconCamera } from "../assets/icons/camera.svg";
import { ReactComponent as IconMicrophone } from "../assets/icons/microphone.svg";
import { ReactComponent as IconMixed } from "../assets/icons/mixed.svg";
import { ReactComponent as IconScreen } from "../assets/icons/screen.svg";
import { ReactComponent as IconPlayback } from "../assets/icons/system.svg";
import { WhiteboardWebRTCSession } from "../helpers/whiteboardWebRTCSession";
import styles from "./WhiteboardPlayerOverlay.module.css";

export interface WhiteboardPlayerOverlayProps {
  session: WhiteboardWebRTCSession | undefined;
  className?: string;
  style?: React.CSSProperties;
}

export interface WhiteboardPlayerOverlayRef {}

const WhiteboardPlayerOverlay = React.forwardRef(
  ({ session, className, style }: WhiteboardPlayerOverlayProps, forwardRef: ForwardedRef<WhiteboardPlayerOverlayRef | undefined>) => {
    const [videoSources, setVideoSources] = useState<string[]>([]);
    const [audioSources, setAudioSources] = useState<string[]>([]);
    const [currVideoSource, setCurrVideoSource] = useState<string>();
    const [currAudioSource, setCurrAudioSource] = useState<string>();

    const classes: string[] = [styles.container];
    if (className) classes.push(className);

    useImperativeHandle(forwardRef, () => ({}));

    useEffect(() => {
      if (!session) return;

      const sourcesListener = (videos: string[], audios: string[]) => {
        setVideoSources(videos);
        setAudioSources(audios);
      };

      const videoSourceListener = (src: string) => {
        setCurrVideoSource(src);
      };

      const audioSourceListener = (src: string) => {
        setCurrAudioSource(src);
      };

      session.addEventListener("sources", sourcesListener);
      session.addEventListener("audioSrc", audioSourceListener);
      session.addEventListener("videoSrc", videoSourceListener);

      session.getVideoInformation();
      return () => {
        session.removeEventListener("sources", sourcesListener);
        session.removeEventListener("audioSrc", audioSourceListener);
        session.removeEventListener("videoSrc", videoSourceListener);
      };
    }, [session]);

    const renderButton = (selected: boolean, icon: React.ReactNode, message: string, callback: () => void, index: number = 0) => {
      const buttonRef = createRef<HTMLDivElement>();
      return (
        <>
          <OverlayHoverMessage targetRef={buttonRef} message={message} />
          <div ref={buttonRef}>
            <Touchable style={{ position: "relative" }} onPress={callback}>
              <>
                {icon}
                {index > 0 ? (
                  <div className={styles.indexIndicator} style={{ color: selected ? Colors.getMainGreen() : Colors.getMainBlack() }}>
                    {index}
                  </div>
                ) : null}
              </>
            </Touchable>
          </div>
        </>
      );
    };

    const renderVideoSelector = () => {
      if (videoSources.length <= 1) return;
      const buttonSize = 20;
      const camerasOnly = videoSources.filter((src) => src !== "screen");
      return (
        <div className={styles.row}>
          <div className={styles.rowTitle}>{videoSources.length > 1 ? "Vidéos" : "Vidéo"}</div>
          <div className={styles.rowContent}>
            {videoSources.map((src) => {
              const selected = currVideoSource === src;
              if (src === "screen") {
                return renderButton(
                  selected,
                  <IconScreen fill={selected ? Colors.getMainGreen() : Colors.getMainBlack()} width={buttonSize} height={buttonSize} />,
                  "Screenshare",
                  () => {
                    session?.updateVideoSource("screen");
                  }
                );
              }
              return renderButton(
                selected,
                <IconCamera fill={selected ? Colors.getMainGreen() : Colors.getMainBlack()} width={buttonSize} height={buttonSize} />,
                "Camera: " + src,
                () => {
                  session?.updateVideoSource(src);
                },
                1 + camerasOnly.findIndex((curr) => curr === src)
              );
            })}
          </div>
        </div>
      );
    };

    const renderAudioSelector = () => {
      if (audioSources.length <= 1) return;
      const buttonSize = 20;
      return (
        <div className={styles.row}>
          <div className={styles.rowTitle}>{audioSources.length > 1 ? "Audios" : "Audio"}</div>
          <div className={styles.rowContent}>
            {audioSources.map((src) => {
              const selected = currAudioSource === src;
              if (src === "playback") {
                return renderButton(
                  selected,
                  <IconPlayback fill={selected ? Colors.getMainGreen() : Colors.getMainBlack()} width={buttonSize} height={buttonSize} />,
                  "Son du système",
                  () => {
                    session?.updateAudioSource("playback");
                  }
                );
              }
              if (src === "microphone") {
                return renderButton(
                  selected,
                  <IconMicrophone stroke={selected ? Colors.getMainGreen() : Colors.getMainBlack()} width={buttonSize} height={buttonSize} />,
                  "Microphone",
                  () => {
                    session?.updateAudioSource("microphone");
                  }
                );
              }
              if (src === "mixed") {
                return renderButton(
                  selected,
                  <IconMixed fill={selected ? Colors.getMainGreen() : Colors.getMainBlack()} width={buttonSize} height={buttonSize} />,
                  "Son du système et microphone",
                  () => {
                    session?.updateAudioSource("mixed");
                  }
                );
              }
              return null;
            })}
          </div>
        </div>
      );
    };

    const render = () => {
      return (
        <div style={style} className={classes.join(" ")}>
          {renderVideoSelector()}
          {renderAudioSelector()}
        </div>
      );
    };

    return render();
  }
);

WhiteboardPlayerOverlay.defaultProps = {
  className: undefined,
  style: undefined,
};

export default WhiteboardPlayerOverlay;

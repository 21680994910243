import { useEffect } from 'react';
import { AuthStatus } from '../interfaces/session';
import { SocketStatus } from '../interfaces/socket';
import { selectAuthStatus } from '../store/session/selectors';
import { socketConnect, socketDisconnect } from '../store/socket/actions';
import { selectSocketStatus } from '../store/socket/selectors';
import { setSocketStatus } from '../store/socket/slices';
import { useAppDispatch, useAppSelector } from './app';

// Use only one time
export const useSocketConnexion = () => {
  const dispatch = useAppDispatch();

  const authStatus = useAppSelector(selectAuthStatus);
  const socketStatus = useAppSelector(selectSocketStatus);

  const connect = () => {
    dispatch(socketConnect());
  };

  const retryConnect = () => {
    dispatch(setSocketStatus(SocketStatus.Loading));
    connect();
  };

  const disconnect = () => {
    dispatch(socketDisconnect());
  };

  useEffect(() => {
    if (authStatus === AuthStatus.In) {
      connect();
    }
    return () => {
      disconnect();
    };
  }, [authStatus]);

  return { socketStatus, retry: retryConnect };
};

export const useSocket = () => {
  const dispatch = useAppDispatch();
  const socketStatus = useAppSelector(selectSocketStatus);
  const retryConnect = () => {
    dispatch(setSocketStatus(SocketStatus.Loading));
    dispatch(socketConnect());
  };
  return { socketStatus, retryConnect };
};

import { REPORT_SCOPE } from "@kalyzee/kast-app-module";
import { awaitWithMinimumDelay, DAY_IN_MS, useEventListenersRef } from "@kalyzee/kast-app-web-components";
import { FlexibleId, WhiteboardUserValidatedSettings } from "@kalyzee/kast-websocket-module";
import {
  CommunicationAdapterState,
  CommunicationAdapterStatus,
  KalyzeeCommunicationAdapter,
  UserSettings,
  VersionsSeenAt,
  VersionTag,
  Whiteboard as WhiteboardExcalidraw,
  WhiteboardImperativeAPI,
  WhiteboardMenuCustomButton,
} from "@kalyzee/whiteboard-react";
import { debounce } from "lodash";
import React, { ForwardedRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import { ReactComponent as IconReportBug } from "../assets/icons/bug.svg";
import { ReactComponent as IconFavoriteOff } from "../assets/icons/favorite-off.svg";
import { ReactComponent as IconFavoriteOn } from "../assets/icons/favorite-on.svg";
import { ReactComponent as IconLogout } from "../assets/icons/logout.svg";
import { ReactComponent as IconSwitch } from "../assets/icons/shuffle.svg";
import { logger } from "../helpers/logger";
import { apiClient, websocketClient } from "../helpers/request";
import { getWhiteboardNews, getWhiteboardSettings, setWhiteboardNews, setWhiteboardSettings } from "../helpers/storage";
import { toastError, toastSuccess } from "../helpers/toast";
import { dataURLToFile, isMongoDBId } from "../helpers/utils";
import { useBugReporter, useRequestAdminPin } from "../hooks/utils";
import { SessionMode } from "../store/session/slices";
import { PageMap, usePageContextRef } from "./navigation/PageContext";
import Versions from "./Versions";
import styles from "./Whiteboard.module.css";
import WhiteboardOverlay from "./WhiteboardOverlay";

export enum WhiteboardConditionalAutojoin {
  ONLY_ID_NAME_ARE_DEFINED,
  IF_ID_IS_DEFINED,
  FIRST_FAVORITE_DEFINED,
}

export type WhiteboardAutojoinByPriority = WhiteboardConditionalAutojoin[];

export interface WhiteboardEventListeners {
  addFavorite: (id: string, name?: string) => void;
  removeFavorite: (id: string, name?: string) => void;
}

export interface WhiteboardProps {
  id?: string;
  name?: string;
  autojoin?: boolean | WhiteboardAutojoinByPriority;
  isMaster?: boolean;
  followMaster?: boolean;
  hideControls?: boolean;
  transparentBackground?: boolean;
  menuButtons?: {
    enableFavorite?: boolean;
    enableSwitchWhiteboard?: boolean;
    enableReportBug?: boolean;
    enableLogout?: boolean;
    custom?: WhiteboardMenuCustomButton[];
  };
  favorites?: { id: string; name?: string }[];
  hideNewIndicatorAfterDelay?: number;
  showNewVersionsOnFirstUse?: boolean;
  invisible?: boolean;
  spectator?: boolean;
  onJoin?: (id: string, name?: string) => void;
  onAddFavorite?: (id: string, name?: string) => void;
  onRemoveFavorite?: (id: string, name?: string) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface WhiteboardRef {
  insertImage: (dataURL: string, insertOnCanvasDirectly: boolean) => void;
  addEventListener: <T extends keyof WhiteboardEventListeners>(event: T, listener: WhiteboardEventListeners[T]) => void;
  removeEventListener: <T extends keyof WhiteboardEventListeners>(event: T, listener: WhiteboardEventListeners[T]) => void;
}

const Whiteboard = React.forwardRef(
  (
    {
      id,
      name,
      autojoin,
      isMaster,
      followMaster,
      hideControls,
      transparentBackground,
      menuButtons,
      favorites,
      hideNewIndicatorAfterDelay,
      showNewVersionsOnFirstUse,
      invisible,
      spectator,
      onJoin,
      onAddFavorite,
      onRemoveFavorite,
      className,
      style,
    }: WhiteboardProps,
    forwardRef: ForwardedRef<WhiteboardRef | undefined>
  ) => {
    const pageContextRef = usePageContextRef();
    const listenersRef = useEventListenersRef<keyof WhiteboardEventListeners, WhiteboardEventListeners>();
    const [socketAdapter, setSocketAdapter] = useState<KalyzeeCommunicationAdapter>();
    const [state, setState] = useState<CommunicationAdapterState>({ status: CommunicationAdapterStatus.Connecting, isLoading: false });
    const [forceDisplayOverlay, setForceDisplayOverlay] = useState(false);
    const [forceLoadingStateyOverlay, setForceLoadingStateyOverlay] = useState(false);
    const [joinError, setJoinError] = useState<string>();
    const [currentWhiteboardIds, setCurrentWhiteboardIds] = useState<{ id?: string; name?: string }>({ id, name });
    const onJoinRef = useRef(onJoin);
    const nameRef  = useRef(name);
    const autoJoinRef = useRef(autojoin);
    const currentNameRef = useRef(name);
    const whiteboardAPI = useRef<WhiteboardImperativeAPI>(null);
    const isDestroyedRef = useRef(false);
    const requestAdminPin = useRequestAdminPin();
    const reportBug = useBugReporter(apiClient, REPORT_SCOPE.WHITEBOARD_CLIENT);
    const autoReconnectRef = useRef(true);

    const classes = [styles.container];
    if (className) classes.push(className);

    const join = useCallback(
      debounce(
        async (id: string | undefined, name: string | undefined, auto = true) => {
          if (isDestroyedRef.current) return;

          autoReconnectRef.current = true; // autoreconnect for the next join
          setCurrentWhiteboardIds({ id, name });

          const error = (message: string) => {
            logger.error("WHITEBOARD Error - ", id, " - ", name, " : ", message);
              toastError(message);
              setJoinError(message);
          };

          if (!id || !auto) {
            setForceDisplayOverlay(true);
            return;
          }

          const idParam: FlexibleId = { type: isMongoDBId(id) ? "normal" : "short", value: id };

          const userSettingsFromLocalstorage = getWhiteboardSettings() ?? {};

          // building settings :
          const isInvisible = invisible;
          const _isMaster = (userSettingsFromLocalstorage.isMaster ?? isMaster) && !isInvisible;
          const _followMasters = (userSettingsFromLocalstorage.followMasters ?? followMaster) && !_isMaster;
          const settingsArray: Partial<UserSettings>[] = [
            {
              ...userSettingsFromLocalstorage,
              username: name,
              isInvisible,
              isMaster: _isMaster,
              followMasters: _followMasters,
            },
          ];

          try {
            setJoinError(undefined);
            setForceDisplayOverlay(false);
            currentNameRef.current = name;
            const joinRes = await awaitWithMinimumDelay(whiteboardAPI.current!.join(idParam, settingsArray), 500);
            if (joinRes?.error) {
              if (joinRes.error?.cname === "WHITEBOARD_ALREADY_JOINED") {
                // leave and rejoin to be sure to be in the correct whiteboard
                if (whiteboardAPI.current) {
                  setForceLoadingStateyOverlay(true);
                  await whiteboardAPI.current?.leave();
                  // retry
                  join(id, name, auto);
                  setForceLoadingStateyOverlay(true);
                } else {
                  throw new Error(joinRes.error?.message);
                }
              } else {
                throw new Error(joinRes.error?.message);
              }
            }
          } catch (err: any) {
            error(err?.message ?? err);
          } finally {
          }
        },
        1000,
        { leading: true }
      ),
      [isMaster, followMaster]
    );

    useImperativeHandle(forwardRef, () => ({
      insertImage: (dataURL: string, insertOnCanvasDirectly: boolean) => {
        whiteboardAPI.current?.insertImage(dataURLToFile(dataURL), insertOnCanvasDirectly);
      },
      addEventListener: (...args) => listenersRef.current?.addEventListener(...args),
      removeEventListener: (...args) => listenersRef.current?.removeEventListener(...args),
    }));

    // ---------------------------------------------------------------- //
    // ---------------------------- EFFECT ----------------------------- //
    // ---------------------------------------------------------------- //

    useLayoutEffect(() => {
      isDestroyedRef.current = false;
      return () => {
        isDestroyedRef.current = true;
        whiteboardAPI.current?.leave();
      };
    }, []);

    useEffect(() => {
      const adapter = new KalyzeeCommunicationAdapter(apiClient, websocketClient);
      setSocketAdapter(adapter);
      return () => {
        whiteboardAPI.current?.leave();
      };
    }, []);

    useEffect(() => {
      if (forceDisplayOverlay) {
        whiteboardAPI.current?.leave();
      }
    }, [forceDisplayOverlay]);

    useEffect(() => {
      onJoinRef.current = onJoin;
    }, [onJoin]);

    useEffect(() => {
      nameRef.current = name;
    }, [name]);
    useEffect(() => {
      autoJoinRef.current = autojoin;
    }, [autojoin]);

    useEffect(() => {
      if (!socketAdapter) return;
      let currAutoJoin = false;
      let currId = id;
      let currName = nameRef.current;

      let autojoinPriorities: WhiteboardAutojoinByPriority = [
        WhiteboardConditionalAutojoin.ONLY_ID_NAME_ARE_DEFINED,
        WhiteboardConditionalAutojoin.IF_ID_IS_DEFINED,
        WhiteboardConditionalAutojoin.FIRST_FAVORITE_DEFINED,
      ]; // DEFAULT
      if (typeof autoJoinRef.current === "boolean" && autoJoinRef.current) {
        currAutoJoin = true;
      } else if (Array.isArray(autoJoinRef.current)) {
        currAutoJoin = true;
        autojoinPriorities = autoJoinRef.current;
      }
      if (currAutoJoin) {
        for (let value of autojoinPriorities) {
          if (value === WhiteboardConditionalAutojoin.ONLY_ID_NAME_ARE_DEFINED && id && name) {
            currAutoJoin = true;
            break;
          } else if (value === WhiteboardConditionalAutojoin.IF_ID_IS_DEFINED && id) {
            currAutoJoin = true;
            break;
          } else if (value === WhiteboardConditionalAutojoin.FIRST_FAVORITE_DEFINED && favorites?.length) {
            currAutoJoin = true;
            currId = favorites[0].id;
            currName = favorites[0].name;
            break;
          }
        }
      }

      if (websocketClient.connected) {
        setState({ status: CommunicationAdapterStatus.Connected, isLoading: false })
        join(currId, currName, currAutoJoin);
      }

      const onAdapterStateCallback = (adapterState: CommunicationAdapterState) => {
        setState({ ...adapterState });
        const currentInfo = whiteboardAPI.current?.getCurrentInfo();
        if (!adapterState.isLoading) {
          if (adapterState.status === CommunicationAdapterStatus.Connected && autoReconnectRef.current) {
            const currentInfo = whiteboardAPI.current?.getCurrentInfo();
            // Socket went offline and is back online, join again automatically
            join(currentInfo?.shortId ?? currId, currName, currAutoJoin);
          } else if (adapterState.status === CommunicationAdapterStatus.Joined) {
            if (currentInfo) {
              onJoinRef.current?.(currentInfo.shortId, currentNameRef.current);
            }
          }
        }
      };
      socketAdapter.onAdapterState(onAdapterStateCallback);
      return () => {
        socketAdapter.offAdapterState(onAdapterStateCallback);
      };
    }, [socketAdapter, id]);

    useEffect(() => {
      logger.log('[Whiteboard] Change state : ', state);
    }, [state]);

    // ---------------------------------------------------------------- //
    // ---------------------------- RENDER ----------------------------- //
    // ---------------------------------------------------------------- //

    const getMenuButtons = (): WhiteboardMenuCustomButton[] | undefined => {
      const buttons: WhiteboardMenuCustomButton[] = [];
      const currentInfo = whiteboardAPI.current?.getCurrentInfo();
      if (currentInfo?.shortId) {
        if (menuButtons?.enableFavorite ?? true) {
          // true by default
          if (!favorites?.find((f) => f.id === currentInfo.shortId && f.name === currentNameRef.current)) {
            buttons.push({
              content: renderMenuButton("Mettre ce tableau en favoris", <IconFavoriteOff />),
              onClick: () => {
                onAddFavorite?.(currentInfo.shortId, currentNameRef.current);
                toastSuccess("Vous avez ajouté ce tableau à vos favoris.");
              },
            });
          } else {
            buttons.push({
              content: renderMenuButton("Retirer ce tableau des favoris", <IconFavoriteOn />),
              onClick: () => {
                onRemoveFavorite?.(currentInfo.shortId, currentNameRef.current);
                toastSuccess("Vous avez retiré ce tableau de vos favoris.");
              },
            });
          }
        }
      }
      if (menuButtons?.enableSwitchWhiteboard ?? false) {
        // false by default
        buttons.push({
          content: renderMenuButton("Changer de tableau blanc", <IconSwitch />),
          onClick: () => {
            requestAdminPin((success) => {
              if (success) {
                autoReconnectRef.current = false;
                setForceDisplayOverlay(true);
              }
            });
          },
        });
      }
      if (menuButtons?.enableReportBug ?? true) {
        // true by default
        buttons.push({
          content: renderMenuButton("Signaler un bug", <IconReportBug />),
          onClick: () => {
            reportBug(
              (state) => {
                if (state === "success") toastSuccess("Le rapport d'erreur a bien été envoyé");
                else if (state === "error") toastSuccess("Une erreur est appartue lors de l'envoi du rapport d'erreur");
              },
              () => {
                if (!whiteboardAPI.current) {
                  return "whiteboardAPI not ready";
                }
                const data = whiteboardAPI.current.getDebugData();
                return JSON.stringify(whiteboardAPI.current.getDebugData());
              }
            );
          },
          versionTag: VersionTag.RELEASE_4_1_0,
        });
      }

      if (menuButtons?.enableLogout ?? false) {
        // false by default
        buttons.push({
          content: renderMenuButton("Se déconnecter", <IconLogout />),
          onClick: () => {
            requestAdminPin((success) => {
              if (success) {
                const pageContext = pageContextRef.current;
                pageContext.goTo?.(PageMap[pageContext.mode as SessionMode].LOGOUT);
              }
            });
          },
        });
      }
      return buttons.length ? buttons : undefined;
    };

    const handleValidatedSettings = (validatedSettings: WhiteboardUserValidatedSettings | undefined) => {
      if (!validatedSettings) return;
      const { settings, errors } = validatedSettings;
      if (settings) {
        setWhiteboardSettings(settings);
      }
      if (errors?.length) {
        const error = errors[0];
        if (error === "There is already a master in the whiteboard") {
          toastError("Il y a déjà un animateur dans le tableau"); // TODO translation
        } else {
          toastError(error);
        }
      }
    };

    const requestPermission = (): Promise<boolean> => {
      const promise = new Promise<boolean>((resolve) => {
        requestAdminPin((success) => {
          resolve(success);
        });
      });
      return promise;
    };

    const renderMenuButton = (name: string, icon: JSX.Element) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {icon}
            <div
              style={{
                marginLeft: "0.5em",
              }}
            >
              {name}
            </div>
          </div>
        </div>
      );
    };

    const renderHelpHeader = (): JSX.Element => {
      return <Versions />;
    };
    return (
      <div className={classes.join(" ")} style={style}>
        <WhiteboardExcalidraw
            ref={whiteboardAPI}
            communicationAdapter={socketAdapter}
            onValidatedUserSettings={handleValidatedSettings}
            onRequestPermission={requestPermission}
            customMenuButtons={getMenuButtons()}
            renderHelpHeader={renderHelpHeader}
            versionsOptions={{
              seenAt: getWhiteboardNews(),
              onSeenAt: (seen: VersionsSeenAt) => {
                setWhiteboardNews(seen);
              },
              isOldAfterDelay: hideNewIndicatorAfterDelay ?? 7 * DAY_IN_MS,
              showNewVersionsOnFirstUse,
            }}
            uiEnabled={!hideControls}
            transparentBackgroundEnabled={transparentBackground}
            easyAccessEnabled
            langCode={"fr"} // TRANSLATION
          />
        <WhiteboardOverlay
          loading={forceLoadingStateyOverlay}
          state={state}
          forceDisplay={forceDisplayOverlay}
          error={joinError}
          id={currentWhiteboardIds.id}
          name={currentWhiteboardIds?.name}
          favorites={favorites}
          onRemoveFavorite={(id: string, name?: string) => {
            listenersRef.current.triggerEvent("removeFavorite", id, name);
            onRemoveFavorite?.(id, name);
          }}
          onValidate={(id: string, name?: string) => {
            join(id, name);
          }}
          onRetry={() => {
            join(currentWhiteboardIds.id, currentWhiteboardIds?.name);
          }}
        />
      </div>
    );
  }
);

Whiteboard.defaultProps = {
  className: undefined,
  style: undefined,
};

export default Whiteboard;



import { Button, destroyMediaStream, EventListeners, Loading, useEventListenersRef } from "@kalyzee/kast-app-web-components";
import { Video } from "@kalyzee/kast-react-player-module";
import React, { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { logger } from "../helpers/logger";
import { WhiteboardWebRTCSession } from "../helpers/whiteboardWebRTCSession";
import styles from "./WhiteboardPlayer.module.css";
import WhiteboardPlayerOverlay from "./WhiteboardPlayerOverlay";

const TIMEOUT_CONNECT = 5000;
const RETRY_TIMEOUT = 3000;
const RETRY_LIMIT = -1; // < 0 for infinite (ex: -1)

export interface WhiteboardPlayerProps {
  ip: string;
  port?: number;
  defaultVideoSource?: "screen" | string;
  defaultAudioSource?: "playback" | "microphone" | "mixed";
  defaultPreferVideoSource?: "screen" | string;
  defaultPreferAudioSource?: "playback" | "microphone" | "mixed";
  defaultAudioPlaybackVolume?: number | undefined | null;
  defaultAudioMicrophoneVolume?: number | undefined | null;
  display?: boolean;
  timeoutConnect?: number;
  retryTimeout?: number;
  retryLimit?: number; // < 0 for infinite (ex: -1)
  onMedia?: (media: MediaStream | undefined) => void;
  onMessage?: (action: string, params?: any) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface WhiteboardPlayerRef {
  player: 'whiteboard',
  getMedia: () => MediaStream | undefined;
  getContext: () => any | undefined;
  getWhiteboardWebRTCSession: () => WhiteboardWebRTCSession | undefined;
  getEventListener: () => EventListeners<keyof WhiteboardPlayerListenerMap, WhiteboardPlayerListenerMap> | undefined;
}
export interface WhiteboardPlayerListenerMap {
  whiteboardWebRTCSession: (session: WhiteboardWebRTCSession | undefined) => void;
  media: (media: MediaStream | undefined) => void;
  context: (context: any | undefined) => void;
}

const WhiteboardPlayer = React.forwardRef(
  (
    {
      ip,
      port,
      defaultVideoSource,
      defaultAudioSource,
      defaultPreferVideoSource,
      defaultPreferAudioSource,
      defaultAudioPlaybackVolume,
      defaultAudioMicrophoneVolume,
      display,
      timeoutConnect,
      retryTimeout,
      retryLimit,
      onMedia,
      onMessage,
      className,
      style,
    }: WhiteboardPlayerProps,
    forwardRef: ForwardedRef<WhiteboardPlayerRef | undefined>
  ) => {
    const listenersRef = useEventListenersRef<keyof WhiteboardPlayerListenerMap, WhiteboardPlayerListenerMap>();
    const [whiteboardWebRTCSession, setWhiteboardWebRTCSession] = useState<WhiteboardWebRTCSession | undefined>(undefined);
    const onMediaRef = useRef(onMedia);
    const onMessageRef = useRef(onMessage);
    const [retryCounter, setRetryCounter] = useState(0);
    const [error, setError] = useState<string | undefined>(undefined);
    const [previewMedia, setPreviewMedia] = useState<MediaStream | undefined>(undefined);
    const mediaRef = useRef<MediaStream>();
    const [context, setContext] = useState<any>(undefined);
    const firstConnexionRef = useRef(true);
    const connectedRef = useRef(false);
    const componentIsDestroyedRef = useRef(false);

    const classes: string[] = [styles.container];
    if (className) classes.push(className);

    useImperativeHandle(
      forwardRef,
      () => ({
        player: 'whiteboard' as const,
        getMedia: () => mediaRef.current,
        getContext: () => context,
        getWhiteboardWebRTCSession: () => whiteboardWebRTCSession,
        getEventListener: () => listenersRef.current,
      }),
      [context, listenersRef]
    );

    useEffect(() => {
      return () => {
        componentIsDestroyedRef.current = true;
      };
    }, []);

    useEffect(() => {
      onMediaRef.current = onMedia;
    }, [onMedia]);
    useEffect(() => {
      onMessageRef.current = onMessage;
    }, [onMessage]);

    useEffect(() => {
      if (retryCounter < 0) {
        setRetryCounter(0);
        return;
      }
      const currentTimeoutConnect = timeoutConnect ?? TIMEOUT_CONNECT;
      const currRetryLimit = retryLimit ?? RETRY_LIMIT;
      const currRetryTimeout = retryTimeout ?? RETRY_TIMEOUT;
      if (currRetryLimit >= 0 && retryCounter >= currRetryLimit) return;
      let session: WhiteboardWebRTCSession | undefined;
      let retryTimeoutNodeJS: NodeJS.Timeout | undefined;
      let timeoutNodeJS: NodeJS.Timeout | undefined;
      let finished = false;
      const finish = (success: boolean, err?: string) => {
        if (componentIsDestroyedRef.current) return;

        finished = true;
        // console.log('Finished : ', ip, success, err);
        setError(success ? undefined : err ?? "Unknown");
        if (timeoutNodeJS) clearTimeout(timeoutNodeJS);
        // console.log('Finished #1 : ', connectedRef.current, retryCounter, success);
        if (!success) {
          setPreviewMedia(undefined);
          const newRetryCounter = connectedRef.current ? 0 : retryCounter + 1;
          if (currRetryLimit < 0 || newRetryCounter < currRetryLimit) {
            retryTimeoutNodeJS = setTimeout(() => {
              if (retryCounter === newRetryCounter) setRetryCounter(-1);
              else setRetryCounter(newRetryCounter);
            }, currRetryTimeout);
          } else if (newRetryCounter === currRetryLimit) {
            setRetryCounter(newRetryCounter);
          }
          session?.destroy();
        }
        connectedRef.current = success;
      };
      timeoutNodeJS = setTimeout(() => {
        logger.log("WHITEBOARD - timeout");
        if (finished) return;
        finish(false, "Impossible to connect...");
      }, currentTimeoutConnect);
      setPreviewMedia(undefined);
      try {
        // console.log('Connect to kast : ', ip, retryCounter);
        session = new WhiteboardWebRTCSession(`ws://${ip}:${port ?? 8000}`, {
          defaultVideoSource,
          defaultAudioSource,
          defaultPreferVideoSource,
          defaultPreferAudioSource,
          defaultAudioPlaybackVolume,
          defaultAudioMicrophoneVolume,
        });
        session.addEventListener("message", (action: string, params: any) => {
          onMessageRef.current?.(action, params);
          if (action === "context/updated") {
            setContext(params);
          }
        });
        session.addEventListener("connect", () => {
          logger.log("WHITEBOARD - CONNECT : ", ip);
          if (firstConnexionRef.current) {
            // TODO set default values
            session?.updateAudioSource(defaultAudioSource, defaultAudioPlaybackVolume, defaultAudioMicrophoneVolume);
          }
          firstConnexionRef.current = false;
        });
        session.addEventListener("disconnect", () => {
          logger.log("WHITEBOARD - DISCONNECT : ", ip);
          finish(false, "Disconnected");
        });
        const promiseMedia = session.getPromiseMedia();
        if (promiseMedia) {
          promiseMedia
            .then((m) => {
              if (finished) return;
              finish(true);
              setPreviewMedia(m);
            })
            .catch((reason) => {
              if (finished) return;
              logger.log("WHITEBOARD - error : ", reason);
              finish(false, reason);
            });
          listenersRef.current?.triggerEvent("whiteboardWebRTCSession", session);
          setWhiteboardWebRTCSession(session);
        } else {
          if (finished) return;
          logger.log("WHITEBOARD - no media");
          finish(false, "No media");
          session.destroy();
        }
      } catch (err) {
        logger.log("WHITEBOARD - error catched : ", err);
        if (finished) return;
        finish(false, (err as any)?.message);
        listenersRef.current?.triggerEvent("whiteboardWebRTCSession", undefined);
        setWhiteboardWebRTCSession(undefined);
      }

      return () => {
        if (retryTimeoutNodeJS) clearTimeout(retryTimeoutNodeJS);
        if (timeoutNodeJS) clearTimeout(timeoutNodeJS);
        session?.removeAllEventListeners();
        session?.destroy();
      };
    }, [
      ip,
      port,
      defaultVideoSource,
      defaultAudioSource,
      defaultPreferVideoSource,
      defaultPreferAudioSource,
      defaultAudioPlaybackVolume,
      defaultAudioMicrophoneVolume,
      retryCounter,
      listenersRef,
      timeoutConnect,
      retryTimeout,
      retryLimit,
    ]);

    useEffect(() => {
      onMediaRef.current = onMedia;
    }, [onMedia]);

    useEffect(() => {
      return () => {
        if (previewMedia) destroyMediaStream(previewMedia);
      };
    }, [previewMedia]);

    useEffect(() => {
      if (mediaRef.current) destroyMediaStream(mediaRef.current);
      mediaRef.current = previewMedia?.clone();
      onMediaRef.current?.(mediaRef.current);
      listenersRef.current?.triggerEvent("media", mediaRef.current);
    }, [previewMedia, listenersRef]);

    useEffect(() => {
      listenersRef.current?.triggerEvent("context", context);
    }, [context, listenersRef]);

    const renderPlayer = () => {
      return (
        <div className={styles.playerContainer}>
          <Video autoPlay play mute media={previewMedia} className={styles.video} />
          <WhiteboardPlayerOverlay session={whiteboardWebRTCSession} />
        </div>
      );
    };

    const renderError = () => (
      <div className={styles.errorContainer}>
        <div className={styles.error}>{error}</div>
        <Button
          className={styles.retryButton}
          title={"Réessayer" /* TRANSLATION */}
          onPress={() => {
            setRetryCounter(0);
          }}
        />
      </div>
    );

    const renderContent = () => {
      if (error && RETRY_LIMIT >= 0 && retryCounter >= RETRY_LIMIT) return renderError();
      if (previewMedia) return renderPlayer();
      return <Loading message={"Connexion en cours..." /* TRANSLATION */} />;
    };

    if (!display) return null;

    return (
      <div className={classes.join(" ")} style={style}>
        {renderContent()}
      </div>
    );
  }
);

WhiteboardPlayer.defaultProps = {
  display: true,
  className: undefined,
  style: undefined,
};

export default WhiteboardPlayer;

import { logger } from "./logger";
import { applyAudioTrackContraints, applyMediaConstraints, applyVideoTrackContraints } from "./media";

export interface AssignParamsOnRTCPeerConnectionParams {
  videoMediaContentHint?: "" | "motion" | "detail" | "text";
  audioMediaContentHint?: "" | "speech" | "speech-recognition" | "music";
  videoMediaConstrains?: Partial<MediaTrackConstraints>;
  audioMediaConstrains?: Partial<MediaTrackConstraints>;
  videoRtcParams?: Partial<Omit<RTCRtpSendParameters, "encodings">>;
  audioRtcParams?: Partial<Omit<RTCRtpSendParameters, "encodings">>;
  videoRtcEncoding?: Partial<RTCRtpEncodingParameters>;
  audioRtcEncoding?: Partial<RTCRtpEncodingParameters>;
}

export const assignParamsOnRTCPeerConnection = async (rtcPeerConnection: RTCPeerConnection, rtcParams: AssignParamsOnRTCPeerConnectionParams) => {
  const senders = rtcPeerConnection.getSenders();
  const promises: Promise<void>[] = [];
  senders.forEach(async (sender: RTCRtpSender) => {
    promises.push(new Promise(async (resolve) => {
      const params = sender.getParameters();
      try {
        if (sender.track?.kind === "video") {
          if (sender.track) await applyVideoTrackContraints(sender.track, rtcParams ?? {});
          params.encodings.forEach((encodingParams: RTCRtpEncodingParameters) => {
            if (rtcParams?.videoRtcEncoding) Object.assign(encodingParams, rtcParams.videoRtcEncoding);
          });
          if (rtcParams?.videoRtcParams) Object.assign(params, rtcParams?.videoRtcParams);
        } else if (sender.track?.kind === "audio") {
          if (sender.track) await applyAudioTrackContraints(sender.track, rtcParams ?? {});
          params.encodings.forEach((encodingParams: RTCRtpEncodingParameters) => {
            if (rtcParams?.audioRtcEncoding) Object.assign(encodingParams, rtcParams.audioRtcEncoding);
          });
          if (rtcParams?.audioRtcParams) Object.assign(params, rtcParams?.audioRtcParams);
        }
        if (rtcPeerConnection.connectionState === 'connected') {
          logger.log('[RTC PARAMS] - ', sender.track?.kind, ' : ', params);
          await sender.setParameters(params);
        }
      } catch (err) {
        /**/
      } finally {
        resolve();
      }
    }));
  });
  await Promise.allSettled(promises);
};

export interface RefreshMediaOnRTCPeerConnectionOptions {
  params: AssignParamsOnRTCPeerConnectionParams;
}

export const refreshMediaOnRTCPeerConnection = async (
  rtcPeerConnection: RTCPeerConnection,
  mediaStream: MediaStream | undefined,
  options?: RefreshMediaOnRTCPeerConnectionOptions
) => {
  const videoTracks = mediaStream?.getVideoTracks() ?? [];
  const audioTracks = mediaStream?.getAudioTracks() ?? [];
  const videoTrack = videoTracks.length ? videoTracks[0] : undefined;
  const audioTrack = audioTracks.length ? audioTracks[0] : undefined;
  if (mediaStream) await applyMediaConstraints(mediaStream, options?.params ?? {});
  const senders = rtcPeerConnection.getSenders();
  const promises: Promise<void>[] = [];
  senders.forEach(async (sender: RTCRtpSender) => {
    promises.push(new Promise(async (resolve) => {
      try {
        if (sender.track?.kind === "video") {
          if (videoTrack) {
            if (sender.track.id !== videoTrack.id) {
              logger.log("[OVENMEDIA - REFRESH] - replace video track : ", rtcPeerConnection.connectionState, videoTrack);
              await sender.replaceTrack(videoTrack);
            }
          } else if (sender.track) {
            sender.track.enabled = false;
          }
        } else if (sender.track?.kind === "audio") {
          if (audioTrack) {
            if (sender.track.id !== audioTrack.id) {
              logger.log("[OVENMEDIA - REFRESH] - replace autio track : ", rtcPeerConnection.connectionState, audioTrack);
              await sender.replaceTrack(audioTrack);
            }
          } else if (sender.track) {
            sender.track.enabled = false;
          }
        }
      } catch (err) {
        /**/
      } finally {
        resolve();
      }
    }));
  });
  await Promise.allSettled(promises);
  if (options?.params) await assignParamsOnRTCPeerConnection(rtcPeerConnection, options.params);
};

export interface RTCStreamVideoTrackStats {
  mode: "input" | "output",
  videoEnabled?: boolean,
  videoLastMeasurementTime?: number,
  videoMeasurementTime?: number,
  videoTotalMeasurementDuration?: number,
  videoTargetBitrate?: number,
  videoBitsSent?: number,
  videoBitsReceived?: number,
  videoBitrate?: number,
  videoAverageBitrate?: number,
  videoMimeType?: string,
  videoCodecId?: string,
  videoEncoder?: string,
  videoDecoder?: string,
  videoFrameWidth?: number,
  videoFrameHeight?: number,
  videoFramePerSecond?: number,
  videoJitter?: number,
  videoAverageJitter?: number,
  videoPacketsReceived?: number,
  videoPacketsSent?: number,
  videoPacketsLostSnap?: number,
  videoPercentagePacketsLostSnap?: number,
  videoPacketsLost?: number,
  videoPercentagePacketsLost?: number,
}

export interface RTCStreamAudioTrackStats {
  mode: "input" | "output",
  audioEnabled?: boolean,
  audioLastMeasurementTime?: number,
  audioMeasurementTime?: number,
  audioTotalMeasurementDuration?: number,
  audioTargetBitrate?: number,
  audioBitsSent?: number,
  audioBitsReceived?: number,
  audioBitrate?: number,
  audioAverageBitrate?: number,
  audioMimeType?: string,
  audioCodecId?: string,
  audioEncoder?: string,
  audioDecoder?: string,
  audioJitter?: number,
  audioAverageJitter?: number,
  audioPacketsReceived?: number,
  audioPacketsSent?: number,
  audioPacketsLostSnap?: number,
  audioPercentagePacketsLostSnap?: number,
  audioPacketsLost?: number,
  audioPercentagePacketsLost?: number,
}

export interface RTCStreamStats extends RTCStreamVideoTrackStats, RTCStreamAudioTrackStats {}

export interface RTCStreamVideoTrackStatsResult {
  track: MediaStreamTrack,
  stats: RTCStreamVideoTrackStats,
  reports: any[],
}

export interface RTCStreamAudioTrackStatsResult {
  track: MediaStreamTrack,
  stats: RTCStreamAudioTrackStats,
  reports: any[],
}

export interface RTCStreamStatsResult {
  media: MediaStream,
  stats: RTCStreamStats,
  reports: {[trackId: string]: any[]}
}

export const getRTCStreamVideoTrackStats = async (peerConnection: RTCPeerConnection, track: MediaStreamTrack, mode: 'input' | 'output', previous?: RTCStreamVideoTrackStatsResult): Promise<RTCStreamVideoTrackStatsResult | undefined> => {
  if (!track || track.kind !== 'video') return undefined;
  const time = new Date().getTime();
  let stats: RTCStatsReport | undefined;
  try {
    stats = await peerConnection.getStats(track);
  } catch (_) {}
  if (!stats) return undefined;
  const reports = Array.from(stats.values());
  const bound = reports.find((report) => {
    return report?.type === (mode === 'input' ? 'inbound-rtp' : 'outbound-rtp')
  });
  const previousBound = previous?.reports?.find((report) => {
    return report?.type === (mode === 'input' ? 'inbound-rtp' : 'outbound-rtp');
  });
  const remoteBound = reports.find((report) => {
    return report?.type === (mode === 'input' ? 'remote-outbound-rtp' : 'remote-inbound-rtp');
  });
  const previousRemoteBound = previous?.reports?.find((report) => {
    return report?.type === (mode === 'input' ? 'remote-outbound-rtp' : 'remote-inbound-rtp');
  });
  const codec = reports.find((report) => {
    return report?.type === "codec"
  });
  const previousStats = previous?.stats;
  // console.log('Bound VIDEO - ', mode, ' : ', statsArray, bound, remoteBound);
  const bitsSent = (bound?.bytesSent ?? 0) * 8;
  const bitsReceived = (bound?.bytesReceived ?? 0) * 8;
  let bitrate: number | undefined;
  if (mode === 'input') {
    bitrate = (bitsReceived && previousStats?.videoBitsReceived && previousStats?.videoMeasurementTime) ? 1000 * (bitsReceived - previousStats.videoBitsReceived) / (time - previousStats.videoMeasurementTime): undefined;
  } else if (mode === 'output') {
    bitrate = (bitsSent && previousStats?.videoBitsSent && previousStats?.videoMeasurementTime) ? 1000 * (bitsSent - previousStats.videoBitsSent) / (time - previousStats.videoMeasurementTime): undefined;
  }

  let packetsLost = 0;
  let totalPacketsLost = 0;
  let totalPercentagePacketsLost = 0;
  let totalPacketsLostSnap: number | undefined;
  let totalPercentagePacketsLostSnap: number | undefined;

  if (mode === 'input') {
    packetsLost = bound?.packetsLost ?? 0;
    totalPacketsLost = packetsLost;
    const totalPackets = packetsLost + (bound?.packetsReceived ?? 0)
    totalPercentagePacketsLost = totalPackets ? totalPacketsLost / totalPackets : 0;
    if (previous) {
      const packetsLostSnap = packetsLost - (previousStats?.videoPacketsLost ?? 0);
      const packetsReceivedSnap = (bound?.packetsReceived ?? 0) - (previousStats?.videoPacketsReceived ?? 0);
      const totalPackets = packetsLostSnap + packetsReceivedSnap;
      if (packetsLostSnap >= 0 && packetsReceivedSnap >= 0 && totalPackets >= 0) {
        totalPacketsLostSnap = packetsLostSnap;
        totalPercentagePacketsLostSnap = totalPackets > 0 ? packetsLostSnap / totalPackets : 0;
      }
    }
  } else if (mode === 'output') {
    if (remoteBound && bound && previousStats && previousRemoteBound) {
      const roundTripDiff = remoteBound.roundTripTimeMeasurements - previousRemoteBound.roundTripTimeMeasurements;
      if (roundTripDiff > 0) {
        packetsLost = (remoteBound.packetsLost ?? 0) * roundTripDiff; // set "packetLost" of other roundTrip to same value
        totalPacketsLost = (previousStats.videoPacketsLost ?? 0) + packetsLost;
        totalPercentagePacketsLost = bound.packetsSent ? totalPacketsLost / bound.packetsSent : 0;
  
        const packetsLostSnap = packetsLost;
        const packetsSentSnap = (bound.packetsSent ?? 0) - (previousStats.videoPacketsSent ?? 0);
        const totalPackets = packetsLostSnap + packetsSentSnap;
        if (packetsLostSnap >= 0 && packetsSentSnap >= 0 && totalPackets >= 0) {
          totalPacketsLostSnap = packetsLostSnap;
          totalPercentagePacketsLostSnap = totalPackets > 0 ? packetsLostSnap / totalPackets : 0;
        }
      }
    }
  }

  let totalMeasurementDuration: number | undefined;
  let avgBitrate: number | undefined;
  let avgJitter: number | undefined;
  if (previousStats && previousStats.videoMeasurementTime) {
    totalMeasurementDuration = (previousStats.videoTotalMeasurementDuration ?? 0) + (time - previousStats.videoMeasurementTime);
    if (bitrate !== undefined) {
      avgBitrate = ((time - previousStats.videoMeasurementTime) * bitrate + (previousStats.videoTotalMeasurementDuration ?? 0) * (previousStats.videoAverageBitrate ?? 0)) / totalMeasurementDuration;
    }
    if (remoteBound?.jitter !== undefined) {
      avgJitter = ((time - previousStats.videoMeasurementTime) * remoteBound.jitter + (previousStats.videoTotalMeasurementDuration ?? 0) * (previousStats.videoAverageJitter ?? 0)) / totalMeasurementDuration;
    }
  }
  return {
    track,
    reports,
    stats: {
      mode,
      videoEnabled: track.enabled,
      videoLastMeasurementTime: previousStats?.videoMeasurementTime,
      videoMeasurementTime: time,
      videoTotalMeasurementDuration: totalMeasurementDuration,
      videoTargetBitrate: bound?.targetBitrate,
      videoBitsSent: bitsSent,
      videoBitsReceived: bitsReceived,
      videoBitrate: bitrate ? Math.floor(bitrate) : undefined,
      videoAverageBitrate: avgBitrate ? Math.floor(avgBitrate) : undefined,
      videoMimeType: codec?.mimeType,
      videoCodecId: bound?.codecId,
      videoEncoder: bound?.encoderImplementation,
      videoDecoder: bound?.decoderImplementation,
      videoFrameWidth: bound?.frameWidth,
      videoFrameHeight: bound?.frameHeight,
      videoFramePerSecond: Math.ceil(bound?.framesPerSecond ?? 0),
      videoJitter: mode === 'input' ? bound?.jitter : remoteBound?.jitter,
      videoAverageJitter: avgJitter,
      videoPacketsReceived: bound?.packetsReceived,
      videoPacketsSent: bound?.packetsSent,
      videoPacketsLostSnap: totalPacketsLostSnap,
      videoPercentagePacketsLostSnap: totalPercentagePacketsLostSnap,
      videoPacketsLost: totalPacketsLost,
      videoPercentagePacketsLost: totalPercentagePacketsLost,
    }
  }
}

export const getRTCStreamAudioTrackStats = async (peerConnection: RTCPeerConnection, track: MediaStreamTrack, mode: 'input' | 'output', previous?: RTCStreamAudioTrackStatsResult): Promise<RTCStreamAudioTrackStatsResult | undefined> => {
  if (!track || track.kind !== 'audio') return undefined;
  const time = new Date().getTime();
  let stats: RTCStatsReport | undefined;
  try {
    stats = await peerConnection.getStats(track);
  } catch (_) {}
  if (!stats) return undefined;
  const reports = Array.from(stats.values());
  const bound = reports.find((report) => {
    return report?.type === (mode === 'input' ? 'inbound-rtp' : 'outbound-rtp');
  });
  const previousBound = previous?.reports?.find((report) => {
    return report?.type === (mode === 'input' ? 'inbound-rtp' : 'outbound-rtp');
  });
  const remoteBound = reports.find((report) => {
    return report?.type === (mode === 'input' ? 'remote-outbound-rtp' : 'remote-inbound-rtp');
  });
  const previousRemoteBound = previous?.reports?.find((report) => {
    return report?.type === (mode === 'input' ? 'remote-outbound-rtp' : 'remote-inbound-rtp');
  });
  const previousStats = previous?.stats;
  const codec = reports.find((report) => {
    return report?.type === "codec";
  });
  // console.log('Bound AUDIO - ', mode, ' : ', statsArray, bound, remoteBound);
  const bitsSent = (bound?.bytesSent ?? 0) * 8;
  const bitsReceived = (bound?.bytesReceived ?? 0) * 8;
  let bitrate: number | undefined;
  if (mode === 'input') {
    bitrate = (bitsReceived && previousStats?.audioBitsReceived && previousStats?.audioMeasurementTime) ? 1000 * (bitsReceived - previousStats.audioBitsReceived) / (time - previousStats.audioMeasurementTime): undefined;
  } else if (mode === 'output') {
    bitrate = (bitsSent && previousStats?.audioBitsSent && previousStats?.audioMeasurementTime) ? 1000 * (bitsSent - previousStats.audioBitsSent) / (time - previousStats.audioMeasurementTime): undefined;
  }

  let packetsLost = 0;
  let totalPacketsLost = 0;
  let totalPercentagePacketsLost = 0;
  let totalPacketsLostSnap: number | undefined;
  let totalPercentagePacketsLostSnap: number | undefined;

  if (mode === 'input') {
    packetsLost = bound?.packetsLost ?? 0;
    totalPacketsLost = packetsLost;
    const totalPackets = packetsLost + (bound?.packetsReceived ?? 0)
    totalPercentagePacketsLost = totalPackets ? totalPacketsLost / totalPackets : 0;
    if (previous) {
      const packetsLostSnap = packetsLost - (previousStats?.audioPacketsLost ?? 0);
      const packetsReceivedSnap = (bound?.packetsReceived ?? 0) - (previousStats?.audioPacketsReceived ?? 0);
      const totalPackets = packetsLostSnap + packetsReceivedSnap;
      if (packetsLostSnap >= 0 && packetsReceivedSnap >= 0 && totalPackets > 0) {
        totalPacketsLostSnap = packetsLostSnap;
        totalPercentagePacketsLostSnap = packetsLostSnap / totalPackets;
      }
    }
  } else if (mode === 'output') {
    if (remoteBound && bound && previousStats && previousRemoteBound) {
      const roundTripDiff = remoteBound.roundTripTimeMeasurements - previousRemoteBound.roundTripTimeMeasurements;
      if (roundTripDiff > 0) {
        packetsLost = (remoteBound.packetsLost ?? 0) * roundTripDiff; // set "packetLost" of other roundTrip to same value
        totalPacketsLost = (previousStats.audioPacketsLost ?? 0) + packetsLost;
        totalPercentagePacketsLost = bound.packetsSent ? totalPacketsLost / bound.packetsSent : 0;
  
        const packetsLostSnap = packetsLost;
        const packetsSentSnap = (bound.packetsSent ?? 0) - (previousStats.audioPacketsLost ?? 0);
        const totalPackets = packetsLostSnap + packetsSentSnap;
        if (packetsLostSnap >= 0 && packetsSentSnap >= 0 && totalPackets > 0) {
          totalPacketsLostSnap = packetsLostSnap;
          totalPercentagePacketsLostSnap = packetsLostSnap / totalPackets;
        }
      }
    }
  }


  let totalMeasurementDuration: number | undefined;
  let avgBitrate: number | undefined;
  let avgJitter: number | undefined;
  if (previousStats && previousStats.audioMeasurementTime) {
    totalMeasurementDuration = (previousStats.audioTotalMeasurementDuration ?? 0) + (time - previousStats.audioMeasurementTime);
    if (bitrate !== undefined) {
      avgBitrate = ((time - previousStats.audioMeasurementTime) * bitrate + (previousStats.audioTotalMeasurementDuration ?? 0) * (previousStats.audioAverageBitrate ?? 0)) / totalMeasurementDuration;
    }
    if (remoteBound?.jitter !== undefined) {
      avgJitter = ((time - previousStats.audioMeasurementTime) * remoteBound.jitter + (previousStats.audioTotalMeasurementDuration ?? 0) * (previousStats.audioAverageJitter ?? 0)) / totalMeasurementDuration;
    }
  }
  return {
    track,
    reports,
    stats: {
      mode,
      audioEnabled: track.enabled,
      audioLastMeasurementTime: previousStats?.audioMeasurementTime,
      audioMeasurementTime: time,
      audioTotalMeasurementDuration: totalMeasurementDuration,
      audioTargetBitrate: bound?.targetBitrate,
      audioBitsSent: bitsSent,
      audioBitsReceived: bitsReceived,
      audioBitrate: bitrate ? Math.floor(bitrate) : undefined,
      audioAverageBitrate: avgBitrate ? Math.floor(avgBitrate) : undefined,
      audioMimeType: codec?.mimeType,
      audioCodecId: bound?.codecId,
      audioEncoder: bound?.encoderImplementation,
      audioDecoder: bound?.decoderImplementation,
      audioJitter: mode === 'input' ? bound?.jitter : remoteBound?.jitter,
      audioAverageJitter: avgJitter,
      audioPacketsReceived: bound?.packetsReceived,
      audioPacketsSent: bound?.packetsSent,
      audioPacketsLostSnap: totalPacketsLostSnap,
      audioPercentagePacketsLostSnap: totalPercentagePacketsLostSnap,
      audioPacketsLost: totalPacketsLost,
      audioPercentagePacketsLost: totalPercentagePacketsLost,
    }
  }
}



export const getRTCStreamStats = async (peerConnection: RTCPeerConnection, stream: MediaStream, mode: 'input' | 'output', previous?: RTCStreamStatsResult): Promise<RTCStreamStatsResult> => {
  const videoTracks = stream.getVideoTracks();
  const videoTrack = videoTracks.length ? videoTracks[0] : undefined;
  const audioTracks = stream.getAudioTracks();
  const audioTrack = audioTracks.length ? audioTracks[0] : undefined;
  const stats: RTCStreamStats = { mode };
  const reports: {[trackId: string]: any[]} = {};
  if (videoTrack) {
    const videoStats = await getRTCStreamVideoTrackStats(peerConnection, videoTrack, mode, previous ? {
      track: videoTrack,
      reports: previous.reports?.[videoTrack.id],
      stats: previous.stats,
    } : undefined);
    if (videoStats) {
      if (videoStats.reports) reports[videoTrack.id] = videoStats.reports;
      if (videoStats.stats) Object.assign(stats, videoStats.stats);
    }
  }
  if (audioTrack) {
    const audioStats = await getRTCStreamAudioTrackStats(peerConnection, audioTrack, mode,  previous ? {
      track: audioTrack,
      reports: previous.reports?.[audioTrack.id],
      stats: previous.stats,
    } : undefined);
    if (audioStats) {
      if (audioStats.reports) reports[audioTrack.id] = audioStats.reports;
      if (audioStats.stats) Object.assign(stats, audioStats.stats);
    }
  }
  return {
    media: stream,
    stats,
    reports,
  };
}
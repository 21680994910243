import { HttpStatusCode } from "../constants/httpStatusCode";

export interface IApiError {
  name: string;
  message: string;
  stack: string;
  code: number; // = -ApiErrorCode
  cname: string;
  data: any;
  httpCode: HttpStatusCode;
}
export class ApiError implements IApiError {
  name: string;
  message: string;
  stack: string;
  code: number; // = -ApiErrorCode
  cname: string;
  data: any;
  httpCode: HttpStatusCode;
  constructor(data: IApiError) {
    this.name = data?.name;
    this.message = data?.message;
    this.stack = data?.stack;
    this.code = data?.code;
    this.cname = data?.cname;
    this.data = data?.data;
    this.httpCode = data?.httpCode;
  }

  static isApiError(error: any) : error is ApiError {
    if (!error) return false;
    if (error.code === undefined) return false;
    if (error.httpCode === undefined) return false;
    return true;

  }
}
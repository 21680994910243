import { Touchable } from "@kalyzee/kast-app-web-components";
import { Meeting } from "@kalyzee/kast-websocket-module";
import React, { useContext, useRef } from "react";
import { Location, NavigateOptions, Params, useLocation, useNavigate } from "react-router-dom";
import { logger } from "../../helpers/logger";
import { getGlobalQueryLabel, NavigateGlobalQuery } from "../../helpers/navigate";
import { useAppDispatch } from "../../hooks/app";
import { useMode } from "../../store/session/hooks";
import { SessionMode, setMode } from "../../store/session/slices";
import SettingsPopup, { SettingsPopupRef } from "../SettingsPopup";
import styles from "./PageContext.module.css";

export enum PageMasterMode {
  LOGIN = "login",
  LOGOUT = "logout",
  JOIN = "join",
  EXCLUDED = "excluded",
  PAIRING = "pairing",
  MEETING = "meeting",
  WHITEBOARD = "whiteboard",
  FORBIDDEN = 'forbidden',
}

export enum PageSlaveMode {
  LOGIN = "login",
  LOGOUT = "logout",
  SPECTATOR_WAITING = "spectator_waiting",
  SPECTATOR = "spectator",
  CONTROLS_WAITING = "controls_waiting",
  CONTROLS = "controls",
  WHITEBOARD_WAITING = "whiteboard_waiting",
  WHITEBOARD = "whiteboard",
  EXCLUDED = "excluded",
  REMOTE = "remote",
  FORBIDDEN = 'forbidden',
  PAIRING = "pairing",
}

export const PageMap = {
  [SessionMode.MASTER]: PageMasterMode,
  [SessionMode.SLAVE]: PageSlaveMode,
};
export interface PageMap {
  [SessionMode.MASTER]: PageMasterMode;
  [SessionMode.SLAVE]: PageSlaveMode;
}


export interface PageParamsMap {
  [SessionMode.MASTER]: {
    [PageMasterMode.LOGIN]: undefined,
    [PageMasterMode.LOGOUT]: undefined,
    [PageMasterMode.JOIN]: { id: string },
    [PageMasterMode.EXCLUDED]: { id: string },
    [PageMasterMode.PAIRING]: undefined,
    [PageMasterMode.MEETING]: { id: string },
    [PageMasterMode.WHITEBOARD]: undefined
    [PageMasterMode.FORBIDDEN]: undefined,
  },
  [SessionMode.SLAVE]: {
    [PageSlaveMode.LOGIN]: undefined,
    [PageSlaveMode.LOGOUT]: undefined,
    [PageSlaveMode.SPECTATOR_WAITING]: undefined,
    [PageSlaveMode.SPECTATOR]: { id: string },
    [PageSlaveMode.CONTROLS_WAITING]: undefined,
    [PageSlaveMode.CONTROLS]: { id: string },
    [PageSlaveMode.WHITEBOARD_WAITING]: undefined,
    [PageSlaveMode.WHITEBOARD]: { id: string },
    [PageSlaveMode.EXCLUDED]: { id: string },
    [PageSlaveMode.REMOTE]: undefined,
    [PageSlaveMode.FORBIDDEN]: undefined,
    [PageMasterMode.PAIRING]: undefined,
  }
}

export const PAGE_PATHS: {[k in SessionMode]: {[key in PageMap[k]]: string}} = {
  [SessionMode.MASTER]: {
    [PageMasterMode.LOGIN]: "/login",
    [PageMasterMode.LOGOUT]: "/logout",
    [PageMasterMode.JOIN]: "/",
    [PageMasterMode.EXCLUDED]: "/:id/excluded",
    [PageMasterMode.PAIRING]: "/pairing",
    [PageMasterMode.MEETING]: "/:id",
    [PageMasterMode.WHITEBOARD]: "/whiteboard",
    [PageMasterMode.FORBIDDEN]: "/forbidden",
  },
  [SessionMode.SLAVE]: {
    [PageSlaveMode.LOGIN]: "/login",
    [PageSlaveMode.LOGOUT]: "/logout",
    [PageSlaveMode.SPECTATOR_WAITING]: "/",
    [PageSlaveMode.SPECTATOR]: "/:id",
    [PageSlaveMode.CONTROLS_WAITING]: "/controls",
    [PageSlaveMode.CONTROLS]: "/controls/:id",
    [PageSlaveMode.WHITEBOARD_WAITING]: "/whiteboard",
    [PageSlaveMode.WHITEBOARD]: "/whiteboard/:id",
    [PageSlaveMode.EXCLUDED]: "/:id/excluded",
    [PageSlaveMode.REMOTE]: "/remote",
    [PageSlaveMode.FORBIDDEN]: "/forbidden",
    [PageMasterMode.PAIRING]: "/pairing",
  }
};

export interface PageOptions extends NavigateOptions {
  mode?: SessionMode;
  queries?: { [key: string]: string | undefined };
}

export interface PageContextState<M extends SessionMode> {
  from: {
    location?: Location;
    page?: PageMap[M];
    params?: Params;
  };
  location?: Location;
  params?: Params;
  page?: PageMap[M];
  searchParams?: URLSearchParams;
  mode: M;
  goTo?: <T extends PageMap[M]>(page: T, params?: PageParamsMap[M], options?: PageOptions) => void;
  goToPrevious?: () => void;
  openSettingsPopup: (meeting?: Meeting) => void;
}
export const PageContext = React.createContext<PageContextState<any>>({ from: {}, mode: SessionMode.MASTER, openSettingsPopup: () => {} });

export const getPageUrl = <M extends SessionMode, T extends PageMap[M]>(mode: M, page: T, params?: PageParamsMap[M], options?: PageOptions): string => {
  let path = (PAGE_PATHS[mode] as any)[page];
  const queries = options?.queries ?? {};
  if (mode === SessionMode.SLAVE) queries[getGlobalQueryLabel(NavigateGlobalQuery.SLAVE)] = undefined;
  if (params) {
    const keys = Object.keys(params);
    keys.forEach((k) => {
      const regex = new RegExp(`(/:${k})(/|$)`);
      const param = (params as any)[k];
      path = path.replace(regex, `/${param}$2`);
    });
  }
  if (queries) {
    const keys = Object.keys(queries);
    if (keys.length) {
      let urlQueries = "?";
      keys.forEach((k, i) => {
        const query: string | undefined = queries[k];
        if (i !== 0) urlQueries = `${urlQueries}&`;
        if (query) urlQueries = `${urlQueries}${k}=${query}`;
        else urlQueries = `${urlQueries}${k}`;
      });
      path = `${path}${urlQueries}`;
    }
  }
  return path;
};

export const PageProvider = <M extends SessionMode>({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const previousLocation = useRef<Location>();
  const previousParams = useRef<Readonly<Params<string>>>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const mode = useMode();
  const pageRef = useRef<PageMap[M]>();
  const previousPageRef = useRef<PageMap[M]>();
  const settingsPopupRef = useRef<SettingsPopupRef>(null);
  const secretButtonDataRef = useRef<{ counter: number; timeout: NodeJS.Timeout | null }>({ counter: 0, timeout: null });

  const state: PageContextState<M> = {
    from: {
      location: previousLocation.current,
      params: previousParams.current,
      page: previousPageRef.current,
    },
    location,
    page: pageRef.current,
    searchParams: new URLSearchParams(location?.search),
    mode: mode as any,
    goTo: <T extends PageMap[M]>(page: T, params?: PageParamsMap[M], options?: PageOptions) => {
      const path = getPageUrl(mode, page, params, options);
      logger.log("[PAGE] Go to : ", page, " params : ", params, " path : ", path);
      previousLocation.current = state.location;
      previousParams.current = state.params;
      previousPageRef.current = state.page;
      pageRef.current = page;
      navigate(path, options);
      if (options?.mode && options.mode !== mode) {
        dispatch(setMode(options.mode));
      }
    },
    goToPrevious: () => {
      const nextRoute = state?.from?.location;
      const nextPath = nextRoute?.pathname;
      const previousPage = previousPageRef.current;
      logger.log("[PAGE] Go to previous : ", previousPage, nextPath);
      previousLocation.current = state.location;
      previousParams.current = state.params;
      previousPageRef.current = state.page;
      if (!nextPath || nextRoute === state?.location) {
        pageRef.current = (PageMap[mode] as any).LOGIN;
        navigate((PAGE_PATHS[mode] as any)[pageRef.current]);
      } else {
        pageRef.current = previousPage;
        navigate(nextPath);
      }
    },
    openSettingsPopup: (meeting) => {
      return settingsPopupRef.current?.openPopup(meeting);
    },
  };

  return (
    <PageContext.Provider value={state}>
      {children}
      <SettingsPopup ref={settingsPopupRef} />
      <Touchable
        className={styles.secretButton}
        onPress={() => {
          secretButtonDataRef.current.counter += 1;
          if (secretButtonDataRef.current.counter >= 3) {
            state.openSettingsPopup();
          }
          if (secretButtonDataRef.current.timeout) clearTimeout(secretButtonDataRef.current.timeout);
          secretButtonDataRef.current.timeout = setTimeout(() => {
            secretButtonDataRef.current.counter = 0;
          }, 500);
        }}
      />
    </PageContext.Provider>
  );
};

export const usePageContext = <M extends SessionMode = any>(): PageContextState<M> => {
  return useContext(PageContext);
};

export const usePageContextRef = <M extends SessionMode = any>(): { current: PageContextState<M> } => {
  const context = useContext(PageContext);
  const ref = useRef<PageContextState<M>>(context);

  ref.current = context;
  return ref;
};


export const pageContextIsSessionMode = <M extends SessionMode>(mode: M, context: PageContextState<any>): context is PageContextState<M> => {
  return context?.mode === mode;
}
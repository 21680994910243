
const generateHeader = () => {
  const now = new Date();
  return `[${now.toLocaleDateString()} ${now.toLocaleTimeString()}.${`000${now.getMilliseconds()}`.slice(-3)}] `;
};
const headerStyle = "color:cyan";

export const logger = {
  log: (...args: any) => {
    console.log(`%c${generateHeader()}`, headerStyle, ...args);
  },
  info: (...args: any) => {
    console.info(`%c${generateHeader()}`, headerStyle, ...args);
  },
  warn: (...args: any) => {
    console.warn(`%c${generateHeader()}`, headerStyle, ...args);
  },
  error: (...args: any) => {
    console.error(`%c${generateHeader()}`, headerStyle, ...args);
  },
};
import { Loading } from "@kalyzee/kast-app-web-components";
import { Outlet } from "react-router-dom";
import { AuthStatus } from "../../interfaces/session";
import { SessionMode } from "../../store/session/slices";

export interface PublicRouteProps {
  authStatus: AuthStatus;
  mode: SessionMode;
}

const PublicRoute = ({ authStatus, mode }: PublicRouteProps) => {
  if (authStatus === AuthStatus.Loading) {
    return <Loading fullscreen />;
  }
  return <Outlet />;
};

export default PublicRoute;

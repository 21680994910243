import {
  MeetingSessionMediaUpdateRequest,
  MeetingSessionMediaUpdateResult,
  MeetingSessionUpdateMedia,
  MeetingSessionUpdateRequest,
  MeetingSessionUpdateResult,
} from "@kalyzee/kast-websocket-module";
import objectAssignDeep from "object-assign-deep";
import { useCallback, useEffect, useRef, useState } from "react";
import { MeetingSource } from "../components/MeetingSource";
import { DEFAULT_SETTINGS_SOURCES, Settings } from "../helpers/settings";
import { asyncDebounce } from "../helpers/utils";
import { MeetingSessionExtra } from "../interfaces/meeting";
import socketMeetingActions from "../store/meeting/actions";
import { useSocketAppDispatch } from "./app";
import { useSettings } from "./settings";

export const useMeetingSessionUpdater = (meetingId?: string, debounceTime = 100) => {
  const socketDispatch = useSocketAppDispatch();
  const queueRef = useRef<MeetingSessionUpdateRequest<MeetingSessionExtra>[]>([]);

  const debounce = useCallback(
    asyncDebounce(async () => {
      if (!queueRef.current?.length) return;
      const requests = queueRef.current;
      queueRef.current = [];
      let merged: Map<string, MeetingSessionUpdateRequest<MeetingSessionExtra>> = new Map();
      requests.forEach((r) => {
        if (r.id !== meetingId) return;
        let current = merged.get(r.sessionId);
        if (!current) {
          current = objectAssignDeep({}, r);
          merged.set(r.sessionId, current);
          return;
        }
        let medias = current?.medias;
        r.medias?.forEach((m1) => {
          if ("id" in m1) {
            let updateMedia: MeetingSessionUpdateMedia | undefined;
            medias?.forEach((m2) => {
              if ("id" in m2 && m1.id === m2.id) updateMedia = m2;
            });
            if (updateMedia) {
              // merge media
              objectAssignDeep(updateMedia, m1);
            } else {
              if(!medias) medias = [];
              medias.push(m1);
            }
          } else {
            if(!medias) medias = [];
            medias.push(m1);
          }
        });
        if (medias) Object.assign(current, r, { medias });
        else Object.assign(current, r);
      });
      if (!merged) return undefined;
      const promises: Promise<MeetingSessionUpdateResult>[] = [];
      merged.forEach((data) => {
        promises.push(socketDispatch<MeetingSessionUpdateResult>(socketMeetingActions.udapteSessionById({ data, storeResult: true })));
      });
      return await Promise.allSettled(promises);
    }, debounceTime),
    [meetingId]
  );

  const enqueueRequest = async (data: MeetingSessionUpdateRequest<MeetingSessionExtra>) => {
    queueRef.current.push(data);
    const meetingId = data.id;
    const sessionId = data.sessionId;
    const results = await debounce();
    let result: MeetingSessionUpdateResult | undefined;
    results?.forEach((p) => {
      if (p.status !== "fulfilled") return;
      const content = p.value?.command?.content;
      if (content && content.id === meetingId && content.sessionId === sessionId) {
        result = p.value;
      }
    });
    return result;
  };

  return enqueueRequest;
};

export const useMeetingSessionUpdaterRef = (meetingId?: string, debounceTime = 100) => {
  const updater = useMeetingSessionUpdater(meetingId, debounceTime);
  const ref = useRef(updater);
  useEffect(() => {
    ref.current = updater;
  }, [updater]);
  return ref;
};

export const useMeetingSessionMediaUpdater = (meetingId?: string, debounceTime = 100) => {
  const socketDispatch = useSocketAppDispatch();
  const queueRef = useRef<MeetingSessionMediaUpdateRequest[]>([]);

  const debounce = useCallback(
    asyncDebounce(async () => {
      if (!queueRef.current?.length) return;
      const requests = queueRef.current;
      queueRef.current = [];
      let merged: Map<string, MeetingSessionMediaUpdateRequest> = new Map();
      requests.forEach((r) => {
        if (r.id !== meetingId) return;
        let current = merged.get(r.mediaId);
        if (!current) {
          current = objectAssignDeep({}, r);
          merged.set(r.mediaId, current);
          return;
        }

        objectAssignDeep(current, r);
      });
      if (!merged) return undefined;
      const promises: Promise<MeetingSessionMediaUpdateResult>[] = [];
      merged.forEach((data) => {
        promises.push(socketDispatch<MeetingSessionMediaUpdateResult>(socketMeetingActions.udapteSessionMediaById({ data, storeResult: true })));
      });
      return await Promise.allSettled(promises);
    }, debounceTime),
    [meetingId]
  );

  const enqueueRequest = async (data: MeetingSessionMediaUpdateRequest) => {
    queueRef.current.push(data);
    const meetingId = data.id;
    const sessionId = data.sessionId;
    const mediaId = data.mediaId;
    const results = await debounce();
    let result: MeetingSessionMediaUpdateResult | undefined;
    results?.forEach((p) => {
      if (p.status !== "fulfilled") return;
      const content = p.value?.command?.content;
      if (content && content.id === meetingId && content.sessionId === sessionId && content.mediaId === mediaId) {
        result = p.value;
      }
    });
    return result;
  };

  return enqueueRequest;
};

export const useMeetingSessionMediaUpdaterRef = (meetingId?: string, debounceTime = 100) => {
  const updater = useMeetingSessionMediaUpdater(meetingId, debounceTime);
  const ref = useRef(updater);
  useEffect(() => {
    ref.current = updater;
  }, [updater]);
  return ref;
};

export const extractMeetingSources = (settings: Settings): MeetingSource[] => {
  return (settings.master?.pages?.meeting?.sources ?? DEFAULT_SETTINGS_SOURCES)
    .sort((a, b) => {
      return (b.priority ?? 0) - (a.priority ?? 0);
    })
    .filter((s) => !s.disabled);
};

export const useMeetingSources = (): MeetingSource[] => {
  const [settings] = useSettings();
  const [sources, setSources] = useState<MeetingSource[]>([]);
  useEffect(() => {
    setSources(extractMeetingSources(settings));
  }, [settings]);
  return sources;
};

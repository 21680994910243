import { PopupButtonType, PopupIconType, showPopup } from "@kalyzee/kast-app-web-components";
import { useEffect } from "react";
import background from "../../assets/backgrounds/kast.png";
import { pageContextIsSessionMode, PageMasterMode, usePageContextRef } from "../../components/navigation/PageContext";
import { SessionMode } from "../../store/session/slices";
import styles from "./excluded.module.css";

const ExcludedPage = () => {
  const pageContextRef = usePageContextRef();

  useEffect(() => {
    showPopup({
      title: "Exclus",
      iconTitle: PopupIconType.ERROR,
      content: "Vous avez été exclu du meeting.",
      buttons: [{ type: PopupButtonType.OK, element: "OK" }],
      enableBackdropDismiss: false,
      enableCloseButton: false,
      onClose: () => {         
        pageContextRef.current.goTo?.(PageMasterMode.JOIN);
        const pageContext = pageContextRef.current;
        if (pageContextIsSessionMode(SessionMode.MASTER, pageContext)) pageContext.goTo?.(PageMasterMode.JOIN);
        else pageContext.goToPrevious?.();
      },
    });
  }, [pageContextRef]);

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${background})` }}>
      {/*<Touchable
        onPress={() => {
          page.goTo(Page.HOME);
        }}
        className={styles.backButton}
      >
        <IconBack width="100%" height="100%" />
      </Touchable>

      <div className={styles.contentcontainer}>
        Vous avez été exclu du meeting
      </div> */}
    </div>
  );
};

export default ExcludedPage;

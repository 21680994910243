import { AppVersion, PopupButtonType, PopupIconType, showPopup, Touchable, useURL } from "@kalyzee/kast-app-web-components";
import { useEffect, useRef, useState } from "react";
import ImgBackground from "../../assets/backgrounds/kast.png";
import { ReactComponent as IconClose } from "../../assets/icons/close.svg";
import { pageContextIsSessionMode, PageMasterMode, PageSlaveMode, usePageContextRef } from "../../components/navigation/PageContext";
import { useSettings } from "../../hooks/settings";
import { SessionMode } from "../../store/session/slices";
import styles from "./join.module.css";

const JoinPage = () => {
  const url = useURL();
  const pageContextRef = usePageContextRef();
  const inputRef = useRef<HTMLInputElement>(null);
  const [enableButton, setEnableButton] = useState(false);
  const [settings, saveSettings] = useSettings();

  const validate = (id: string) => {
    const pageContext = pageContextRef.current;
    if (pageContextIsSessionMode(SessionMode.SLAVE, pageContext)) pageContext.goTo?.(PageSlaveMode.SPECTATOR, { id });
    else pageContext.goTo?.(PageMasterMode.MEETING, { id });
  };

  useEffect(() => {
    if (settings.autoJoinMeeting && url.searchParams.get("autojoin") !== "false") {
      const meetingId = settings.autoJoinMeeting;
      const pageContext = pageContextRef.current;
      if (pageContextIsSessionMode(SessionMode.SLAVE, pageContext)) pageContext.goTo?.(PageSlaveMode.SPECTATOR, { id: meetingId });
      else pageContext.goTo?.(PageMasterMode.MEETING, { id: meetingId });
    }
  }, [url, settings, pageContextRef]);

  const renderFavorites = () => {
    const renderFavorite = (id: string, name?: string) => {
      if (!id) return null;
      return (
        <div key={id} className={styles.favoriteContainer}>
          <Touchable
            className={styles.favoriteButton}
            onPressOut={() => {
              if (id) validate(id);
            }}
          >
            <div className={styles.favoriteButtonContainer}>
              {name ? <span className={styles.favoriteName}>{`${name}`}</span> : null}
              <div className={styles.favoriteId}>{`id: ${id}`}</div>
            </div>
          </Touchable>
          <Touchable
            className={styles.favoriteRemoveButton}
            onPressOut={() => {
              const newFavorites = settings.favorites?.filter((d) => d.id !== id);
              showPopup({
                title: "Retirer de vos favoris",
                iconTitle: PopupIconType.INFO,
                content: `Êtes-vous sûr de vouloir retirer ce meeting de vos favoris?`,
                buttons: [
                  { type: PopupButtonType.CANCEL, element: "Non" },
                  {
                    type: PopupButtonType.VALIDATE,
                    element: "Oui",
                    onClick: () => {
                      saveSettings({
                        favorites: newFavorites,
                      });
                      return true;
                    },
                  },
                ],
                enableBackdropDismiss: true,
                enableCloseButton: true,
              });
            }}
          >
            <IconClose width={10} strokeWidth={3} />
          </Touchable>
        </div>
      );
    };
    const favorites = settings.favorites ?? [];
    return (
      <>
        <div className={styles.description}>Choisissez un meeting parmis vos favoris:</div>
        <div className={styles.favoritesContainer}>{favorites?.map((d) => renderFavorite(d?.id, d?.name))}</div>
        <div className={styles.orContainer}>
          <div className={styles.orSeparator} />
          <div className={styles.orText}>OU</div>
          <div className={styles.orSeparator} />
        </div>
      </>
    );
  };

  return (
    <div className={styles.page} style={{ backgroundImage: `url(${ImgBackground})` }}>
      <Touchable
        className={styles.disconnect}
        onPress={() => {
          const pageContext = pageContextRef.current;
          if (pageContextIsSessionMode(SessionMode.SLAVE, pageContext)) pageContext.goTo?.(PageSlaveMode.LOGOUT);
          else pageContext.goTo?.(PageMasterMode.LOGOUT);
        }}
      >
        Se déconnecter
      </Touchable>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>Rejoindre un meeting</div>
          {settings.favorites?.length ? renderFavorites() : null}
          <div className={styles.description}>Saisissez l'identifiant du meeting que vous souhaitez rejoindre:</div>
          <input
            className={styles.input}
            ref={inputRef}
            type="text"
            onChange={(e) => {
              const id = e.target?.value?.trim();
              setEnableButton(id.length ? true : false);
            }}
          />
        </div>

        <Touchable
          className={`${styles.button}${enableButton ? "" : ` ${styles.disabled}`}`}
          disabled={!enableButton}
          onPress={() => {
            const id = inputRef.current?.value?.trim();
            if (id) validate(id);
          }}
        >
          Rejoindre
        </Touchable>
      </div>
      <AppVersion />
    </div>
  );
};

export default JoinPage;

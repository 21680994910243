import {
  destroyMediaStream,
  getAudioTrack,
  getVideoTrack,
  Hover,
  HoverMessage,
  HoverMessageIcon,
  isVirtualMediaDeviceInfo,
  MediaCapture,
  MediaCaptureRef,
  MediaType,
  removeAllAudioTracks,
  removeAllVideoTracks,
  setAudioTrack,
  setVideoTrack,
  useStateWithRef,
  VirtualMediaDeviceInfo,
} from "@kalyzee/kast-app-web-components";
import React, { createRef, ForwardedRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ReactComponent as IconError } from "../assets/icons/error.svg";
import { logger } from "../helpers/logger";
import { applyMediaConstraints, ApplyMediaConstraintsOptions } from "../helpers/media";
import { DisplayOnWhiteboardOptions } from "../interfaces/whiteboard";
import KastPlayer, { KastPlayersRef } from "./KastPlayer";
import styles from "./MeetingSource.module.css";
import OvenMediaPlayer, { OvenMediaPlayerRef } from "./OvenMediaPlayer";
import VideoStats, { VideoStatsRef } from "./VideoStats";
import WhiteboardPlayer, { WhiteboardPlayerRef } from "./WhiteboardPlayer";

declare const MediaStreamTrackProcessor: any;
declare const MediaStreamTrackGenerator: any;
declare const VideoEncoder: any;
declare const VideoFrame: any;

export enum MeetingSourceDevicePTZMode {
  CUSTOM = 'custom',
  AVER = 'aver', // https://www.averusa.com/pro-av/downloads/control-codes/TR3XX_V2_CGI_Commands.pdf
}

export interface MeetingSourceDevicePTZDataMap {
  [MeetingSourceDevicePTZMode.CUSTOM]: undefined,
  [MeetingSourceDevicePTZMode.AVER]: { hostname: string, username?: string, password?: string };
}

export type MeetingSourceDevicePTZCustomData = { url: 'string', init?: RequestInit };
export type MeetingSourceDevicePTZCustom = {
  move: {[key in 'up' | 'down' | 'left' | 'right']: MeetingSourceDevicePTZCustomData},
  stopMove: {[key in 'up' | 'down' | 'left' | 'right']: MeetingSourceDevicePTZCustomData},
  zoom: {[key in 'in' | 'out']: MeetingSourceDevicePTZCustomData},
  stopZoom: {[key in 'in' | 'out']: MeetingSourceDevicePTZCustomData},
  loadPreset: {[key in 1 | 2 | 3]: MeetingSourceDevicePTZCustomData},
  setPreset: {[key in 1 | 2 | 3]: MeetingSourceDevicePTZCustomData},
  scene: {[key in 1 | 2 | 3 | 4]: MeetingSourceDevicePTZCustomData},
};

export interface MeetingSourceDevicePTZ<T extends MeetingSourceDevicePTZMode = any> {
  mode: T,
  data: MeetingSourceDevicePTZDataMap[T],
  custom?: MeetingSourceDevicePTZCustom;
}

export type MeetingSourceDeviceMode = "audiovideo" | "audio" | "video";
export enum MeetingSourceDeviceType {
  OVENMEDIA = "ovenmedia",
  WHITEBOARD = "whiteboard",
  KAST = "kast",
  WEB = "web",
  UNKNOWN = "unknown"
};
export const isMeetingSourceDevice = <T extends MeetingSourceDeviceType>(source: MeetingSourceDevice<any>, type: T): source is MeetingSourceDevice<T> => {
  if (!source || !type) return false;
  return source.type === type;
}
export interface MeetingSourceDevice<T extends MeetingSourceDeviceType = any, M extends MeetingSourceDeviceMode = any> {
  mode: M;
  type: MeetingSourceDeviceType;
  disabled?: boolean;
  priority?: number;
  data: MeetingSourceDeviceDataMap[T];
  extra?: MeetingSourceDeviceExtraMap<T>[M];
}
export type MeetingSourcePlayerRef = KastPlayersRef | MediaCaptureRef | WhiteboardPlayerRef | OvenMediaPlayerRef;

export interface MeetingSourceDeviceDataMap {
  [MeetingSourceDeviceType.OVENMEDIA]: MeetingSourceDeviceOvenMedia;
  [MeetingSourceDeviceType.WHITEBOARD]: MeetingSourceDeviceWhiteboard;
  [MeetingSourceDeviceType.KAST]: MeetingSourceDeviceKast;
  [MeetingSourceDeviceType.WEB]: MeetingSourceDeviceWeb;
  [MeetingSourceDeviceType.UNKNOWN]: undefined;
}

export interface MeetingSourceDeviceExtraAudioMap {
  [MeetingSourceDeviceType.OVENMEDIA]: undefined;
  [MeetingSourceDeviceType.WHITEBOARD]: undefined;
  [MeetingSourceDeviceType.KAST]: undefined;
  [MeetingSourceDeviceType.WEB]: undefined;
  [MeetingSourceDeviceType.UNKNOWN]: undefined;
}

export interface MeetingSourceDeviceExtraVideoMap {
  [MeetingSourceDeviceType.OVENMEDIA]: { style?: any };
  [MeetingSourceDeviceType.WHITEBOARD]: { style?: any };
  [MeetingSourceDeviceType.KAST]: { style?: any };
  [MeetingSourceDeviceType.WEB]: { style?: any };
  [MeetingSourceDeviceType.UNKNOWN]: undefined;
}

export interface MeetingSourceDeviceExtraMap<T extends MeetingSourceDeviceType> {
  audiovideo: MeetingSourceDeviceExtraVideoMap[T] & MeetingSourceDeviceExtraAudioMap[T];
  video: MeetingSourceDeviceExtraVideoMap[T];
  audio: MeetingSourceDeviceExtraAudioMap[T];
}

export interface MeetingSourceDeviceKast {
  ip: string;
  port: number | undefined | null;
  defaultScene?: number | undefined | null;
  defaultView?: number | undefined | null;
  mediaConstraints?: MediaStreamConstraints;
}


export interface MeetingSourceDeviceOvenMedia {
  url: string;
  sdpFormatter?: string;
  ptz?: MeetingSourceDevicePTZ;
}

export interface MeetingSourceDeviceWhiteboard {
  ip: string;
  port: number | undefined | null;
  defaultVideoSource?: "screen" | string;
  defaultAudioSource?: "playback" | "microphone" | "mixed";
  defaultPreferVideoSource?: "screen" | string;
  defaultPreferAudioSource?: "playback" | "microphone" | "mixed";
  defaultAudioPlaybackVolume?: number | undefined | null;
  defaultAudioMicrophoneVolume?: number | undefined | null;
  mediaConstraints?: MediaStreamConstraints;
}

export type MeetingSourceDeviceWebChildrenDeviceTypes = MeetingSourceDeviceType.KAST | MeetingSourceDeviceType.WHITEBOARD | MeetingSourceDeviceType.OVENMEDIA;
export interface MeetingSourceDeviceWebChild<T extends MeetingSourceDeviceWebChildrenDeviceTypes> extends MeetingSourceDevice<T> {
  name: string;
}
export interface MeetingSourceDeviceWeb {
  disableShareScreen?: boolean;
  defaultVideoDevice?: Partial<MediaDeviceInfo>;
  defaultAudioDevice?: Partial<MediaDeviceInfo>;
  filterVideoDevices?: Partial<MediaDeviceInfo> | Partial<MediaDeviceInfo>[];
  filterAudioDevices?: Partial<MediaDeviceInfo> | Partial<MediaDeviceInfo>[];
  excludeVideoDevices?: Partial<MediaDeviceInfo> | Partial<MediaDeviceInfo>[];
  excludeAudioDevices?: Partial<MediaDeviceInfo> | Partial<MediaDeviceInfo>[];
  hideVideoDevices?: boolean;
  hideAudioDevices?: boolean;
  hideVideoDevicesIfNoSeveralDevices?: boolean;
  hideAudioDevicesIfNoSeveralDevices?: boolean;
  autoSetVideoDevices?: (Partial<MediaDeviceInfo> & { priority?: number })[];
  autoSetAudioDevices?: (Partial<MediaDeviceInfo> & { priority?: number })[];
  mediaConstraints?: MediaStreamConstraints;
  children?: MeetingSourceDeviceWebChild<MeetingSourceDeviceWebChildrenDeviceTypes>[];
  ptz?: MeetingSourceDevicePTZ;
}

export interface MeetingSource {
  name?: string;
  disabled?: boolean;
  hideIfNoMedia?: boolean;
  onSelect?: {
    displayOnWhiteboardOptions?: Partial<DisplayOnWhiteboardOptions>;
  };
  priority?: number;
  devices: MeetingSourceDevice<any>[];
}

export enum ErrorStatus {
  NO_TRACK = "no_track",
  PERMISSION_DENIED = "permission_denied",
}

export interface MeetingSourceData {
  source: MeetingSource;
  using: boolean;
  hidden: boolean;
  video: {
    device?: MeetingSourceDevice<any, "audiovideo" | "video">;
    player?: MeetingSourcePlayerRef;
    error?: ErrorStatus;
    label?: string;
  };
  audio: {
    device?: MeetingSourceDevice<any, "audiovideo" | "audio">;
    player?: MeetingSourcePlayerRef;
    error?: ErrorStatus;
    label?: string;
  };
  media?: MediaStream;
}

export const isSourceDevice = <T extends MeetingSourceDeviceType, M extends MeetingSourceDeviceMode>(
  device: MeetingSourceDevice | undefined,
  type: T,
  mode: M
): device is MeetingSourceDevice<T, M> => {
  if (!device) return false;
  return device.type === type && device.mode === mode;
};

export const isSourceDeviceType = <T extends MeetingSourceDeviceType>(device: MeetingSourceDevice | undefined, type: T): device is MeetingSourceDevice<T> => {
  if (!device) return false;
  return device.type === type;
};

export const isSourceDeviceMode = <M extends MeetingSourceDeviceMode>(
  device: MeetingSourceDevice | undefined,
  mode: M
): device is MeetingSourceDevice<any, M> => {
  if (!device) return false;
  return device.mode === mode;
};

export type VirtualMediaDeviceInfoKast = VirtualMediaDeviceInfo<{
  device: MeetingSourceDevice<MeetingSourceDeviceType.KAST>;
  type: MeetingSourceDeviceType.KAST;
}>;
export type VirtualMediaDeviceInfoOvenMedia = VirtualMediaDeviceInfo<{
  device: MeetingSourceDevice<MeetingSourceDeviceType.OVENMEDIA>;
  type: MeetingSourceDeviceType.OVENMEDIA;
}>;
export type VirtualMediaDeviceInfoWhiteboard = VirtualMediaDeviceInfo<{
  device: MeetingSourceDevice<MeetingSourceDeviceType.WHITEBOARD>;
  type: MeetingSourceDeviceType.WHITEBOARD;
}>;


export const isPTZMode = <M extends MeetingSourceDevicePTZMode>(
  ptz: MeetingSourceDevicePTZ,
  mode: M
): ptz is MeetingSourceDevicePTZ<M> => {
  if (!ptz) return false;
  return ptz.mode === mode;
}

export interface MeetingSourceElementProps {
  source: MeetingSource;
  using: boolean;
  displayVideoStats?: boolean;
  onHide?: (data: MeetingSourceData) => void;
  onUpdate?: (data: MeetingSourceData) => void;
  onSelect?: (data: MeetingSourceData) => void;
  onMedia?: (track: MediaStreamTrack | undefined, data: MeetingSourceData) => void;
  onPlayer?: <T extends "audio" | "video">(type: T, playerRef: MeetingSourceData[T]["player"], source: MeetingSourceData) => void;
  onDestroy?: (data?: MeetingSourceData) => void;
  className?: string;
  style?: React.CSSProperties;
}

export interface MeetingSourceElementRef {
  getData: () => MeetingSourceData;
}

const filterDevices = (devices?: MeetingSourceDevice[]): MeetingSourceDevice[] => {
  if (!devices) return [];
  return [...devices].filter((d) => !d.disabled).sort((d1, d2) => (d2.priority ?? 0) - (d1.priority ?? 0));
};

const toArrayProps = function <T>(data: T | T[] | undefined): T[] | undefined {
  if (!data) return undefined;
  if (Array.isArray(data)) return data;
  return [data];
};

export const ERROR_MESSAGE: { [key in 'audio'| 'video']: { [key in ErrorStatus]: string }} = {
  video: {
    [ErrorStatus.NO_TRACK]: "Impossible de récupérer le flux vidéo.",
    [ErrorStatus.PERMISSION_DENIED]: "Veuillez autoriser l'accès à la caméra",
  },
  audio: {
    [ErrorStatus.NO_TRACK]: "Impossible de récupérer le flux audio.",
    [ErrorStatus.PERMISSION_DENIED]: "Veuillez autoriser l'accès au micro",
  }
};

const MeetingSourceElement = React.forwardRef(
  (
    { source, using, displayVideoStats, onHide, onUpdate, onSelect, onMedia, onPlayer, onDestroy, className, style }: MeetingSourceElementProps,
    forwardRef: ForwardedRef<MeetingSourceElementRef | undefined>
  ) => {
    const [videoDevice, setVideoDevice] = useState<MeetingSourceDevice>();
    const [audioDevice, setAudioDevice] = useState<MeetingSourceDevice>();
    const [hide, setHide] = useState<boolean>(source.hideIfNoMedia ?? false);
    const [videoErrorStatus, setVideoErrorStatus, videoErrorStatusRef] = useStateWithRef<ErrorStatus | undefined>();
    const [audioErrorStatus, setAudioErrorStatus, audioErrorStatusRef] = useStateWithRef<ErrorStatus | undefined>();
    const videoErrorStatusDelayRef = useRef<NodeJS.Timeout>();
    const audioErrorStatusDelayRef = useRef<NodeJS.Timeout>();
    const videoPlayerRef = useRef<MeetingSourcePlayerRef | undefined>();
    const audioPlayerRef = useRef<MeetingSourcePlayerRef | undefined>();
    const headerRef = useRef<HTMLDivElement>(null);
    const mediaRef = useRef<MediaStream>(new MediaStream());
    const videoTrackRef = useRef<MediaStreamTrack>();
    const audioTrackRef = useRef<MediaStreamTrack>();
    const onDestroyRef = useRef(onDestroy);
    const dataRef = useRef<MeetingSourceData>({
      source,
      using,
      hidden: hide,
      video: {},
      audio: {},
      media: mediaRef.current,
    });
    const sameDeviceForAudioAndVideoRef = useRef(false);

    useImperativeHandle(forwardRef, () => ({
      getData: () => dataRef.current,
    }));

    const assignErrorAfterDelay = (mediaType: "video" | "audio", error: ErrorStatus | undefined, delay = 3000) => {
      let errorStatusDelayRef: React.MutableRefObject<NodeJS.Timeout | undefined> | undefined;
      let setErrorStatus: React.Dispatch<React.SetStateAction<ErrorStatus | undefined>> | undefined;
      if (mediaType === "video") {
        errorStatusDelayRef = videoErrorStatusDelayRef;
        setErrorStatus = setVideoErrorStatus;
      } else if (mediaType === "audio") {
        errorStatusDelayRef = audioErrorStatusDelayRef;
        setErrorStatus = setAudioErrorStatus;
      }

      if (!errorStatusDelayRef || !setErrorStatus) return;

      if (errorStatusDelayRef.current) clearTimeout(errorStatusDelayRef.current);
      if (error) {
        errorStatusDelayRef.current = setTimeout(() => {
          if (!setErrorStatus) return;
          setErrorStatus(error);
        }, delay);
      } else {
        setErrorStatus(error);
      }
    };

    // ----------------------------------------------- //
    // ------------------ EVENTS -------------------- //
    // ----------------------------------------------- //

    const triggerOnMedia = useCallback(
      (track: MediaStreamTrack | undefined) => {
        if (onMedia) onMedia(track, dataRef.current);
      },
      [onMedia]
    );

    const triggerOnPlayerRef = useCallback(
      <T extends "audio" | "video">(type: T, playerRef: MeetingSourceData[T]["player"]) => {
        if (onPlayer) onPlayer(type, playerRef, dataRef.current);
      },
      [onPlayer]
    );

    // ----------------------------------------------- //
    // ------------------ UTILS -------------------- //
    // ----------------------------------------------- //

    const getVideoDeviceOfCurrentMedia = () => dataRef.current.video.device;
    const getAudioDeviceOfCurrentMedia = () => dataRef.current.audio.device;

    const assignVideoTrack = async (media?: MediaStream, callback?: (error?: ErrorStatus) => void) => {
      let track = media ? getVideoTrack(media) : undefined;

      // DEBUG
      /*
      if (track) {
        const trackProcessor = new MediaStreamTrackProcessor({ track: track });
        const trackGenerator = new MediaStreamTrackGenerator({ kind: "video" });

        const videoEncoder = new VideoEncoder({
          output(chunk: any, metadata:any) {
            console.log('chunk T : ', chunk.timestamp);
            // console.log('chunk B : ', chunk.byteLength);
            // console.log('chunk M : ',JSON.stringify(metadata));
          },
          error(error: any) {
            console.log('chunk - error: ',error);
          },
        });
        try {
          videoEncoder.configure({
            codec: "avc1.42e01f",
            width: 176,
            height: 144,
            bitrate: 1_000_000, // 2 Mbps
            framerate: 30,
          })
        } catch(error : any) {
          console.log('Impossible to configure : ', error);
        }

        let frameNum = 0;
        let counter = 0;
        const start = new Date().getTime();
        let lastTS = 0;
        const transformer = new TransformStream({
          async transform(videoFrame, controller) {
            const time = (new Date().getTime() - start)/1000.0;
            const field = ["codedHeight","codedRect","codedWidth","colorSpace","displayHeight","displayWidth","duration","format","timestamp","visibleRect"];
            const oldVF: any = {};
            field.forEach((f) => oldVF[f] = videoFrame[f]);
            console.log('FRAME n°', counter, ' - ', time, ' - FPS : ',  counter / time,' - ', videoFrame.timestamp - lastTS ,' : ', oldVF)
            lastTS = videoFrame.timestamp;
            // videoEncoder.encode(videoFrame, { keyFrame: frameNum === 0});
            controller.enqueue(videoFrame);

            counter ++;
            frameNum = (frameNum + 1 ) % 30;
          },
        });
        trackProcessor.readable.pipeThrough(transformer).pipeTo(trackGenerator.writable);
        track = trackGenerator;
      }
      */

      if (!videoTrackRef.current || track?.id !== videoTrackRef.current?.id) {
        if (track) setVideoTrack(mediaRef.current, track);
        else removeAllVideoTracks(mediaRef.current);
        videoTrackRef.current = track;
        await applyConstraints();
        triggerOnMedia(track);
        updateHideValue();
        callback?.(track ?  undefined : ErrorStatus.NO_TRACK);
      } else {
        callback?.(undefined);
      }
      
    };

    const assignAudioTrack = async (media?: MediaStream, callback?: (error?: ErrorStatus) => void) => {
      const track = media ? getAudioTrack(media) : undefined;
      if (!audioTrackRef.current || track?.id !== audioTrackRef.current?.id) {
        if (track) setAudioTrack(mediaRef.current, track);
        else removeAllAudioTracks(mediaRef.current);
        audioTrackRef.current = track;
        await applyConstraints();
        triggerOnMedia(track);
        updateHideValue();
        callback?.(track ?  undefined : ErrorStatus.NO_TRACK);
      } else {
        callback?.(undefined);
      }
    };

    const updatePlayerRef = (type: "audio" | "video") => (device: MeetingSourceDevice, ref: MeetingSourcePlayerRef | undefined | null) => {
      if (type === "video" && ref !== videoPlayerRef.current && device === getVideoDeviceOfCurrentMedia()) {
        videoPlayerRef.current = ref ?? undefined;
        dataRef.current.video.player = videoPlayerRef.current;
        triggerOnPlayerRef(type, ref ?? undefined);
      } else if (type === "audio" && ref !== audioPlayerRef.current && device === getAudioDeviceOfCurrentMedia()) {
        audioPlayerRef.current = ref ?? undefined;
        dataRef.current.audio.player = audioPlayerRef.current;
        triggerOnPlayerRef(type, ref ?? undefined);
      }
    };

    const applyConstraints = async () => {
      if (!mediaRef.current) return;
      const options: ApplyMediaConstraintsOptions = {};
      const currentVideoDevice = getVideoDeviceOfCurrentMedia();
      const currentAudioDevice = getAudioDeviceOfCurrentMedia();
      // VIDEO
      if (isSourceDeviceType(currentVideoDevice, MeetingSourceDeviceType.KAST)) {
        if (currentVideoDevice.data.mediaConstraints) {
          if (currentVideoDevice.data.mediaConstraints.video && typeof currentVideoDevice.data.mediaConstraints.video !== "boolean") {
            options.videoMediaConstrains = currentVideoDevice.data.mediaConstraints.video;
          }
        }
      } else if (isSourceDeviceType(currentVideoDevice, MeetingSourceDeviceType.WHITEBOARD)) {
        if (currentVideoDevice.data.mediaConstraints) {
          if (currentVideoDevice.data.mediaConstraints.video && typeof currentVideoDevice.data.mediaConstraints.video !== "boolean") {
            options.videoMediaConstrains = currentVideoDevice.data.mediaConstraints.video;
          }
        }
      }
      // AUDIO
      if (isSourceDeviceType(currentAudioDevice, MeetingSourceDeviceType.KAST)) {
        if (currentAudioDevice.data.mediaConstraints) {
          if (currentAudioDevice.data.mediaConstraints.audio && typeof currentAudioDevice.data.mediaConstraints.audio !== "boolean") {
            options.audioMediaConstrains = currentAudioDevice.data.mediaConstraints.audio;
          }
        }
      } else if (isSourceDeviceType(currentAudioDevice, MeetingSourceDeviceType.WHITEBOARD)) {
        if (currentAudioDevice.data.mediaConstraints) {
          if (currentAudioDevice.data.mediaConstraints.audio && typeof currentAudioDevice.data.mediaConstraints.audio !== "boolean") {
            options.audioMediaConstrains = currentAudioDevice.data.mediaConstraints.audio;
          }
        }
      }
      await applyMediaConstraints(mediaRef.current, options);
    };

    const updateHideValue = () => {
      const media = mediaRef.current;
      if (!source.hideIfNoMedia) {
        setHide(false);
        return;
      }
      if (!media) {
        setHide(true);
        return;
      }
      const videoTrack = getVideoTrack(media);
      if (!videoTrack) setHide(true);
      else setHide(false);
    };

    const usingInVideoMode = (device: MeetingSourceDevice): boolean => {
      return device.mode === "video" || device.mode === "audiovideo";
    };

    const usingInAudioMode = (device: MeetingSourceDevice): boolean => {
      return device.mode === "audio" || device.mode === "audiovideo";
    };

    // ----------------------------------------------- //
    // ------------------ EFFECTS -------------------- //
    // ----------------------------------------------- //

    useEffect(() => {
      let currVideoDevice: MeetingSourceDevice | undefined;
      let currAudioDevice: MeetingSourceDevice | undefined;
      let videoDeviceAssigned = false;
      let audioDeviceAssigned = false;
      let sameDeviceForAudioAndVideo = false;
      const devices = filterDevices(source?.devices);
      devices.forEach((d) => {
        let currVideoAssigned = false;
        let currAudioAssigned = false;
        if (!videoDeviceAssigned && usingInVideoMode(d)) {
          currVideoDevice = d;
          currVideoAssigned = true;
          videoDeviceAssigned = true;
        }
        if (!audioDeviceAssigned && usingInAudioMode(d)) {
          currAudioDevice = d;
          currAudioAssigned = true;
          audioDeviceAssigned = true;
        }
        if (currAudioAssigned && currVideoAssigned) {
          sameDeviceForAudioAndVideo = true;
        }
      });
      sameDeviceForAudioAndVideoRef.current = sameDeviceForAudioAndVideo;
      const mediaStream = new MediaStream();
      setVideoDevice(currVideoDevice);
      setAudioDevice(currAudioDevice);
      dataRef.current.source = source;
      dataRef.current.media = mediaStream;
      dataRef.current.video.device = currVideoDevice;
      dataRef.current.audio.device = currAudioDevice;
      dataRef.current.video.label = currVideoDevice?.type;
      dataRef.current.audio.label = currAudioDevice?.type;
      updateHideValue();

      // trigger at least one time at start
      onHide?.(dataRef.current);
      onUpdate?.(dataRef.current);

      setVideoErrorStatus(undefined);
      setAudioErrorStatus(undefined);

      mediaRef.current = mediaStream;
      return () => {
        destroyMediaStream(mediaStream);
      };
    }, [source]);

    useEffect(() => {
      let previous = dataRef.current.using;
      dataRef.current.using = using;
      if (using !== previous) onUpdate?.(dataRef.current);
    }, [using, onUpdate]);

    useEffect(() => {
      let previous = dataRef.current.hidden;
      dataRef.current.hidden = hide;
      if (hide !== previous) onHide?.(dataRef.current);
    }, [hide, onHide]);

    useEffect(() => {
      dataRef.current.video.error = videoErrorStatus ?? undefined;
      onUpdate?.(dataRef.current);
    }, [videoErrorStatus]);

    useEffect(() => {
      dataRef.current.audio.error = audioErrorStatus ?? undefined;
      onUpdate?.(dataRef.current);
    }, [audioErrorStatus]);

    useEffect(() => {
      onDestroyRef.current = onDestroy;
    }, [onDestroy]);

    useEffect(() => {
      return () => {
        onDestroyRef.current?.(dataRef.current);
      };
    }, []);

    // ----------------------------------------------- //
    // ------------------ RENDER -------------------- //
    // ----------------------------------------------- //

    const renderKast = (
      device: MeetingSourceDevice<MeetingSourceDeviceType.KAST>,
      display = true,
      webDependanceRef?: React.RefObject<MediaCaptureRef | undefined>,
      name?: string
    ) => {
      const video = usingInVideoMode(device);
      const audio = usingInAudioMode(device);
      const currClasses = [styles.player, styles.playerKast];
      if (!sameDeviceForAudioAndVideoRef.current && audioDevice) {
        if (audioDevice.type === MeetingSourceDeviceType.WEB && !audioDevice.data.hideAudioDevices) {
          currClasses.push(styles.borderTopOnly);
        }
      }
      const videoStatsRef = createRef<VideoStatsRef>();
      const ref: React.MutableRefObject<KastPlayersRef | undefined> = { current: undefined };
      const refMethod = (r: KastPlayersRef | null | undefined) => {
        ref.current = r ?? undefined;
        if (video) updatePlayerRef("video")(device, r);
        else if (audio) updatePlayerRef("audio")(device, r);
      };
      return (
        <>
          <KastPlayer
            ref={refMethod}
            key={name}
            className={currClasses.join(" ")}
            display={display}
            ip={device.data.ip}
            port={device.data.port ?? undefined}
            defaultScene={video ? device.data.defaultScene ?? undefined : undefined}
            defaultView={video ? device.data.defaultView ?? undefined : undefined}
            onMedia={(m) => {
              if (videoStatsRef.current) videoStatsRef.current.setMedia(m);
              if (webDependanceRef) {
                if (webDependanceRef.current) {
                  const deviceName = name ?? "Kast";
                  webDependanceRef.current.removeVirtualDevices({ label: deviceName });
                  if (m && video) {
                    const track = getVideoTrack(m);
                    if (track) {
                      const virtualDevice: VirtualMediaDeviceInfoKast = webDependanceRef.current.addVirtualDevice(deviceName, track);
                      virtualDevice.data = {
                        device,
                        type: MeetingSourceDeviceType.KAST,
                      };
                    }
                  }
                  if (m && audio) {
                    const track = getAudioTrack(m);
                    if (track) {
                      const virtualDevice: VirtualMediaDeviceInfoKast = webDependanceRef.current.addVirtualDevice(deviceName, track);
                      virtualDevice.data = {
                        device,
                        type: MeetingSourceDeviceType.KAST,
                      };
                    }
                  }
                }
              } else {
                if (video) {
                  assignVideoTrack(m, (error) => {
                    if (error) {
                      if (videoErrorStatusRef.current !== ErrorStatus.PERMISSION_DENIED) assignErrorAfterDelay('video', error);
                    } else {
                      assignErrorAfterDelay('video', undefined);  
                    }
                  });
                }
                if (audio) {
                  assignAudioTrack(m, (error) => {
                    if (error) {
                      if (audioErrorStatusRef.current !== ErrorStatus.PERMISSION_DENIED) assignErrorAfterDelay('audio', error);
                    } else {
                      assignErrorAfterDelay('audio', undefined);  
                    }
                  });
                }
              }
            }}
          />
          {displayVideoStats && video ? <VideoStats ref={videoStatsRef} mode={"output"} media={mediaRef.current} className={styles.videoStats} /> : null}
        </>
      );
    };

    const renderOvenMedia = (
      device: MeetingSourceDevice<MeetingSourceDeviceType.OVENMEDIA>,
      display = true,
      webDependanceRef?: React.RefObject<MediaCaptureRef | undefined>,
      name?: string
    ) => {
      const video = usingInVideoMode(device);
      const audio = usingInAudioMode(device);
      const currClasses = [styles.player, styles.playerOvenMedia];
      if (!sameDeviceForAudioAndVideoRef.current && audioDevice) {
        if (audioDevice.type === MeetingSourceDeviceType.WEB && !audioDevice.data.hideAudioDevices) {
          currClasses.push(styles.borderTopOnly);
        }
      }
      const videoStatsRef = createRef<VideoStatsRef>();
      const ref: React.MutableRefObject<OvenMediaPlayerRef | undefined> = { current: undefined };
      const refMethod = (r: OvenMediaPlayerRef | null | undefined) => {
        ref.current = r ?? undefined;
        if (video) updatePlayerRef("video")(device, r);
        else if (audio) updatePlayerRef("audio")(device, r);
      };
      return (
        <>
          <OvenMediaPlayer
            ref={refMethod}
            url={device.data.url}
            key={name}
            className={currClasses.join(" ")}
            display={display}
            onMedia={(m) => {
              if (videoStatsRef.current) videoStatsRef.current.setMedia(m);
              if (webDependanceRef) {
                if (webDependanceRef.current) {
                  const deviceName = name ?? "OvenMedia";
                  webDependanceRef.current.removeVirtualDevices({ label: deviceName });
                  if (m && video) {
                    const track = getVideoTrack(m);
                    if (track) {
                      const virtualDevice: VirtualMediaDeviceInfoOvenMedia = webDependanceRef.current.addVirtualDevice(deviceName, track);
                      virtualDevice.data = {
                        device,
                        type: MeetingSourceDeviceType.OVENMEDIA,
                      };
                    }
                  }
                  if (m && audio) {
                    const track = getAudioTrack(m);
                    if (track) {
                      const virtualDevice: VirtualMediaDeviceInfoOvenMedia = webDependanceRef.current.addVirtualDevice(deviceName, track);
                      virtualDevice.data = {
                        device,
                        type: MeetingSourceDeviceType.OVENMEDIA,
                      };
                    }
                  }
                }
              } else {
                if (video) {
                  assignVideoTrack(m, (error) => {
                    if (error) {
                      if (videoErrorStatusRef.current !== ErrorStatus.PERMISSION_DENIED) assignErrorAfterDelay('video', error);
                    } else {
                      assignErrorAfterDelay('video', undefined);  
                    }
                  });
                }
                if (audio) {
                  assignAudioTrack(m, (error) => {
                    if (error) {
                      if (audioErrorStatusRef.current !== ErrorStatus.PERMISSION_DENIED) assignErrorAfterDelay('audio', error);
                    } else {
                      assignErrorAfterDelay('audio', undefined);  
                    }
                  });
                }
              }
            }}
          />
          {displayVideoStats && video ? <VideoStats ref={videoStatsRef} mode={"output"} media={mediaRef.current} className={styles.videoStats} /> : null}
        </>
      );
    };

    const renderWhiteboard = (
      device: MeetingSourceDevice<MeetingSourceDeviceType.WHITEBOARD>,
      display = true,
      webDependanceRef?: React.RefObject<MediaCaptureRef | undefined>,
      name?: string
    ) => {
      const video = usingInVideoMode(device);
      const audio = usingInAudioMode(device);
      const currClasses = [styles.player, styles.playerWhiteboard];
      if (!sameDeviceForAudioAndVideoRef.current && audioDevice) {
        if (audioDevice.type === MeetingSourceDeviceType.WEB && !audioDevice.data.hideAudioDevices) {
          currClasses.push(styles.borderTopOnly);
        }
      }
      const videoStatsRef = createRef<VideoStatsRef>();
      const ref: React.MutableRefObject<WhiteboardPlayerRef | undefined> = { current: undefined };
      const refMethod = (r: WhiteboardPlayerRef | null | undefined) => {
        ref.current = r ?? undefined;
        if (video) updatePlayerRef("video")(device, r);
        else if (audio) updatePlayerRef("audio")(device, r);
      };
      return (
        <>
          <WhiteboardPlayer
            ref={refMethod}
            key={name}
            className={currClasses.join(" ")}
            display={display}
            ip={device.data.ip}
            port={device.data.port ?? undefined}
            defaultVideoSource={video ? device.data.defaultVideoSource ?? undefined : undefined}
            defaultAudioSource={video ? device.data.defaultAudioSource ?? undefined : undefined}
            defaultPreferVideoSource={video ? device.data.defaultPreferVideoSource ?? undefined : undefined}
            defaultPreferAudioSource={video ? device.data.defaultPreferAudioSource ?? undefined : undefined}
            defaultAudioPlaybackVolume={audio ? device.data.defaultAudioPlaybackVolume ?? undefined : undefined}
            defaultAudioMicrophoneVolume={audio ? device.data.defaultAudioMicrophoneVolume ?? undefined : undefined}
            onMedia={(m) => {
              if (videoStatsRef.current) videoStatsRef.current.setMedia(m);
              if (webDependanceRef) {
                if (webDependanceRef.current) {
                  const deviceName = name ?? "Whiteboard";
                  webDependanceRef.current.removeVirtualDevices({ label: deviceName });
                  if (m && video) {
                    const track = getVideoTrack(m);
                    if (track) {
                      const virtualDevice: VirtualMediaDeviceInfoWhiteboard = webDependanceRef.current.addVirtualDevice(deviceName, track);
                      virtualDevice.data = {
                        device,
                        type: MeetingSourceDeviceType.WHITEBOARD,
                      };
                    }
                  }
                  if (m && audio) {
                    const track = getAudioTrack(m);
                    if (track) {
                      const virtualDevice: VirtualMediaDeviceInfoWhiteboard = webDependanceRef.current.addVirtualDevice(deviceName, track);
                      virtualDevice.data = {
                        device,
                        type: MeetingSourceDeviceType.WHITEBOARD,
                      };
                    }
                  }
                }
              } else {
                if (video) {
                  assignVideoTrack(m, (error) => {
                    if (error) {
                      if (videoErrorStatusRef.current !== ErrorStatus.PERMISSION_DENIED) assignErrorAfterDelay('video', error);
                    } else {
                      assignErrorAfterDelay('video', undefined);  
                    }
                  });
                }
                if (audio) {
                  assignAudioTrack(m, (error) => {
                    if (error) {
                      if (audioErrorStatusRef.current !== ErrorStatus.PERMISSION_DENIED) assignErrorAfterDelay('audio', error);
                    } else {
                      assignErrorAfterDelay('audio', undefined);  
                    }
                  });
                }
              }
            }}
          />
          {displayVideoStats && video ? <VideoStats ref={videoStatsRef} mode={"output"} media={mediaRef.current} className={styles.videoStats} /> : null}
        </>
      );
    };

    const renderWeb = (device: MeetingSourceDevice<MeetingSourceDeviceType.WEB>) => {
      const video = usingInVideoMode(device);
      const audio = usingInAudioMode(device);
      const currClasses = [styles.player];
      const videoStatsRef = createRef<VideoStatsRef>();
      const ref: React.MutableRefObject<MediaCaptureRef | undefined> = { current: undefined };

      const updateCurrVideoDevice = () => {
        if (!video) return;
        // set default
        dataRef.current.video.device = device;
        dataRef.current.video.player = ref?.current;

        const currentWebDevice = ref.current?.getVideoDevice();
        dataRef.current.video.label = currentWebDevice?.label;
        if (isVirtualMediaDeviceInfo(currentWebDevice) && currentWebDevice.data?.type === MeetingSourceDeviceType.KAST) {
          const kastDevice: VirtualMediaDeviceInfoKast = currentWebDevice;
          dataRef.current.video.device = kastDevice.data?.device;
        } else if (isVirtualMediaDeviceInfo(currentWebDevice) && currentWebDevice.data?.type === MeetingSourceDeviceType.WHITEBOARD) {
          const whiteboardDevice: VirtualMediaDeviceInfoWhiteboard = currentWebDevice;
          dataRef.current.video.device = whiteboardDevice.data?.device;
        } else if (isVirtualMediaDeviceInfo(currentWebDevice) && currentWebDevice.data?.type === MeetingSourceDeviceType.OVENMEDIA) {
          const ovenMediaDevice: VirtualMediaDeviceInfoOvenMedia = currentWebDevice;
          dataRef.current.video.device = ovenMediaDevice.data?.device;
        }
      };

      const updateCurrAudioDevice = () => {
        if (!audio) return;
        // set default
        dataRef.current.audio.device = device;
        dataRef.current.audio.player = ref?.current;

        const currentWebDevice = ref.current?.getAudioDevice();
        dataRef.current.audio.label = currentWebDevice?.label;
        if (isVirtualMediaDeviceInfo(currentWebDevice) && currentWebDevice.data?.type === MeetingSourceDeviceType.KAST) {
          const kastDevice: VirtualMediaDeviceInfoKast = currentWebDevice;
          dataRef.current.audio.device = kastDevice.data?.device;
        } else if (isVirtualMediaDeviceInfo(currentWebDevice) && currentWebDevice.data?.type === MeetingSourceDeviceType.WHITEBOARD) {
          const whiteboardDevice: VirtualMediaDeviceInfoWhiteboard = currentWebDevice;
          dataRef.current.audio.device = whiteboardDevice.data?.device;
        } else if (isVirtualMediaDeviceInfo(currentWebDevice) && currentWebDevice.data?.type === MeetingSourceDeviceType.OVENMEDIA) {
          const ovenMediaDevice: VirtualMediaDeviceInfoOvenMedia = currentWebDevice;
          dataRef.current.audio.device = ovenMediaDevice.data?.device;
        }
      };

      const refMethod = (r: MediaCaptureRef | null | undefined) => {
        ref.current = r ?? undefined;
        updateCurrVideoDevice();
        updateCurrAudioDevice();
        if (video) updatePlayerRef("video")(device, r);
        else if (audio) updatePlayerRef("audio")(device, r);
      };
      const renderChildren = () => {
        const children = device.data.children?.filter((d) => !d.disabled);
        if (!children?.length) return null;
        return children.map((c, i) => {
          if (isMeetingSourceDevice(c, MeetingSourceDeviceType.KAST)) {
            const name = c.name ?? `Kast - ${i + 1}`;
            return (
              <div key={name} style={{ display: "none" }}>
                {renderKast(c, true, ref, name)}
              </div>
            );
          } else if (isMeetingSourceDevice(c, MeetingSourceDeviceType.WHITEBOARD)) {
            const name = c.name ?? `Whiteboard - ${i + 1}`;
            return (
              <div key={name} style={{ display: "none" }}>
                {renderWhiteboard(c, true, ref, name)}
              </div>
            );
          } else if (isMeetingSourceDevice(c, MeetingSourceDeviceType.OVENMEDIA)) {
            const name = c.name ?? `Video - ${i + 1}`;
            return (
              <div key={name} style={{ display: "none" }}>
                {renderOvenMedia(c, true, ref, name)}
              </div>
            );
          }
          return null;
        });
      };

      if (video) currClasses.push(styles.playerWeb);
      else currClasses.push(styles.borderBottomOnly);
      return (
        <>
          <MediaCapture
            ref={refMethod}
            className={currClasses.join(" ")}
            disableVideo={!video}
            disableAudio={!audio}
            displayVideo={video}
            displayScreenSharingButton={video ? !device.data.disableShareScreen : false}
            displayVideoButton={false}
            displayAudioButton={false}
            displayVideoDeviceList={video && !device.data.hideVideoDevices}
            displayAudioDeviceList={audio && !device.data.hideAudioDevices}
            displayVideoDeviceListOnlyIfSeveralDevices={device.data.hideVideoDevicesIfNoSeveralDevices}
            displayAudioDeviceListOnlyIfSeveralDevices={device.data.hideAudioDevicesIfNoSeveralDevices}
            defaultSelectVideoDevice={device.data.defaultVideoDevice}
            defaultSelectAudioDevice={device.data.defaultAudioDevice}
            filterVideoDevices={toArrayProps(device.data.filterVideoDevices)}
            filterAudioDevices={toArrayProps(device.data.filterAudioDevices)}
            excludeVideoDevices={toArrayProps(device.data.excludeVideoDevices)}
            excludeAudioDevices={toArrayProps(device.data.excludeAudioDevices)}
            autoSetVideoDevices={device.data.autoSetVideoDevices}
            autoSetAudioDevices={device.data.autoSetAudioDevices}
            defaultMediaConstraint={device.data.mediaConstraints}
            onVideoDevice={(curr) => {
              if(source.hideIfNoMedia && !curr) {
                setHide(true);
              }
              updateCurrVideoDevice();
              onUpdate?.(dataRef.current);
            }}
            onAudioDevice={(curr) => {
              updateCurrAudioDevice();
              onUpdate?.(dataRef.current);
            }}
            onMediaCaptureError={(mediaType, error) => {
              logger.log('onMediaCaptureError - ' + mediaType + ' : ', error, '\nSource: ', source);
              if (video && mediaType === "video") assignErrorAfterDelay("video", ErrorStatus.PERMISSION_DENIED, 0);
              if (audio && mediaType === "audio") assignErrorAfterDelay("audio", ErrorStatus.PERMISSION_DENIED, 0);
            }}
            onMedia={(m) => {
              if (videoStatsRef.current) videoStatsRef.current.setMedia(m);
              if (video) {
                assignVideoTrack(m, (error) => {
                  if (error) {
                    if (videoErrorStatusRef.current !== ErrorStatus.PERMISSION_DENIED) assignErrorAfterDelay('video', error);
                  } else {
                    assignErrorAfterDelay('video', undefined);  
                  }
                });
              }
              if (audio) {
                assignAudioTrack(m, (error) => {
                  if (error) {
                    if (audioErrorStatusRef.current !== ErrorStatus.PERMISSION_DENIED) assignErrorAfterDelay('audio', error);
                  } else {
                    assignErrorAfterDelay('audio', undefined);  
                  }
                });
              }
            }}
          />
          {displayVideoStats && video ? <VideoStats ref={videoStatsRef} mode={"output"} media={mediaRef.current} className={styles.videoStats} /> : null}
          {renderChildren()}
        </>
      );
    };

    // ----------------------------------- VIDEO ------------------------ //

    const renderVideoKast = (device: MeetingSourceDevice<MeetingSourceDeviceType.KAST>, display = true) => {
      return renderKast(device, display);
    };

    const renderVideoOvenMedia = (device: MeetingSourceDevice<MeetingSourceDeviceType.OVENMEDIA>, display = true) => {
      return renderOvenMedia(device, display);
    };

    const renderVideoWhiteboard = (device: MeetingSourceDevice<MeetingSourceDeviceType.WHITEBOARD>, display = true) => {
      return renderWhiteboard(device, display);
    };

    const renderVideoWeb = (device: MeetingSourceDevice<MeetingSourceDeviceType.WEB>) => {
      return renderWeb(device);
    };

    const renderVideo = () => {
      if (!videoDevice) return null;
      if (isSourceDeviceType(videoDevice, MeetingSourceDeviceType.OVENMEDIA)) return renderVideoOvenMedia(videoDevice);
      if (isSourceDeviceType(videoDevice, MeetingSourceDeviceType.WHITEBOARD)) return renderVideoWhiteboard(videoDevice);
      if (isSourceDeviceType(videoDevice, MeetingSourceDeviceType.KAST)) return renderVideoKast(videoDevice);
      if (isSourceDeviceType(videoDevice, MeetingSourceDeviceType.WEB)) return renderVideoWeb(videoDevice);
      return null;
    };

    // ----------------------------------- AUDIO ------------------------ //

    const renderAudioKast = (device: MeetingSourceDevice<MeetingSourceDeviceType.KAST>, display = false) => {
      return renderKast(device, display);
    };

    const renderAudioOvenMedia = (device: MeetingSourceDevice<MeetingSourceDeviceType.OVENMEDIA>, display = false) => {
      return renderOvenMedia(device, display);
    };

    const renderAudioWhiteboard = (device: MeetingSourceDevice<MeetingSourceDeviceType.WHITEBOARD>, display = false) => {
      return renderWhiteboard(device, display);
    };

    const renderAudioWeb = (device: MeetingSourceDevice<MeetingSourceDeviceType.WEB>) => {
      return renderWeb(device);
    };

    const renderAudio = () => {
      if (!audioDevice) return null;
      if (sameDeviceForAudioAndVideoRef.current && videoDevice) {
        if (videoDevice.type === MeetingSourceDeviceType.WHITEBOARD) return null;
        if (videoDevice.type === MeetingSourceDeviceType.KAST) return null;
        if (videoDevice.type === MeetingSourceDeviceType.WEB) return null;
      }
      if (isSourceDeviceType(audioDevice, MeetingSourceDeviceType.OVENMEDIA)) return renderAudioOvenMedia(audioDevice);
      if (isSourceDeviceType(audioDevice, MeetingSourceDeviceType.WHITEBOARD)) return renderAudioWhiteboard(audioDevice);
      if (isSourceDeviceType(audioDevice, MeetingSourceDeviceType.KAST)) return renderAudioKast(audioDevice);
      if (isSourceDeviceType(audioDevice, MeetingSourceDeviceType.WEB)) return renderAudioWeb(audioDevice);
      return null;
    };

    const renderContent = () => {
      const video = renderVideo();
      const audio = renderAudio();
      if (!video) return null;
      return (
        <>
          {video}
          {audio}
        </>
      );
    };

    const renderError = () => {
      if (!videoErrorStatus && !audioErrorStatus) return null;
      const errorRef = createRef<HTMLDivElement>();
      const size = 20;
      let message = '';
      if (videoErrorStatus) message += (message.length ? '\n': '') + ERROR_MESSAGE.video[videoErrorStatus];
      if (audioErrorStatus) message += (message.length ? '\n': '') + ERROR_MESSAGE.audio[audioErrorStatus];
      return (
        <div ref={errorRef} className={styles.error}>
          <IconError width={size} height={size} />
          <Hover targetRef={errorRef}>
            <div className={styles.errorHover}>
              {message}
            </div>
          </Hover>
        </div>
      );
    };

    const classes: string[] = [styles.container];
    if (className) classes.push(className);
    if (hide) classes.push(styles.hide);

    const content = renderContent();
    if (using) classes.push(styles.using);
    if (!content) return null;
    return (
      <div className={classes.join(" ")} style={style}>
        <div ref={headerRef} className={styles.header}>
          {source.name}
        </div>
        <HoverMessage
          targetRef={headerRef}
          message={sameDeviceForAudioAndVideoRef.current ? `Device : ${videoDevice?.type}` : `Video: ${videoDevice?.type} - Audio: ${audioDevice?.type}`}
        />
        <div className={styles.content}>
          {content}
          {renderError()}
        </div>
        {!using ? (
          <div className={styles.footer} onClick={() => onSelect?.(dataRef.current)}>
            {"Sélectionner" /* TRANSLATION */}
          </div>
        ) : null}
      </div>
    );
  }
);

MeetingSourceElement.defaultProps = {
  className: undefined,
  style: undefined,
};

export default MeetingSourceElement;

import React, { useEffect } from "react";
import { Route, RouteProps, useParams } from "react-router-dom";
import { logger } from "../../helpers/logger";
import { SessionMode } from "../../store/session/slices";
import { PageMap, PAGE_PATHS, usePageContext } from "./PageContext";

export interface PageElementProps<M extends SessionMode, T extends PageMap[M]>{
  mode?: M;
  page?: T;
  element: React.ReactNode;
}

export const generatePage = <M extends SessionMode, T extends PageMap[M]>(mode: M, page: T, element: React.ReactNode, routeProps?: RouteProps) => {
  return <Route {...routeProps} path={(PAGE_PATHS[mode] as any)[page]} element={<PageElement mode={mode} page={page} element={element} />} />;
}

const PageElement = <M extends SessionMode, T extends PageMap[M]>({ mode, page, element}: PageElementProps<M, T>) => {
  const pageContext = usePageContext();
  const params = useParams();
  pageContext.mode = mode;
  pageContext.page = page;
  pageContext.params = params;

  useEffect(() => {
    logger.log(`[PAGE:${page?.toUpperCase()}] - enter: `, window.location.href);
    return () => {
      logger.log(`[PAGE:${page?.toUpperCase()}] - leave: `, window.location.href);
    };
  }, []);

  return <>{element}</>;
};

export default PageElement;


